exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Avatar-imgBackground-3Fu2o {\n\tbackground-color: #bbb;\n}", ""]);

// exports
exports.locals = {
	"imgBackground": "Avatar-imgBackground-3Fu2o"
};
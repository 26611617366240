import React from 'react';
// import AdminLayout from '../../../components/Layout/AdminLayout';
import SubAdminLayout from '../../../components/Layout/SubadminLayout';
import SiteSettings from './SiteSettings';
import { restrictUrls } from '../../../helpers/adminPrivileges';

const title = 'Site Settings';

export default async function action({ store }) {

  // From Redux Store
  let isAdminAuthenticated = store.getState().runtime.issubAdminAuthenticated;
  let adminPrivileges = store.getState().adminPrevileges.privileges && store.getState().adminPrevileges.privileges.privileges;
  // let adminPrivileges = store.getState().adminPrevileges.privileges;

  if (!isAdminAuthenticated) {
    return { redirect: '/subadmin/login' };
  }

  // Admin restriction
  // if (!restrictUrls('/subadmin/settings/site', adminPrivileges)) {
  //   return { redirect: '/subadmin' };
  // }

  return {
    title,
    component: <SubAdminLayout><SiteSettings title={title} /></SubAdminLayout>,
  };
}

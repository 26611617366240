import React, { Component } from 'react';
import { connect } from 'react-redux';

class GetYourGuideWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      City:"Ahmedabad",
    };
  }
getLocation(){
    if (navigator.geolocation){
        navigator.geolocation.getCurrentPosition(showPosition,showError);
    }
    else{
        alert("Geolocation is not supported by this browser.");
    }
}

showPosition(position){
  let  lat=position.coords.latitude;
  let  long=position.coords.longitude;
    displayLocation(lat,long);
}

showError(error){
    switch(error.code){
        case error.PERMISSION_DENIED:
          alert("User denied the request for Geolocation.")
        break;
        case error.POSITION_UNAVAILABLE:
            alert("Location information is unavailable.")
        break;
        case error.TIMEOUT:
            alert("The request to get user location timed out.")
        break;
        case error.UNKNOWN_ERROR:
            alert("An unknown error occurred.")
        break;
    }
}

displayLocation(latitude,longitude){
    var geocoder;
    geocoder = new google.maps.Geocoder();
    var latlng = new google.maps.LatLng(latitude, longitude);

    geocoder.geocode(
        {'latLng': latlng}, 
        function(results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
                if (results[0]) {
                    var add= results[0].formatted_address ;
                    var  value=add.split(",");

                    count=value.length;
                    country=value[count-1];
                    state=value[count-2];
                    city=value[count-3];
                    this.setState({City:city})
                    // x.innerHTML = "city name is: " + city;
                }
                else  {
                    // x.innerHTML = "address not found";
                }
            }
            else {
                // x.innerHTML = "Geocoder failed due to: " + status;
            }
        }
    );
}
  componentDidMount() {
    const { City } = this.props;
    const { partnerId } = this.props;
    const script = document.createElement('script');
    script.async = true;
    script.defer = true;
    script.src = 'https://widget.getyourguide.com/dist/pa.umd.production.min.js';
    script.setAttribute('data-gyg-partner-id', partnerId ? partnerId : 'YLJCUN5');
    document.head.appendChild(script);

    const widgetDiv = document.querySelector('[data-gyg-widget="activities"]');
    if (widgetDiv) {
      widgetDiv.setAttribute('data-gyg-href', 'https://widget.getyourguide.com/default/activities.frame');
      widgetDiv.setAttribute('data-gyg-locale-code', 'en-US');
      widgetDiv.setAttribute('data-gyg-number-of-items', '4');
      widgetDiv.setAttribute('data-gyg-partner-id', partnerId ? partnerId : 'YLJCUN5');
      widgetDiv.setAttribute('data-gyg-q',`${City}`);
    }
  }
  render() {
    const { partnerId } = this.props;
    console.log("partnerId", partnerId);
    return (
      <div>
        <div data-gyg-widget="activities"></div>
      </div>
    );
  }
}

const mapState = (state) => ({
  partnerId: state.siteSettings && state.siteSettings.data.partnerId,
});

const mapDispatch = {

};
export default (connect(mapState, mapDispatch)(GetYourGuideWidget));

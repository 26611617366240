import gql from 'graphql-tag';
import history from '../../core/history';
import {
    SUB_ADMIN_USER_LOGOUT_START,
    SUB_ADMIN_USER_LOGOUT_SUCCESS,
    SUB_ADMIN_USER_LOGOUT_ERROR,
    SET_RUNTIME_VARIABLE
} from '../../constants';

export function subadminLogout() {
    return async (dispatch, getState, { client }) => {

        const query = gql`
        query {
            subadminUserLogout {
                status
            }
          }
        `;

        dispatch({
            type: SUB_ADMIN_USER_LOGOUT_START
        })

        try {
            const { data } = await client.query({
                query,
                fetchPolicy: 'network-only'
            })
            if (data && data.subadminUserLogout && data.subadminUserLogout.status === 200) {
                history.push('/subadmin/login')

                dispatch({
                    type: SUB_ADMIN_USER_LOGOUT_SUCCESS
                })

                dispatch({
                    type: SET_RUNTIME_VARIABLE,
                    payload: {
                        name: 'issubAdminAuthenticated',
                        value: false
                    }
                })
            } else {
                dispatch({
                    type: SUB_ADMIN_USER_LOGOUT_ERROR
                });
            }
        } catch (error) {
            dispatch({
                type: SUB_ADMIN_USER_LOGOUT_ERROR,
                payload: {
                    error
                }
            });
            return false;
        }
        return true;
    }
}
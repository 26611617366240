import {
    LOADING_SUGGESTED_RESULTS,
    FETCH_SUGGESTED_RESULTS_START,
    FETCH_SUGGESTED_RESULTS_SUCCESS,
    FETCH_SUGGESTED_RESULTS_ERROR,
  } from '../constants';
  
  
  export function getSuggestedResults(data) {
  // console.log("getSuggestedResults","getSuggestedResults")
    return async (dispatch, getState, { client }) => {
      // console.log("finaldatafinaldatafinaldatafinaldata")
      dispatch({
        type: FETCH_SUGGESTED_RESULTS_START,
        isResultLoading: true
      });
  
      try {
  
        if (data) {
          // Dispatch a success action
          // console.log("payloadaction",data)
          dispatch({
            type: FETCH_SUGGESTED_RESULTS_SUCCESS,
            payload: {
              data,
              isResultLoading: false
            }
          });
        } else {
          dispatch({
            type: FETCH_SUGGESTED_RESULTS_ERROR,
            payload: {
              isResultLoading: false
            }
          });
        }
  
      } catch (error) {
        dispatch({
          type: FETCH_SUGGESTED_RESULTS_ERROR,
          payload: {
            error,
            isResultLoading: false
          }
        });
        return false;
      }
  
      return true;
    };
  }
  
  export function loadingSuggestedResults() {
    return (dispatch) => {
      dispatch({
        type: LOADING_SUGGESTED_RESULTS,
        payload: {
          isResultLoading: true
        }
      });
    };
  }
  
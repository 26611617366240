import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';

// Style
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './AffiliateDocumentList.css';
import { FormattedMessage, injectIntl } from 'react-intl';

//pdf image
import pdfIcon from '../../../public/SiteIcons/pdf_image.png';

//GraphGL
import affiliateShowDocumentListQuery from './AffiliateShowListDocument.graphql';
import affiliateRemoveDocumentList from './AffiliateRemoveDocumentList.graphql';

//toastr
import { toastr } from 'react-redux-toastr';

// Locale
import messages from '../../locale/messages';

class AffiliateDocumentList extends Component {

  static defaultProps = {
    data: {
      loading: true,
      affiliateshowDocumentList: []
    },
  };

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  async handleClick(id, fileName) {
    const { mutate } = this.props;

    const { data } = await mutate({
      affiliateRemoveDocumentList,
      variables: { id },
      refetchQueries: [{ query: affiliateShowDocumentListQuery }]
    });
    


    if (data && data.affiliateRemoveDocumentList && data.affiliateRemoveDocumentList.status == 200) {
      const resp = await fetch('/AffiliatedeleteDocuments', {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ fileName }),
        credentials: 'include',
      });
      const { status } = await resp.json();
      toastr.success("Success!", "Document has been removed successfully!");
    }
  }


  render() {
    const { data: { AffiliateShowDocumentList }, data } = this.props;
    let path = "/images/AffiliateDocument/";

    return (
      <div className={cx('row', s.space2)}>
        {
          AffiliateShowDocumentList && AffiliateShowDocumentList.map((item, key) => {
   

            let icon = item.fileType == 'application/pdf' ? pdfIcon : (path + item.fileName);
            return (
              // console.log(icon),
              <div key={key} className={cx('col-lg-4 col-md-4 col-sm-6 col-xs-12 text-center')}>
                <a href={path + item.fileName} target="_blank">
                  <div className={s.listPhotoCover}>
                    <div className={s.listPhotoMedia}>
                      <img className={s.imgResponsive} src={icon} />
                    </div>
                  </div>
                </a>
                <a className={s.linkColor} href="javascript:void(0);" onClick={() => this.handleClick(item.id, item.fileName)}>
                  <FormattedMessage {...messages.removeFile} />
                </a>
              </div>
            );
          })
        }
      </div>
    );
  }
}

export default compose(
  injectIntl,
  withStyles(s),
  graphql(affiliateShowDocumentListQuery, {
    options: {
      fetchPolicy: 'network-only'
    }
  }),
  graphql(affiliateRemoveDocumentList, {
    options: {
      fetchPolicy: 'network-only'
    }
  }),
)(AffiliateDocumentList);






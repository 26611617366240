// General
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './websitelist.css';
import bt from '../../components/commonStyle.css';
import cx from 'classnames';
//Image
import commonBg from '../../../public/SiteImages/becomeHostBg.svg';
import footerImage from '../../../public/SiteImages/1656587767_41-illustration_svg.svg';
// import footerImage from '../../../public/SiteImages/vectorFooterImage.svg';
import darkImage from '../../../public/SiteImages/stepDarkBg.svg';

class SidePanel extends Component {

	render() {
		return (
			<div className={s.listBannerImageSection}>
				<div className={cx(s.listBannerImageBg, 'listBannerImageBgDark')} style={{ backgroundImage: `url(${commonBg})` }} />
				<img src={footerImage} className={cx(s.footerVector, 'lightModeImg')} />
				<img src={darkImage} className={cx(s.footerVector, 'darkModeImg')} />
			</div>
		)
	}
}


export default withStyles(s, bt)(SidePanel);
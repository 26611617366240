import React from 'react';
import SEOform from "../../components/SEOform"
import PropTypes from 'prop-types';

class Test2 extends React.Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
  };

  render() {
      const {listId,PropertyTitle} = this.props;
    return (
      <>
        <SEOform listId={listId} PropertyTitle={PropertyTitle}/>
      </>
    );
  }
}

export default Test2
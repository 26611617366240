import React from 'react';
import AdminLayout from '../../../components/Layout/SubadminLayout';
import ChangeAdmin from './ChangeAdmin';

const title = 'Change Admin Email/Password';

export default async function action({ store }) {

  // From Redux Store
  let issubAdminAuthenticated = store.getState().runtime.issubAdminAuthenticated;

  if (!issubAdminAuthenticated) {
    return { redirect: '/subadmin/login' };
  }

  return {
    title,
    component: <AdminLayout><ChangeAdmin title={title} /></AdminLayout>,
  };

}

import { toastr } from "react-redux-toastr";
// import { sendEmail } from '../../../core/email/sendEmail';
import { sendEmail } from '../../core/email/sendEmail';
import { getSpecificConfig } from '../../helpers/getConfigValue';
import { reset} from 'redux-form';
// Fetch request
import fetch from "../../core/fetch";

async function submit(values, dispatch) {
  // console.log("Values from form", values);
  const query = `mutation (
    $userId: String, 
    $name: String, 
    $email: String, 
    $country: String, 
    $number: String, 
    $no_Of_units_list: String, 
    ) {
        CreateCustomPlanRequest (userId: $userId, 
            name: $name, email: $email,
            country: $country,
            number: $number,
            no_Of_units_list:$no_Of_units_list) {
            status
            errorMessage
          }
    }`;

  const params = {
    userId: "788df6e0-0a82-11ee-b0da-3359da8a8064",
    name: values.fullName,
    email: values.email,
    no_Of_units_list: values.units,
    country: values.country,
    number: values.phoneNumber,
  };

  const resp = await fetch("/graphql", {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: query,
      variables: params,
    }),
    credentials: "include",
  });

  const { data } = await resp.json();

  // console.log("After fetching data", data.CreateCustomPlanRequest.status);
  const emailData = {
    name: values.fullName,
    email: values.email,
    no_Of_units_list: values.units,
    country: values.country,
    number: values.phoneNumber,
  };
  // const configData = await getSpecificConfig({ name: ['smptEmail'] });

  // sendEmail(configData.smptEmail,'customePlanRequest',emailData)
  if (data && data.CreateCustomPlanRequest.status == 200) {
    toastr.success("Your request has been sent!");
    dispatch(reset('customplanForm'));

  } else {
    toastr.error("Error while your plan subscription!");
  }
}

export default submit;

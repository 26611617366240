exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ShowMap-labelText-36nIn {\n    display: inline-block;\n    font-weight: normal;\n    margin: 0px;\n    font-size: 16px;\n}\n\n.ShowMap-mapFlex-2Rb6b {\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-align: center;\n        -ms-flex-align: center;\n            align-items: center;\n    -webkit-column-gap: 8px;\n       -moz-column-gap: 8px;\n            column-gap: 8px;\n    -webkit-box-pack: center;\n        -ms-flex-pack: center;\n            justify-content: center;\n}\n\n.ShowMap-btnHeight-2_Ncv {\n    height: 36px;\n}\n\n@media (max-width: 993px) and (min-width: 769px) {\n\t.ShowMap-labelText-36nIn {\n\t\tfont-size: 14px;\n\t}\n}", ""]);

// exports
exports.locals = {
	"labelText": "ShowMap-labelText-36nIn",
	"mapFlex": "ShowMap-mapFlex-2Rb6b",
	"btnHeight": "ShowMap-btnHeight-2_Ncv"
};
import React from "react"
import {
  Grid,
  Row,
  Col,
  Panel,
  Tooltip
} from 'react-bootstrap';
import Select from "react-select";
import {
  toastr
} from 'react-redux-toastr';
import ReactDOM from 'react-dom';
// import {
//   CChart
// } from '@coreui/react-chartjs';
// import GaugeChart from "react-gauge-chart";
import '@coreui/coreui/dist/css/coreui.min.css';
import moment from 'moment';
import DateRange from "../../components/Home/AffiliateDateRange";
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './OccupancyInsight.css';
import cx from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import history from '../../core/history';
// Graphql
// Component
import SideMenu from '../ManageListing/SideMenu/SideMenu';
import messages from '../../locale/messages';
import GetYourGuideWidget from "../GetYourGuideWidget";
// import ChartComponent from "../ChartComponent"
import ReactSpeedometer from "react-d3-speedometer";
import getGeoLocationData from "./getGeoLocationData.graphql";
import { graphql, gql, compose } from 'react-apollo';
import NoItem from "./NoItem";
import ManageListing from "./ManageListing.graphql";

const options = [
  { label: "Day", value: "Day" },
  { label: "Week", value: "Week" },
  { label: "Month", value: "Month" },
  { label: "Date", value: "Date" },
  { label: "All", value: "AllData" },
];
// const getGeoLocationData1 = `query calculateOccupancyRate($lat:Float,$lng:Float,$filter:String,$startDate:String,$endDate:String){
//   calculateOccupancyRate(lat:$lat,lng:$lng,filter:$filter,startDate:$startDate,endDate:$endDate){
//       Occupancy_Rate
//       filter
//       status
//       errorMessage
//       }
//   }`;
const OCCUPANCY_QUERY = `query ManageListings($searchKey: String){
  ManageListings(searchKey: $searchKey) {
      results{
          id
          title
          city
          updatedAt
          lastUpdatedAt
          coverPhoto
          isPublished
          listApprovalStatus
          isReady
          listPhotos{
              id
              name
          }
          settingsData {
              listsettings {
                  id
                  itemName
              }
          }
          listingSteps {
              id
              step1
              step2
              step3
              step4
          }
          user {
              id
              userBanStatus
          }
      }
      userListingCount
      status
      searchKey
  }
}`
const styles = {
  dial: {
    display: "inline-block",
    width: `350px`,
    height: `auto`,
    color: "#000",
    border: "0.5px solid #fff",
    padding: "2px",
    // border: "1px solid red",
  },
  title: {
    fontSize: "1em",
    color: "#000",
  },
};
class OccupancyInsight extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      graphData: [],
      filter: "Week",
      startDate: null,
      endDate: null,
      selectedOption: options[3],
      show2tooltip: false,
      show3tooltip: false,
      show1Tooltip: false,
      show4tooltip: false,
      lat:0,
      long:0,
      listNew:false
    };
    this.handlePickDate = this.handlePickDate.bind(this);
  }

  handleChange = async (value) => {
    const { latitude, longitude } = this.props;
    const { calculateOccupancyRate: { refetch } } = this.props
    const { filter,lat,long } = this.state;
  

    let variables = {
      lat: lat,
      lng: long,
      filter: value.value,
      startDate: "",
      endDate: "",

    };
    this.setState({ filter: value.value,startDate: "",endDate: "" });
    refetch(variables)

  };

async  componentDidMount() {
    // const { calculateOccupancyRate: { calculateOccupancyRate, refetch } } = this.props;
    // let doc3 = document.querySelectorAll(".speedo-segment");
    // let listing = document.getElementsByClassName("listingTab")
    // listing.addEventListener("mouseover", () => { this.setState({ show1Tooltip: true }) })
    // let doc2 = Array.from(document.getElementsByClassName("arc"));
    // let doc3 = document.qu("speedo-segment");
    ///////////////////////////////////////

      const doc = document.querySelectorAll(".speedo-segment");
  
      if (doc.length >= 4) {
        doc[3].addEventListener("mouseover", () => { this.setState({ show4tooltip: true }) });
        doc[3].addEventListener("mouseleave", () => { this.setState({ show4tooltip: false }) });
  
        doc[0].addEventListener("mouseover", () => { this.setState({ show1Tooltip: true }) });
        doc[0].addEventListener("mouseleave", () => { this.setState({ show1Tooltip: false }) });
  
        doc[1].addEventListener("mouseover", () => { this.setState({ show2Tooltip: true }) });
        doc[1].addEventListener("mouseleave", () => { this.setState({ show2Tooltip: false }) });
  
        doc[2].addEventListener("mouseover", () => { this.setState({ show3Tooltip: true }) });
        doc[2].addEventListener("mouseleave", () => { this.setState({ show3Tooltip: false }) });
      
    }
      // console.log(doc, "hellll")
      // console.log(listing, "hellll")
      // console.log(doc2, "hellll")
      // console.log(doc3, "hellll")
      // console.log(doc3[0], "hellll")
      // console.log(calculateOccupancyRate, "hellll")
    // if (calculateOccupancyRate && calculateOccupancyRate.Occupancy_Rate) {
      


      // doc3[0].addEventListener("mouseover", () => { this.setState({ show1Tooltip: true }) })
      // doc3[0].addEventListener("mouseleave", () => { this.setState({ show1Tooltip: false }) })

      // doc3[1].addEventListener("mouseover", () => { this.setState({ show2Tooltip: true }) })
      // doc3[1].addEventListener("mouseleave", () => { this.setState({ show2Tooltip: false }) })

      // doc3[2].addEventListener("mouseover", () => { this.setState({ show3Tooltip: true }) })
      // doc3[2].addEventListener("mouseleave", () => { this.setState({ show3Tooltip: false }) })

      // doc3[3].addEventListener("mouseover", () => { this.setState({ show4tooltip: true }) })
      // doc3[3].addEventListener("mouseleave", () => { this.setState({ show4tooltip: false }) })

    // }
//     const response = await fetch("/graphql", {
//       method: "POST",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         query: OCCUPANCY_QUERY,
//         variables: {
//           $searchKey:""
//         },
//       }),
//     });
//     const {data} = await response.json();
// console.log(data,"response in occupancy")
// if(data && data.ManageListings.userListingCount > 0){
//     this.setState({listNew:true})
// }else{
//     this.setState({listNew:false})
// }

    if (navigator.geolocation) {
      // console.log("navigator",navigator.geolocation)
      navigator.geolocation.getCurrentPosition((position) => {
        latitude = position.coords.latitude;
        longitude = position.coords.longitude;
        this.setState({ lat: latitude })
        this.setState({ long: longitude })
      }, error);
    } else {
      // console.log("Geolocation not supported");
      toastr.info('Opps!', "Please turn on your browser's location")
      
    }
    let latitude = 0;
    let longitude = 0
    function success(position) {
      latitude = position.coords.latitude;
      longitude = position.coords.longitude;


      // console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
    }

    function error() {
      toastr.info('Opps!', "Please turn on your browser's location")
    }

    // console.log(doc,"hhhhhhheeeeellllllllooooooo");

  }

  // handlePickDate = async (value) => {
  //   const { latitude, longitude } = this.props;
  //   const { calculateOccupancyRate: { refetch } } = this.props
  //   const {lat,long} = this.state;
  //   const answer_array = value.replaceAll("'", '').split(" AND ");
  //   console.log(answer_array, "value");
  //   let variables = {
  //     lat: lat,
  //     lng: long,
  //     filter: "Date",
  //     startDate: answer_array[0],
  //     endDate: answer_array[1]
  //   }
  //   setStateVariable({ startDate: answer_array[0], endDate: answer_array[1] });
  //   refetch(variables)
  //   this.setState({ startDate: answer_array[0], endDate: answer_array[1], filter: "Date", selectedOption: options[3] })

  // };
  handlePickDate =  (value) => {
    const { calculateOccupancyRate: { calculateOccupancyRate,refetch } } = this.props
    const {lat,long} = this.state;
    // this.setState({ startDate: "", endDate: ""})
    // console.log("zingalala",startDate)
    // console.log("zingalala",endDate)
    const answer_array = value.replaceAll("'", '').split(" AND ");
    
    let variables = {
      lat: lat,
      lng: long,
      filter: "Date",
      startDate: answer_array[0],
      endDate: answer_array[1]
    }
    // setStateVariable({ startDate: answer_array[0], endDate: answer_array[1] });
    refetch(variables)
    this.setState({ startDate: answer_array[0], endDate: answer_array[1], filter: "Date", selectedOption: options[3] })

  };

  render() {
    const { calculateOccupancyRate: { calculateOccupancyRate ,loading} } = this.props;
    const { ManageListings: { ManageListings } } = this.props;
    const { latitude, longitude } = this.props;
    // console.log(ManageListings, "longitude component");
    const { startDate, endDate, filter, show1Tooltip, show2Tooltip, show3Tooltip, show4tooltip,listNew } = this.state;
    // console.log("filter",data)
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();

    let filteroption = {};
    let momentStartDate = moment(date).format('MMM');
    if (filter == "Week") {
      filteroption = options[1]
    }
    else if (filter == "Day") {
      filteroption = options[0]
    }
    else if (filter == "Date") {
      filteroption = options[3]
    }
    else if (filter == "Month") {
      filteroption = options[2]
    }
    else if (filter == "AllData") {
      filteroption = options[4]
    }
    let occupRate = 0;
    if(calculateOccupancyRate){
      const { calculateOccupancyRate: { calculateOccupancyRate } } = this.props;
        occupRate =  calculateOccupancyRate.Occupancy_Rate
      }
      let noData = false;
      if((calculateOccupancyRate == null || undefined) || calculateOccupancyRate.Occupancy_Rate == 0){
          noData = true
      }
      // console.log("calculateOccupancyRate",calculateOccupancyRate);
    return (
      <>
        <Grid fluid className={'dashBoardContainer'}>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <div className={'dashBoardListingGrid'}>
                <SideMenu />
                <div className={cx('commonListingBg', 'tripContainer')}>
                {ManageListings && ManageListings.userListingCount > 0 ?   <Panel className={cx(s.panelHeader, 'bgBlack')}>
                    <div className={cx('listingTab', s.panelBorder, 'listingMobileTab', 'tabReservation')}>
                      <h1>Occupancy Index</h1>
                    </div>
                    <div className={s.SelectAndDate} >
                      <span className={cx('homeDate', s.formControlInput, s.input, 'homeDateAR', 'headerSearchDate')}>
                        <Select
                          className={s.selectTag}
                          styles={{ width: "50%" }}
                          value={filteroption}
                          options={options}
                          onChange={this.handleChange}
                          name="filterSelect"
                        />
                      </span>

                      {
                        filter === "Date" && <DateRange
                          startDate={startDate}
                          endDate={endDate}
                          onChange={this.handlePickDate}
                          // isOutsideRange={(day) => day.isAfter(new Date())}
                          isOutsideRange={()=>false}
                        />
                      }


                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div id="speedometer_container" style={{ width: "90%", textAlign: "center", marginTop: "30px" }}>
                        <FormattedMessage {...messages.occupancyInsightsBase} />

                        <div style={styles.dial}>
                          {show1Tooltip && <Tooltip placement="left" className="in" id="tooltip-left" style={{ position: "absolute", top: "40%", left: "30%" }}>
                          <strong style={{fontSize:"16px"}}>Extreme Low!</strong> 
                          <br/>
                          High extreme low (less than 30) suggests a good time to lower Per Night Charges, as properties are likely to be vacant .
                          </Tooltip>}
                          {show2Tooltip && <Tooltip placement="left" className="in" id="tooltip-left" style={{ position: "absolute", top: "34%", left: "30%" }}>
                          <strong style={{fontSize:"16px"}}>Low!</strong> 
                          <br/>It suggests that host are fearful in the property market, but the action to be taken depends on the Property Market Mood Index (PMMI) trajectory.
                            If it is dropping from Greed to Fear, it means fear is increasing in the market & host should wait till it reaches Extreme High, as that is when the Property market is expected to turn upwards and you can put a higher per night price.
                          </Tooltip>}
                          {show3Tooltip && <Tooltip placement="right" className="in" id="tooltip-right" style={{ position: "absolute",top: "37%", left: "78%" }}>
                          <strong style={{fontSize:"16px"}}>High!</strong> 
                          <br/>It suggests that investors are acting greedy in the market, but the action to be taken depends on the PMMI trajectory.
                            If PMMI is dropping from Extreme Low to High, it means greed is reducing in the market. But more patience is suggested before looking for fresh high per night prices for your properties.
                          </Tooltip>}
                          {show4tooltip && <Tooltip placement="right" className="in" id="tooltip-right" style={{ position: "absolute", top: "37%", left: "78%" }}>
                          <strong style={{fontSize:"16px"}}>Extreme High!</strong> 
                          <br/> High extreme greed (greater than 80) suggests host should put a fresh price on their units as markets are overbought and likely to give you more revenue from your properties.
                          </Tooltip>}
                          { loading &&        <div> <img width={"50%"} src="https://cdn.pixabay.com/animation/2023/05/02/04/29/04-29-06-428_512.gif" alt="loader" />  </div>}
                {loading == false ?  <ReactSpeedometer
                            className={"speed"}
                            ringWidth={33}
                            maxValue={100}
                            minValue={0}
                            height={300}
                            width={350}
                            value={occupRate == null ? 0 : occupRate}
                            textColor={"black"}
                            // needleColor="rgba(39, 55, 230)"
                            maxSegmentLabels={5}
                            paddingVertical={20}
                            needleHeightRatio={0.8}
                            customSegmentStops={[0, 30, 50, 70, 100]}
                            // currentValueText={`${occupRate ? occupRate:0}%`}
                            currentValueText={`${occupRate == null ? 0 : occupRate}%`}

                            valueTextFontSize={"30px"}
                            segmentColors={["red", "orange", "yellow", "rgb(0,255,0)"]}
                          />  :""  }

                          {/* <div>Acceleration X</div> */}
                        </div>
                   { loading == false ?     <div style={{ width: "100%", height: "fit-content", margin: "auto", padding: "10px", fontSize: "14px", color: "rgba(0,0,0,0.7)", fontWeight: "bold", backgroundColor: "rgb(255,165,0,0.2)" }}>
                          {/* <p> <FormattedMessage {...messages.occupancyInsightsDesc} /> </p> */}
                          <p>The Property Market Mood Index or PMMI is a market sentiment metric developed by SolutionValley,  Asia-based quantitative behavioural Tech company. This sentiment metric has now evolved into a highly sophisticated index leveraging advanced technologies such as sentiment analysis, machine learning, Geo Locations of the property, Total Property booked in your area and Total Property available in your area.
                          </p>
                        </div>:""}
                        {/* <GetYourGuideWidget/> */}
                      </div>
                    </div>

                  </Panel> : <NoItem/> }
                </div>
              </div>
            </Col>
          </Row>
        </Grid>
      </>
    )
  }
}

// export default injectIntl(withStyles(s)(OccupancyInsight));

export default compose(
  injectIntl,
  withStyles(s),
  graphql(getGeoLocationData, {
    name: 'calculateOccupancyRate',
    options: (props) => ({
      variables: {
        lat: props.latitude,
        lng: props.longitude,
        filter: "Week",
        startDate: "",
        endDate: ""
      },
      fetchPolicy: 'network-only',
    })
  }),
  graphql(ManageListing, {
    name: 'ManageListings',
    options: (props) => ({
      variables: {
        searchKey:""
      },
      fetchPolicy: 'network-only',
    })
  })
)(OccupancyInsight);

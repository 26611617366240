import React from 'react';
import Layout from '../../components/Layout/Layout';

import UserLayout from '../../components/Layout/UserLayout';
import NewListLayout from '../../components/Layout/NewListLayout';

import AffiliatedocumentVerification from './affiliatedocumentVerification';

const title = 'AffiliatedocumentVerification';

export default function action({ store }) {

  // From Redux Store
  let isAuthenticated = store.getState().runtime.isAuthenticated;


  if (!isAuthenticated) {
    return { redirect: '/login' };
  }


  // let account = store.getState().account;

  // if (account) {
  //   let document = account.data.verification.isIdVerification;
  //   if (document == true) {
  //     return { redirect: '/user/verification' };
  //   }
  // }

  return {
    title,
    component: <NewListLayout><AffiliatedocumentVerification title={title} /></NewListLayout>,
  };
}




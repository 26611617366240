import { initialize, change } from 'redux-form';

export function applycoupon({ discount, couponCode, coupondiscount,coupondiscountValue,discountType,discountstatus }) {

    return async (dispatch, getState) => {
      // console.log("action discountstatus",discountstatus);
      // console.log("coupondiscountValue discountstatus",coupondiscountValue);
      await dispatch(change('MemberPaymentForm', 'discount', discount));
      await dispatch(change('MemberPaymentForm', 'couponCode', couponCode));
      await dispatch(change('MemberPaymentForm', 'coupondiscount', coupondiscount));
      await dispatch(change('MemberPaymentForm', 'coupondiscountValue', coupondiscountValue));
      await dispatch(change('MemberPaymentForm', 'discountType', discountType));
      await dispatch(change('MemberPaymentForm', 'discountstatus', discountstatus));
      
    };
  
  }
// import React from 'react';
// import Layout from '../../components/Layout/Layout';
// import Websitelist from './websitelist';

// const title = 'Affiliate Manager';

// export default function action({ store, query }) {

//   // From Redux Store
//   let isAuthenticated = store.getState().runtime.isAuthenticated;
//   let refer = query.refer;

//   if (isAuthenticated) {
//     return { redirect: '/' };
//   }

//   return {
//     title,
//     component: <Layout><Websitelist title={title} refer={refer} /></Layout>,
//   };
// }

import React from 'react';
import Websitelist from './websitelist';
import NewListLayout from '../../components/Layout/NewListLayout';


const title = 'Affiliate Manager';

export default async function action({ store }) {

  // From Redux Store
  
  // From Redux Store
  let isAuthenticated = store.getState().runtime.isAuthenticated;

  if (!isAuthenticated) {
    return { redirect: '/login' };
  }

  return {
    title,
    component: <NewListLayout><Websitelist title={title}/></NewListLayout>,
  };
};

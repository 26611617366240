import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../layouts/Layout';
import Header from '../modules/Header';
import Body from '../modules/Body';
import Footer from '../modules/Footer';
import EmptySpace from '../modules/EmptySpace';
import { url, sitename } from '../../../config';
import { PRIMARYCOLOR } from '../../../constants';

class CustomPlanEmail extends React.Component {

    static propTypes = {
        content: PropTypes.shape({
            email: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,

        })
    };

    render() {

        const textStyle = {
            color: '#484848',
            backgroundColor: '#F7F7F7',
            fontFamily: 'Arial',
            fontSize: '16px',
            padding: '35px'
        };
        const buttonStyle = {
            margin: 0,
            fontFamily: 'Arial',
            padding: '10px 16px',
            textDecoration: 'none',
            borderRadius: '2px',
            border: '1px solid',
            textAlign: 'center',
            verticalAlign: 'middle',
            fontWeight: 'normal',
            fontSize: '18px',
            whiteSpace: 'nowrap',
            background: '#ffffff',
            borderColor: PRIMARYCOLOR,
            backgroundColor: PRIMARYCOLOR,
            color: '#ffffff',
            borderTopWidth: '1px',
        };
        let textBold = {
            fontWeight: 'bold'
        }
        const { content: { country, email, name, no_Of_units_list, logo, number },websiteTitle,currentWeb } = this.props;
        return (
            <Layout>
                <Header color={PRIMARYCOLOR} backgroundColor="#F7F7F7" logo={logo}  websiteTitle={websiteTitle}/>
                <Body textStyle={textStyle}>
                    <div>
                    Dear Admin,
                    </div>
                    <EmptySpace height={20} />
                    {/* <div> You have got a Custom Plan enquiry by {name}. It’s a perfect time to make our users happy and grow our business as well.
                        Please take a moment and seal the deal! </div> */}
                    <div> 

We have received a Custom Plan enquiry from {name}. This is an excellent opportunity to delight our users and drive business growth. Please take a moment to review the details and finalize the arrangement. </div>
                    <EmptySpace height={20} />
                    <div>

                        <br />
                        The user details are as follows: <br />

                        <span style={textBold}> Name:</span> {name}<br />
                        <span style={textBold}> Email ID::</span> {email}<br />
                        <span style={textBold}> Country:</span> {country}<br />
                        <span style={textBold}> Phone number:</span> {number}<br />
                        <span style={textBold}>Units:</span> {no_Of_units_list}<br />

                        <br />
                    </div>
                    <EmptySpace height={30} />

                    <div>
                    Please ensure that the necessary hosting space is assigned accordingly.

Thank you for your prompt attention to this matter.
<br />
                        <a style={buttonStyle} href={`${currentWeb}/subadmin/subscription-management`}>Assign Hosting Space</a>
                    </div>
                    <EmptySpace height={30} />
                    <div>
                        Thanks, <br />
                        The {websiteTitle} Team
                    </div>
                </Body>
                <Footer  websiteTitle={websiteTitle}/>
                <EmptySpace height={20} />
            </Layout>
        );
    }

}

export default CustomPlanEmail;
import React from 'react';
import { graphql, gql, compose } from 'react-apollo';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import Loader from '../../../../components/Loader/Loader'
import s from './WhyHostBlock6.css';
import WhyHostFormBlock6 from '../../../../components/subadmin/WhyHostPageSettings/WhyHostFormBlock6/WhyHostFormBlock6'
import getWhyHostPageSettings from './getWhyHostPageSettings.graphql';

class WhyHostBlock6 extends React.Component {

    static defaultProps = {
        data: {
            loading: false
        }
    };

    render() {
        const { data: { loading, getWhyHostPage } } = this.props
        let settingsCollection =getWhyHostPage ? getWhyHostPage[0] : {}
        if(loading){
            return <Loader type={"text"} />;
        } else {
            // getWhyHostPage.map((item, key) => {
            //     settingsCollection[item.name] = item.value
            // });
            // console.log("block=6",settingsCollection);
            return <WhyHostFormBlock6 initialValues={settingsCollection}  />
        }
    }
}

export default compose(
    withStyles(s),
    graphql(getWhyHostPageSettings, {
        options: {
            fetchPolicy: 'network-only',
            ssr: false
        }
    }),
)(WhyHostBlock6);

// General
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

// Redux
import { connect } from 'react-redux';

// Redux Form
import { formValueSelector } from 'redux-form';

// Locale
import messages from '../../locale/messages';

// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import { Button } from 'react-bootstrap';
import * as FontAwesome from 'react-icons/lib/fa';
import * as Material from 'react-icons/lib/md';
import s from './affiliatelinkManagerRoutes.css';

// Components
import AffiliateLinkManager from '../../components/AffiliateLinkManager/affiliateLinkListing';
import MapResults from '../../components/SearchListing/MapResults/MapResults';
import Loader from '../../components/Loader/Loader';
// New Design
// import SearchHeader from '../../components/SearchListing/SearchHeader';

// Redux Action
import { showMap, showResults, showForm, showFilter } from '../../actions/mobileSearchNavigation';
import { getListingFields } from '../../actions/getListingFields';

import ReactGoogleMapLoader from "react-google-maps-loader";
import { googleMapAPI } from '../../config';

//image
import listIcon from '../../../public/SiteIcons/listArrowLeft.svg';

class affiliatelinkManagerRoutes extends React.Component {
  static propTypes = {
    initialFilter: PropTypes.object,
    affiliateResults: PropTypes.object,

    searchSettings: PropTypes.object,
    filterToggle: PropTypes.bool,
    showMap: PropTypes.func.isRequired,
    showResults: PropTypes.func.isRequired,
    showForm: PropTypes.func.isRequired,
    formatMessage: PropTypes.func,
    mobileSearch: PropTypes.shape({
      searchMap: PropTypes.bool,
      searchResults: PropTypes.bool,
      AffiliateSearchForm: PropTypes.bool
    }),
    getListingFields: PropTypes.func,
  };

  static defaultProps = {
    mobileSearch: {
      searchMap: false,
      searchResults: true,
      AffiliateSearchForm: false,
      searchFilter: false
    },
    isMapShow: true
  };

  constructor(props) {
    super(props);

    this.state = {
      smallDevice: false,
      load: false
    };

    this.handleResize = this.handleResize.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { getListingFields } = this.props;
    // Get listing settings fields data
    // getListingFields();
  }

  componentDidMount() {
    let isBrowser = typeof window !== 'undefined';
    if (isBrowser) {
      this.handleResize();
      window.addEventListener('resize', this.handleResize);
    }

    this.setState({
      load: true
    })
  }

  componentWillUnmount() {
    let isBrowser = typeof window !== 'undefined';
    if (isBrowser) {
      window.removeEventListener('resize', this.handleResize);
    }
  }

  handleResize(e) {
    const { showResults } = this.props;
    let isBrowser = typeof window !== 'undefined';
    let smallDevice = isBrowser ? window.matchMedia('(max-width: 1199px)').matches : false;
    if (smallDevice) {
      showResults();
    }
    this.setState({ smallDevice });
  }

  mobileNavigation() {
    const {
      mobileSearch: { searchMap, searchResults },
      showMap,
      showResults,
      showForm
    } = this.props;

    let leftNav, rightNav;
    
    if (searchResults) {
      leftNav = <Button className={cx(s.filterButton, s.locationBtn, 'bgBlack')} bsStyle="link" onClick={() => showMap()}>
        <Material.MdRoom className={s.icon} />
        <FormattedMessage {...messages.showMap} />
      </Button>;
      rightNav = <Button className={cx(s.filterButton)} bsStyle="link" onClick={() => showForm()}><FormattedMessage {...messages.filters} /><FontAwesome.FaSliders /></Button>
    }

    if (searchMap) {
      leftNav = <Button className={cx(s.filterButton, 'bgBlack')} bsStyle="link" onClick={() => showResults()}><img src={listIcon} className={cx(s.listIconMb, 'listIconMbRTL')} /><FormattedMessage {...messages.mapListing} />{' '}</Button>
      rightNav = <Button className={cx(s.filterButton, 'bgBlack')} bsStyle="link" onClick={() => showForm()}><FormattedMessage {...messages.filters} /><FontAwesome.FaSliders /></Button>
    }

    return (
      <div className={cx(s.mobileNavigation, 'mobileNavigationRTL')}>
        <div className={s.buttonOuter}>
          <div className={cx(s.buttonContainer)}>
            {
              leftNav
            }
            {
              //rightNav
            }
          </div>
        </div>
      </div>
    );
  }

  render() {
    // console.log('in render');
    const {
      mobileSearch: { searchMap, searchResults, AffiliateSearchForm, searchFilter },
      searchSettings,
      initialFilter,
      filterToggle,
      isMapShow,
      showFilter,
      showResults,
      extendMap,
      
      affiliateResults
    } = this.props;
    // console.log("result show"+ JSON.stringify(affiliateResults.results));
    const { smallDevice, load } = this.state;


    let DesktopResults = true;
    if (filterToggle === true) {
      DesktopResults = false;
    }
    const isBrowser = typeof window !== 'undefined';

    if (!load || !isBrowser) {
      return (
        <div className={s.searchLoaderContainer}>
          <Loader type={"text"} />
        </div>
      );
    }

    return (
      <div className={cx(s.root, 'searchPage')}>
        <div className={s.container}>
          {/* {
            !smallDevice && <SearchHeader searchSettings={searchSettings} />
          } */}
          {/* {
            smallDevice && !searchMap && <SearchHeader showFilter={showFilter} showResults={showResults} searchSettings={searchSettings} />
          } */}
          <div>

            {
              !extendMap && !smallDevice && DesktopResults && <div className={cx(s.resultsBody)}>
                <AffiliateLinkManager affiliateResults={affiliateResults}/>
              </div>
            }

            {
              smallDevice && searchResults && <div className={cx(s.resultsBody)}>
                <AffiliateLinkManager affiliateResults={affiliateResults}/>
              </div>
            }

          </div>

          {/* {
            !smallDevice && isMapShow && <div className={cx(s.searchMapContainer, 'searchMapSection searchMapSectionRtl', { [s.searchMapContainerShow]: extendMap == true }, { ['searchMapContainerShowRTL']: extendMap == true })}>
              <ReactGoogleMapLoader
                params={{
                  key: googleMapAPI, // Define your api key here
                  libraries: "places,geometry,markerwithlabel"// To request multiple libraries, separate them with a comma
                }}
                render={googleMaps =>
                  googleMaps && (
                    <MapResults initialFilter={initialFilter} searchSettings={searchSettings} />
                  )}
              />
            </div>
          } */}

          {/* {
            smallDevice && searchMap && <div className={cx(s.searchMapContainer, 'searchMapSection searchMapSectionRtl')}>
              <MapResults initialFilter={initialFilter} searchSettings={searchSettings} />
            </div>
          } */}

          {
            !AffiliateSearchForm && this.mobileNavigation()
          }

        </div>
      </div>
    );
  }
}

const selector = formValueSelector('AffiliateSearchForm');

const mapState = (state) => ({
  filterToggle: state.toggle.filterToggle,
  mobileSearch: state.mobileSearch.data,
  isMapShow: state.personalized.showMap,
  extendMap: state.personalized.extendMap,
});

const mapDispatch = {
  showMap,
  showResults,
  showForm,
  getListingFields,
  showFilter
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(affiliatelinkManagerRoutes)));

import React from 'react';
import AdminLayout from '../../../components/Layout/SubadminLayout';
import { restrictUrls } from '../../../helpers/adminPrivileges';
import PayoutManagement from './PayoutManagement';

const title = 'Payout Management';

export default async function action({ store }) {

    // From Redux Store
    let issubAdminAuthenticated = store.getState().runtime.issubAdminAuthenticated;
    let adminPrivileges = store.getState().adminPrevileges.privileges && store.getState().adminPrevileges.privileges.privileges;

    if (!issubAdminAuthenticated) {
        return { redirect: '/subadmin/login' };
    }

    // Admin restriction
    // if (!restrictUrls('/subadmin/payout', adminPrivileges)) {
    //     return { redirect: '/subadmin' };
    // }

    return {
        title,
        component: <AdminLayout><PayoutManagement title={title} /></AdminLayout>
    }
}
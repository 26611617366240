import React, { Component } from "react";
import PropTypes from "prop-types";

import { Field, reduxForm } from "redux-form";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import CountryList from "../CountryList/CountryList";
import submit from "./submit";
import validate from "./validate";

// Locale
import messages from "../../locale/messages";

// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";
import s from "./host.css";
import bt from "../../components/commonStyle.css";
import { Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";

class customplanForm extends Component {
  static propTypes = {
    formatMessage: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.renderCountryList = this.renderCountryList.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  renderFormControl = ({
    input,
    label,
    type,
    meta: { touched, error },
    className,
    max,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <FormGroup className={cx(bt.space1)}>
        <label className={s.formLabel}>{label}</label>
        <FormControl
          {...input}
          placeholder={label}
          type={type}
          className={className}
          max={max}
        />
        {touched && error && (
          <span className={bt.errorMessage}>{formatMessage(error)}</span>
        )}
      </FormGroup>
    );
  };

  renderFormControlEmail = ({
    input,
    label,
    type,
    meta: { touched, error },
    className,
    disabled,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <FormGroup className={cx(bt.space1)}>
        <label className={cx(s.formLabel, "textWhite")}>{label}</label>
        <FormControl
          {...input}
          placeholder={label}
          type={type}
          className={className}
          disabled={disabled}
        />
        {touched && error && (
          <span className={s.errorMessage}>{formatMessage(error)}</span>
        )}
      </FormGroup>
    );
  };
  renderCountryList({ input, meta: { touched, error }, className }) {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <CountryList input={input} className={className} isEmptyFirst />
        {touched && error && (
          <span className={s.errorMessage}>{formatMessage(error)}</span>
        )}
      </div>
    );
  }

  render() {
    const {
      error,
      handleSubmit,
      submitting,
      getColor,
      getTitleColor,
    } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <div className={s.planCard} style={{ backgroundColor: getColor }}>
        <form
          onSubmit={handleSubmit(submit)}
          className={cx("SelectFocus", "inputDateHide")}
        >
          <div className={s.plantitle} style={{ color: getTitleColor }}>
            <FormattedMessage {...messages.customPlan} />
          </div>
          {error && (
            <span className={bt.errorMessage}>{formatMessage(error)}</span>
          )}

          <Field
            name="fullName"
            type="text"
            component={this.renderFormControl}
            label={formatMessage(messages.fullName)}
            className={s.customPlanControlInput}
            value="abc"
          />
          <Field
            name="email"
            type="text"
            component={this.renderFormControlEmail}
            label={formatMessage(messages.email)}
            className={s.customPlanControlInput}
          />
          <FormGroup className={cx(bt.space1)}>
            <ControlLabel className={cx(s.formLabel, "textWhite")}>
              <FormattedMessage {...messages.country} />
            </ControlLabel>
            <Field name="country" component={this.renderCountryList} />
          </FormGroup>
          <Field
            name="phoneNumber"
            type="text"
            component={this.renderFormControl}
            label={formatMessage(messages.phoneNumber)}
            className={s.customPlanControlInput}
          />
          <Field
            name="units"
            type="text"
            component={this.renderFormControl}
            label={formatMessage(messages.units)}
            className={s.customPlanControlInput}
          />

          <FormGroup className={s.formButtonContainer}>
            <Button
              className={cx(s.purchaseButton)}
              block
              type="submit"
              disabled={submitting}
              style={{ backgroundColor: getTitleColor }}
            >
              <FormattedMessage {...messages.continue} />
            </Button>
          </FormGroup>
        </form>
      </div>
    );
  }
}

customplanForm = reduxForm({
  form: "customplanForm", // a unique name for this form
  validate,
  enableReinitialize: true,
})(customplanForm);

const mapState = (state) => {};

export default injectIntl(withStyles(s, bt)(connect(mapState)(customplanForm)));

// General
import React from 'react';
import PropTypes from 'prop-types';
// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './AdminLogin.css';

//Component
import SubadminLoginForm from '../../../components/subadmin/SubAdminLoginForm';

class SubadminLogin extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
  };

  render() {
    return (
      <div>
        <SubadminLoginForm />
      </div>
    );
  }
}

export default withStyles(s)(SubadminLogin);
require("dotenv").config();

/* eslint-disable max-len */

export const port = process.env.PORT || 3000;
export const host = process.env.WEBSITE_HOSTNAME || `product.boosturrental.com:${port}`;
// export const host = process.env.WEBSITE_HOSTNAME || `product.boosturrental.com`;
export const url = process.env.WEBSITE_URL || "https://product.boosturrental.com";
export const sitename = "boosturrental";

// default locale is the first one
export const locales = ["en-US", "es", "it-IT", "fr-FR", "pt-PT", "ar", "he"];

export const databaseUrl = process.env.DATABASE_URL;

// Listing Photos Upload Directory
export const fileuploadDir = process.env.FILEUPLOAD_DIR || "./images/upload/";

// Logo upload directory
export const logouploadDir = process.env.LOGOUPLOAD_DIR || "./images/logo/";

// Home page Banner upload directory
export const banneruploadDir =
  process.env.BANNER_UPLOAD_DIR || "./images/banner/";

// User Profile Photos Upload Directory
export const profilePhotouploadDir =
  process.env.PROFILE_PHOTO_UPLOAD_DIR || "./images/avatar/";

//Document Upload
export const documentuploadDir =
  process.env.FILEUPLOAD_DIR || "./images/document/";

// Location upload directory
export const locationuploadDir =
  process.env.LOGOUPLOAD_DIR || "./images/popularLocation/";

// Static block image upload directory
export const homebanneruploadDir =
  process.env.HOME_BANNER_UPLOAD_DIR || "./images/home/";

// Amenities upload directory
export const amenitiesUploadDir =
  process.env.AMENITIES_UPLOAD_DIR || "./images/amenities/";

// DocumentAffiliate
export const DocumentAffiliateDir =
  process.env.FILEUPLOAD_DIR || "./images/AffiliateDocument/";

// Favicon images update directory
export const faviconUploadDir =
  process.env.FAVICON_UPLOAD_DIR || "./images/favicon/";

// whyHostUploadDir
export const whyHostUploadDir =
  process.env.WHYHOST_UPLOAD_DIR || "./images/whyhost/";

export const analytics = {
  // https://analytics.google.com/
  google: {
    trackingId: "UA-XXXXX-X",
  },
};

export const googleMapAPI = "AIzaSyCahMFhT2mZ5sytEjAJo2YbTV14UoOkMq4";
export const googleMapServerAPI = process.env.GOOGLE_MAP_SERVER_API;

export const payment = {
  paypal: {
    returnURL: `${url}${process.env.PAYPAL_RETURN_URL}`,
    cancelURL: `${url}${process.env.PAYPAL_CANCEL_URL}`,
    redirectURL: {
      success: `${url}${process.env.PAYPAL_SUCCESS_REDIRECT_URL}`,
      successplan: `${url}${process.env.PAYPAL_SUCCESS_REDIRECT_URL_SUBSCRIPTION}`,
      cancel: `${url}${process.env.PAYPAL_CANCEL_URL}`,
    },
  },

  stripe: {
    secretKey: process.env.STRIPE_SECRET /* From ENV */,
    publishableKey: "pk_live_vRogLalXbvIoHzQ9zVEKZYxr",
  },
};

// site key for google recaptcha
//v2
export const googleCaptcha = {
  sitekey: "6LcovuUpAAAAAEGJXpA6NvETt4KaaWWHijNblR_i",
};
//Owners - paradiseterrabiz@gmail.com

// export const googleCaptcha = {
//   sitekey: "6LcdQacpAAAAANC02IOzFNgc24ntfkwaHTHba3Et",
// };
// export const googleCaptcha = {
//   sitekey: "6LfRSacpAAAAAND-53KLkUde4UDLR_zqUmOsqZVE",
// };

//mohit site key - "6LcovuUpAAAAAEGJXpA6NvETt4KaaWWHijNblR_i"
//mohit secret key - "6LcovuUpAAAAALqN5Bmkel0pFxEFEabcDLMracqp"
// link to generate the site key - https://www.google.com/recaptcha/admin
export const auth = {
  jwt: { secret: process.env.JWT_SECRET },

  redirectURL: {
    login: "/dashboard",
    verification: "/user/verification",
    userbanned: "/userbanned",
    returnURLDeletedUser: "/userbanned",
  },

  // https://developers.facebook.com/
  facebook: {
    returnURL: `${url}/login/facebook/return`,
  },

  // https://cloud.google.com/console/project
  google: {
    returnURL: `${url}/login/google/return`,
  },
};

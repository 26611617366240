import moment from 'moment';
import { toastr } from 'react-redux-toastr';
// Redux Form
import { SubmissionError } from 'redux-form';

// Fetch request
import fetch from '../../core/fetch';

// Language
import messages from '../../locale/messages';
import PopulateData from '../../helpers/populateData';

// Redux
// import { setRuntimeVariable } from '../../actions/runtime';
// import { loadAccount } from '../../actions/account';
// import { closeSignupModal } from '../../actions/modalActions';

async function submit(values, dispatch) {

  // console.log("VALUES",values);

//  let typelistobj = {
//   "Amenities":false,
//   "Country":false,
//   "Lorem":false
// };
let typelistobj = {
  "Travel Products":false,
  "Travel Events":false,
  "Other":false
};
  let websiteDriveobj = {
  "ECommerce website":false,
  "Business website":false,
  "Blog website":false,
  "Portfolio website":false,
  "Event website":false,
  "Membership website":false,
  "Personal website":false,
  "Nonprofit website":false,
  "Informational website":false,
  "Online forum":false
};
  let obj = (values.typeList)
  let obj1 = (values.websiteDrive)
  let res ;

  obj.forEach(async function (element, i) {
    
    
    if(element == 1)
    {
    typelistobj['Travel Products'] = true
    }
   else if(element == 2)
    {
      typelistobj['Travel Events'] = true
    }
   else if(element== 3)
    {
      typelistobj['Other'] = true
    }
  })

  obj1.forEach(async function (element, i) {
    
    
    if(element == 1)
    {
      websiteDriveobj['ECommerce website'] = true
    }
   else if(element == 2)
    {
      websiteDriveobj['Business website'] = true
    }
   else if(element== 3)
    {
      websiteDriveobj['Blog website'] = true
    }
    if(element == 4)
    {
      websiteDriveobj['Portfolio website'] = true
    }
   else if(element == 5)
    {
      websiteDriveobj['Event website'] = true
    }
   else if(element== 6)
    {
      websiteDriveobj['Membership website'] = true
    }
    if(element == 7)
    {
      websiteDriveobj['Personal website'] = true
    }
   else if(element == 8)
    {
      websiteDriveobj['Nonprofit website'] = true
    }
   else if(element== 9)
    {
      websiteDriveobj['Informational website'] = true
    }
    else if(element== 10)
    {
      websiteDriveobj['Online forum'] = true
    }
  })

  // console.log("AFTER CONVERSION !!!!!");
  // console.log("websiteDriveobj",JSON.stringify(websiteDriveobj));
  // console.log("typelistobj",JSON.stringify(typelistobj));

  const query = `mutation (
    $websiteName: String, 
    $websiteUrl: String, 
    $websiteAbout: String,
    $typeList: String, 
    $websiteDrive: String,
    $typesOfWebsite: String,
    $primryJoining: String, 
    $websiteVisitors: String,
    $buildLinks: String, 
    $websiteMonitize: String
    ) {
    createAffiliateUserWebList (
      websiteName: $websiteName, 
      websiteUrl: $websiteUrl, 
      websiteAbout: $websiteAbout, 
      typeList: $typeList, 
      websiteDrive: $websiteDrive,
      typesOfWebsite: $typesOfWebsite,
      primryJoining: $primryJoining,
      websiteVisitors: $websiteVisitors,
      buildLinks: $buildLinks,
      websiteMonitize: $websiteMonitize
      ){
            status
    errorMessage
  }
}`;

  
  const params = {
    websiteName: values.websiteName, 
      websiteUrl: values.websiteUrl, 
      websiteAbout: values.websiteAbout, 
      typeList: JSON.stringify(typelistobj), 
      websiteDrive: JSON.stringify(websiteDriveobj), 
      typesOfWebsite: values.typesOfWebsite,
      primryJoining: values.primryJoining,
      websiteVisitors: values.websiteVisitors,
      buildLinks: values.buildLinks,
      websiteMonitize: values.websiteMonitize
    
  };

  const resp = await fetch('/graphql', {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query: query,
      variables: params
    }),
    credentials: 'include'
  });

  const { data } = await resp.json();

  // console.log("Data from response in STEP_2",data);

window.location.href='/affiliate/manageinfo/step3'
}

export default submit;

// import { createAffiliateUser } from '../../actions/siteadmin/AffiliateUser/manageAffiliateUser';

// async function submit(values, dispatch) {
 

//   dispatch(createAffiliateUser(
//     values.id,
//     values.email,
//     values.password,
//     values.findUS,
//     values.phonenumber,

//     // values.roleId
//   ));
// }

// export default submit;

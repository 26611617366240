import React from 'react';
import AdminLayout from '../../../components/Layout/SubadminLayout';
import ManageSubscription from './manageSubscription';
import fetch from '../../../core/fetch';
const title = 'Manage Subscription';
const GET_SUBSCRIPTION_DATA = `query getSubscriptionInfo($searchList:String,$currentPage:Int){
    getSubscriptionInfo(searchList:$searchList,currentPage:$currentPage) {
        count
        Subscriptionuser{
            id
            userId
            planId
            name
            transactionId
            purchaseDate
            expiryDate
            planName
            status
        }
}
}`
export default async function action({ store }) {
    let issubAdminAuthenticated = store.getState().runtime.issubAdminAuthenticated;
    if (!issubAdminAuthenticated) {
        return { redirect: '/subadmin/login' };
    }

    const resp = await fetch('/graphql', {
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            query: GET_SUBSCRIPTION_DATA,
            variables:{searchList:"",currentPage:1}
        }),
    });
    const { data } = await resp.json();
    // console.log("kkksksks",data)
    let subscriptionData =data && data.getSubscriptionInfo && data.getSubscriptionInfo.Subscriptionuser;
    

    return {
        title,
        component: <AdminLayout> <ManageSubscription subscriptionData={subscriptionData} title={title} /> </AdminLayout>,
    };
}

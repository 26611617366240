import React from "react"
import ManageCoupon from "./manageCoupon"
import UserLayout from '../../components/Layout/UserLayout';
import fetch from '../../core/fetch';

const title = "Manage Coupon";


export default async function action({ store, params }) {

  const isAuthenticated = store.getState().runtime.isAuthenticated;
  const type = params.type;
  const listId = params.listId
  if (!isAuthenticated) {
    return { redirect: '/login' };
  }

  return {
    title,
    component: <UserLayout><ManageCoupon listId={listId}/></UserLayout>,
  };
  }
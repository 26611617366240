import React from "react";
import Slider from "react-slick";
import CustomplanForm from "./customPlanForm";
import Loader from "../Loader/Loader";

//Styles
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./host.css";
import cx from "classnames";
import PlanCard from "./planCard";

const nextArrowStyle = {
  // right: '4px',
  color: "#484848",
  zIndex: "0",
  width: "34px",
  height: "34px",
  top: "50%",
  fontSize: "18px",
  cursor: "pointer",
  textAlign: "center",
  backgroundColor: "#fff",
  backgroundRepeat: "repeat-x",
  filter:
    'progid:DXImageTransform.Microsoft.gradient(startColorstr="#00000000",endColorstr="#80000000",GradientType=1)',
  borderRadius: "50%",
};

const prevArrowStyle = {
  // left: '4px',
  color: "#484848",
  zIndex: "1",
  width: "34px",
  height: "34px",
  top: "50%",
  fontSize: "18px",
  cursor: "pointer",
  textAlign: "center",
  backgroundColor: "#fff",
  backgroundRepeat: "repeat-x",
  filter:
    'progid:DXImageTransform.Microsoft.gradient(startColorstr="#80000000",endColorstr="#00000000",GradientType=1)',
  borderRadius: "50%",
};

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={nextArrowStyle} onClick={onClick}>
      <svg
        viewBox="0 0 18 18"
        role="img"
        aria-label="Previous"
        focusable="false"
        style={{
          height: "13px",
          width: "13px",
          display: "block",
          fill: "#484848",
          position: "absolute",
          top: "31%",
          right: "8px",
        }}
      >
        <path d="m4.29 1.71a1 1 0 1 1 1.42-1.41l8 8a1 1 0 0 1 0 1.41l-8 8a1 1 0 1 1 -1.42-1.41l7.29-7.29z"></path>
      </svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={prevArrowStyle} onClick={onClick}>
      <svg
        viewBox="0 0 18 18"
        role="img"
        aria-label="Previous"
        focusable="false"
        style={{
          height: "13px",
          width: "13px",
          display: "block",
          fill: "#484848",
          position: "absolute",
          top: "31%",
          left: "8px",
        }}
      >
        <path d="m13.7 16.29a1 1 0 1 1 -1.42 1.41l-8-8a1 1 0 0 1 0-1.41l8-8a1 1 0 1 1 1.42 1.41l-7.29 7.29z"></path>
      </svg>
    </div>
  );
}
const getColor = (id) => {
  switch (id) {
    case 1:
      return "rgba(251, 140, 0, 0.1)";
    case 2:
      return "rgba(84, 110, 122, 0.1)";
    case 3:
      return "rgba(0, 136, 133, 0.1)";
    case 4:
      return "rgba(29, 190, 29, 0.1)";
    case 5:
      return "rgba(255, 90, 95, 0.1)";
    default:
      return "white";
  }
};
const getTitleColor = (id) => {
  switch (id) {
    case 1:
      return "rgba(251, 140, 0)";
    case 2:
      return "rgba(84, 110, 122)";
    case 3:
      return "rgba(0, 136, 133)";
    case 4:
      return "rgba(29, 190, 29)";
    case 5:
      return "rgba(255, 90, 95)";
    default:
      return "white";
  }
};
const getToggleColor = (id) => {
  switch (id) {
    case 1:
      return "rgba(251, 140, 0,0.6)";
    case 2:
      return "rgba(84, 110, 122,0.6)";
    case 3:
      return "rgba(0, 136, 133,0.6)";
    case 4:
      return "rgba(29, 190, 29,0.6)";
    case 5:
      return "rgba(255, 90, 95)";
    default:
      return "white";
  }
};

class PlanSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSlide: 0,
    };
  }

  render() {
    const { planDetails, nextPage, handleCardDetails,planPrice, planTheme, planHeader,planExpire } = this.props;
    // console.log("INSLIDER MATHOAD", planDetails);
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      prevArrow: <SamplePrevArrow />,
      nextArrow: <SampleNextArrow />,
      beforeChange: (current, next) => this.setState({ currentSlide: next }),
      responsive:[
        {
          breakpoint: 2560,
          settings: {
            slidesToShow:3 || 2 ,
            arrows:true
          },
        },
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 3 || 2,
            arrows:true
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow:2,
            arrows:true
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            arrows:true
          },
        },
      ],
    };

    return (
      <div>
        {planDetails.length == 0 ||
        planDetails == null ||
        planDetails == undefined ? (
          <Loader type={"text"} />
        ) : (
          <Slider {...settings}>
            {planDetails != null &&
              planDetails.length &&
              planDetails.slice(0, -1).map((item, itemIndex) => {
                return (
                  <PlanCard
                    cardDetails={item}
                    cardIndex={itemIndex}
                    getColor={getColor(item.id)}
                    getTitleColor={getTitleColor(item.id)}
                    getToggleColor={getToggleColor(item.id)}
                    nextPage={nextPage}
                    handleCardDetails={handleCardDetails}
                    planPrice={planPrice}
                    planTheme={planTheme}
                    planHeader={planHeader}
                    planExpire={planExpire}
                  />
                );
              })}
            <div>
              <CustomplanForm
                getColor={getColor(5)}
                getTitleColor={getTitleColor(5)}
              />
            </div>
          </Slider>
        )}
      </div>
    );
  }
}

export default withStyles(s)(PlanSlider);

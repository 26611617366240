import React from 'react';
import PropTypes from 'prop-types';

// Translation
import { injectIntl, FormattedMessage } from 'react-intl';

// Style
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  Button,
  Col,
  Panel
} from 'react-bootstrap';
import bt from '../../../components/commonStyle.css';
import s from './NoItem.css';

import Link from '../../Link';
import { connect } from 'react-redux';

// Internal Helpers
import history from '../../../core/history';

// Locale
import messages from '../../../locale/messages';
import noListImage from '../../../../public/SiteImages/noListImage.svg';
const GET_PROPERTY_COUNT = `query getPropertieCount($userId: String,){
  getPropertieCount(userId:$userId){
    errormessage
      status
results{
  planId
  propertieCount
}
  }
}`
class NoItem extends React.Component {
  static propTypes = {
    formatMessage: PropTypes.any,
  };
  constructor(props) {
    super(props);
    this.state = {
      redirect: false
    }
  }
  async componentDidMount() {
    const {userId} = this.props

    const resp = await fetch('/graphql', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query: GET_PROPERTY_COUNT,
        variables: { userId: userId }
      }),
    });
    const { data } = await resp.json();
    if (data && data.getPropertieCount.status == 200) {
      this.setState({ redirect: true })
    }
    // console.log("Noitem in knw", userId) 
    // console.log("datadatadatadatadatadata", data) 
  }
  handleClick() {
    history.push('/become-a-host');
  }

  render() {
  const {redirect} = this.state;
  // console.log("redirect", redirect) 

    return (
      <Panel className={cx(s.panelEmpty, 'managelisitinempty', 'bgBlackTwo')}>
        <Col xs={12} sm={8} md={9} lg={9} className={cx(s.space4, s.panelSpace)}>
          <div className={s.textCenter}>
            <img src={noListImage} />
            <div className={s.noListHeading}>
              <FormattedMessage {...messages.Nohostitem} />
            </div>
            {/* <div className={s.noListSubHeading}>
              <FormattedMessage {...messages.noListSmall} />
            </div> */}
{/*             
              <Link to={redirect ? '/become-a-host?mode=new':'/host/membership' } className={cx(bt.btnPrimary, s.addNewBtn)}>
                <FormattedMessage {...messages.addNewlisting} />
              </Link> */}
            
            {/* <Link to={'/become-a-host?mode=new'} className={cx(bt.btnPrimary, s.addNewBtn)}>
              <FormattedMessage {...messages.addNewlisting} />
            </Link> */}
          </div>
        </Col>
      </Panel>
    );
  }
}
const mapState = (state) => ({
  userId: state.account.data.userId,
});

const mapDispatch = {
};
// export default injectIntl(withStyles(s, bt)(NoItem));

export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(NoItem)));

// General
import React from 'react';
import PropTypes from 'prop-types';
// Redux
import { connect } from 'react-redux';

// Styles
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Header.css';
import * as FontAwesome from 'react-icons/lib/fa'
import cx from 'classnames';
import AffiliateHeaderLocationSearch from './AffiliateHeaderLocationSearch';
import {
  Navbar
} from 'react-bootstrap';

// Internal Components
import AffiliateNavigation from '../siteadmin/AffiliateNavigation';
import Logo from '../Logo/Logo';
import Navigation from '../Navigation';

// import { toggleOpen, toggleClose } from '../../actions/Menu/toggleControl';

// import history from '../../core/history';
// import { closeLoginModal } from '../../actions/modalActions';
import DropDownMenu from '../DropDownMenu/DropDownMenu';

//Image
import closeIcon from '../../../public/SiteIcons/loginClose.svg';

// External Components
import Toaster from '../Toaster/Toaster';
import LoadingBar from 'react-redux-loading-bar';

class AdminHeader extends React.Component {
  static propTypes = {
    borderLess: PropTypes.bool
  };

  static defaultProps = {
    borderLess: false
  }

  render() {
    const { siteSettings, borderLess,page } = this.props;
    let borderClass;
    if (borderLess) {
      borderClass = s.rentAllHeaderBorderLess;
    }
    return (
      <div className={s.root}>
        <Toaster />
        <LoadingBar />
        <div className={s.container}>
          <Navbar fluid className={cx(s.rentAllHeaderAdmin, 'rentAllAdminHeader', 'rentallAdminHeaderNoBorder', 'hidden-xs')} collapseOnSelect>
            <Navbar.Header>
              <Navbar.Brand className={cx('hidden-xs')}>
                <Logo link={"/affiliateDashboard"} className={cx(s.brandAdmin, s.brandImg)} />
              </Navbar.Brand>
            </Navbar.Header>
            {
              <Navbar.Form pullLeft className={cx('hidden-xs', s.breakPoint, 'searchHeaderAR')} style={{position: "absolute",
                left: "27%",
                width: "43%"}}>
                <AffiliateHeaderLocationSearch page={page} />
              </Navbar.Form>
            }
            <Navbar.Collapse>
              <AffiliateNavigation />
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>

      // <div className={s.root}>
      //   <Toaster />
      //   <LoadingBar />
      //   <div className={cx(s.container, 'bgBlack', 'dashboardBottomLayout')}>
      //     <Navbar className={cx(s.rentAllHeaderAdmin, 'rentAllAdminHeader', 'rentallAdminHeaderNoBorder', 'hidden-xs')} collapseOnSelect>
      //       <Navbar.Header>
      //         <Navbar.Brand className={cx('hidden-xs')}>
      //           <Logo link={"/affiliateDashboard"} className={cx(s.brandAdmin, s.brandImg)} />
      //         </Navbar.Brand>
      //         <div onClick={() => this.openMenu()}>
      //           <div className={cx('hidden-lg', 'hamburgerButton', 'hamburgerInnerButton')}>
      //             <DropDownMenu />
      //           </div>
      //         </div>
      //       </Navbar.Header>
      //       {/* {
      //         !searchHide && <Navbar.Form pullLeft className={cx('hidden-xs', s.breakPoint, 'searchHeaderAR')}>
      //           {!whyHostSearchHide && <AffiliateHeaderLocationSearch page={page} />}
      //         </Navbar.Form>
      //       } */}
      //       <div className={cx({ [s.menuOpen]: this.state.isOpen == 1 }, s.mobileMenu, 'homeMobileMenu', 'mobileMenuDark')}>
      //         <div className={cx({ [s.menuClose]: this.state.isOpen == 0 }, s.rightMenuClose, 'hidden-lg')}>
      //           <div className={cx(s.closeButtonPosition, 'closeButtonPositionDark')}>
      //             <div className={cx(s.closeColor, 'textWhite', 'closeColorRTL', 'svgImg')} onClick={() => this.openClose()} >
      //               <img src={closeIcon} />
      //             </div>
      //           </div>
      //         </div>
      //         <div onClick={() => this.openClose()}>
      //           <Navigation whyHostSearchHide={whyHostSearchHide} page={page} />
      //         </div>
      //       </div>
      //     </Navbar>
      //   </div>
      // </div>
    );
  }
}

const mapState = (state) => ({
  siteSettings: state.siteSettings.data,
});

const mapDispatch = {};

export default withStyles(s)(connect(mapState, mapDispatch)(AdminHeader));

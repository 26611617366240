

// Fetch request
import fetch from '../../../core/fetch';

// Redux
import { affiliateGetSearchResults, loadingSearchResults } from '../../../actions/affiliateGetSearchResults';

async function AffiliateSubmit(values, dispatch) {

  // dispatch(loadingSearchResults());
  const query =
    `query ( $userId: String,$address: String,$orderBy: String) {
      affiliateSearchListing( userId: $userId,address: $address,orderBy: $orderBy) {
        results {
          id
           title
           city
           affiliateId
           referralId
            isGenerated
           description
          listPhotos {
            id
            name
            type
          }
          listingData {
            basePrice
            currency
          }
        }
        status
        errorMessage
      }
    }
  `;



  const resp = await fetch('/graphql', {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query: query,
      variables: values
    }),
    credentials: 'include',
  });

  const { data } = await resp.json();
  console.log(data+" affiliateSearchListing");
  if (data) {
    
    if (data && data.affiliateSearchListing) {
    
    // dispatch(affiliateGetSearchResults(data.affiliateSearchListing));
  }
  }
}


export default AffiliateSubmit;

import React from "react";
import NewListLayout from "../../components/Layout/AffiliateNewListLayout";
import Manageinfo from "./affiliateDashboard";

const title = "Affiliate Dashboard";

export default async function action({ store }) {

  let isAuthenticated = store.getState().runtime.isAuthenticated;
  let userId =store.getState().user && store.getState().user.id;
  if (!isAuthenticated) {
    return { redirect: '/login' };
  }
  return {
    title,
    component: (
      <NewListLayout>
        <Manageinfo title={title} userId={userId}/>
      </NewListLayout>
    ),
  };
}

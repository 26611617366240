// // General
// import React, { Component } from 'react';
// import PropTypes from 'prop-types';

// // Redux Form
// import { Field, reduxForm } from 'redux-form';

// // Redux
// import { connect } from 'react-redux';

// // Translation
// import { injectIntl, FormattedMessage } from 'react-intl';

// // Locale
// import messages from '../../locale/messages';


// // Helpers
// import validateStep3 from './validateStep3';

// // Style
// import withStyles from 'isomorphic-style-loader/lib/withStyles';
// import cx from 'classnames';
// import {
//   Grid,
//   Button,
//   Row,
//   FormGroup,
//   Col,
//   ControlLabel,
//   FormControl,
//   Checkbox,
//   Alert
// } from 'react-bootstrap';
// import s from './ListPlaceStep1.css';
// import bt from '../../components/commonStyle.css';

// // Component
// import updateStep3 from './updateStep3';
// import FooterButton from './FooterButton';
// import SidePanel from './SidePanel';
// import CustomCheckbox from '../CustomCheckbox';

// //Image
// import toolTipIcon from '../../../public/SiteIcons/listCommonToolTip.svg';
// import underscore from "../../../public/SiteImages/underscore.svg";

// class Pricing extends Component {

//   static propTypes = {
//     previousPage: PropTypes.any,
//     nextPage: PropTypes.any
//   };

//   constructor(props) {
//     super(props);
//     this.state = {
//       isDisabled: true,
//       isvisible: false,
//       isResize:false,
//     };
//   }

//   UNSAFE_componentWillMount() {
//     const { valid, listingFields } = this.props;

//     if (valid) {
//       this.setState({ isDisabled: false });
//     } else {
//       this.setState({ isDisabled: true });
//     }
//   }

//   UNSAFE_componentWillReceiveProps(nextProps) {
//     const { valid, listingFields } = nextProps;

//     if (valid) {
//       this.setState({ isDisabled: false });
//     } else {
//       this.setState({ isDisabled: true });
//     }
//   }

//   handleFocus = () => {
//     const {isResize} = this.state
//     this.setState({ isvisible: true });
//     if(window.innerWidth <= 750){
//       this.setState({isResize:true})
//     }
//   }

//   handleBlur = () => {
//     this.setState({ isvisible: false });
//     this.setState({isResize:false})
//   }

//   renderFormControl = ({ input, label, type, meta: { touched, error }, className }) => {
//     const { formatMessage } = this.props.intl;
//     const { isvisible,isResize } = this.state;
//     const { pricing } = this.props;
//     return (
//       <div>
//         <FormControl
//           {...input}
//           placeholder={label}
//           type={type}
//           className={className}
//           maxLength={12}
//         />
//         {
//           isvisible && <div className={s.priceCardContainer} >
//             <div className={s.bestPricePara} >
//            Best Price you can put here
//               <img className={s.underscoreSvg}  src={underscore}  />
// <img style={{width:"20%"}} src='https://cdn.trustpulse.com/wp-content/themes/trustpulse/assets/images/arrow-pointing-down-reversed.png'  />
//             </div>
//             <div className={s.secMainDiv}>
//               <div style={{ width: "50%" }}> <img style={{ width: "100%", borderRadius: "50%" }} src='https://img.freepik.com/premium-vector/quick-tips-icon-badge-top-tips-advice-note-icon-idea-bulb-education-tricks_268834-838.jpg?w=2000' />
//               </div>
//               <div className={s.h3pTagDiv} >
//                 <h3>${pricing}</h3>
//                 <p>Based on your geo location and occupancy pattern</p>
//               </div>
//             </div>
//           </div>
//         }
//         {
//           isvisible && <img className={s.secArrowImg} src='https://cdn.trustpulse.com/wp-content/themes/trustpulse/assets/images/arrow-pointing-down-reversed.png' />
//         }
//         {
//           isvisible && isResize && <Alert  bsStyle="success" >
//             <strong>😀 you can put ${pricing} here!</strong> <br/> Based on your geo location🌍 and occupancy pattern
//           </Alert>
//         }
//         {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
//       </div>
//     )
//   }
//   renderFormControlWork = ({ input, meta: { touched, error }, label, name }) => {
//     const { formatMessage } = this.props.intl;
//     return (
//         <div>
//             {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
//             <span className={s.checkBoxSection}>
//                 <CustomCheckbox
//                     name={name}
//                     className={'icheckbox_square-green'}
//                     checked={input.value == true}
//                     onChange={event => {
//                         return input.onChange(event,"=========================");
//                     }}
//                 />
//                  </span>
//                 <span className={cx(s.checkBoxSection, s.checkBoxLabel)}>
//                   <label className={cx(s.checkboxLabel, s.noPadding, 'textWhite')}><FormattedMessage {...messages.isaffiliate} /></label>
//                 </span>
//             </div>

//     )
// }

//   renderFormControlSelect = ({ input, label, meta: { touched, error }, children, className }) => {
//     const { formatMessage } = this.props.intl;
//     return (
//       <div>
//         <FormControl componentClass="select" {...input} className={className} >
//           {children}
//         </FormControl>
//       </div>
//     )
//   }

//   render() {
//     const { handleSubmit, nextPage, previousPage, formPage, step } = this.props;
//     const { isDisabled } = this.state;
//     const { formatMessage } = this.props.intl;
//     const { availableCurrencies,pricing } = this.props;
//     console.log(pricing,"pricing================")
//     return (
//       <div className={s.grid}>
//         <SidePanel
//           title={formatMessage(messages.stepThreeCommonHeading)}
//           landingContent={formatMessage(messages.tabPricing)}
//         />
//         <form onSubmit={handleSubmit}>
//           <div className={s.landingMainContent}>
//             <FormGroup className={s.formGroup}>
//               <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
//                 <FormattedMessage {...messages.currency} />
//               </ControlLabel>

//               <Field name="currency" component={this.renderFormControlSelect} className={cx(s.formControlSelect, s.jumboSelect, s.listCommonJumboSelect, 'listCommonJumboSelectRTL')} >
//                 {
//                   availableCurrencies.map((currency, key) => {
//                     if (currency.isEnable === true) {
//                       return <option key={key} value={currency.symbol}>{currency.symbol}</option>
//                     }
//                   })
//                 }
//               </Field>
//             </FormGroup>
//             <FormGroup className={cx(s.formGroup, s.space4, s.spaceTop4)}>
//               <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
//                 <FormattedMessage {...messages.basePrice} />
//               </ControlLabel>
//               <Field
//                 onFocus={this.handleFocus}
//                 onBlur={this.handleBlur}
//                 name="basePrice"
//                 type="text"
//                 component={this.renderFormControl}
//                 label={formatMessage(messages.basePriceLabel)}
//                 className={cx(s.formControlInput, s.jumboSelect, s.listCommonJumboSelect, 'listCommonJumboSelectRTL')}
//               />
//             </FormGroup>
//             <div className={s.tipCommonCss}>
//               <img src={toolTipIcon} />
//               <span className={cx(s.commonTipCsss, 'textWhite')}><FormattedMessage {...messages.basePriceTip} /></span>
//             </div>
//             <FormGroup className={cx(s.formGroup, s.space4)}>
//               <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
//                 <FormattedMessage {...messages.cleaningPrice} />
//               </ControlLabel>
//               <Field
//                 name="cleaningPrice"
//                 type="text"
//                 component={this.renderFormControl}
//                 label={formatMessage(messages.cleaningPrice)}
//                 className={cx(s.formControlInput, s.jumboSelect, s.listCommonJumboSelect, 'listCommonJumboSelectRTL')}
//               />
//             </FormGroup>
//             <Field
//               	name="is_affiliate"
//                 component={this.renderFormControlWork}
//               	increaseArea="20%" >
//                 <FormattedMessage {...messages.isaffiliate} />
//               </Field>
//             <FormGroup className={cx(s.formGroup,s.spaceTop3, s.space4)}>
//               <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
//                 <FormattedMessage {...messages.revenuepercentages} />
//               </ControlLabel>
//               <Field
//                 name="affiliate_commission"
//                 type="text"
//                 component={this.renderFormControl}
//                 label={formatMessage(messages.revenuepercentages)}
//                 className={cx(s.formControlInput, s.jumboSelect, s.listCommonJumboSelect, 'listCommonJumboSelectRTL')}
//               />
//             	</FormGroup>
//           </div>
//           <FooterButton
//             nextPage={nextPage}
//             previousPage={previousPage}
//             nextPagePath={"discount"}
//             previousPagePath={"advance-notice"}
//             formPage={formPage}
//             step={step}
//             isDisabled={isDisabled}
//           />
//         </form>
//       </div>
//     );
//   }
// }

// Pricing = reduxForm({
//   form: 'ListPlaceStep3', // a unique name for this form
//   destroyOnUnmount: false,
//   forceUnregisterOnUnmount: true,
//   validate: validateStep3,
//   onSubmit: updateStep3
//   // onSubmit: updateStep4

// })(Pricing);

// const mapState = (state) => ({
//   listingFields: state.listingFields.data,
//   availableCurrencies: state.currency.availableCurrencies,
//   base: state.currency.base,
//   pricing: state.calendar.pricingRate,
//   mapUpdateLoading: state.location.mapUpdateLoading
// });

// const mapDispatch = {};

// export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(Pricing)));

// General
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux Form
import { Field, reduxForm, formValueSelector } from 'redux-form';

// Redux
import { connect } from 'react-redux';

// Translation
import { injectIntl, FormattedMessage } from 'react-intl';

// Locale
import messages from '../../locale/messages';


// Helpers
import validateStep3 from './validateStep3';

// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
// import {
//   Grid,
//   Button,
//   Row,
//   FormGroup,
//   Col,
//   ControlLabel,
//   FormControl,
//   Checkbox,
//   Alert
// } from 'react-bootstrap';
import {
  Grid,
  Button,
  Row,
  FormGroup,
  Col,
  ControlLabel,
  FormControl,
  Checkbox,
  Alert,
  Modal,
  Table,
  OverlayTrigger
} from 'react-bootstrap';
import s from './ListPlaceStep1.css';
import bt from '../../components/commonStyle.css';

// Component
import updateStep3 from './updateStep3';
import FooterButton from './FooterButton';
import SidePanel from './SidePanel';
import CustomCheckbox from '../CustomCheckbox';

//Image
import toolTipIcon from '../../../public/SiteIcons/listCommonToolTip.svg';
import underscore from "../../../public/SiteImages/underscore.svg";
const GET_PRICE_QUERY = `query pricing($listId:Int){
    pricing(listId:$listId){
        errorMessage
        status
        results{
            id,
            title,
            distance,
            basePrice,
            score,
            UserAmenities{
                    itemName,
                    score
                }
                sefetyAmenities{
                    itemName,
                    score
                }
                places{
                      itemName,
                    score
                }
                userspace{
                      itemName,
                    score
                }
                otherdata{
                    bedrooms,
                    personCapacity,
                    cancle,
                    bookingtype
                }
                rating{
                    rating
                }
                occupacy{
                    occupacy
                }
            
        }
        currentPropertyResult{
                  id,
            title,
            distance,
            basePrice,
            score,
            UserAmenities{
                    itemName,
                    score
                }
                sefetyAmenities{
                    itemName,
                    score
                }
                places{
                      itemName,
                    score
                }
                userspace{
                      itemName,
                    score
                }
                otherdata{
                    bedrooms,
                    personCapacity,
                    cancle,
                    bookingtype
                }
                rating{
                    rating
                }
                occupacy{
                    occupacy
                }
        }
    }
}`;

const GET_AFFILIATE_VALUE = `query ($listId:String!, $preview: Boolean) {
  UserListing (listId:$listId, preview: $preview) {
    listingData {
      is_affiliate
    }

  }
}`
class Pricing extends Component {

  static propTypes = {
    previousPage: PropTypes.any,
    nextPage: PropTypes.any
  };

  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
      isvisible: false,
      isResize: false,
      show: false,
      priceData: [],
      priceData1: [],
      showPrice: false,
      showAffiliate: false,
      showPricing: false,
      priceMessage: ""
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { valid, listingFields } = this.props;

    if (valid) {
      this.setState({ isDisabled: false });
    } else {
      this.setState({ isDisabled: true });
    }

  }
  async componentDidMount() {
    const { listId } = this.props;
    // console.log(listId,"listId");
    const locationResp = await fetch('/graphql', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: GET_PRICE_QUERY,
        // variables:"133"
        variables: { listId: listId }
      }),
      credentials: 'include',
    });
    const { data } = await locationResp.json();
    this.setState({ priceMessage: data.pricing.errorMessage })
    // console.log("GET_PRICE_QUERY",data)
    if (data) {
      if (data.pricing.results.length > 0) {
        this.setState({ showPrice: true })
      } else {
        this.setState({ showPrice: false })
      }

      //   this.setState({priceData:data.data.pricing.results}) 
      if (data.pricing.results.length > 0) {
        this.setState({ priceData: data.pricing.results[0] })
        this.setState({ priceMessage: data.pricing.errorMessage })
      }
      if (data.pricing.currentPropertyResult.length > 0) {
        this.setState({ priceData1: data.pricing.currentPropertyResult[0] })

      } else {
        this.setState({ showPrice: false });
      }

    } else if (data.pricing.status == 400) {
      this.setState({ showPrice: false })
    }

    const affiliate_resp = await fetch('/graphql', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: GET_AFFILIATE_VALUE,
        // variables:"133"
        variables: { listId: listId, preview: true }
      }),
      credentials: 'include',
    });
    const data1 = await affiliate_resp.json();

    // console.log("affiliate_resp",data1.data.UserListing.listingData.is_affiliate)
    if (data1 && data1.data.UserListing.listingData.is_affiliate == 0) {
      this.setState({ showAffiliate: false })
    } else if (data1 && data1.data.UserListing.listingData.is_affiliate == 1) {
      this.setState({ showAffiliate: true })
    }
    // console.log("GET_PRICE_QUERY======>",data)
    //data.data.pricing.results
    //data.data.pricing.results.UserAmenities
    //data.data.pricing.results..sefetyAmenities
    //data.data.pricing.currentPropertyResult
    //title,distance,score
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { valid, listingFields } = nextProps;

    if (valid) {
      this.setState({ isDisabled: false });
    } else {
      this.setState({ isDisabled: true });
    }
  }

  handleFocus = () => {
    const { isResize, showPrice, priceData } = this.state
    // this.setState({ isvisible: true });
    // this.setState({ showPrice: true });
    if (priceData && priceData.basePrice) {
      this.setState({ showPrice: true });
    } else {
      this.setState({ showPrice: false });
    }

    if (window.innerWidth <= 750) {
      this.setState({ isResize: true })
    }


  }

  handleBlur = () => {
    this.setState({ isvisible: true });
    this.setState({ showPrice: false });
    this.setState({ isResize: false })
  }

  renderFormControl = ({ input, label, type, meta: { touched, error }, className }) => {
    const { formatMessage } = this.props.intl;
    const { isvisible, isResize, priceMessage, priceData,showPrice } = this.state;
    const { pricing ,pricingValue} = this.props;
    console.log("pricing",pricingValue)
    return (
      <div>
        <FormControl
          {...input}
          placeholder={label}
          type={type}
          className={className}
          maxLength={12}
        />
        {
        pricingValue &&  isvisible && <div className={s.priceCardContainer} >
            <div className={s.bestPricePara} >
              {priceMessage}
              <img className={s.underscoreSvg} src={underscore} />
              <img style={{ width: "20%" }} src='https://cdn.trustpulse.com/wp-content/themes/trustpulse/assets/images/arrow-pointing-down-reversed.png' />
            </div>
            <div className={s.secMainDiv}>
              <div style={{ width: "50%" }}> <img style={{ width: "100%", borderRadius: "50%" }} src='https://img.freepik.com/premium-vector/quick-tips-icon-badge-top-tips-advice-note-icon-idea-bulb-education-tricks_268834-838.jpg?w=2000' />
              </div>
              <div className={s.h3pTagDiv} >
              {showPrice &&  <h3>{showPrice?"$":""}{priceData && priceData.basePrice}</h3>}
                <p>Based on your geo location and occupancy pattern</p>
              </div>
            </div>
          </div>

        }
        {
          isvisible && <img className={s.secArrowImg} src='https://cdn.trustpulse.com/wp-content/themes/trustpulse/assets/images/arrow-pointing-down-reversed.png' />
        }
        {
          isvisible && isResize && <Alert bsStyle="success" >
            <strong>😀 you can put ${priceData && priceData.basePrice} here!</strong> <br /> Based on your geo location🌍 and occupancy pattern
          </Alert>
        }
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </div>
    )
  }
  renderFormControlWork = ({ input, meta: { touched, error }, label, name }) => {
    const { formatMessage } = this.props.intl;
    // this.componentDidUpdate = ()=>{
    //   console.log("componentDidMount",input);
    //   if(input.value == true){
    //     console.log("event",input.value);
    //     this.setState({showAffiliate:true})
    //   }else{
    //     this.setState({showAffiliate:false})
    //     console.log("event f",input.value);
    //   }

    // }
    const { showAffiliate } = this.state;
    const { isAffiliate } = this.props;

    return (
      <div>
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
        <span className={s.checkBoxSection}>
          <CustomCheckbox
            name={name}
            className={'icheckbox_square-green'}
            checked={isAffiliate == 1 ? true : false}
            onChange={event => {
              if (event == true) {
                // console.log("event",event);
                this.setState({ showAffiliate: true })
              }
              return input.onChange(event, "=========================");
            }}
          />
        </span>
        <span className={cx(s.checkBoxSection, s.checkBoxLabel)}>
          <label className={cx(s.checkboxLabel, s.noPadding, 'textWhite')}><FormattedMessage {...messages.isaffiliate} /></label>
        </span>
      </div>

    )
  }

  renderFormControlSelect = ({ input, label, meta: { touched, error }, children, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormControl componentClass="select" {...input} className={className} >
          {children}
        </FormControl>
      </div>
    )
  }

  render() {
    const { handleSubmit, nextPage, previousPage, formPage, step } = this.props;
    const { isDisabled, priceData, priceData1, isvisible, showPrice, showAffiliate } = this.state;
    const { formatMessage } = this.props.intl;
    const { availableCurrencies, pricing, isAffiliate,pricingValue } = this.props;

    // console.log(priceData1,"priceData1")
    //  console.log(priceData,"priceData")
    //  console.log(isAffiliate,"isAffiliate")
    // let currentResult = [];


    // if(priceData.length > 0){
    //     currentResult = priceData;
    //     console.log(priceData,"pricing1111111================")
    // }else{
    //     console.log(priceData,"pricing1111111empty================")
    // } 






    // let currentResult = priceData.pricing.results[0];

    return (
      <div className={s.grid}>
        <SidePanel
          title={formatMessage(messages.stepThreeCommonHeading)}
          landingContent={formatMessage(messages.tabPricing)}
        />
        <form onSubmit={handleSubmit}>
          <div className={s.landingMainContent}>
            <FormGroup className={s.formGroup}>
              <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                <FormattedMessage {...messages.currency} />
              </ControlLabel>

              <Field name="currency" component={this.renderFormControlSelect} className={cx(s.formControlSelect, s.jumboSelect, s.listCommonJumboSelect, 'listCommonJumboSelectRTL')} >
                {
                  availableCurrencies.map((currency, key) => {
                    if (currency.isEnable === true) {
                      return <option key={key} value={currency.symbol}>{currency.symbol}</option>
                    }
                  })
                }
              </Field>
            </FormGroup>
            <FormGroup className={cx(s.formGroup, s.space4, s.spaceTop4)}>
              <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                <FormattedMessage {...messages.basePrice} />
              </ControlLabel>
              <Field
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                name="basePrice"
                type="text"
                component={this.renderFormControl}
                label={formatMessage(messages.basePriceLabel)}
                className={cx(s.formControlInput, s.jumboSelect, s.listCommonJumboSelect, 'listCommonJumboSelectRTL')}
              />

              {/* {<Button bsStyle="primary" bsSize="small" onClick={this.handleShow}>Detail</Button>} */}
              <span className={cx(s.commonTipCsss1, 'textWhite')}><FormattedMessage {...messages.basePriceTip} /></span>


              <Modal show={this.state.show} onHide={this.handleClose} bsSize="large">
                <Modal.Header closeButton>
                  <Modal.Title>Compare Properties</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row><Col md={6} xs={6} lg={6} sm={6}>
                    {
                      priceData1 &&
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th colSpan={2}>Current Poperty</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Title</td>
                            <td>{priceData1.title}</td>
                          </tr>

                          <tr>
                            <td>Score</td>
                            <td><b>{priceData1.score}</b></td>
                          </tr>

                          {priceData1.places &&
                            <tr>
                              <td colSpan={2}><b>Place Type</b></td>
                            </tr>
                          }
                          {priceData1.places && priceData1.places.map(data => (
                            <tr>
                              <td>{data.itemName}</td>
                              <td>{data.score}</td>
                            </tr>
                          ))}


                          {priceData1.UserAmenities &&
                            <tr>
                              <td colSpan={2}><b>Amenities</b></td>
                            </tr>
                          }
                          {priceData1.UserAmenities && priceData1.UserAmenities.map(data => (
                            <tr>
                              <td>{data.itemName}</td>
                              <td>{data.score}</td>
                            </tr>
                          ))}


                          {priceData1.sefetyAmenities &&
                            <tr>
                              <td colSpan={2}><b>Safety Amenities</b></td>
                            </tr>
                          }
                          {priceData1.sefetyAmenities && priceData1.sefetyAmenities.map(data => (
                            <tr>
                              <td>{data.itemName}</td>
                              <td>{data.score}</td>
                            </tr>
                          ))}


                          {priceData1.userspace &&
                            <tr>
                              <td colSpan={2}><b>Shares Spaces</b></td>
                            </tr>
                          }
                          {priceData1.userspace && priceData1.userspace.map(data => (
                            <tr>
                              <td>{data.itemName}</td>
                              <td>{data.score}</td>
                            </tr>
                          ))}

                          {priceData1.otherdata &&
                            <tr>
                              <td colSpan={2}><b>Size, Guest capacity, Cancellation Policy,Booking Type </b></td>
                            </tr>
                          }

                          {priceData1.otherdata && priceData1.otherdata.map(data => (
                            data.bedrooms &&
                            <tr>
                              <td>Size (Bedrooms)</td>
                              <td>{data.bedrooms}</td>
                            </tr>
                          ))}

                          {priceData1.otherdata && priceData1.otherdata.map(data => (
                            data.personCapacity &&
                            <tr>
                              <td>Guest capacity</td>
                              <td>{data.bedrooms}</td>
                            </tr>
                          ))}

                          {priceData1.otherdata && priceData1.otherdata.map(data => (
                            data.cancle &&
                            <tr>
                              <td>Cancellation Policy - <small>Flexible (5), Moderate (3), Strict (1)</small></td>
                              <td>{data.cancle}</td>
                            </tr>
                          ))}

                          {priceData1.otherdata && priceData1.otherdata.map(data => (
                            data.bookingtype &&
                            <tr>
                              <td>Booking Type - <small>Instant Booking (5), Request Booking (1)</small></td>
                              <td>{data.bookingtype}</td>
                            </tr>
                          ))}


                          {priceData1.rating &&
                            <tr>
                              <td colSpan={2}><b>Rating - Review</b></td>
                            </tr>
                          }
                          {priceData1.rating && priceData1.rating.map(data => (
                            <tr>
                              <td><small>Round of Average of number of rating</small></td>
                              <td>{data.rating}</td>
                            </tr>
                          ))}

                          {priceData1.occupacy &&
                            <tr>
                              <td colSpan={2}><b>Occupacy Rate</b></td>
                            </tr>
                          }
                          {priceData1.occupacy && priceData1.occupacy.map(data => (
                            <tr>
                              <td><small>(Total booked nights/Total nights) * 100</small></td>
                              <td>{data.occupacy}</td>
                            </tr>
                          ))}

                        </tbody>
                      </Table>


                    }


                  </Col>
                    <Col md={6} xs={6} lg={6} sm={6}>
                      {
                        priceData &&
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th colSpan={2}>Result Poperty</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Title</td>
                              <td>{priceData.title}</td>
                            </tr>
                            <tr>
                              <td>Base Price</td>
                              <td>{priceData.basePrice}</td>
                            </tr>
                            <tr>
                              <td>Distance</td>
                              <td>{priceData.distance}</td>
                            </tr>
                            <tr>
                              <td>Score</td>
                              <td><b>{priceData.score}</b></td>
                            </tr>

                            {priceData.places &&
                              <tr>
                                <td colSpan={2}><b>Place Type</b></td>
                              </tr>
                            }
                            {priceData.places && priceData.places.map(data => (
                              <tr>
                                <td>{data.itemName}</td>
                                <td>{data.score}</td>
                              </tr>
                            ))}


                            {priceData.UserAmenities &&
                              <tr>
                                <td colSpan={2}><b>Amenities</b></td>
                              </tr>
                            }
                            {priceData.UserAmenities && priceData.UserAmenities.map(data => (
                              <tr>
                                <td>{data.itemName}</td>
                                <td>{data.score}</td>
                              </tr>
                            ))}


                            {priceData.sefetyAmenities &&
                              <tr>
                                <td colSpan={2}><b>Safety Amenities</b></td>
                              </tr>
                            }
                            {priceData.sefetyAmenities && priceData.sefetyAmenities.map(data => (
                              <tr>
                                <td>{data.itemName}</td>
                                <td>{data.score}</td>
                              </tr>
                            ))}


                            {priceData.userspace &&
                              <tr>
                                <td colSpan={2}><b>Shares Spaces</b></td>
                              </tr>
                            }
                            {priceData.userspace && priceData.userspace.map(data => (
                              <tr>
                                <td>{data.itemName}</td>
                                <td>{data.score}</td>
                              </tr>
                            ))}

                            {priceData.otherdata &&
                              <tr>
                                <td colSpan={2}><b>Size, Guest capacity, Cancellation Policy,Booking Type </b></td>
                              </tr>
                            }

                            {priceData.otherdata && priceData.otherdata.map(data => (
                              data.bedrooms &&
                              <tr>
                                <td>Size (Bedrooms)</td>
                                <td>{data.bedrooms}</td>
                              </tr>
                            ))}

                            {priceData.otherdata && priceData.otherdata.map(data => (
                              data.personCapacity &&
                              <tr>
                                <td>Guest capacity</td>
                                <td>{data.bedrooms}</td>
                              </tr>
                            ))}

                            {priceData.otherdata && priceData.otherdata.map(data => (
                              data.cancle &&
                              <tr>
                                <td>Cancellation Policy - <small>Flexible (5), Moderate (3), Strict (1)</small></td>
                                <td>{data.cancle}</td>
                              </tr>
                            ))}

                            {priceData.otherdata && priceData.otherdata.map(data => (
                              data.bookingtype &&
                              <tr>
                                <td>Booking Type - <small>Instant Booking (5), Request Booking (1)</small></td>
                                <td>{data.bookingtype}</td>
                              </tr>
                            ))}


                            {priceData.rating &&
                              <tr>
                                <td colSpan={2}><b>Rating - Review</b></td>
                              </tr>
                            }
                            {priceData.rating && priceData.rating.map(data => (
                              <tr>
                                <td><small>Round of Average of number of rating</small></td>
                                <td>{data.rating}</td>
                              </tr>
                            ))}

                            {priceData.occupacy &&
                              <tr>
                                <td colSpan={2}><b>Occupacy Rate</b></td>
                              </tr>
                            }
                            {priceData.occupacy && priceData.occupacy.map(data => (
                              <tr>
                                <td><small>(Total booked nights/Total nights) * 100</small></td>
                                <td>{data.occupacy}</td>
                              </tr>
                            ))}

                          </tbody>
                        </Table>


                      }


                    </Col>
                  </Row>


                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={this.handleClose}>Close</Button>
                </Modal.Footer>
              </Modal>
            </FormGroup>
            <div className={s.tipCommonCss}>
              <img src={toolTipIcon} />
              <span className={cx(s.commonTipCsss, 'textWhite')}><FormattedMessage {...messages.basePriceTip} /></span>
            </div>
            <FormGroup className={cx(s.formGroup, s.space4)}>
              <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                <FormattedMessage {...messages.cleaningPrice} />
              </ControlLabel>
              <Field
                name="cleaningPrice"
                type="text"
                component={this.renderFormControl}
                label={formatMessage(messages.cleaningPrice)}
                className={cx(s.formControlInput, s.jumboSelect, s.listCommonJumboSelect, 'listCommonJumboSelectRTL')}
              />
            </FormGroup>
            <Field
              name="is_affiliate"
              component={this.renderFormControlWork}
              increaseArea="20%" >
              <FormattedMessage {...messages.isaffiliate} />
            </Field>
            <FormGroup className={cx(s.formGroup, s.spaceTop3, s.space4)}>
              {isAffiliate == 1 && <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                <FormattedMessage {...messages.revenuepercentages} />
              </ControlLabel>}
              {isAffiliate == 1 && <Field
                name="affiliate_commission"
                // type="number"
                component={this.renderFormControl}
                label={formatMessage(messages.revenuepercentages)}
                className={cx(s.formControlInput, s.jumboSelect, s.listCommonJumboSelect, 'listCommonJumboSelectRTL')}

              />}
            </FormGroup>
          </div>
          <FooterButton
            nextPage={nextPage}
            previousPage={previousPage}
            nextPagePath={"discount"}
            previousPagePath={"advance-notice"}
            formPage={formPage}
            step={step}
            isDisabled={isDisabled}
          />
        </form>
      </div>
    );
  }
}

Pricing = reduxForm({
  form: 'ListPlaceStep3', // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validateStep3,
  onSubmit: updateStep3
  // onSubmit: updateStep4

})(Pricing);
const selector = formValueSelector('ListPlaceStep3');
const mapState = (state) => ({
  listingFields: state.listingFields.data,
  availableCurrencies: state.currency.availableCurrencies,
  base: state.currency.base,
  pricingValue: state.form && state.form.ListPlaceStep3.values && state.form.ListPlaceStep3.values.basePrice,
  mapUpdateLoading: state.location.mapUpdateLoading,
  isAffiliate: selector(state, 'is_affiliate'),
});

const mapDispatch = {};

export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(Pricing)));
import React from "react"
import OccupancyInsight from "./occupancyInsight"
import UserLayout from '../../components/Layout/UserLayout';
import fetch from '../../core/fetch';

const title = "Occupancy Insight";


export default async function action({ store, params }) {


  const isAuthenticated = store.getState().runtime.isAuthenticated;
  const type = params.type;

  if (!isAuthenticated) {
    return { redirect: '/login' };
  }
  if (navigator.geolocation) {
    // console.log("navigator",navigator.geolocation)
    navigator.geolocation.getCurrentPosition(success, error);
  } else {
    // console.log("Geolocation not supported");
  }
  let latitude=0;
  let longitude=0
  function success(position) {
     latitude = position.coords.latitude;
     longitude = position.coords.longitude;

    // console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
  }

  function error() {
    console.log("Unable to retrieve your location");
  }
//   const getLocationBasedDataQuery = `query calculateOccupancyRate($lat:Float,$lng:Float,$filter:String,$startDate:String,$endDate:String){
//     calculateOccupancyRate(lat:$lat,lng:$lng,filter:$filter,startDate:$startDate,endDate:$endDate){
//         Occupancy_Rate
//         filter
//         status
//         errorMessage
//         }
//     }`;
//   const resp = await fetch('/graphql', {
//     method: 'post',
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify({
//       query: getLocationBasedDataQuery,
//       variables: {
//         lat: "23.033863",
//         lng: "72.585022",
//         filter: "Date",
//         startDate: "2023-07-20",
//         endDate: "2023-07-27"
//       }
//     }),
// });
// console.log(`1Latitude: ${latitude}, Longitude: ${longitude}`);

// const { data } = await resp.json();
// console.log(data,"getLocationBasedDataQuery");
  return {
    title,
    component: <UserLayout><OccupancyInsight latitude={latitude} longitude={longitude}/></UserLayout>,
  };
  }
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import cx from 'classnames';

// Component
import PanelItem from '../PanelItem';
import NoItem from '../NoItem';

// Locale
import messages from '../../../locale/messages';

import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './PanelWrapper.css';

import {
    Tabs,
    Tab,
    Button
} from 'react-bootstrap';

const GET_PROPERTY_COUNT = `query getPropertieCount($userId: String,){
    getPropertieCount(userId:$userId){
      errormessage
        status
  results{
    planId
    propertieCount
  }
    }
  }`

class PanelWrapper extends Component {

    static propTypes = {
        data: PropTypes.array.isRequired,
        formatMessage: PropTypes.any,
    };
    constructor(props) {
        super(props);
        this.state = {
          redirect: false
        }
      }
      async componentDidMount() {
        const {userId} = this.props
    
        const resp = await fetch('/graphql', {
          method: 'post',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            query: GET_PROPERTY_COUNT,
            variables: { userId: userId }
          }),
        });
        const { data } = await resp.json();
        if (data && data.getPropertieCount.status == "200") {
          this.setState({ redirect: true })
        }
        // console.log("Noitem", userId) 
        // console.log("datadatadatadatadatadata", data) 
      }
    handleClick() {
        history.push('/become-a-host');
    }

    render() {
        const { data: { ManageListings, refetch, loading },userId } = this.props;
        let listedItems = [];
        let inProgressItems = [];
        let unListedItems = [];

        if (ManageListings && ManageListings.status == 200 && ManageListings.userListingCount > 0) {
            ManageListings.results && ManageListings.results.length > 0 && ManageListings.results.map((item) => {
                if (!item.isReady) {
                    inProgressItems.push(item);
                } else if (item.isReady && item.isPublished) {
                    listedItems.push(item);
                } else if (item.isReady && !item.isPublished) {
                    unListedItems.push(item);
                }
            });
            // console.log(ManageListings,"Panel Wrapper")
            const {redirect} = this.state;
            // console.log("redirect", redirect) 
            return (
                <div className={cx('listingTab', s.panelBorder, 'listingMobileTab', 'tabReservation')}>
                    <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                        <Tab eventKey={1} title={<FormattedMessage {...messages.panelHeader1} />}>
                            <PanelItem redirect={redirect && redirect} data={inProgressItems} refetch={refetch} loading={loading} searchKey={ManageListings.searchKey} userId={userId}/>
                        </Tab>
                        <Tab eventKey={2} title={<FormattedMessage {...messages.listed} />}>
                            <PanelItem redirect={redirect && redirect} data={listedItems} refetch={refetch} loading={loading} searchKey={ManageListings.searchKey} userId={userId}/>
                        </Tab>
                        <Tab eventKey={3} title={<FormattedMessage {...messages.unListed} />}>
                            <PanelItem redirect={redirect && redirect} data={unListedItems} refetch={refetch} loading={loading} searchKey={ManageListings.searchKey} userId={userId}/>
                        </Tab>
                    </Tabs>
                </div>
            );
        } else {
            const {redirect} = this.state;
            return <NoItem redirect={redirect && redirect} userId={userId}/>;
        }

    }
}

export default injectIntl(withStyles(s)(PanelWrapper));
// Fetch Request
import fetch from '../../../core/fetch';
import { sendEmail } from '../../../core/email/sendEmail';
// import { sendEmail } from '../../../core/email/sendEmail';
// Toaster
import { toastr } from 'react-redux-toastr';
import { setSiteSettings } from '../../../actions/siteSettings';

async function submit(values, dispatch) {
//  console.log('values',values);
  // values.appAvailableStatus = Number(values.appAvailableStatus);

  const query = `
  mutation (
    $websiteLink:String,
    $siteName: String,
    $siteTitle: String,
    $metaDescription: String,
    $metaKeyword: String,
    $logo: String,
    $facebookLink: String,
    $twitterLink: String,
    $instagramLink: String
    $logoHeight: Int,
    $logoWidth: Int,
    $homePageType: Int,
    $videoLink: String,
    $phoneNumberStatus: Int
    $homeLogo: String,
    $emailLogo: String,
    $homeLogoHeight: Int,
    $homeLogoWidth: Int,
    $appAvailableStatus: Boolean,
    $playStoreUrl: String,
    $appStoreUrl: String,
    $email: String,
    $phoneNumber: String,
    $address: String,
    $listingApproval: String,
    $appForceUpdate: String,
    $androidVersion: String,
    $iosVersion: String
  ) {
    createSubadminsite (
      siteName: $siteName,
      websiteLink:$websiteLink,
      siteTitle: $siteTitle,
      metaDescription: $metaDescription,
      metaKeyword: $metaKeyword,
      logo: $logo,
      facebookLink: $facebookLink,
      twitterLink: $twitterLink,
      instagramLink: $instagramLink,
      logoHeight: $logoHeight,
      logoWidth: $logoWidth,
      homePageType: $homePageType,
      videoLink: $videoLink,
      phoneNumberStatus: $phoneNumberStatus,
      homeLogo: $homeLogo,
      emailLogo: $emailLogo,
      homeLogoHeight: $homeLogoHeight,
      homeLogoWidth: $homeLogoWidth,
      appAvailableStatus: $appAvailableStatus,
      playStoreUrl: $playStoreUrl,
      appStoreUrl: $appStoreUrl,
      email: $email,
      phoneNumber: $phoneNumber,
      address: $address,
      listingApproval: $listingApproval,
      appForceUpdate: $appForceUpdate,
      androidVersion: $androidVersion,
      iosVersion: $iosVersion
    ) {
        status
        errorMessage
    }
  }
  `;
  let logoHeight = values.logoHeight != '' ? values.logoHeight : 0;
  let logoWidth = values.logoWidth != '' ? values.logoWidth : 0;
  let homeLogoHeight = values.homeLogoHeight != '' ? values.homeLogoHeight : 0;
  let homeLogoWidth = values.homeLogoWidth != '' ? values.homeLogoWidth : 0;
  let variables = Object.assign({}, values, { logoHeight, logoWidth, homeLogoHeight, homeLogoWidth });

  const resp = await fetch('/graphql', {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
     query,
      variables
    }),
    credentials: 'include',
  });

  const { data } = await resp.json();
  // console.log("datadatadatadata",data);

  if (data.createSubadminsite.status === 200) {
    toastr.success("Success!", "Site settings changes are updated!");
    if(values.websiteLink){
      // console.log("variables.websiteLink")
      let content = {websiteLink:values.websiteLink}
      sendEmail(values.email,"subadminSiteLink",content)
      toastr.success("Success!", "Your request submitted successfully");
    }
    dispatch(setSiteSettings());
  } else {
    toastr.error("Failed!", "Site settings changes update failed!");
  }

}

export default submit;

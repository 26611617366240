import React from 'react';
import AdminLayout from '../../../components/Layout/SubadminLayout';
import EditReview from './EditReview';

const title = 'Admin Reviews';

export default async function action({ store, params }) {

    // From Redux Store
    let issubAdminAuthenticated = store.getState().runtime.issubAdminAuthenticated;

    if (!issubAdminAuthenticated) {
        return { redirect: '/subadmin/login' };
    }

    const reviewId = Number(params.reviewId);

    return {
        title,
        component: <AdminLayout><EditReview title={title} reviewId={reviewId} /></AdminLayout>,
    };
}

import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import { graphql, gql, compose } from "react-apollo";
import getSuggestedQuery from "./getSuggestedQuery.graphql";

// Redux
import { connect } from "react-redux";

// Redux Form
import {
  change,
  submit as submitForm,
  formValueSelector,
  reduxForm,
} from "redux-form";

// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";
import { Row,Button } from "react-bootstrap";
import s from "./HeaderTest.css";

import CustomPagination from "../SearchListing/CustomPagination";
import ListingItem from "../SearchListing/ListingItem/ListingItem";
import starIcon from "../../../public/SiteIcons/starIcon.svg"
import NoResults from "./NoResults/NoResults";
import submit from "../SearchListing/SearchForm/submitt";
// import { getSuggestedResults } from "../../actions/getSuggestedResults";
import { suggestListing } from "../../actions/suggestListing";

class HeaderTest extends React.Component {
  static propTypes = {
    change: PropTypes.any,
    submitForm: PropTypes.any,
    results: PropTypes.array,
    currentPage: PropTypes.number,
    total: PropTypes.number,
    isResultLoading: PropTypes.bool,
  };

  static defaultProps = {
    result: [],
    price:[],
    //isResultLoading: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      button1:false,
      button2:false,
      button3:false,
      button4:false,
    }
    this.handlePagination = this.handlePagination.bind(this);
    this.handleRating = this.handleRating.bind(this);
    // this.handleReset = this.handleReset.bind(this);
  }

  componentDidMount() {
    const { getSuggested: { getSuggested, refetch } } = this.props;
    // if (getSuggested && getSuggested.results) {
    //   this.setState({ result: getSuggested.results });
    // }
    const { currentPage, results } = this.props;
    if (currentPage != undefined) {
      this.setState({ page: currentPage });
    }
    // if (results != null && results.length > 0) {
    //   this.setState({ result: getSuggested.results });
    // }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { currentPage,priceRange,results,getSuggested: { getSuggested, refetch } } = nextProps;
    // console.log("currentPage",currentPage)
    if (currentPage != undefined) {
      this.setState({ page: currentPage });
    }
    if(priceRange != []){
      this.setState({ price: priceRange });
    }
    // if(results != []){
    //   this.setState({ result: results });
    // }
    // if(getSuggested != []){
    //   this.setState({ result: getSuggested.results });
    // }else{
    //   this.setState({ result: results });
    // }

  }

  handleRating(rat){
    const { getSuggested: { getSuggested, refetch },dispatch,priceRange} = this.props;
    // const {page,price} = this.state;
    let price = [10,10000]
    let variables =  {
          currentPage:1,
          priceRange:price,
          review:rat
        }
          refetch(variables)
          // console.log("variables",variables)
          // console.log("variables",priceRange)
          dispatch(suggestListing(variables))

  }

  async handlePagination(currenctPage, size) {
    const { change, handleSubmit } = this.props;

    await change("currentPage", currenctPage);
    await handleSubmit();
    window.scrollTo(0, 0);
    // console.log("currenctPage",currenctPage,"size",size)
    // updateResultState()
  }
//  handleReset(){
//   const { getSuggested: { getSuggested, refetch },dispatch,priceRange} = this.props;
//   let variables =  {
//     currentPage:1,
//     priceRange:[10,10000],
//     review:""
//   }

//   refetch(variables)
//   dispatch(suggestListing(variables))
//  }
  // updateResultState(){
  //   const { results } = this.props;

  //   if (results != null && results.length > 0) {
  //     this.setState({ result: results });
  //   }
  // }

  render() {
    const { page,price,result } = this.state;
    const {
      results,
      total,
      isResultLoading,
      showMap,
      showMapLoader,
      guests,
      priceRange,
      dispatch,
      count      
    } = this.props;

    // const {
    //   getSuggested: { getSuggested, refetch },
    // } = this.props;
    // console.log("resultsssss", price);

    // console.log(results, "sssssssssssss");
    if (results != null && results && results.length > 0) {
      return (
        <div
          className={cx(s.searchResults, {
            [s.listItemOnly]: showMap == false,
          })}
        >
          {!showMapLoader && (
            <Row className={s.noMargin}>
              <div className={cx(s.resultsContainer, "resultsContainerRtl")}>
                {/* <Loader
                    type={"page"}
                    show={isResultLoading}
                  /> */}
                {isResultLoading && (
                  <div className={cx(s.loadingOverlay, "loadingOverlayDark")} />
                )}
                <div className={s.ratingButtonContainer}>
                <div className={s.buttonDiv}> <Button className={s.ratingButton} onClick={()=>this.handleRating("1")}>1  <img className={s.startIcon} src={starIcon} alt="starIcon" />{" "}& Above   </Button></div>
                <div> <Button className={s.ratingButton}  onClick={()=>this.handleRating("2")}>2 <img className={s.startIcon} src={starIcon} alt="starIcon" />{" "}& Above  </Button>   </div>
                <div> <Button className={s.ratingButton}  onClick={()=>this.handleRating("3")}>3 <img className={s.startIcon} src={starIcon} alt="starIcon" />{" "}& Above  </Button>   </div>
                <div> <Button className={s.ratingButton}  onClick={()=>this.handleRating("4")}>4 <img className={s.startIcon} src={starIcon} alt="starIcon" />{" "}& Above  </Button>   </div>
                {/* <div> <Button className={s.ratingButton}  onClick={()=>this.handleReset()}>Reset Filter  </Button>   </div> */}
                </div>
                {results && results.length >0 &&
                  results.map((item, listIndex) => {
                    return (
                      <div
                        className={cx(s.listItem, s.displayInlineBlock)}
                        key={item.id}
                      >
                        <ListingItem
                          id={item.id}
                          basePrice={item.listingData.basePrice}
                          currency={item.listingData.currency}
                          title={item.title}
                          beds={item.beds}
                          personCapacity={item.personCapacity}
                          roomType={
                            item.settingsData &&
                            item.settingsData[0] &&
                            item.settingsData[0].listsettings &&
                            item.settingsData[0].listsettings.itemName
                          }
                          coverPhoto={item.coverPhoto}
                          listPhotos={item.listPhotos}
                          bookingType={item.bookingType}
                          reviewsCount={item.reviewsCount}
                          reviewsStarRating={item.reviewsStarRating}
                          wishListStatus={item.wishListStatus}
                          isListOwner={item.isListOwner}
                          personCount={guests}
                        />
                      </div>
                    );
                  })}
              </div>
              <div className={s.resultsFooter}>
                <div className={s.resultsPagination}>
                  <div className={s.pagination}>
                    <CustomPagination
                      total={count && count}
                      current={page}
                      defaultCurrenct={1}
                      defaultPageSize={12}
                      handleChange={this.handlePagination}
                    />
                  </div>
                </div>
              </div>
            </Row>
          )}
        </div>
      );
    } else {
      return (
        <div>
          {/* {
            isResultLoading && <div className={cx(s.loadingOverlay, 'loadingOverlayDark')} />
          } */}
          <NoResults/>
        </div>
      );
    }
  }
}

HeaderTest = reduxForm({
  form: "SearchForm", // a unique name for this form
  onSubmit: submit,
  destroyOnUnmount: false,
})(HeaderTest);

const selector = formValueSelector("SearchForm");
const mapState = (state) => (
  // console.log(state, "stateee"),
  {
    results: state.suggested.data,
    count: state.suggested.count,
    currentPage: selector(state, "currentPage"),
    total: state.suggested.count,
    isResultLoading: state.suggested.isResultLoading,
    showMap: state.personalized.showMap,
    showMapLoader: state.loader.showMapLoading,
    guests: Number(selector(state, "personCapacity")),
    priceRange: selector(state, "priceRange"),
  }
);

const mapDispatch = {
  change,
  submitForm,
};

// export default withStyles(s)(connect(mapState, mapDispatch)(HeaderTest));
export default compose(
  injectIntl,
  withStyles(s),
  connect(mapState, mapDispatch),
  graphql(getSuggestedQuery, {
    name: "getSuggested",
    options: (props) => ({
      variables: {
        currentPage: 1,
        review: "",
        priceRange:[10,10000]
      },
      fetchPolicy: "network-only",
    }),
  })
)(HeaderTest);


import React from 'react';
import AdminLayout from '../../../../components/Layout/SubadminLayout';
import EditCancelPolicy from './EditCancelPolicy';

const title = 'Edit Cancel Policy';

export default async function action({ store, params }) {

    // From Redux Store
    let issubAdminAuthenticated = store.getState().runtime.issubAdminAuthenticated;

    if (!issubAdminAuthenticated) {
        return { redirect: '/subadmin/login' };
    }

    const id = Number(params.id);

    return {
        title,
        component: <AdminLayout><EditCancelPolicy title={title} id={id} /></AdminLayout>,
    };
}

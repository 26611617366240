import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './AdminLayout.css';
import Header from '../Header/AffiliateDashboardHeader';
import FooterToggle from '../FooterToggle/FooterToggle';
import SideBar from '../siteadmin/AffiliateSideBar';
import CookiesDisclaimer from '../CookiesDisclaimer/CookiesDisclaimer';
import cx from 'classnames';
import history from '../../core/history';

class NewListLayout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  

  render() {
    let location;
    if (history.location) {
      location = history.location.pathname
    }
    return (
      <div className="adminstyle">
        <Header />
        <div>
          <SideBar location={location} />
          <div className={("hamburger")}>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </div>
        </div>
        <div>
        {this.props.children}
        </div>
        {/* <div className={cx('hidden-xs hidden-sm')}> */}
          {/* <FooterToggle /> */}
          {/* <Footer /> */}
        {/* </div> */}
        {/* <CookiesDisclaimer /> */}
      </div>
    );
  }
}

export default withStyles(s)(NewListLayout);

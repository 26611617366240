import { updateSideMenuInfo } from '../../../actions/subadmin/updateSideMenu';

async function submit(values, dispatch) {

	dispatch(
		updateSideMenuInfo(
      values
		)
	);
}
export default submit;

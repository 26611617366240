import React from 'react';
import Oy from 'oy-vey';
import { IntlProvider } from 'react-intl';
import fetch from '../fetch';
import EmailTemplate from './template/EmailTemplate';
import { getSubject } from './template/subjects';

export async function sendEmail(to, type, content,siteAdminId,weburl) {
    let currentWeb;
    let siteTitle;
    let html;
    // console.log("content-------------------->>>>>>>>>>",content);
    let subjectData = getSubject(type), emailContent = content;
    if(siteAdminId && weburl ){
        let query = `query getEmailLogo($siteAdminId:String,$weburl:String){
            getEmailLogo (siteAdminId:$siteAdminId,weburl:$weburl) {
            data
            status
            currentWeb
            siteTitle
            errorMessage
            }
            
          }`;
    
        if (content && !content.logo) {
            const logoResp = await fetch('/graphql', {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    query,
                    variables: {
                        siteAdminId,
                        weburl
                      },
                }),
                credentials: 'include',
            });
    
            const { data } = await logoResp.json();
            
            console.log("email",data);
      
            // emailContent.logo = data && data.getEmailLogo && data.getEmailLogo.value;
            emailContent.logo = data && data.getEmailLogo && data.getEmailLogo.data;
            currentWeb = data && data.getEmailLogo && data.getEmailLogo.currentWeb;
            siteTitle = data && data.getEmailLogo && data.getEmailLogo.siteTitle
        }
    }else{
        let query = `query getEmailLogo{
            getEmailLogo{
            data
            status
            currentWeb
            siteTitle
            errorMessage
            }
            
          }`;
    
        if (content && !content.logo) {
            const logoResp = await fetch('/graphql', {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    query
                }),
                credentials: 'include',
            });
    
            const { data } = await logoResp.json();
            
            console.log("email",data);
      
            // emailContent.logo = data && data.getEmailLogo && data.getEmailLogo.value;
            emailContent.logo = data && data.getEmailLogo && data.getEmailLogo.data;
            currentWeb = data && data.getEmailLogo && data.getEmailLogo.currentWeb;
            siteTitle = data && data.getEmailLogo && data.getEmailLogo.siteTitle
        }
    }
   
    // console.log("email",emailContent);
    // let title = window.location.origin;
    // console.log("Title of the webpage: " + title);
    
    console.log("email----",currentWeb);

    html = Oy.renderTemplate(
        <IntlProvider locale={"en"}>
            <EmailTemplate type={type} content={emailContent} currentWeb={currentWeb} websiteTitle={siteTitle} />
        </IntlProvider>, {
            title: subjectData.subject,
            previewText: subjectData.previewText
        });

    let mailOptions = {
        to, // list of receivers
        subject: subjectData.subject, 
        appcontent:emailContent,// Subject line
        //text: textMessage, // plain text body
        html,
        type
    };
    const resp = await fetch('/sendEmail', {
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ mailOptions }),
        credentials: 'include'
    });
    const { status, response } = await resp.json();
    // console.log("response &&&&&&&&&&&&&&&&&&&&7 sendEmail",mailOptions)
    // console.log("response sendEmail",response)
    // console.log("response sendEmail",status)
    return { status, response };
} 

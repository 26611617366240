import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './addCoupon.css';
import ADDCOUPON from '../../../components/siteadmin/AddCoupon/AddCoupon';
// import {graphql, compose} from 'react-apollo';
class AddCoupon extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          currentPage: 1,
          searchList: '',
        };
        this.setStateVariable = this.setStateVariable.bind(this);
      }
      setStateVariable(variables) {
        this.setState(variables)
      }
    render() {
        const { currentPage, searchList} = this.state;

        return (
            <div>
                <ADDCOUPON  setStateVariable={this.setStateVariable} currentPage={currentPage} searchList={searchList}/>
            </div>
        )
    }
}
export default
    withStyles(s)
    (AddCoupon);

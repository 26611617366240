import { gql } from 'react-apollo';

import {
    ADMIN_UPDATE_STATIC_START,
    ADMIN_UPDATE_STATIC_SUCCESS,
    ADMIN_UPDATE_STATIC_ERROR
} from '../../constants';

// Redirection
import history from '../../core/history';

// Toaster
import { toastr } from 'react-redux-toastr';


const mutation = gql`
mutation updateStaticPage(
    $id: Int,
    $content: String,
    $metaTitle: String,
    $metaDescription: String,
    $pageName:String
  ) {
    updateStaticPage(
      id: $id,
      content: $content,
      metaTitle: $metaTitle,
      metaDescription: $metaDescription,
      pageName:$pageName
    ) {
        status
    }
  }
`;

const query = gql`query getEditStaticPage ($pageName: String) {
    getEditStaticPage (pageName: $pageName) {
        id
        pageName
        content
        createdAt
        metaTitle
        metaDescription
    }
}`;

export function updateStaticPageAction(values) {
// console.log("updateStaticPageAction",values);
    return async (dispatch, getState, { client }) => {
        try {
            dispatch({ type: ADMIN_UPDATE_STATIC_START });
            const { data } = await client.mutate({
                mutation,
                variables: {
                    content: values.content,
                    metaTitle: values.metaTitle,
                    metaDescription: values.metaDescription,
                    id: values.id,
                    pageName: values.pageName
                },
                refetchQueries: [{ query, variables: { pageName: values.pageName } }]
            });


            if (data.updateStaticPage.status === "success") {
                toastr.success("Success!", "Static Content page has been updated successfully!");
                history.push('/subadmin/staticpage/management');
                dispatch({ type: ADMIN_UPDATE_STATIC_SUCCESS });
            }
            else {
                toastr.error("Error", "Updating failed");
                dispatch({ type: ADMIN_UPDATE_STATIC_ERROR });
            }

        } catch (error) {
            toastr.error("Failed!", "Failed to change  status");
            dispatch({ type: ADMIN_UPDATE_STATIC_ERROR });
            return false;
        }
        return true;
    };
}
import React from 'react';
import PropTypes from 'prop-types';
import { graphql, gql, compose } from 'react-apollo';

// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './EditStaticPage.css';
import cx from 'classnames';

// Component
import EditStaticPageManage from '../../../components/subadmin/EditStaticPage/EditStaticPage';

// Query
import editStaticPage from './editStaticPage.graphql';
import NotFound from '../../notFound/NotFound';


class EditStaticPage extends React.Component {

    static propTypes = {
        title: PropTypes.string.isRequired,
        // blogId: PropTypes.number.isRequired,
        editStaticPageData: PropTypes.shape({
            loading: PropTypes.bool,
            getEditStaticPage: PropTypes.object
        }),
    };


    render() {
        const {editStaticPageData:{getEditStaticPage} , title,pageName,pageId } = this.props;
        // console.log("editStaticPageData",getEditStaticPage);
        
        // if(editStaticPageData && editStaticPageData.getEditStaticPage === null) {
        //     return <div className={cx(s.pagecontentWrapper)}><NotFound /></div>
        // }

        return (
            <EditStaticPageManage
                title={title} initialValues={getEditStaticPage} pageId={pageId} pageName={pageName}
            />
        );
    }
}

export default compose(
    withStyles(s),
    graphql(editStaticPage, {
        name: 'editStaticPageData',
        options: (props) => ({
            variables: {
                pageName: props.pageName,
            },
            fetchPolicy: 'network-only'
        })
    }),
)(EditStaticPage);
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SocialLogin-displayFlexIcon-18ThY {\n    display: grid;\n    -webkit-box-pack: center;\n        -ms-flex-pack: center;\n            justify-content: center;\n    -webkit-box-align: center;\n        -ms-flex-align: center;\n            align-items: center;\n    grid-template-columns: auto auto;\n    grid-gap: 20px;    \n    \n}", ""]);

// exports
exports.locals = {
	"displayFlexIcon": "SocialLogin-displayFlexIcon-18ThY"
};
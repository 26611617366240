exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".RedoSearch-redoContainer-1ZWmy {\n  position: absolute;\n  bottom: 20px;\n  left: 35%;\n  z-index: 1;\n  -webkit-box-shadow: 0px 3px 6px #00000029;\n          box-shadow: 0px 3px 6px #00000029;\n  border-radius: 6px;\n  min-width: 240px;\n  max-width: 240px;\n  right: 0;\n  left: 0;\n  margin: 0 auto;\n\n}\n.RedoSearch-redoContent-1CpWX {\n  padding: 9px 12px 9px 12px;\n  background-color: #ffffff;\n  border-radius: 8px;\n  -webkit-box-shadow: 0px 0px 8px 0px #dedede;\n          box-shadow: 0px 0px 8px 0px #dedede;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n.RedoSearch-redoText-10hvV {\n    margin: 3px 0px 0px 10px;\n    word-wrap: break-word;\n    font-size: 14px;\n    line-height: 16px;\n    letter-spacing: 0.2px;\n    padding-top: 0px;\n    padding-bottom: 0px;\n    vertical-align: middle\n}\n@media screen and (max-width: 1199px) {\n  .RedoSearch-redoText-10hvV {\n    font-size: 12px;\n  }\n  .RedoSearch-redoContainer-1ZWmy {\n    top: 30px;\n    right: auto;\n    left: 17px;\n    bottom: unset;\n  }\n}", ""]);

// exports
exports.locals = {
	"redoContainer": "RedoSearch-redoContainer-1ZWmy",
	"redoContent": "RedoSearch-redoContent-1CpWX",
	"redoText": "RedoSearch-redoText-10hvV"
};
import messages from '../../../../locale/messages';

const validate = values => {

  const errors = {}

  if (!values.whyhostBannerHeading) {
    errors.whyhostBannerHeading = messages.required;
  } else if (values.whyhostBannerHeading.trim() == "") {
    errors.whyhostBannerHeading = messages.required;
  }

  if (!values.paymentTitleHeading) {
    errors.paymentTitleHeading = messages.required;
  } else if (values.paymentTitleHeading.toString().trim() == "") {
    errors.paymentTitleHeading = messages.required;
  } else if (values.paymentTitleHeading && values.paymentTitleHeading.length > 200) {
    errors.paymentTitleHeading = messages.exceedLimit;
  }


  return errors
}

export default validate;
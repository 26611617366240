import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Layout.css';
import Header from '../Header/AffiliateHeader';
// import Header from '../Header/AffiliateDashboardHeader';
import SideBar from '../siteadmin/AffiliateSideBar';
import Feedback from '../Feedback';
import Footer from '../Footer';
import FooterToggle from '../FooterToggle';
import cx from 'classnames';
import CookiesDisclaimer from '../CookiesDisclaimer';
import history from '../../core/history';

class Layout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    page: PropTypes.string
  };

  static defaultProps = {
    page: ''
  };

  render() {
    let location;
    if (history.location) {
      location = history.location.pathname
    }
    // const { page, className, fixedHeader } = this.props;

    return (
      // <div>
      //   <Header fixedHeader={fixedHeader} page={page} />
      //   <div>
      //     <SideBar />
      //     <div className={("hamburger")}>
      //       <span className="icon-bar"></span>
      //       <span className="icon-bar"></span>
      //       <span className="icon-bar"></span>
      //     </div>
      //   </div>
      //   {this.props.children}
      //   <div className={cx('hidden-xs', { [s.searchFooter]: page === 'search' }, className)}>
      //     <FooterToggle />
      //   </div>
      //   <CookiesDisclaimer />
      // </div>
      <div className="adminstyle">
        <Header />
        <div>
        <SideBar location={location} />
          <div className={("hamburger")}>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </div>
        </div>
        <div>
        {this.props.children}
        </div>
        {/* <div className={cx('hidden-xs hidden-sm')}> */}
          {/* <FooterToggle /> */}
          {/* <Footer /> */}
        {/* </div> */}
        {/* <CookiesDisclaimer /> */}
      </div>
    );
  }
}

export default withStyles(s)(Layout);

exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root {\n  /*\n   * Typography\n   * ======================================================================== */\n\n  --font-family-base: \"Circular\", Arial, Helvetica, sans-serif;\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  --max-content-width: 1080px;\n  --max-container-width: 1274px;\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */\n\n  --screen-xs-min: 480px;  /* Extra small screen / phone */\n  --screen-sm-min: 768px;  /* Small screen / tablet */\n  --screen-md-min: 992px;  /* Medium screen / desktop */\n  --screen-lg-min: 1200px; /* Large screen / wide desktop */\n\n  --border-color: #DDDDDD;\n  --background-color: #ffffff;\n  --btn-color: #282828;\n  --btn-primary-bg: #8c52ff;\n  --btn-primary-color: #ffffff;\n  --btn-primary-hover-bg: #8c52ff;\n  --btn-primaryBorder-bg: #ffffff;\n  --btn-primaryBorder-color: #8c52ff;\n  --btn-primaryBorder-hover-bg: #8c52ff;\n  --btn-secondary-bg: #008489;\n  --btn-secondary-color: #ffffff;\n  --btn-secondary-hover-bg: #008489;\n  --text-color: #A02F18;\n  --common-text-color: #282828;\n  --common-text-Secondry-color: #696969;\n  --background-common-color: #F8F8F8;\n  --border-secondary-color: #dadada;\n  --background-secondary-color: #F2F8FF;\n  --menu-background-color: #f3f3f3;\n  --darkmode-primary-color: #171D37;\n  --darkmode-secondary-color: #0E142F;\n  --darkmode-text-color: #C7D8EB;\n}\n/** RentAll - Begin **/\n.BookingModal-logInModalContainer-1eX2k {\n  max-width: 450px;\n}\n.BookingModal-logInModalBody-2ifuz {\n  padding: 0px;\n}", ""]);

// exports
exports.locals = {
	"logInModalContainer": "BookingModal-logInModalContainer-1eX2k",
	"logInModalBody": "BookingModal-logInModalBody-2ifuz"
};
import React from 'react';
import PropTypes from 'prop-types';
import {graphql, gql, compose} from 'react-apollo';

// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './SiteConfiguration.css';

// Component
import SiteConfigForm from '../../../components/subadmin/SiteConfigForm/SiteConfigForm';
import Loader from '../../../components/Loader/Loader';

// Query
import siteSettingsQuery from './siteSettingsQuery.graphql';

class SiteConfiguration extends React.Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.shape({
      loading: PropTypes.bool,
      siteSettings: PropTypes.array,
    })
  };

  static defaultProps = {
    data: {
      loading: true
    }
  };


  render () {
    const { data: { loading, siteSettings } } = this.props;
    let settingsCollection ={
      "stripePublishableKey": "asdadedd",
      "maxUploadSize": 45,
      "pushNotificationKey": "asdasda",
      "deepLinkBundleId": "saSDs",
      "smtpHost": "saacs",
      "smtpPort": 14,
      "smptEmail": "",
      "smtpSender": "",
      "smtpSenderEmail": "",
      "smtpPassWord": "",
      "twillioAccountSid": "daddafdas",
      "twillioAuthToken": "",
      "twillioPhone": "",
      "paypalEmail": "",
      "paypalClientId": "",
      "paypalSecret": "",
      "paypalHost": "",
      "paypalHostMode": "",
      "androidVersion": "",
      "appForceUpdate": "",
      "iosVersion": "",
      "facebookAppId": "",
      "facebookSecretId": "",
      "googleClientId": "",
      "googleSecretId": "",
      "deepLinkContent": ""
  };
  if(siteSettings){
    settingsCollection = siteSettings[0]
  }
  // console.log("siteSettings in route",settingsCollection)

    if(loading){
      return <Loader type={"text"} />;
    } else {
      // siteSettings.map((item, key) => {
      //   settingsCollection[item.name] = item.value;
      // });
      return <SiteConfigForm initialValues={settingsCollection} />
    }
  } 

}

export default compose(
    withStyles(s),
    graphql(siteSettingsQuery,
      {
        options: (props) => ({
          variables: {
            type: 'config_settings',
            // userId:"fccbd20e-c35b-4460-9a1d-c87751575113"
          },
          fetchPolicy: 'network-only'
        })
      }
    ),
)(SiteConfiguration);

// import React, { Component } from "react";
// import PropTypes from "prop-types";
// import moment from "moment";
// import { graphql, gql, compose } from "react-apollo";
// import createApolloClient from "../../core/createApolloClient/createApolloClient.client";

// import DocumentList from "../DocumentList/DocumentList";
// import DropzoneComponent from "react-dropzone-component";

// import { Field, reduxForm, change, formValueSelector } from "redux-form";
// import submit from "./submit";
// import validate from "./validate";
// import Loader from "../Loader/Loader";
// import { injectIntl, FormattedMessage } from "react-intl";
// import { connect } from "react-redux";
// import CountryList from "../CountryList/CountryList";

// // Locale
// import messages from "../../locale/messages";
// import PopulateData from "../../helpers/populateData";

// // Style
// import withStyles from "isomorphic-style-loader/lib/withStyles";
// import cx from "classnames";
// import s from "./manageinfo.css";
// import bt from "../../components/commonStyle.css";
// import {
//   Button,
//   FormGroup,
//   FormControl,
//   Row,
//   Col,
//   ControlLabel,
//   Checkbox,
//   InputGroup,
// } from "react-bootstrap";

// //Images
// import ShowPassword from "../../../public/SiteIcons/pswVisible.svg";
// import HidePassword from "../../../public/SiteIcons/pwdHidden.svg";
// import toolTipIcon from "../../../public/SiteIcons/listCommonToolTip.svg";

// import documentIcon from "../../../public/SiteIcons/documentUpload.svg";
// import { getSpecificConfig } from "../../helpers/getConfigValue";

// import { Stepper, StepLabel, Step } from "@material-ui/core";

// const client = createApolloClient();

// // const query = gql`
// //   query {
// //     getAffiliateUserStep {
// //       status
// //       errorMessage
// //       stepInfo
// //       stepDetails {
// //         payeeName
// //         address
// //         address2
// //         city
// //         state
// //         zipcode
// //         country
// //         phoneNumber
// //         countryCode
// //       }
// //     }
// //   }
// // `;

// // const params = {
// //   userId: "816e3340-0403-11ee-9a73-6b1d77ce8fba",
// // };
// const query = gql`
//   query {
//     getAffiliateUserStep {
//       status
//       errorMessage
//       stepInfo
//       stepDetails {
//         payeeName
//         address
//         city
//         state
//         zipcode
//         country
//         countryCode
//         phoneNumber
//       }
//     }
//   }
// `;
// class manageinformation extends Component {
//   // constructor(props) {
//   //   super(props);
//   //   this.state = {
//   //     hideSuggestInput: true,
//   //   };
//   //   // this.renderCountryList = this.renderCountryList.bind(this);
//   // }

//   static propTypes = {
//     formatMessage: PropTypes.func,
//   };

//   constructor(props) {
//     super(props);
//     this.state = {
//       loading: true,
//       countryCode: 'US',
//       country: '+1',
//       phoneNumber: '',
//       submitting: false,
//       error: null
//     };
//     this.handleChange = this.handleChange.bind(this);
//     this.renderCountryList = this.renderCountryList.bind(this);
//     this.handleCountryChange = this.handleCountryChange.bind(this);
//     // this.handleCountryChange = this.handleCountryChange.bind(this);
//     this.routeChange = this.routeChange.bind(this);
//     // this.fetchUserData = this.fetchUserData.bind(this);
//   }

//   componentDidMount() {
//     // this.fetchUserData();
//     this.setState({ loading: false });
//   }
//   handleCountryChange(e, selectedData) {
//     console.log("selectedData",selectedData);
//     this.setState({
//       countryCode: selectedData.dialCode,
//       country: selectedData.countryCode
//     });
//   }
//   handleChange(event) {
//     this.setState({ [event.target.name]: event.target.value });
//   }
//   routeChange() {
//     let path = `/affliliate/manageinfo/step2`;
//     history.push(path);
//   }


//   renderFormControlCurrency = ({
//     input,
//     label,
//     type,
//     meta: { touched, error },
//     className,
//     country,
//   }) => {
//     const { formatMessage } = this.props.intl;
//     return (
//       <>
//         <InputGroup>
//           <InputGroup.Addon>{country}</InputGroup.Addon>
//           <FormControl
//             {...input}
//             placeholder={label}
//             type={type}
//             className={className}
//           />
//         </InputGroup>
//         {touched && error && (
//           <span className={s.errorMessage}>{formatMessage(error)}</span>
//         )}
//       </>
//     );
//   };

//   renderFormControl = ({
//     input,
//     label,
//     type,
//     meta: { touched, error },
//     className,
//     showPassword,
//     placeHolder,
//     max,
//   }) => {
//     const { formatMessage } = this.props.intl;
//     return (
//       <FormGroup className={cx(bt.space3, bt.pwdSection)}>
//         <FormControl
//           {...input}
//           placeholder={label}
//           type={showPassword === input.name ? input : type}
//           className={className}
//           max={max}
//         />
//         {type == "password" && (
//           <span
//             className={cx(
//               bt.pwdImage,
//               bt.loginPwdSection,
//               "svgImg",
//               "pwdImageRTL"
//             )}
//             onClick={() => this.handleChange(input.name)}
//           >
//             {showPassword === input.name ? (
//               <img src={ShowPassword} />
//             ) : (
//               <img src={HidePassword} />
//             )}
//           </span>
//         )}
//         {touched && error && (
//           <span className={bt.errorMessage}>{formatMessage(error)}</span>
//         )}
//       </FormGroup>
//     );
//   };
//   // placeholder={placeHolder ? placeHolder : label}

//   renderFormControlSelect = ({
//     input,
//     label,
//     meta: { touched, error },
//     children,
//     className,
//   }) => {
//     return (
//       <FormGroup className={cx(bt.space3, bt.pwdSection)}>
//         <FormControl componentClass="select" {...input} className={className}>
//           {children}
//         </FormControl>
//       </FormGroup>
//     );
//   };
//   renderCountryList({
//     input,
//     label,
//     meta: { touched, error },
//     children,
//     className,
//   }) {
//     const { formatMessage } = this.props.intl;
//     return (
//       <div>
//         <CountryList input={input} className={className} isEmptyFirst />
//         {touched && error && (
//           <span className={s.errorMessage}>{formatMessage(error)}</span>
//         )}
//       </div>
//     );
//   }

//   render() {
//     const { error, handleSubmit, submitting } = this.props;
//     const { country, countryCode,phoneNumber } = this.state;
//     console.log("countryCode",countryCode);
//     console.log("country",country);
//     const { formatMessage } = this.props.intl;

//     return (
//       <div>
//         {this.state.loading ? (
//           <Loader type={"text"} />
//         ) : (
//           <div>
//             <header>
//               <div className="center-stepper">
//                 <Stepper
//                   alternativeLabel
//                   style={{ width: "100%" }}
//                   activeStep="1"
//                 >
//                   <Step>
//                     <StepLabel>Account Information</StepLabel>
//                   </Step>
//                   <Step>
//                     <StepLabel>Website List</StepLabel>
//                   </Step>
//                   <Step>
//                     <StepLabel>Upload Your Document</StepLabel>
//                   </Step>
//                 </Stepper>
//               </div>
//             </header>
//             <form
//               onSubmit={handleSubmit(submit)}
//               className={cx("SelectFocus", "inputDateHide")}
//             >
//               {error && (
//                 <span className={bt.errorMessage}>{formatMessage(error)}</span>
//               )}
//               {/* <div className={s.landingMainContent}> */}

//               <ControlLabel>
//                 <FormattedMessage {...messages.Payeename} />
//               </ControlLabel>
//               <Field
//                 name="payeeName"
//                 type="text"
//                 component={this.renderFormControl}
//                 label={formatMessage(messages.Payeename)}
//                 className={bt.affiliateControlInput}
//               />
//               <ControlLabel>
//                 <FormattedMessage {...messages.address1} />
//               </ControlLabel>
//               {/* <p className={cx(s.noMarginBottom, bt.spaceTop1)}>{formatMessage(messages.phoneNumberInfo)}</p> */}
//               <Field
//                 name="address"
//                 type="text"
//                 component={this.renderFormControl}
//                 label={formatMessage(messages.address1)}
//                 className={bt.affiliateControlInput}
//               // showPassword={this.state.showPassword}
//               />
//               <ControlLabel>
//                 <FormattedMessage {...messages.address2} />
//               </ControlLabel>
//               <Field
//                 name="address2"
//                 type="text"
//                 component={this.renderFormControl}
//                 label={formatMessage(messages.address2)}
//                 className={bt.affiliateControlInput}
//               />
//               <ControlLabel>
//                 <FormattedMessage {...messages.city} />
//               </ControlLabel>
//               <Field
//                 name="city"
//                 type="text"
//                 component={this.renderFormControl}
//                 label={formatMessage(messages.city)}
//                 className={bt.affiliateControlInput}
//               />
//               <ControlLabel>
//                 <FormattedMessage {...messages.state} />
//               </ControlLabel>
//               <Field
//                 name="state"
//                 type="text"
//                 component={this.renderFormControl}
//                 label={formatMessage(messages.state)}
//                 className={bt.affiliateControlInput}
//               />
//               <ControlLabel>
//                 <FormattedMessage {...messages.zipcode} />
//               </ControlLabel>
//               <Field
//                 name="zipcode"
//                 type="text"
//                 component={this.renderFormControl}
//                 label={formatMessage(messages.zipcode)}
//                 className={bt.affiliateControlInput}
//               />
//                <FormGroup className={s.formGroup}>
//           <label className={cx(s.labelText, 'textWhite')} >
//             <FormattedMessage {...messages.chooseACountry} />
//           </label>
//           <CountryList
//             input={
//               {
//                 name: 'country',
//                 onChange: this.handleChange,
//                 value: country
//               }
//             }
//             className={cx(bt.commonControlSelect)}
//             dialCode={true}
//             getSelected={this.handleCountryChange}
//           />
//         </FormGroup>

//         <FormGroup className={s.formGroup}>
//           <label className={cx(s.labelText, 'textWhite')} >
//             <FormattedMessage {...messages.addAPhoneNumber} />
//           </label>
//           <InputGroup>
//             <InputGroup.Addon className={s.inputGroupAddon} name="countryCode">{countryCode}</InputGroup.Addon>
//             <FormControl
//               name={'phoneNumber'}
//               value={phoneNumber}
//               placeholder={''}
//               type={'text'}
//               className={cx(bt.commonControlInput)}
//               onChange={this.handleChange} />
//           </InputGroup>
//         </FormGroup>
//               <Field
//                 name="countryCode"
//                 type="hidden"
//                 component={this.renderFormControl}
//               // label={formatMessage(messages.zipcode)}
//               // className={bt.affiliateControlInput}
//               />
//               {/* <FormGroup className={s.space3}>
//             <ControlLabel>
//               <FormattedMessage {...messages.country} />
//             </ControlLabel>
//             <Field name="country" component={this.renderCountryList} />
//           </FormGroup>
//           <ControlLabel>
//               <FormattedMessage {...messages.phoneNumber} />
//             </ControlLabel>
//           <Field
//             name="phoneNumber"
//             type="text"
//             component={this.renderFormControl}
//             label={formatMessage(messages.phoneNumber)}
//             className={bt.affiliateControlInput}
//           /> */}

//               <FormGroup className={cx(s.formGroup)}>
//                 <Button
//                   className={cx(s.button, bt.btnPrimary, bt.btnLarge)}
//                   block
//                   type="submit"
//                   disabled={submitting}
//                 >
//                   <FormattedMessage {...messages.continue} />
//                 </Button>
//               </FormGroup>
//               {/* </div> */}
//             </form>
//           </div>
//         )}
//       </div>
//     );
//   }
// }

// manageinformation = reduxForm({
//   form: "manageinformation", // a unique name for this form
//   validate,
// })(manageinformation);

// export default injectIntl(withStyles(s, bt)(manageinformation));
import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { graphql, gql, compose } from "react-apollo";
import createApolloClient from "../../core/createApolloClient/createApolloClient.client";

import DocumentList from "../DocumentList/DocumentList";
import DropzoneComponent from "react-dropzone-component";

import { Field, reduxForm, change, formValueSelector } from "redux-form";
import submit from "./submit";
import validate from "./validate";
import Loader from "../Loader/Loader";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import CountryList from "../CountryList/CountryList";

// Locale
import messages from "../../locale/messages";
import PopulateData from "../../helpers/populateData";

// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";
import s from "./manageinfo.css";
import bt from "../../components/commonStyle.css";
import {
  Button,
  FormGroup,
  FormControl,
  Row,
  Col,
  ControlLabel,
  Checkbox,
  InputGroup,
} from "react-bootstrap";

//Images
import ShowPassword from "../../../public/SiteIcons/pswVisible.svg";
import HidePassword from "../../../public/SiteIcons/pwdHidden.svg";
import toolTipIcon from "../../../public/SiteIcons/listCommonToolTip.svg";

import documentIcon from "../../../public/SiteIcons/documentUpload.svg";
import { getSpecificConfig } from "../../helpers/getConfigValue";

import { Stepper, StepLabel, Step } from "@material-ui/core";

const client = createApolloClient();

const query = gql`
query {
  getAffiliateUserStep {
    status
    errorMessage
    stepInfo
    stepDetails {
      payeeName
      address
      address2
      city
      state
      zipcode
      country
      countryCode
      phoneNumber
    }
  }
}
`;

// const params = {
//   userId: "816e3340-0403-11ee-9a73-6b1d77ce8fba",
// };
class manageinformation extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     hideSuggestInput: true,
  //   };
  //   // this.renderCountryList = this.renderCountryList.bind(this);
  // }

  static propTypes = {
    formatMessage: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      countryCode: 'US',
      country: '+1',
      phoneNumber: '',
      submitting: false,
      error: null
    };
    this.handleChange = this.handleChange.bind(this);
    this.renderCountryList = this.renderCountryList.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.routeChange = this.routeChange.bind(this);
    this.fetchUserData = this.fetchUserData.bind(this);
  }
  async fetchUserData() {
    const { data } = await client.query({
      query,
      // variables: { params },
      fetchPolicy: "network-only",
    });
    let res = data.getAffiliateUserStep;

    if (res.status == 200 && res.stepDetails.length !== 0) {
      // console.log("RESPONSE OBJECT", res.stepDetails[0]);
      const response = res.stepDetails[0];
      // console.log("response m", response)
      // let address = response.address.split(",");
      this.setState({ countryCode: response.country, country: response.countryCode, phoneNumber: response.phoneNumber })
      this.props.initialize({
        payeeName: response.payeeName,
        address: response.address,
        address2: response.address2,
        city: response.city,
        state: response.state,
        zipcode: response.zipcode,
        country: response.country,
        countryCode: response.countryCode,
        phoneNumber: response.phoneNumber,
      });
    }
  }

  componentDidMount() {
    this.fetchUserData();
    this.setState({ loading: false });
  }
  handleCountryChange(e, selectedData) {
    // console.log("selectedData",selectedData);
    
    this.setState({
      country: selectedData.dialCode,
      countryCode: selectedData.countryCode
    });
    
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  routeChange() {
    let path = `/affliliate/manageinfo/step2`;
    history.push(path);
  }

  // handleCountryChange(e, selectedData) {
  //   this.setState({
  //     country: selectedData.dialCode,
  //     countryCode: selectedData.countryCode
  //   });
  // }

  renderFormControlCurrency = ({
    input,
    label,
    type,
    meta: { touched, error },
    className,
    country,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <>
        <InputGroup>
          <InputGroup.Addon>{country}</InputGroup.Addon>
          <FormControl
            {...input}
            placeholder={label}
            type={type}
            className={className}
          />
        </InputGroup>
        {touched && error && (
          <span className={s.errorMessage}>{formatMessage(error)}</span>
        )}
      </>
    );
  };

  renderFormControl = ({
    input,
    label,
    type,
    meta: { touched, error },
    className,
    showPassword,
    placeHolder,
    max,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <FormGroup className={cx(bt.space3, bt.pwdSection)}>
        <FormControl
          {...input}
          placeholder={label}
          type={showPassword === input.name ? input : type}
          className={className}
          max={max}
        />
        {type == "password" && (
          <span
            className={cx(
              bt.pwdImage,
              bt.loginPwdSection,
              "svgImg",
              "pwdImageRTL"
            )}
            onClick={() => this.handleChange(input.name)}
          >
            {showPassword === input.name ? (
              <img src={ShowPassword} />
            ) : (
              <img src={HidePassword} />
            )}
          </span>
        )}
        {touched && error && (
          <span className={bt.errorMessage}>{formatMessage(error)}</span>
        )}
      </FormGroup>
    );
  };
  // placeholder={placeHolder ? placeHolder : label}

  renderFormControlSelect = ({
    input,
    label,
    meta: { touched, error },
    children,
    className,
  }) => {
    return (
      <FormGroup className={cx(bt.space3, bt.pwdSection)}>
        <FormControl componentClass="select" {...input} className={className}>
          {children}
        </FormControl>
      </FormGroup>
    );
  };
  renderCountryList({
    input,
    label,
    meta: { touched, error },
    children,
    className,
  }) {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <CountryList input={input} className={className} isEmptyFirst />
        {touched && error && (
          <span className={s.errorMessage}>{formatMessage(error)}</span>
        )}
      </div>
    );
  }

  render() {
    const { error, handleSubmit, submitting } = this.props;
    const { country, countryCode } = this.state;
    // console.log("countryCode",countryCode);
    // console.log("countryCode",country);
    const { formatMessage } = this.props.intl;
    // console.log("selectedData",country,countryCode);
    return (
      <div>
        {this.state.loading ? (
          <Loader type={"text"} />
        ) : (
          <div>
            <header>
              <div className="center-stepper">
                <Stepper
                  alternativeLabel
                  style={{ width: "100%" }}
                  activeStep="1"
                >
                  <Step>
                    <StepLabel>Account Information</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Website List</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Upload Your Document</StepLabel>
                  </Step>
                </Stepper>
              </div>
            </header>
            <form
              onSubmit={handleSubmit((values) => submit(values, country, countryCode))}
              className={cx("SelectFocus", "inputDateHide")}
            >
              {error && (
                <span className={bt.errorMessage}>{formatMessage(error)}</span>
              )}
              {/* <div className={s.landingMainContent}> */}

              <ControlLabel>
                <FormattedMessage {...messages.Payeename} />
              </ControlLabel>
              <Field
                name="payeeName"
                type="text"
                component={this.renderFormControl}
                label={formatMessage(messages.Payeename)}
                className={bt.affiliateControlInput}
              />
              <ControlLabel>
                <FormattedMessage {...messages.address1} />
              </ControlLabel>
              {/* <p className={cx(s.noMarginBottom, bt.spaceTop1)}>{formatMessage(messages.phoneNumberInfo)}</p> */}
              <Field
                name="address"
                type="text"
                component={this.renderFormControl}
                label={formatMessage(messages.address1)}
                className={bt.affiliateControlInput}
              // showPassword={this.state.showPassword}
              />
              <ControlLabel>
                <FormattedMessage {...messages.address2} />
              </ControlLabel>
              <Field
                name="address2"
                type="text"
                component={this.renderFormControl}
                label={formatMessage(messages.address2)}
                className={bt.affiliateControlInput}
              />
              <ControlLabel>
                <FormattedMessage {...messages.city} />
              </ControlLabel>
              <Field
                name="city"
                type="text"
                component={this.renderFormControl}
                label={formatMessage(messages.city)}
                className={bt.affiliateControlInput}
              />
              <ControlLabel>
                <FormattedMessage {...messages.state} />
              </ControlLabel>
              <Field
                name="state"
                type="text"
                component={this.renderFormControl}
                label={formatMessage(messages.state)}
                className={bt.affiliateControlInput}
              />
              <ControlLabel>
                <FormattedMessage {...messages.zipcode} />
              </ControlLabel>
              <Field
                name="zipcode"
                type="text"
                component={this.renderFormControl}
                label={formatMessage(messages.zipcode)}
                className={bt.affiliateControlInput}
              />
              <FormGroup className={s.formGroup}>
                <label className={cx(s.labelText, 'textWhite')} >
                  <FormattedMessage {...messages.chooseACountry} />
                </label>
                <CountryList
                  input={
                    {
                      name: 'country',
                      onChange: this.handleChange,
                      value: countryCode
                    }
                  }
                  className={cx(bt.commonControlSelect)}
                  dialCode={false}
                  getSelected={this.handleCountryChange}
                />
              </FormGroup>

              <FormGroup className={s.formGroup}>
                <label className={cx(s.labelText, 'textWhite')} >
                  <FormattedMessage {...messages.addAPhoneNumber} />
                </label>
                <InputGroup>
                  <InputGroup.Addon className={s.inputGroupAddon}>{country}</InputGroup.Addon>
                  <Field
                    name={'phoneNumber'}
                    type={'text'}
                    className={cx(bt.commonControlInput)}
                    component={this.renderFormControl}
                    label={formatMessage(messages.phoneNumber)} />
                </InputGroup>
              </FormGroup>
              <Field
                name="countryCode"
                type="hidden"
                component={this.renderFormControl}
              // label={formatMessage(messages.zipcode)}
              // className={bt.affiliateControlInput}
              />
              {/* <FormGroup className={s.space3}>
            <ControlLabel>
              <FormattedMessage {...messages.country} />
            </ControlLabel>
            <Field name="country" component={this.renderCountryList} />
          </FormGroup>
          <ControlLabel>
              <FormattedMessage {...messages.phoneNumber} />
            </ControlLabel>
          <Field
            name="phoneNumber"
            type="text"
            component={this.renderFormControl}
            label={formatMessage(messages.phoneNumber)}
            className={bt.affiliateControlInput}
          /> */}

              <FormGroup className={cx(s.formGroup)}>
                <Button
                  className={cx(s.button, bt.btnPrimary, bt.btnLarge)}
                  block
                  type="submit"
                  disabled={submitting}
                >
                  <FormattedMessage {...messages.continue} />
                </Button>
              </FormGroup>
              {/* </div> */}
            </form>
          </div>
        )}
      </div>
    );
  }
}

manageinformation = reduxForm({
  form: "manageinformation", // a unique name for this form
  validate,
})(manageinformation);

export default injectIntl(withStyles(s, bt)(manageinformation));

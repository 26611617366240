// General
import React from 'react';
import PropTypes from 'prop-types';

// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import s from './manageinfo.css';
import bt from '../../components/commonStyle.css';
import {
  Col
} from 'react-bootstrap';

import SidePanel from './infoSidePanel';


// Components
import Manageinformation from '../../components/ManageInfo';
import SocialLogin from '../../components/SocialLogin/SocialLogin';
import Link from '../../components/Link/Link';

// Translation
import { FormattedMessage, injectIntl } from 'react-intl';

// Locale
import messages from '../../locale/messages';

class ManageInfo extends React.Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
  };

  render() {
    return (
      <div className={s.grid}>
         <SidePanel/>

 <div className={s.root}>
         {/* <div className={s.container}> */}
         <div className={s.landingMainContent}>
           <Manageinformation/>
           </div>
         {/* </div> */}
       </div>

       
          </div>
    );
  }
}
  

export default injectIntl(withStyles(s, bt)(ManageInfo));

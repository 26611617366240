exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".AccountSettingsSideMenu-listContainer-3VAbh {\n  list-style-type: none;\n}\n.AccountSettingsSideMenu-sideNavitem-hFcOX{\n  display: block;\n  padding: 8px 0;\n  font-size: 16px;\n  color: #767676;\n  text-decoration: none !important;\n}", ""]);

// exports
exports.locals = {
	"listContainer": "AccountSettingsSideMenu-listContainer-3VAbh",
	"sideNavitem": "AccountSettingsSideMenu-sideNavitem-hFcOX"
};
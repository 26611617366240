import React, { Component } from "react";
import { ProgressBar } from 'react-bootstrap';
import cx from 'classnames';
import s from "./Test2.css";
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import google from "./google.png";

import { toastr } from 'react-redux-toastr';

const myClass = cx({
  'col-sm-1': true, // Apply 'col-sm-1' class by default
  'col-xs-1': true, // Apply 'col-xs-1' class if the 'condition' variable is true
});
const POST_SEO = `
mutation createSeoform($title:String,$tags:String,$description:String,$listId:Int) {
  createSeoform (title:$title,tags:$tags,description:$description,listId:$listId) {
        id
        title
        tags
        listId
        description
        status
      
}
}
`
class Test2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      inputValue: "",
      title: "",
      description: "",
      descriptionLength: 0,
      currentHost:""
    };
  }
  componentDidMount(){
    let host = window.location.origin;
    // window.location
    this.setState({currentHost:host})
    // console.log("window.location", window.location.origin)
  }
  handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { title, description, tags } = this.state;
      const {listId} = this.props;
      // console.log(tags);
      let tag = JSON.stringify(tags.join(','));
      const response = await fetch("/graphql", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: POST_SEO,
          variables: {
            title,
            tags: tag,
            description,
            listId: listId
          },
        }),
      });

      const { data } = await response.json();
      
      if (data && data.createSeoform && data.createSeoform.status === 200) {
        toastr.success("Success!", "Data added successfully");
        window.location.href = "/rooms"
      }

      // Clear the input fields
      this.setState({
        tags: [],
        inputValue: "",
        title: "",
        description: "",
        descriptionLength: 0,
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleInputChange = (e) => {
    this.setState({ inputValue: e.target.value });
  };

  handleEnter = (e) => {
    if (e.key == " " ||
      e.code == "Space" ||
      e.keyCode == 32) {
      e.preventDefault(); // Prevent the default form submission behavior
      const { tags } = this.state;
      let val = e.target.value.trim(); // Trim the value to remove leading/trailing spaces
      if (val !== "" & val != val.includes("!") || val != val.includes("@") || val != val.includes("#") || val != val.includes("$") || val != val.includes("%") || val != val.includes("^") || val != val.includes("&") || val != val.includes("*") || val != val.includes("(") || val != val.includes(")") || val != val.includes("/") || val != val.includes(">") || val != val.includes("<") || val != val.includes(">") || val != val.includes(",") || val != val.includes(";")) {
        this.setState((prevState) => ({
          tags: [...prevState.tags, val],
          inputValue: "" // Clear the input field after adding the value
        }));
      }
      this.setState({ inputValue: "" })
    }
  };
  deleteEntry = (index) => {
    const { tags } = this.state;
    const updatedArr = tags.filter((_, i) => i !== index);
    this.setState({ tags: updatedArr });
  };
  
  handleTitleProgress=()=>{
  const {title} = this.state
  let titleProgress=title.length
  let titleVariant="danger";

  if (titleProgress <= 4) {
    titleVariant = "danger";
  } else if (titleProgress <= 4 && titleProgress >= 15) {
    titleVariant = "danger";
  } else if (titleProgress >= 15 && titleProgress <= 30) {
    titleVariant = "warning";
  } else if (titleProgress >= 30 && titleProgress <= 40) {
    titleVariant = "warning";
  } else if (titleProgress >= 40 && titleProgress <= 50) {
    titleVariant = "success";
  }else if (titleProgress >= 50 && titleProgress <= 60) {
    titleVariant = "success";
  }
  return { titleProgress, titleVariant };
}
  calculateProgress = () => {
    const { tags } = this.state;
    const length = tags.length;


    let progress = 0;
    let variant = "danger";
    let words = "min 6 tags required"
    if (length === 0) {
      words = "min 6 tags required"
      variant = "danger";

    } else if (length === 1) {
      words = "min 6 tags required"
      progress = 20;
      variant = "danger";

    } else if (length === 2) {
      words = "great! add some more"
      progress = 40;
      variant = "warning";

    } else if (length === 3) {
      words = "great! add some more"
      progress = 65;
      variant = "warning";
    } else if (length === 4) {
      words = "great! add some more"
      progress = 70;
      variant = "success";
    } else if (length === 5) {
      words = "great! add some more"
      progress = 85;
      variant = "success";
    } else if (length >= 6) {
      words = "awesome! almost done"
      progress = 100;
      variant = "success";
    }

    return { progress, variant, words };
  };
  DescriptionProgress = () => { 
    const { description } = this.state;
    const DescLength = description.length;

    let descVariant = "danger";

    if (DescLength <= 5) {
      descVariant = "danger";
    } else if (DescLength <= 5 && DescLength >= 30) {
      descVariant = "info";
    } else if (DescLength >= 30 && DescLength <= 50) {
      descVariant = "info";
    } else if (DescLength >= 50 && DescLength <= 80) {
      descVariant = "warning";
    } else if (DescLength >= 80 && DescLength <= 120) {
      descVariant = "warning";
    } else if (DescLength >= 120 && DescLength <= 140) {
      descVariant = "success";
    }else if (DescLength >= 140 && DescLength <= 165) {
      descVariant = "success";
    }

    return { descVariant, DescLength };
  };
  render() {
    const { title, description, tags, inputValue,currentHost } = this.state;
    const { progress, variant, words } = this.calculateProgress();
    const { descVariant, DescLength } = this.DescriptionProgress();
    const { titleProgress, titleVariant } = this.handleTitleProgress();
    const {listId,PropertyTitle} = this.props;
    //console.log(tags);
    //console.log(description.length + " " + DescProgress + " " + descVariant);
    return (
      <div
        className={s.main_container}

      >
        <div
          className={s.subdiv}
        >
          <form onSubmit={this.handleSubmit}>
            <label htmlFor="title">SEO Title</label>
            <input
              className={s.title_input}
              placeholder="Enter SEO title here"
              type="text"
              id="title"
              name="title"
              value={title}
              onChange={this.handleChange}
              required
            />
            <p style={{ color: variant }} className={s.description_pTag} htmlFor="description">{titleProgress >= 59 ? "awesome! ":""}{titleProgress}{titleProgress>=2?" characters ":" character "} {"(maximum 55-60 characters allowed)"}</p>
           {title.length ?   <ProgressBar style={{ height: "6px", backgroundColor: "white" }} max={60} now={titleProgress} striped active bsStyle={titleVariant} /> :""}
            <br />
            <label htmlFor="Meta Tags">Meta Tags</label>
            <div className={s.tags_div}>
              <div
                className={s.tags_mapping_div}
              >
                {tags.length > 0 &&
                  tags.map((el, i) => {
                    return (
                      <div
                        className={s.mapped_tags_div}
                        key={i}
                      >
                        <div style={{ margin: "auto" }}>{el}</div>
                        <button
                          className={s.tags_close_btn}
                          onClick={() => this.deleteEntry(i)}
                        >
                          x
                        </button>
                      </div>
                    );
                  })}

              </div>
              <input
                onKeyDown={this.handleEnter}
                onChange={(e) => this.setState({ inputValue: e.target.value })}
                value={inputValue}
                type="text"
                placeholder="add tags here"
                className={s.tags_input}
              />
            </div>
            <p style={{ color: variant }} className={s.description_pTag} htmlFor="description">{words}</p>
            <br />
            <ProgressBar style={{ height: "6px", backgroundColor: "white" }} max={100} now={progress} striped active bsStyle={variant} />
            <label htmlFor="description">Meta Description</label>
            <textarea
              placeholder="description"
              className={s.textArea}
              id="description"
              name="description"
              value={description}
              onChange={this.handleChange}
              required
            />
            <p className={s.description_pTag} htmlFor="description">{DescLength >= 100 ? "awesome!":""} {DescLength}{DescLength>=1?" characters ":" character "}{"(maximum 160-165 characters allowed)"}</p>
            <br />
            <ProgressBar style={{ height: "6px" }} max={165} now={description.length} striped active bsStyle={descVariant} />
            <br />
            <input
              type="submit"
              value="Submit"
              className={s.submit_input}
            />
          </form>
        </div>
        <div>
        <div className={s.googleScreenshot}> <img style={{ width: "95%", marginLeft: "5%" }} src={google} />   </div>
        <div
          className={s.google_result}>
          {/* <h4 style={{ color: "#67b1e6",borderBottom:"1px solid gray",padding:"8px" }}>google result preview</h4> */}

          <div className={s.g_result_subDIv}>
            <div className={s.h5_p_div}>
              <h5 style={{ color: "#2E27B4" }}>{title ? title : PropertyTitle}</h5>
            </div>
            <div className={s.green_link_div}><img style={{ marginRight: "5px" }} width={"15px"} marginLeft="3px" src="https://cdn.icon-icons.com/icons2/2954/PNG/512/badge_ad_icon_185345.png" /> {currentHost}   <img width={"12px "} height={"12px"} style={{ marginLeft: "3px" }} marginLeft="3px" src="https://img.icons8.com/?size=60&id=99977&format=png" /> </div>
            <div className={s.google_result_tags_mappingDiv}>

              {tags.length ?
                tags.map((el, i) => {
                  if (i === tags.length - 1) {
                    return (
                      <span className={s.span} key={i}>
                        {el}
                      </span>
                    )
                  } else {
                    return (
                      <span className={s.span} key={i}>
                        {el}{" · "}
                      </span>
                    );
                  }
                }) : "Hotels Near Me · Cheap Hotels · Hotel Deals · Browse Now"
              }
            </div>
            <div className={s.google_result_descriptionDiv}>
              {description ? description + "..." : "Cancel Free on Most Hotels. Pay Now or Later on Most Rooms. Great Deals to Send Your Loved Ones Somewhere Nice and Close or Far. Your Choice. Customer Support. Hotels Near Me · Cheap Hotels · Hotel Deals · Browse Now..."}
            </div>
          </div>
        </div>
        </div>
      </div>

    );
  }
}

export default withStyles(s)(Test2);

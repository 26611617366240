import React from "react"
import KYIbyDate from "./KYIbyDate"
import UserLayout from '../../components/Layout/UserLayout';
import fetch from '../../core/fetch';

const title = "Manage Listing";


export default async function action({ store, params }) {


  const isAuthenticated = store.getState().runtime.isAuthenticated;
  const type = params.type;
  if (!isAuthenticated) {
    return { redirect: '/login' };
  }

  const getListquery = `
    query getPropertyManagersData($filter:String,$graphType: String,$startDate: String,$endDate: String){
    getPropertyManagersData(filter:$filter,graphType:$graphType,startDate:$startDate,endDate:$endDate){
     Inquiry_to_Booking_Conversion_Rate
     Occupancy_Rate
     Average_Daily_Rate
     RevPAR
     total1
     total2
     rate
     filter
     graphType
     status
     errorMessage
     graphData
     {
         data1
         {
             graphDate
             value
         }
         data2
         {
             graphDate
             value
         }
     }
    }
}
  `;
  const resp = await fetch('/graphql', {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query: getListquery,
      variables: {filter:"Week",graphType:type }
    }),
  });
  const { data } = await resp.json();
  let noData = false;
  if(data.getPropertyManagersData === null || undefined){
      noData=true
  }
  // console.log('data index knw',data);
  return {
    title,
    component: <UserLayout><KYIbyDate data={data} type={type}  noData={noData}/></UserLayout>,
  };
  }
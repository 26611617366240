import React, { Component } from "react";
import ToggleButton from "./toggleButton";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap";
import history from "../../core/history";
import fetch from "../../core/fetch";
// messages
import messages from "../../locale/messages";
import cx from 'classnames';
//Styles
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./host.css";
import moment from "moment";
//Image
import Check from "../../../public/SiteIcons/check.svg";
import { toastr } from 'react-redux-toastr';
class PlanCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stateFromToggle: true,
      cardId: 0,
    };
    this.handleToggleValue = this.handleToggleValue.bind(this);
    this.handleToggleId = this.handleToggleId.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  handleToggleId = (value) => {
    this.setState({ cardId: value });
  };

  handleToggleValue = (value) => {
    this.setState({ stateFromToggle: value });
  };

  handlePageChange = (values) => {
    const { getColor, getTitleColor } = this.props;
    const {
      nextPage,
      handleCardDetails,
      planPrice,
      planTheme,
      planHeader,
      planExpire
    } = this.props;
    let { stateFromToggle } = this.state;
    let priceType = stateFromToggle == true ? "monthly" : "yearly";
    let price = stateFromToggle == true ? values.monthly : values.yearly;
    handleCardDetails(values);
    // console.log({ priceType, price });
    planPrice({ planType: priceType, price: price });
    planHeader(getTitleColor);
    planTheme(getColor);
    nextPage("payment");
  };
  handleUpgradeCard(values){
    const { getColor, getTitleColor } = this.props;
    const {
      nextPage,
      handleCardDetails,
      planPrice,
      planTheme,
      planHeader,
    } = this.props;
    let { stateFromToggle } = this.state;
    let priceType = stateFromToggle == true ? "monthly" : "yearly";
    let price = stateFromToggle == true ? values.monthly : values.yearly;
    handleCardDetails(values);
    // console.log({ priceType, price });
    planPrice({ planType: priceType, price: price });
    planHeader(getTitleColor);
    planTheme(getColor);
    nextPage("payment");
  }
  handlePlanCancel = async ()=>{
    const query = `query Cancelmembership {
      Cancelmembership{
          status
          errormessage
      }
  }`;

    const resp = await fetch("/graphql", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: query,
      }),
      credentials: "include",
    });
    const {data} =await resp.json()
    // console.log("Cancelmembership",data);
    if(data && data.Cancelmembership.status == 201){
      toastr.error("Cannot cancel",data.Cancelmembership.errormessage);
      window.location.href="/contact"
    }else if(data && data.Cancelmembership.status == 200){
      toastr.info("Hello",'Your Plan is Successfully canceled');
      window.location.href="/";
    }
    // console.log("RESPONSE FROM API ", data);
  }
  render() {
    const {
      cardDetails,
      cardIndex,
      getColor,
      getTitleColor,
      getToggleColor,
      planExpire
    } = this.props;
    let today = new Date()
    // let day = date.getDate();
// console.log("cardDetails",moment(today).format("DD-MM-YY"))
// console.log("cardDetails",planExpire)
    return (
      <div className={s.contentContainer}>
        <div
          key={cardIndex}
          className={s.planCard}
          style={{ background: getColor }}
        >
          <div className={s.plantitle} style={{ color: getTitleColor }}>
            {cardDetails.title}
          </div>
          <div className={s.toggleContainer}>
            <div
              className={
                this.state.cardId == cardDetails.id
                  ? this.state.stateFromToggle == true
                    ? s.activePlanPriceType
                    : s.planPriceType
                  : s.activePlanPriceType
              }
            >
              <FormattedMessage id={"title"} {...messages.monthly} />
            </div>

            <ToggleButton
              toggleColor={getToggleColor}
              toggle={this.state.stateFromToggle}
              buttonId={cardDetails.id}
              fromToggleId={this.handleToggleId}
              fromToggle={this.handleToggleValue}
            />
            <div
              className={
                this.state.cardId == cardDetails.id
                  ? this.state.stateFromToggle == false
                    ? s.activePlanPriceType
                    : s.planPriceType
                  : s.planPriceType
              }
            >
              <FormattedMessage id={"title"} {...messages.annual} />
            </div>
          </div>

          <div className={s.priceContainer}>
            <div>
              {this.state.cardId == cardDetails.id ? (
                <div>
                  {this.state.stateFromToggle == true ? (
                    <div className={s.priceText}>
                      ${cardDetails.monthly} {cardDetails.currency}{" "}
                    </div>
                  ) : (
                    <div className={s.priceText}>
                      ${cardDetails.yearly} {cardDetails.currency}{" "}
                    </div>
                  )}
                </div>
              ) : (
                <div className={s.priceText}>
                  ${cardDetails.monthly} {cardDetails.currency}{" "}
                </div>
              )}
            </div>
          </div>

          <div className={s.contentContainer}>
            <div className={s.infoContainer}>
              <div className={s.imgDiv}>
                <img src={Check} alt="check" className={s.image} />
              </div>
              <div className={s.planInfo}>{cardDetails.one}</div>
            </div>
            <div className={s.infoContainer}>
              <div className={s.imgDiv}>
                <img src={Check} alt="check" className={s.image} />
              </div>
              <div className={s.planInfo}>{cardDetails.two}</div>
            </div>
            <div className={s.infoContainer}>
              <div className={s.imgDiv}>
                <img src={Check} alt="check" className={s.image} />
              </div>
              <div className={s.planInfo}>{cardDetails.three}</div>
            </div>
            <div className={s.infoContainer}>
              <div className={s.imgDiv}>
                <img src={Check} alt="check" className={s.image} />
              </div>
              <div className={s.planInfo}>{cardDetails.four}</div>
            </div>
            <div className={s.infoContainer}>
              <div className={s.imgDiv}>
                <img src={Check} alt="check" className={s.image} />
              </div>
              <div className={s.planInfo}>{cardDetails.five}</div>
            </div>
            <div className={s.infoContainer}>
              <div className={s.imgDiv}>
                <img src={Check} alt="check" className={s.image} />
              </div>
              <div className={s.planInfo}>{cardDetails.six}</div>
            </div>
          </div>

          <div className={s.buttonContainer}>
            {cardDetails.status == 0 && (
              <Button
                className={s.purchaseButton}
                onClick={() => this.handlePageChange(cardDetails)}
              >
                <FormattedMessage id={app.listNow} {...messages.buyNow} />
              </Button>
            )}
            {cardDetails.status !== 0 && (
              <div className={s.condionalButton}> 
                <Button className={s.purchaseButton}
                // disabled={!(moment(today).format("DD-MM-YY") == planExpire)}DD-MM-YY
                onClick={() => this.handleUpgradeCard(cardDetails)}>
                  <FormattedMessage id={app.upgrade} {...messages.upgrade}  />
                </Button>
                <Button className={s.purchaseButton}>
                 <a href="javascript:void(0);"className={cx(s.cancelLinkText)} onClick={this.handlePlanCancel}><FormattedMessage id={app.cancel} {...messages.cancel} /></a> 
                </Button>
              </div>
            )}
          </div>
          <div> {cardDetails.status !== 0 && (
              <Button className={s.purchaseButton}>
              <a href="/become-a-host?mode=new"className={cx(s.cancelLinkText)} ><FormattedMessage id={app.startFromHere} {...messages.startFromHere} /></a> 
             </Button>
            )}</div>
          {cardDetails.status !== 0 && (
            <>
            <div>Plan Expiry Date: {planExpire}</div>
            <br />
            <div> YOUR CURRENT PLAN </div>
            </>
             
             
            //  <div>Plan Expiry Date: {moment(planExpire && planExpire).format("yyyy/MM/DD")}</div>
            )}
            
           
        </div>
      </div>
    );
  }
}
export default withStyles(s)(PlanCard);

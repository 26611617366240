import messages from "../../locale/messages";

const validate = (values) => {
  const errors = {};

  if (!values.PayeeName) {
    errors.PayeeName = messages.required;
  } else if (values.PayeeName.trim() == "") {
    errors.PayeeName = messages.required;
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = messages.required;
  } else if (values.phoneNumber.trim() == "") {
    errors.phoneNumber = messages.required;
  } else if (values.phoneNumber.length !== 10) {
    errors.phoneNumber = messages.phoneNumberInvalid;
  }

  if (
    !values.address || (values.address && values.address.toString().trim() == "")
  ) {
    errors.address = messages.required;
  }
  if (
    !values.address2 || (values.address2 && values.address2.toString().trim() == "")
  ) {
    errors.address2 = messages.required;
  }

  if (!values.country) {
    errors.country = messages.countryRequired;
  }

  if (!values.city || (values.city && values.city.toString().trim() == "")) {
    errors.city = messages.cityRequired;
  }

  if (!values.state || (values.state && values.state.toString().trim() == "")) {
    errors.state = messages.stateRequired;
  }

  if (!values.zipcode) {
    errors.zipcode = messages.zipcodeRequired;
  }

  return errors;
};

export default validate;

import React from 'react';
import AdminLayout from '../../../components/Layout/SubadminLayout';
import BecomeHostStaticBlock from './BecomeHostStaticBlock';
import { getStaticBlockInfo } from '../../../actions/siteadmin/getStaticBlockInfo';
import { restrictUrls } from '../../../helpers/adminPrivileges';

const title = 'Become Host Static Block';

export default async function action({ store, dispatch }) {

  // From Redux Store
  let issubAdminAuthenticated = store.getState().runtime.issubAdminAuthenticated;
  let adminPrivileges = store.getState().adminPrevileges.privileges && store.getState().adminPrevileges.privileges.privileges;

  // await store.dispatch(getStaticBlockInfo())

  if (!issubAdminAuthenticated) {
    return { redirect: '/subadmin/login' };
  }

  // // Admin restriction
  if (!restrictUrls('/subadmin/static-block', adminPrivileges)) {
    return { redirect: '/subadmin' };
  }

  return {
    title,
    component: <AdminLayout><BecomeHostStaticBlock title={title} /></AdminLayout>,
  };
}

import React from "react";
import FooterLessLayout from "../../components/Layout/FooterLessLayout";
import HeaderText from "./HeaderTest";
import fetch from "../../core/fetch";
import { suggestListing } from "../../actions/suggestListing";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { setPersonalizedValues } from "../../actions/personalized";
import moment from "moment";
import { Button } from "react-bootstrap";
const title = "Try-Me";

export default async function action({ params, store, query }) {
  store.dispatch(showLoading());
// console.log("params",store.getState().user.id)
  const searchQuery = `
    {
      getSearchSettings {
        minPrice
        maxPrice
        priceRangeCurrency
      }
    }
  `;

  const resp = await fetch("/graphql", {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: searchQuery,
    }),
    credentials: "include",
  });

  const { data } = await resp.json();
  // console.log(data, "getSearchSettings");

  // From Redux Store
  const geographyData = store.getState().personalized.geography;
  const personCapacityData = store.getState().personalized.personCapacity;
  const startDateData = store.getState().personalized.startDate;
  const endDateData = store.getState().personalized.endDate;
  let geoType = store.getState().personalized.geoType;
  let lat = store.getState().personalized.lat;
  let lng = store.getState().personalized.lng;
  let sw_lat = store.getState().personalized.sw_lat;
  let sw_lng = store.getState().personalized.sw_lng;
  let ne_lat = store.getState().personalized.ne_lat;
  let ne_lng = store.getState().personalized.ne_lng;
  let userId = store.getState().user.id,
    priceRange = [],
    review = "";
  let personCapacity,
    dates,
    geography,
    currentPage = 1,
    location;
  let initialFilter = {};
  let fixedHeader = "searchFixedHeader";

  // PersonCapacity
  if (personCapacityData != undefined && personCapacityData != null) {
    personCapacity = personCapacityData;
  } else {
    if ("guests" in query && query.guests) {
      initialFilter["personCapacity"] = Number(query.guests);
      personCapacity = Number(query.guests);
    }
  }

  store.dispatch(
    setPersonalizedValues({ name: "personCapacity", value: personCapacity })
  );

  await store.dispatch(
    suggestListing({
      userId,
      currentPage,
      priceRange,
      review
    })
  );

  // const GET_SUGGESTED_QUERY = `query getSuggested($userId: String,$currentPage: Int,){
  //   getSuggested(userId:$userId,currentPage: $currentPage,){
  //       count
  //          results {
  //     id
  //     title
  //     personCapacity
  //     lat
  //     lng
  //     beds
  //     bookingType
  //     coverPhoto
  //     reviewsCount
  //     reviewsStarRating
  //     listPhotos {
  //       id
  //       name
  //       type
  //       status
  //     }
  //     listingData {
  //       basePrice
  //       currency
  //     }
  //     settingsData {
  //       listsettings {
  //         id
  //         itemName
  //       }
  //     }
  //     wishListStatus
  //     isListOwner
  //   }
  //       errorMessage
  //       status

  //   }
  // }`;
  // const respons = await fetch("/graphql", {
  //   method: "post",
  //   headers: {
  //     Accept: "application/json",
  //     "Content-Type": "application/json",
  //   },
  //   body: JSON.stringify({
  //     query: GET_SUGGESTED_QUERY,
  //     variables: {
  //       userId: "8bd91e80-de9c-11ed-91f1-b3743c049fa7",
  //       currentPage: 1,
  //     },
  //   }),
  // });
  // const dataa = await respons.json();

  // let suggestedData = dataa.data.getSuggested.results;
  // console.log(suggestedData, "data.getSuggested.results");
  // console.log(suggestedData, "suggested");

  return {
    title,
    component: (
      <FooterLessLayout fixedHeader={fixedHeader}>
        <HeaderText
          title={title}
          searchSettings={data.getSearchSettings}
          dispatch={store.dispatch}
        />
      </FooterLessLayout>
    ),
  };
}

import React from 'react';
import AdminLayout from '../../../components/Layout/SubadminLayout';
import Currency from './Currency';

const title = 'Currency Management';

export default async function action({ store }) {

  // From Redux Store
  let issubAdminAuthenticated = store.getState().runtime.issubAdminAuthenticated;
  let isSuperAdmin = store.getState().runtime.isSuperAdmin

  // if (!issubAdminAuthenticated || !isSuperAdmin) {
  //   return { redirect: '/subadmin/login' };
  // }

  return {
    title,
    component: <AdminLayout><Currency title={title} /></AdminLayout>,
  };
}

import React from "react"
import DemoHtml from "./demohtml"
import fetch from '../../core/fetch';

const title = "Payment Receipt";


export default async function action({ store, params }) {


  const isAuthenticated = store.getState().runtime.isAuthenticated;

  const userId = params.userId;
// console.log("userId in index of demo",userId);
  if (!isAuthenticated) {
    return { redirect: '/login' };
  }
  return {
    title,
    component: <DemoHtml  userId={userId}/> ,
  };
  }
// import React from 'react';
// import PropTypes from 'prop-types';

// // Redux
// import { connect } from 'react-redux';

// // Redux Form
// import { change, AffiliateSubmit as submitForm, formValueSelector, reduxForm } from 'redux-form';

// // Style
// import withStyles from 'isomorphic-style-loader/lib/withStyles';
// import s from './AffiliateSearchListing.css';
// import cx from 'classnames';
// import {
//   Row
// } from 'react-bootstrap';

// // Component
// import CustomPagination from '../CustomPagination/CustomPagination';
// import AffiliateListing from './affiliateListing/affiliateListing';
// import NoResults from '../SearchListing/NoResults/NoResults';

// import AffiliateSubmit from './AffiliateSearchForm/AffiliateSubmit';

// class AffiliateSearchListing extends React.Component {
//   static propTypes = {
//     change: PropTypes.any,
//     // submitForm: PropTypes.any,
//     results: PropTypes.array,
//     currentPage: PropTypes.number,
//     total: PropTypes.number,
//     isResultLoading: PropTypes.bool,
//   //  description: PropTypes.string,
//   };

//   static defaultProps = {
//     results: [],
//     // isResultLoading: false,
//     // showMap: false,
//     // showMapLoader: false
//   };

//   constructor(props) {
//     super(props);
//     this.state = {
//       page: 1,
//       title: '',
//       description: ''
//     };
    
//     this.handlePagination = this.handlePagination.bind(this);
//   }

//   componentDidMount() {
//     const { currentPage } = this.props;
//     if (currentPage != undefined) {
//       this.setState({ page: currentPage });
//     }
//   }


//   UNSAFE_componentWillReceiveProps(nextProps) {
//     const { currentPage } = nextProps;
//     if (currentPage != undefined) {
//       this.setState({ page: currentPage });
//     }
//   }

//   async handlePagination(currenctPage, size) {
//     const { change, handleSubmit } = this.props;
//     await change('currentPage', currenctPage);
//     await handleSubmit();
//     window.scrollTo(0, 0)
//   }


//   render() {
//     const { page } = this.state;
//     const { results, total, isResultLoading, guests } = this.props;
//     console.log("heeeeeeelllllooooooooo" +  results);

//     if (results != null && results.length > 0) {
    
//       return (
//         <div className={cx(s.searchResults)}>
//           {
//           <Row className={s.noMargin}>

//               <div className={cx(s.resultsContainer, 'resultsContainerRtl')}>
//                 {/* <Loader
//                     type={"page"}
//                     show={isResultLoading}
//                   /> */}
//                 {
//                   isResultLoading && <div className={cx(s.loadingOverlay, 'loadingOverlayDark')} />
//                 }
//                 {
//                   results.map((item, listIndex) => {
//                     return (
//                       <div className={cx(s.listItem, s.displayInlineBlock)} key={item.id}>
//                         <AffiliateListing
//                           id={item.id}
//                           basePrice={item.listingData.basePrice}
//                           currency={item.listingData.currency}
//                           title={item.title}
//                           description={item.description}
//                           city={item.city}
//                           affiliateId={item.affiliateId}
//                           referralId={item.referralId}
//                            isGenerated={item.isGenerated}
                         
                        
//                           // beds={item.beds}
//                           // personCapacity={item.personCapacity}
//                           // roomType={item.settingsData && item.settingsData[0] && item.settingsData[0].listsettings && item.settingsData[0].listsettings.itemName}
//                           coverPhoto={item.coverPhoto}
//                           listPhotos={item.listPhotos}
//                           // bookingType={item.bookingType}
//                           // reviewsCount={item.reviewsCount}
//                           // reviewsStarRating={item.reviewsStarRating}
//                           // wishListStatus={item.wishListStatus}
//                           // isListOwner={item.isListOwner}
//                           // personCount={guests}
//                         />
//                          <div>
//                 <Modal
//                     {...this.props}
//                     size="lg"
//                     aria-labelledby="contained-modal-title-vcenter"
//                     centered
//                 >
//                     <Modal.Header className={s.modalCustom} closeButton>
//                     </Modal.Header>
//                     <Modal.Body>
//                         <div class={s.font20}>
//                             <p>{title}
//                             </p>
//                         </div>
//                         <div className={s.font16}>
//                             <p>
//                                 {description}
//                             </p>

//                         </div>
//                     </Modal.Body>
//                 </Modal>
//                 <Button variant="primary" onClick={() => this.setState({ modalShow: true, title: ' Airbnb host?', description: 'test' })}>
//                     <FormattedMessage {...messages.Modal} />
//                 </Button>
//             </div>
//                       </div>
//                     )
//                   })
//                 }
//               </div>
//               <div className={s.resultsFooter}>
//                 <div className={s.resultsPagination}>
//                   <div className={s.pagination}>
//                     <CustomPagination
//                       total={total}
//                       current={page}
//                       defaultCurrenct={1}
//                       defaultPageSize={12}
//                       handleChange={this.handlePagination}

//                     />
//                   </div>
//                 </div>
//               </div>

//             </Row>
//           }
//         </div>
//       );

//     } else {
//       console.log("11123456787654");
//       return (
//         <div>
//           {/* {
//             isResultLoading && <div className={cx(s.loadingOverlay, 'loadingOverlayDark')} />
//           } */}
//           <NoResults />
//         </div>
//       );
//     }
//   }
// }

// AffiliateSearchListing = reduxForm({
//   form: 'AffiliateSearchListing', // a unique name for this form
//   onSubmit: AffiliateSubmit,
//   destroyOnUnmount: false,
// })(AffiliateSearchListing);


// const selector = formValueSelector('AffiliateSearchForm');

// const mapState = (state) => ({
//   results: state.search.data,
//   currentPage: selector(state, 'currentPage'),
//   total: state.search.count,
//   isResultLoading: state.search.isResultLoading,
//   // showMap: state.personalized.showMap,
//   // showMapLoader: state.loader.showMapLoading,
//   guests: Number(selector(state, 'personCapacity')),
// });

// const mapDispatch = {
//   change,
//   // submitForm,
// };

// export default withStyles(s)(connect(mapState, mapDispatch)(AffiliateSearchListing));


import React from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Redux Form
import {Field, change, submit as submitForm, formValueSelector, FormControl,reduxForm } from 'redux-form';
import messages from '../../locale/messages';
import { injectIntl, FormattedMessage } from 'react-intl';
// Style
import bt from '../../components/commonStyle.css';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './affiliateLinkListing.css';
import cx from 'classnames';
import {
  Row,
  Button,
  FormGroup
} from 'react-bootstrap';

// Component
import CustomPagination from '../SearchListing/CustomPagination';
import AffiliateLinkListings from './AffiliateLinkListings/affiliateLinkListings';
import NoResults from '../SearchListing/NoResults/NoResults';

import submit from './AffiliateSearchForm/AffiliateSubmit';

class AffiliateLinkListing extends React.Component {
  static propTypes = {
    change: PropTypes.any,
    submitForm: PropTypes.any,
    results: PropTypes.array,
    currentPage: PropTypes.number,
    total: PropTypes.number,
    isResultLoading: PropTypes.bool,
    affiliateResults: PropTypes.object
    // formatMessage: PropTypes.func,
  };

  static defaultProps = {
    results: [],
    //isResultLoading: false,
    showMap: false,
    showMapLoader: false
  };

  constructor(props) {
    super(props);
    this.state = {
      page: 1
    };
    this.handlePagination = this.handlePagination.bind(this);
  }

  componentDidMount() {
    const { currentPage } = this.props;
    if (currentPage != undefined) {
      this.setState({ page: currentPage });
    }
  }


  UNSAFE_componentWillReceiveProps(nextProps) {
    const { currentPage } = nextProps;
    if (currentPage != undefined) {
      this.setState({ page: currentPage });
    }
  }

  async handlePagination(currenctPage, size) {
    const { change, handleSubmit } = this.props;
    await change('currentPage', currenctPage);
    await handleSubmit();
    window.scrollTo(0, 0)
  }

//   renderFormControl = ({ input, label, type, meta: { touched, error }, className, isDisabled }) => {
//     // const { formatMessage } = this.props.intl;
//     return (
//         <div>
//             <FormControl {...input} placeholder={label} type={type} className={className} disabled={isDisabled} />
//             {/* {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>} */}
//         </div>
//     )
// }
// renderFormControl = ({ input, label, type, meta: { touched, error }, className, showPassword, placeHolder, max }) => {
//   // const { formatMessage } = this.props.intl;
//   return (
//     <FormGroup className={cx(bt.space3, bt.pwdSection)}>
//       <label className={s.loginLabel}>{label}</label>
//       <FormControl {...input} placeholder={label} type={showPassword === input.name ? input : type} className={className} max={max} />
//       {type == 'password' && <span className={cx(bt.pwdImage, bt.loginPwdSection, 'svgImg', 'pwdImageRTL')} onClick={() => this.handleChange(input.name)}>
//         {showPassword === input.name ? <img src={ShowPassword} /> : <img src={HidePassword} />}
//       </span>}
//       {/* {touched && error && <span className={bt.errorMessage}>{formatMessage(error)}</span>} */}
//     </FormGroup>
//   )
// }
  render() {
    
    const { page } = this.state;
    const { results, isResultLoading, showMap, showMapLoader, guests,submitting,affiliateResults } = this.props;
    // const { formatMessage } = this.props.intl;
    // console.log("result show"+ JSON.stringify(affiliateResults));
    // console.log("result show"+ JSON.stringify(affiliateResults));
    let results1 =affiliateResults && affiliateResults.results;
    let total = affiliateResults.count;
    if (affiliateResults && results1.length > 0) {
      return (
       
       <div className={cx(s.searchResults, { [s.listItemOnly]: showMap == false })}>
          {
            !showMapLoader && <Row className={s.noMargin}>

              <div className={cx(s.resultsContainer,s.pagecontentWrapper, s.bgColor, 'resultsContainerRtl')}>
                {/* <Loader
                    type={"page"}
                    show={isResultLoading}
                  /> */}
                {
                  isResultLoading && <div className={cx(s.loadingOverlay, 'loadingOverlayDark')} />
                }
   
                {
                  
                  results1.map((item, listIndex) => {

                    return (

                      <div className={cx(s.listItem, s.displayInlineBlock)} key={item.id}>
                   
                        <AffiliateLinkListings
                          id={item.id}
                          basePrice={item.listingData.basePrice}
                          currency={item.listingData && item.listingData.currency}
                          affiliate_commission={item.listingData.affiliate_commission}
                          affiliateId={item.affiliateId}
                          title={item.title}
                          createdAt={item.createdAt}
                          earning={item.earning}
                          clickResult={item.clickResult}
                          // city={item.city}
                          // affiliateId={userID}
                          // description={item.description}
                          // beds={item.beds}
                          // bathrooms={item.bathrooms}
                          referralId={item.referralId}
                          // isGenerated={item.isGenerated}
                          // country={item.country}
                          // personCapacity={item.personCapacity}
                          roomType={item.settingsData && item.settingsData[0] && item.settingsData[0].listsettings && item.settingsData[0].listsettings.itemName}
                          coverPhoto={item.coverPhoto}
                          listPhotos={item.listPhotos}
                          bookingType={item.bookingType}
                          reviewsCount={item.reviewsCount}
                          reviewsStarRating={item.reviewsStarRating}
                          wishListStatus={item.wishListStatus}
                          isListOwner={item.isListOwner}
                          personCount={guests}
                        />
                      </div>
                    )
                  })
                }
              </div>
           {/*   <div className={s.resultsFooter}>
                <div className={s.resultsPagination}>
                  <div className={s.pagination}>
                    <CustomPagination
                      total={total}
                      current={page}
                      defaultCurrenct={1}
                      defaultPageSize={12}
                      handleChange={this.handlePagination}

                    />
                  </div>
                </div>
              </div>*/}

            </Row>
          }
        </div>
      );

    } else {
      return (
        <div>
          {/* {
            isResultLoading && <div className={cx(s.loadingOverlay, 'loadingOverlayDark')} />
          } */}
          <NoResults />
        </div>
      );
    }
  }
}

AffiliateLinkListing = reduxForm({
  form: 'AffiliateSearchForm', // a unique name for this form
  onSubmit: submit,
  destroyOnUnmount: false,
})(AffiliateLinkListing);


const selector = formValueSelector('AffiliateSearchForm');

const mapState = (state) => ({
  results: state.search.data,
  currentPage: selector(state, 'currentPage'),
  total: state.search.count,
  isResultLoading: state.search.isResultLoading,
  showMap: state.personalized.showMap,
  showMapLoader: state.loader.showMapLoading,
  guests: Number(selector(state, 'personCapacity')),
});

const mapDispatch = {
  change,
  submitForm,
};

export default withStyles(s)(connect(mapState, mapDispatch)(AffiliateLinkListing));

import React from 'react';
import SubAdminLayout from '../../../components/Layout/SubadminLayout';
import Dashboard from './Dashboard';

const title = 'Admin Dashboard';

export default function action({ store }) {

  // From Redux Store
  let isAdminAuthenticated = store.getState().runtime.issubAdminAuthenticated;


  if (!isAdminAuthenticated) {
    return { redirect: '/subadmin/login' };
  }

  return {
    title,
    component: 
    <SubAdminLayout>
      <Dashboard title={title} />
      </SubAdminLayout>,
  };
}
import React from 'react';
import Layout from '../../../components/Layout';
import Page from '../../../components/Page';
import fetch from '../../../core/fetch';
import Help from "./Help"
const query = `query getEditStaticPage ($pageName: String) {
  getEditStaticPage (pageName: $pageName) {
      id
      pageName
      content
      createdAt
      metaTitle
      metaDescription
  }
}`;

export default async function action({ locale }) {
  // const dataResult = await new Promise((resolve) => {
  //   require.ensure([], (require) => {
  //     resolve(require('./help.md'));
  //   }, 'help');
  // });

  // const resp = await fetch('/graphql', {
  //   method: 'post',
  //   headers: {
  //     Accept: 'application/json',
  //     'Content-Type': 'application/json',
  //   },
  //   body: JSON.stringify({
  //     query: query,
  //     variables: {pageName:"Help" },
  //   }),
  //   credentials: 'include',
  // });

  // const { data } = await resp.json();
// console.log("data help",data);
  // if (data && data.getEditStaticPage) {

    return {
      title: 'Help',
      // description: data.getEditStaticPage.metaDescription,
      component: <Layout><Help  /></Layout>,
    };

  // } else {

  //   return {
  //     title: dataResult.title,
  //     component: <Layout><Page {...dataResult} /></Layout>,
  //   };

  // }


}



import React from 'react';
import FooterLessLayout from '../../components/Layout/AffiliateListingLayout';
import AffiliateSearchListingRoutes from './affiliateSearchListingRoutes';
import fetch from '../../core/fetch';

// import { searchListing } from '../../actions/searchListing';
import { setPersonalizedValues } from '../../actions/personalized';

import { showLoading, hideLoading } from 'react-redux-loading-bar';
import moment from 'moment';
import { FaSearch } from 'react-icons/lib/fa';

const title = 'affiliateSearchListing';

export default async function action({ params, store, query }) {

  let isAuthenticated = store.getState().runtime.isAuthenticated;

  if (!isAuthenticated) {
    return { redirect: '/login' };
  }

  store.dispatch(showLoading());

  // Fetch Search Settings
  const searchQuery = `
    {
      getSearchSettings {
        minPrice
        maxPrice
        priceRangeCurrency
      }
    }
  `;

  const resp = await fetch('/graphql', {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: searchQuery,
    }),
    credentials: 'include',
  });


  const geographyData = store.getState().personalized.geography;
  const personCapacityData = store.getState().personalized.personCapacity;
  const startDateData = store.getState().personalized.startDate;
  const endDateData = store.getState().personalized.endDate;
  let geoType = store.getState().personalized.geoType;
  let lat = store.getState().personalized.lat;
  let lng = store.getState().personalized.lng;
  let sw_lat = store.getState().personalized.sw_lat;
  let sw_lng = store.getState().personalized.sw_lng;
  let ne_lat = store.getState().personalized.ne_lat;
  let ne_lng = store.getState().personalized.ne_lng;
  let personCapacity, dates, geography, currentPage = 1, location;
  let initialFilter = {};
  let fixedHeader = 'searchFixedHeader'
  let affiliateResults = {};

  let latAndLngQuery = `
        query ($address: String,$orderBy: String) {
          affiliateSearchListing(address: $address,orderBy: $orderBy) {
            results {
              id
               title
               city
               beds
               personCapacity
               bathrooms
               country
               affiliateId
               referralId
                isGenerated
               description
              listPhotos {
                id
                name
                type
              }
              listingData {
                basePrice
                currency
              }
            }
            count
            Userid
            status
            errorMessage
          }
        }
      `;
  let query_address = '';
  if ("address" in query && encodeURI(query.address)) {
    query_address = query.address;
  }



  const locationResp = await fetch('/graphql', {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: latAndLngQuery,
      variables: {
        address: query_address,
        "orderBy": "LATEST"
      }
    }),
    credentials: 'include',
  });
  // console.log('hiiiiii');
  const { data } = await locationResp.json();
  if (data && data.affiliateSearchListing) {
    affiliateResults = data.affiliateSearchListing;


    initialFilter["address"] = query_address;

  }

  return {
    title,
    component: <FooterLessLayout page={'affiliateSearchListing'} fixedHeader={fixedHeader}><AffiliateSearchListingRoutes
      initialFilter={initialFilter} affiliateResults={affiliateResults}

    />
    </FooterLessLayout>,
  };
}

import React from "react";
import { FormattedMessage } from "react-intl";
import PlanSlider from "./planSlider";
import PurchasePlan from "../PurchasePlan/purchasePlan";

// Fetch request
import { gql } from "react-apollo";

import createApolloClient from "../../core/createApolloClient/createApolloClient.client";

// messages
import messages from "../../locale/messages";

//Styles
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";
import s from "./host.css";

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      {/* <FaAngleLeft /> */}
      <div className={s.arrowBox}>
        <div className={s.arrow}>{"<"}</div>
      </div>
    </div>
  );
}
function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <div className={s.arrowBox}>
        <div className={s.arrow}>{">"}</div>
      </div>
    </div>
  );
}

const client = createApolloClient();

const query = gql`
  query getPlanDetails($userId: String) {
    getPlanDetails(userId: $userId) {
      errorMessage
      status
      results {
        id
        title
        one
        two
        three
        four
        five
        six
        monthly
        yearly
        currency
        status
      }
    }
  }
`;

const params = {
  userId: "d10b7d80-feaa-11ed-a3f7-99837cdf96df",
};

class PlanContainer extends React.Component {
  constructor(props) {
    super(props);
    this.setMonthlyPlan = this.setMonthlyPlan.bind(this);
    this.setAnnuallyPlan = this.setAnnuallyPlan.bind(this);
    this.getPlanDetails = this.getPlanDetails.bind(this);
    this.state = {
      planPriceType: "monthly",
      planDetails: [],
      page: "merbership",
      cardDetails: {},
      planPrice: {},
      theme: "",
      header: "",
    };
    this.nextPage = this.nextPage.bind(this);
    this.handleCardDetails = this.handleCardDetails.bind(this);
    this.planPrice = this.planPrice.bind(this);
    this.planTheme = this.planTheme.bind(this);
    this.planHeader = this.planHeader.bind(this);
  }

  handleCardDetails(values) {
    this.setState({ cardDetails: values });
  }
  planPrice(value) {
    this.setState({ planPrice: value });
  }

  planTheme(value) {
    this.setState({ theme: value });
  }
  planHeader(value) {
    this.setState({ header: value });
  }

  nextPage(page) {
    this.setState({ page: page });
  }

  async getPlanDetails() {
    const { data } = await client.query({
      query,
      variables: { params },
      fetchPolicy: "network-only",
    });
    // console.log("Response from API", data.getPlanDetails.results);
    this.setState({ planDetails: data.getPlanDetails.results });
  }

  componentDidMount() {
    this.getPlanDetails();
  }

  setMonthlyPlan() {
    this.setState({ planPriceType: "monthly" });
  }

  setAnnuallyPlan() {
    this.setState({ planPriceType: "yearly" });
  }

  render() {
    const { page, cardDetails, planPrice, theme, header } = this.state;
    const {userId,planExpire} = this.props
    return (
      <div>
        {page == "merbership" && (
          <div className={cx(s.planContainer)}>
            <div className={s.cardContainer}>
              <div className={s.title}>
                {/* <FormattedMessage id={"title"} {...messages.planTitle} /> */}
              </div>
              <PlanSlider
                planPriceType={this.state.planPriceType}
                planDetails={this.state.planDetails}
                nextPage={this.nextPage}
                handleCardDetails={this.handleCardDetails}
                planPrice={this.planPrice}
                planTheme={this.planTheme}
                planHeader={this.planHeader}
                planExpire={planExpire}
              />
            </div>
          </div>
        )}
        {page == "payment" && (
          <PurchasePlan
            cardDetails={cardDetails}
            planPrice={planPrice}
            header={header}
            theme={theme}
            userId={userId}
          />
        )}
      </div>
    );
  }
}

export default withStyles(s)(PlanContainer);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

// Style
import { Panel, FormGroup, FormControl, Button } from 'react-bootstrap';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './PanelItem.css';
import cx from 'classnames';
import * as FontAwesome from 'react-icons/lib/fa';
import { toastr } from 'react-redux-toastr';

// Component
import ListItem from '../ListItem';
import Loader from '../../Loader';
import Link from '../../Link';

import bt from '../../commonStyle.css';

// Locale
import messages from '../../../locale/messages';
import { debounce } from '../../../helpers/debounce';
import history from '../../../core/history';

//Image
import addIcon from '../../../../public/SiteIcons/addListIcon.svg';
import noListImage from '../../../../public/SiteImages/noListImage.svg';
const GET_PROPERTY_COUNT = `query getPropertieCount($userId: String,){
    getPropertieCount(userId:$userId){
      errormessage
        status
  results{
    planId
    propertieCount
  }
    }
  }`
class PanelItem extends Component {
    static propTypes = {
        data: PropTypes.array.isRequired,
        panelTitle: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            redirect: false
        }
        this.handleSearchChange = debounce(this.handleSearchChange.bind(this));
        this.handleClick = this.handleClick.bind(this);
    }

    handleSearchChange(searchKey) {
        const { refetch } = this.props;
        let variables = {
            searchKey
        };
        refetch(variables);
    }
    async componentDidMount() {
        const {userId} = this.props
    
        const resp = await fetch('/graphql', {
          method: 'post',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            query: GET_PROPERTY_COUNT,
            variables: { userId: userId }
          }),
        });
        const { data } = await resp.json();
        // if (data && data.getPropertieCount.status == "200") {
        //   this.setState({ redirect: true })
        // //   toastr.success('Hurray!', data.getPropertieCount.errormessage)
        // }else{
        //     toastr.error('Opps!', data.getPropertieCount.errormessage)
        // }
        // console.log("PanelItem", userId) 
        // console.log("datadatadatadatadatadata panelitem", data) 
      }
    handleClick(){
        const { redirect } = this.props;
        // console.log("redirect",redirect)
        if(redirect == false){
           history.push('/host/membership') 
        }else{
           history.push('/become-a-host?mode=new') 
        }
   }
    render() {
        const { data, panelTitle, loading, searchKey, refetch } = this.props;
        const { formatMessage } = this.props.intl;
        const {redirect} = this.state;
// // console.log("redirect",redirect)
        return (
            <div className={cx('manageListingItem')}>
                <div className={s.listFlex}>
                    <div className={s.mobileSearchWidth}>
                        <FormGroup className={s.noMargin}>
                            <FormControl
                                type="text"
                                placeholder={formatMessage(messages.searchYourListing)}
                                onChange={(e) => this.handleSearchChange(e.target && e.target.value)}
                                className={cx(s.formControlInput, s.jumboInput, s.locationBgIcon, 'locationBgIconRTL')}
                                value={searchKey}
                            />
                        </FormGroup>
                    </div>
                    {/* Removed + add property button */}
                    <div className={s.mobileSearchWidth}>
                        <Button onClick={this.handleClick} className={cx(bt.btnPrimary, s.linkCss)}>
                            <img src={addIcon} className={cx(s.plusIcon, 'addPlusIcon')} />
                            <FormattedMessage {...messages.addListing} />
                        </Button>
                    </div>
                </div>

                <Panel className={s.panelHeader} header={panelTitle}>
                    {
                        loading && <Loader type={"text"} />
                    }
                    {!loading && <ul className={cx(s.listContainer, 'listLayoutArbic')}>
                        {
                            data && data.length > 0 && data.map((item, index) => {
                                return (
                                    <ListItem data={item} key={index} refetch={refetch} searchKey={searchKey} />
                                )
                            })
                        }
                        {
                            data && data.length == 0 && <div className={s.textCenter}>
                                <img src={noListImage} />
                                <div className={s.noListHeading}>
                                    <FormattedMessage {...messages.noListHeading} />
                                </div>
                                <div className={s.noListSubHeading}>
                                    <FormattedMessage {...messages.noListSmall} />
                                </div>
                                {/* <Link to={redirect ? '/become-a-host?mode=new': "/host/membership"} className={cx(bt.btnPrimary, s.addNewBtn)}>
                                    <FormattedMessage {...messages.addNewlisting} />
                                </Link> */}
                            </div>
                        }
                    </ul>}
                </Panel>
            </div>
        )
    }
}

export default injectIntl(withStyles(s)(PanelItem));

import {
    GET_SUB_ADMIN_USER_START,
    GET_SUB_ADMIN_USER_SUCCESS,
    GET_SUB_ADMIN_USER_ERROR
} from '../../../constants';

import { gql } from 'react-apollo';

// Toaster
// import { toastr } from 'react-redux-toastr';

// import query from '../../../routes/siteadmin/adminUser/adminUserQuery.graphql';

// import { setRuntimeVariable } from '../../runtime';
// import { closeAdminUserModal } from '../modalActions';

import { getAllAdminPrivilegesId } from '../../../helpers/adminPrivileges';

const getAdminUserQuery = gql`
query {
    getSubAdminUser {
      id
      email
      createdAt
      updatedAt
      status
      errorMessage
    }
  }
`;


export function getSubAdminUser() {
    return async (dispatch, getState, { client }) => {
        let adminPrivileges;
        let defaultPrivileges = getAllAdminPrivilegesId();
        
        try {
            await dispatch({
                type: GET_SUB_ADMIN_USER_START,
                payload: {
                    getAdminUserLoading: true
                }
            });

            const { data } = await client.query({
                query: getAdminUserQuery
            });

            if (data && data.getSubAdminUser && data.getSubAdminUser.id) {
                // dispatch(setRuntimeVariable({
                //     name: 'isSuperAdmin',
                //     value: data && data.getAdminUser 
                // }));

                adminPrivileges = {
                    id: data.getSubAdminUser.id,
                    email: data.getSubAdminUser.email,
                    isSuperAdmin: true,
                    // roleId: data.getAdminUser.roleId,
                    privileges:  [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,20,21,22,23,24]
                };

                // if (adminPrivileges && adminPrivileges.isSuperAdmin) {
                //     adminPrivileges['privileges'] = defaultPrivileges;
                // }

                await dispatch({
                    type: GET_SUB_ADMIN_USER_SUCCESS,
                    payload: {
                        getAdminUserLoading: false,
                        adminPrivileges
                    }
                });

                return adminPrivileges;
            } else {
                await dispatch({
                    type: GET_SUB_ADMIN_USER_SUCCESS,
                    payload: {
                        getAdminUserLoading: false,
                        error: data && data.getSubAdminUser && data.getSubAdminUser.errorMessage
                    }
                });
                return false;
            }
        } catch(error) {
            await dispatch({
                type: GET_SUB_ADMIN_USER_ERROR,
                payload: {
                    getAdminUserLoading: false,
                    error
                }
            });
            return false;
        }
    }
}
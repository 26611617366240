exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ExpandToggle-expandContainer-UZbFN {\n  height: 36px;\n  -webkit-box-shadow: 0px 3px 6px #00000029;\n          box-shadow: 0px 3px 6px #00000029;\n  border-radius: 6px;\n  position: absolute;\n  top: 25px;\n  left: 25px;\n  z-index: 2;\n  background: #fff;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  cursor: pointer;\n  padding: 0 15px;\n}\n\n.ExpandToggle-expandContent-1qg-k {\n  display: inline-block;\n  margin: 0 6px;\n  position: relative;\n  top: 1px;\n  cursor: pointer;\n}\n\n.ExpandToggle-expandContainer-UZbFN img {\n  -webkit-transition: all 0.3s ease;\n  -o-transition: all 0.3s ease;\n  transition: all 0.3s ease;\n}\n\n.ExpandToggle-exPandIcon-JW4sx {\n  position: relative;\n  top: 0.1px;\n}\n\n.ExpandToggle-listingIcon-3uMmW {\n  position: relative;\n  top: 1px;\n}\n\n@media screen and (max-width: 1199px) {\n  .ExpandToggle-expandContainer-UZbFN {\n    display: none;\n  }\n}", ""]);

// exports
exports.locals = {
	"expandContainer": "ExpandToggle-expandContainer-UZbFN",
	"expandContent": "ExpandToggle-expandContent-1qg-k",
	"exPandIcon": "ExpandToggle-exPandIcon-JW4sx",
	"listingIcon": "ExpandToggle-listingIcon-3uMmW"
};
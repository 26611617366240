exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".WishListIcon-iconContainer-365H5 {\n\tposition: absolute;\n\tright: 15px;\n\ttop: 15px;\n\tz-index: 2;\n}\n.WishListIcon-buttonContainer-HVmFC {\n    text-align: center;\n\tcursor: pointer;\n\tdisplay: -webkit-box;\n\tdisplay: -ms-flexbox;\n\tdisplay: flex;\n    -webkit-column-gap: 9px;\n       -moz-column-gap: 9px;\n            column-gap: 9px;\n}\n.WishListIcon-wishListIcon-HTtpo {\n\tline-height: 1;\n\tcursor: pointer;\n\twidth: 100%;\n\tmax-width: 35px;\n}\n.WishListIcon-buttonContainer-HVmFC .WishListIcon-wishListIcon-HTtpo {\n\tmax-width: 20px !important;\n}\n.WishListIcon-buttonText-1jIWs {\n\tpadding-left: 1px;\n}\n.WishListIcon-vtrBottom-25Cet {\n\tvertical-align: middle;\n}\n.WishListIcon-vtrMiddle-5sLjK {\n\tvertical-align: middle;\n}\n.WishListIcon-viewListIcon-2YGZ2{\n\tmax-width: 20px !important;\n    height: 20px;\n}\n.WishListIcon-iconAlignCenter-2aP87{\n\t-webkit-box-align: center;\n\t    -ms-flex-align: center;\n\t        align-items: center;\n}", ""]);

// exports
exports.locals = {
	"iconContainer": "WishListIcon-iconContainer-365H5",
	"buttonContainer": "WishListIcon-buttonContainer-HVmFC",
	"wishListIcon": "WishListIcon-wishListIcon-HTtpo",
	"buttonText": "WishListIcon-buttonText-1jIWs",
	"vtrBottom": "WishListIcon-vtrBottom-25Cet",
	"vtrMiddle": "WishListIcon-vtrMiddle-5sLjK",
	"viewListIcon": "WishListIcon-viewListIcon-2YGZ2",
	"iconAlignCenter": "WishListIcon-iconAlignCenter-2aP87"
};
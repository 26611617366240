
import React from 'react';
// import UserLayout from '../../components/Layout/UserLayout';
import Manageinfo from './manageinfo';
import NewListLayout from '../../components/Layout/NewListLayout';


const title = 'Affiliate Manager';

export default async function action({ store }) {

  // From Redux Store
  
  // From Redux Store
  let isAuthenticated = store.getState().runtime.isAuthenticated;

  if (!isAuthenticated) {
    return { redirect: '/login' };
  }

  return {
    title,
    component: <NewListLayout><Manageinfo title={title}/></NewListLayout>,
  };
};



import React from "react"
import KYIByDate from "../../components/KnowYourIncome/KYIbyDate"
class KYIbyDate extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
         graphData: [],
          filter: "Month",
        };
      }
   
render(){
    const {type,data,noData} = this.props;
    return (
        <> <KYIByDate type={type} data={data}  noData={noData}/> </>
    )
}
}
export default (KYIbyDate)
exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n.EmptyInbox-textLead-3mgH_ {\n\tcolor: #484848;\n\tline-height: 1.1;\n}\n.EmptyInbox-widthImg-kHcL6{\n\twidth: 200px;\n}\n.EmptyInbox-textCenter-Ge-oN{\n\ttext-align: center;\n\tcolor: #707070;\n\tmax-width: 396px;\n\tmargin: 20px auto;\n\tfont-size: 18px;\n}\n.EmptyInbox-textMuted-3QtSy{\n\tmargin: 20px 0;\n}", ""]);

// exports
exports.locals = {
	"textLead": "EmptyInbox-textLead-3mgH_",
	"widthImg": "EmptyInbox-widthImg-kHcL6",
	"textCenter": "EmptyInbox-textCenter-Ge-oN",
	"textMuted": "EmptyInbox-textMuted-3QtSy"
};
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
// Redux Form
import { SubmissionError } from 'redux-form';

// Fetch request
import fetch from '../../core/fetch';

// Language
import messages from '../../locale/messages';
import PopulateData from '../../helpers/populateData';

// Redux
// import { setRuntimeVariable } from '../../actions/runtime';
// import { loadAccount } from '../../actions/account';
// import { closeSignupModal } from '../../actions/modalActions';

async function submit(values,country, countryCode ,dispatch) {
  // console.log("values",values);
  // console.log("values",countryCode);
  // console.log("values",country);
  const query = `mutation (
    $payeeName: String, 
    $address: String,
    $address2: String, 
    $city: String, 
    $state: String, 
    $zipcode: Int, 
    $country: String, 
    $phoneNumber: String,
    $countryCode: String
    ) {
    createAffiliateUserAccountInfo (
      payeeName: $payeeName, 
      address: $address,
      address2: $address2,
      city: $city, 
      state: $state, 
      zipcode: $zipcode, 
      country: $country,
      phoneNumber: $phoneNumber,
      countryCode: $countryCode
      ) {
    status
    errorMessage
  }
}`;

let Address = values.address
let Address2 = values.address2

const params = {
      // userId: 'd10b7d80-feaa-11ed-a3f7-99837cdf96df',
      payeeName: values.payeeName, 
      address: Address,
      address2: Address2,
      city: values.city, 
      state: values.state, 
      zipcode: values.zipcode, 
      country: countryCode,
      countryCode:country,
      phoneNumber: values.phoneNumber
  
};
// console.log("params",params);
  
//   const resp = await fetch('/graphql', {
//     method: 'post',
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify({
//       query: query,
//       variables: params
//     }),
//     credentials: 'include'
//   });

//   const { data } = await resp.json();

const resp = await fetch('/graphql', {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query: query,
      variables: params
    }),
    credentials: 'include'
  });

  const { data } = await resp.json();

  // console.log("After fetching data",data);
  
  window.location.href='/affiliate/manageinfo/step2'

}

export default submit;

// import { createAffiliateUser } from '../../actions/siteadmin/AffiliateUser/manageAffiliateUser';

// async function submit(values, dispatch) {
 

//   dispatch(createAffiliateUser(
//     values.id,
//     values.email,
//     values.password,
//     values.findUS,
//     values.phonenumber,

//     // values.roleId
//   ));
// }

// export default submit;

import React from 'react';
import AdminLayout from '../../../components/Layout/SubadminLayout';
import Reservations from './Reservations';
import { restrictUrls } from '../../../helpers/adminPrivileges';


const title = 'Reservations';

export default async function action({ store }) {


  // From Redux Store
  let issubAdminAuthenticated = store.getState().runtime.issubAdminAuthenticated;
  let adminPrivileges = store.getState().adminPrevileges.privileges && store.getState().adminPrevileges.privileges.privileges;


  if (!issubAdminAuthenticated) {
    return { redirect: '/subadmin/login' };
  }
// console.log("issubAdminAuthenticated",adminPrivileges);
  // Admin restriction
  // if (!restrictUrls('/subadmin/reservations', adminPrivileges)) {
  //   return { redirect: '/subadmin' };
  // }

  return {
    title,
    component: <AdminLayout><Reservations title={title} /></AdminLayout>,
  };
}

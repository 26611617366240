import React, { Component } from "react";
import MemberPaymentForm from "./memberPaymentForm";
import PlanDetails from "./planDetails";
import { Button } from "react-bootstrap";

// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./purchasePlan.css";
import bt from "../../components/commonStyle.css";

import { FormattedMessage, injectIntl } from "react-intl";

// Locale
import { Elements } from "react-stripe-elements";
import messages from "../../locale/messages";

// Image
import correct from "../../../public/SiteIcons/check.svg";

class purchasePlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCoupon:false
    };
  }

  render() {
    const { cardDetails, planPrice, header, theme,userId } = this.props;

    const {showCoupon} = this.state;
    // console.log("planPrice",planPrice)
    return (
      <div className={s.grid}>
        {/* <Button className={s.claimBtn} onClick={()=>this.setState({showCoupon:!showCoupon})}>{showCoupon?"REMOVE COUPON":"APPLY COUPON"}</Button> */}

        <div className={s.cardContainer}>
          <Elements>
            <MemberPaymentForm showCoupon={showCoupon} cardDetails={cardDetails} planPrice={planPrice} userId={userId}/>
          </Elements>
        </div>
        <div className={s.container}>
          <PlanDetails
            cardDetails={cardDetails}
            planPrice={planPrice}
            header={header}
            theme={theme}
            userId={userId}
          />
        </div>
      </div>
    );
  }
}

export default injectIntl(withStyles(s, bt)(purchasePlan));

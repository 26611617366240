
import messages from '../../locale/messages';
import { toastr } from 'react-redux-toastr';

const validateStep3 = values => {

	const errors = {};

	if (Number(values.checkInStart) >= Number(values.checkInEnd)) {
		errors.checkInStart = messages.advanceNoticeError;
	}

	if (Number(values.checkInStart) < 8 || Number(values.checkInStart) > 25) {
		errors.checkInStart = messages.advanceNoticeInvalid;
	}

	if (Number(values.checkInEnd) < 9 || Number(values.checkInEnd) > 26) {
		errors.checkInEnd = messages.advanceNoticeInvalid;
	}

	if (!values.basePrice) {
		errors.basePrice = messages.required;
	}

	if (isNaN(values.basePrice) || (!/^[0-9\.]+$/.test(values.basePrice)) || (parseFloat(values.basePrice, 10) < 1)) {
		errors.basePrice = messages.basePriceInvalid;
	}

	if (values.cleaningPrice) {
		if (isNaN(values.cleaningPrice) || (!/^[0-9\.]+$/.test(values.cleaningPrice)) || (parseInt(values.cleaningPrice, 10) < 0)) {
			errors.cleaningPrice = messages.cleaningPriceInvalid;
		}
	}

	if (values.weeklyDiscount) {
		if (isNaN(values.weeklyDiscount) || (!/^[0-9]+$/.test(values.weeklyDiscount)) || (parseInt(values.weeklyDiscount, 10) < 0) || parseInt(values.weeklyDiscount, 10) > 99) {
			errors.weeklyDiscount = messages.discountInvalid;
		}
	}

	if (values.monthlyDiscount) {
		if (isNaN(values.monthlyDiscount) || (!/^[0-9]+$/.test(values.monthlyDiscount)) || (parseInt(values.monthlyDiscount, 10) < 0) || parseInt(values.monthlyDiscount, 10) > 99) {
			errors.monthlyDiscount = messages.discountInvalid;
		}
	}

	if (Number(values.maxNight) > 0) {
		if (values.minNight > values.maxNight) {
			errors.minNight = 'Minimum nights can`t be higher than maximum nights';
		}
	}

	if (values.taxRate) {
		if (isNaN(values.taxRate) || (!/^[0-9\.]+$/.test(values.taxRate)) || (parseInt(values.taxRate, 10) < 0) || parseInt(values.taxRate, 10) > 99) {
			errors.taxRate = messages.taxRateInvalidError;
		}
	}
	if (values.affiliate_commission) {
		if (isNaN(values.affiliate_commission) || !(/(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/.test(values.affiliate_commission)) || (parseInt(values.affiliate_commission, 10) < 0) || parseInt(values.affiliate_commission, 10) > 99) {
			errors.affiliate_commission = messages.affiliate_commissionInvalid;
			// console.log("affiliate_commission",messages.affiliate_commission);
			toastr.error('opps',messages.affiliate_commissionInvalid.defaultMessage)
		}
	}

	return errors;
}

export default validateStep3;

// import React, { Component } from 'react';
// import s from "./AddCoupon.css";
// import cx from 'classnames';
// // import { Table, Tr, Td, Thead, Th } from 'reactable';
// import { Button,Table } from 'react-bootstrap';
// import withStyles from 'isomorphic-style-loader/lib/withStyles';
// import { FormControl, FormGroup, ControlLabel, DropdownButton, MenuItem, ButtonToolbar } from 'react-bootstrap';
// import bt from '../../../components/commonStyle.css';
// import { FormattedMessage, injectIntl } from 'react-intl';
// // Redux Actions
// import { openAdminUserModal } from '../../../actions/siteadmin/modalActions';
// import { deleteAdminUser } from '../../../actions/siteadmin/AdminUser/manageAdminUser';
// import AdminUserModal from '../AdminUserModal';

// // import moment from 'moment';
// // import { debounce } from '../../../helpers/debounce';
// import messages from '../../../locale/messages';
// // import { graphql, gql, compose } from 'react-apollo';
// const usersData = [
//     {
//         name: "abc",
//         transactionId: 123,
//         planName: "Custom",
//         purchaseDate: 300,
//         expireData: 120,
//         affiliateStatus: "active",
//     },
//     {
//         name: "abc",
//         transactionId: 123,
//         planName: "Custom",
//         purchaseDate: 300,
//         expiryDate: 120,
//         affiliateStatus: "Inactive",
//     },
//     {
//         name: "abc",
//         transactionId: 123,
//         planName: "Custom",
//         purchaseDate: 300,
//         expiryDate: 120,
//         affiliateStatus: "active",
//     },
//     {
//         name: "abc",
//         transactionId: 123,
//         planName: "Custom",
//         purchaseDate: 300,
//         expiryDate: 120,
//         affiliateStatus: "active",
//     },
//     {
//         name: "abc",
//         transactionId: 123,
//         planName: "Custom",
//         purchaseDate: 300,
//         expiryDate: 120,
//         affiliateStatus: "Inactive",
//     },
//     {
//         name: "abc",
//         transactionId: 123,
//         planName: "Custom",
//         purchaseDate: 300,
//         expiryDate: 120,
//         affiliateStatus: "active",
//     },
//     {
//         name: "abc",
//         transactionId: 123,
//         planName: "Custom",
//         purchaseDate: 300,
//         expiryDate: 120,
//         affiliateStatus: "Inactive",
//     },
//     {
//         name: "abc",
//         transactionId: 123,
//         planName: "Custom",
//         purchaseDate: 300,
//         expiryDate: 120,
//         affiliateStatus: "active",
//     },
//     {
//         name: "abc",
//         transactionId: 123,
//         planName: "Custom",
//         purchaseDate: 300,
//         expiryDate: 120,
//         affiliateStatus: "active",
//     },
//     {
//         name: "abc",
//         transactionId: 123,
//         planName: "Custom",
//         purchaseDate: 300,
//         expiryDate: 120,
//         affiliateStatus: "active",
//     },
//     {
//         name: "abc",
//         transactionId: 123,
//         planName: "Custom",
//         purchaseDate: 300,
//         expiryDate: 120,
//         affiliateStatus: "active",
//     },
//     {
//         name: "abc",
//         transactionId: 123,
//         planName: "Custom",
//         purchaseDate: 300,
//         expiryDate: 120,
//         affiliateStatus: "Inactive",
//     },
//     {
//         name: "abc",
//         transactionId: 123,
//         planName: "Custom",
//         purchaseDate: 300,
//         expiryDate: 120,
//         affiliateStatus: "active",
//     },
//     {
//         name: "abc",
//         transactionId: 123,
//         planName: "Custom",
//         purchaseDate: 300,
//         expiryDate: 120,
//         affiliateStatus: "Inactive",
//     },
//     {
//         name: "abc",
//         transactionId: 123,
//         planName: "Custom",
//         purchaseDate: 300,
//         expiryDate: 120,
//         affiliateStatus: "Inactive",
//     },
//     {
//         name: "abc",
//         transactionId: 123,
//         planName: "Custom",
//         purchaseDate: 300,
//         expiryDate: 120,
//         affiliateStatus: "active",
//     },
//     {
//         name: "abc",
//         transactionId: 123,
//         planName: "Custom",
//         purchaseDate: 300,
//         expiryDate: 120,
//         affiliateStatus: "Inactive",
//     },
//     {
//         name: "abc",
//         transactionId: 123,
//         planName: "Custom",
//         purchaseDate: 300,
//         expiryDate: 120,
//         affiliateStatus: "active",
//     },
// ];
// class AddCoupon extends Component {
//     constructor(props) {
//         super(props);
//         // const { subscriptionData } = this.props
//         this.state = {
//             filteredData: [],
//         }

//     }

//     //1. coupon code
//     //2. select -> property booking/ subscription
//     //3. discount
//     //4. start date and end date
//     render() {
//         const { formatMessage } = this.props.intl;
//         return (
//             <div className={cx(s.pagecontentWrapper, 'pagecontentAR')}>
//                 <div>
//                     <h1 className={s.headerTitle}><FormattedMessage {...messages.addCoupon} /></h1>
//                     <AdminUserModal />
//                     <div className={s.space4}>
//                         <Button
//                             className={cx(bt.btnPrimary, bt.btnLarge)}
//                             onClick={() => openAdminUserModal('add')}>
//                             <FormattedMessage {...messages.addNewLabel} />
//                         </Button>
//                     </div>
//                     <div className={cx('table-responsive', 'tableBorderRadiusAdmin', 'NewAdminResponsiveTable', 'NewResponsiveTableAdmin')}>
//                       <div>
//                     <Table striped bordered condensed hover>
//                             <thead>
//                                 <tr>
//                                     <th>#</th>
//                                     <th>First Name</th>
//                                     <th>Last Name</th>
//                                     <th>Username</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 <tr>
//                                     <td>1</td>
//                                     <td>Mark</td>
//                                     <td>Otto</td>
//                                     <td>@mdo</td>
//                                 </tr>
//                                 <tr>
//                                     <td>2</td>
//                                     <td>Jacob</td>
//                                     <td>Thornton</td>
//                                     <td>@fat</td>
//                                 </tr>
//                                 <tr>
//                                     <td>3</td>
//                                     <td colSpan="2">Larry the Bird</td>
//                                     <td>@twitter</td>
//                                 </tr>
//                             </tbody>
//                         </Table>
//                     </div>
//                     </div>

//                 </div>
//             </div>
//         )
//     }

// }

// export default injectIntl(withStyles(s, bt), (AddCoupon));


// mutation Createcouponcode($couponCode: String, $couponType: String, $discount: Float, $startDate: String, $endDate: String, $description: String){
//   Createcouponcode(couponCode: $couponCode, couponType: $couponType, discount: $discount, startDate: $startDate, endDate: $endDate, description: $description){
//     status
//   }
// }
import React, { Component } from "react";

import { Table, Tr, Td, Thead, Th } from "reactable";
import { FormGroup, Modal, Button, FormControl, ControlLabel, HelpBlock, FieldGroup, InputGroup } from "react-bootstrap";
import { toastr } from 'react-redux-toastr';
import { graphql, gql, compose } from 'react-apollo';

// Style
import st from "./AddCoupon.css"
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./affiliateDataTable.css";
import bt from "../../commonStyle.css";
import CustomPagination from "../../CustomPagination/CustomPagination";
import DateRange from "../../Home/AffiliateDateRange";

// Translation
import { FormattedMessage, injectIntl } from "react-intl";
import messages from "../../../locale/messages";

import AddCouponQuery from "./AddCouponQuery.graphql"
// Image
import ExportImage from "../../../../public/adminIcons/export.png";
import { debounce } from "../../../helpers/debounce";
import moment from "moment";

const usersData = [
];
const SEND_MODAL_DATA = `mutation Createcouponcode($couponCode:String,$couponType:String,$discount:Float,$startDate:String,$endDate:String,$description:String,$userType:String,$subscriptionType:String){
  Createcouponcode(couponCode:$couponCode,couponType:$couponType,discount:$discount,startDate:$startDate,endDate:$endDate,description:$description,userType:$userType,subscriptionType:$subscriptionType){
      status
      }
  }`
// const total = usersData.length
// console.log("TOTAL", total);

class AffiliateTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      couponName: "",
      couponType: "",
      couponDiscount: 0,
      startDate: "",
      endDate: "",
      couponDesc: "",
      show: false,
      searchList:"",
      subscriptionType:""

    };
    this.handleChange = this.handleChange.bind(this);
    this.paginationData = this.paginationData.bind(this);
    this.handleSearchChange = debounce(this.handleSearchChange.bind(this));
    this.handleDropDown = this.handleDropDown.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);

  }

  handleChange(e) {
    // console.log("Value", e.target.value);
    // let affiliateStatus = e.target.value;
  }
  handleDropDown(e) {
    const { Getcoupon: { refetch }, setStateVariable } = this.props
    // console.log("Valueeeeeeee",e.target.value);
    let variables = {
      // searchList:'"'+ e.target.value+'"',
      searchList: e.target.value,
      currentPage: 1
    };
    this.setState({status:e.target.value})
    setStateVariable({ searchList: e.target.value, currentPage: 1 });
    refetch(variables)
    refetch(variables)
  }
  paginationData(currentPage) {
    const { Getcoupon: { refetch }, setStateVariable } = this.props;
    let variables = { currentPage };
    setStateVariable({ currentPage });
    refetch(variables);
  }
  handleSearchChange(searchList) {
    const { Getcoupon: { refetch }, setStateVariable } = this.props;
    let variables = {
      currentPage: 1,
      searchList
    };
    setStateVariable(variables);
    refetch(variables);
  }
  handleClose() {
    // toastr.success("Success!", "Promo code coupon added successfully");
    this.setState({ show: false });
  }
  handleShow() {
    this.setState({ show: true });
  }
  handlePickDate = async (value) => {
    const answer_array = value.replaceAll("'", '').split(" AND ");
    // console.log(answer_array, "value");
    this.setState({ startDate: answer_array[0], endDate: answer_array[1] })

  };
  handleModalSubmit = async () => {
    const { startDate, endDate, couponName, couponDesc, couponType, couponDiscount, subscriptionType } = this.state;
    const { Getcoupon: { Getcoupon,refetch } } = this.props;
    if (!couponName) {
      toastr.error("Opps!", "Cannot submit without name");
      return; // Exit function early if coupon name is missing
    }
    if (
      !couponDiscount ||
      isNaN(couponDiscount) ||
      !(Number(couponDiscount) >= 0 && Number(couponDiscount) <= 100)
    ) {
      toastr.error("Opps!", "Invalid coupon discount value. Please enter a number between 0 and 100.");
      return; // Exit function early if coupon discount is invalid
    }
  
    if (!startDate || startDate == null || startDate == '') {
      toastr.error("Opps!", "Cannot submit without start Date");
      return; // Exit function early if start date is missing
    }
  
    if (!endDate || endDate == null || endDate == '') {
      toastr.error("Opps!", "Cannot submit coupon without end Date");
      return; // Exit function early if end date is missing
    }
    const resp = await fetch('/graphql', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query: SEND_MODAL_DATA,
        variables: {
          userType:"admin",
          couponCode: couponName,
          couponType: 'subscription',
          discount: couponDiscount,
          startDate: startDate,
          endDate: endDate,
          description: couponDesc,
          subscriptionType:subscriptionType
        }
      }),
    });
    const { data } = await resp.json();
    if (data.Createcouponcode.status == 200) {
      toastr.success("Success!", "coupon added successfully");
      let vari ={
        currentPage: 1,
        searchList:''
      }
      this.setState({ couponCode: "", couponType: "", couponDiscount: "", startDate: "", endDate: "", couponDesc: "" , subscriptionType:""})
      refetch(vari)
    }
    // console.log(data, "SEND_MODAL_DATA");
    this.setState({ show: false });
  }
  //     //1. coupon code
  //     //2. select -> property booking/ subscription
  //     //3. discount
  //     //4. start date and end date
  render() {
    const { formatMessage } = this.props.intl;
    const { Getcoupon: { Getcoupon } } = this.props;
    const { currentPage, searchList } = this.props;
    const { status, show, startDate, endDate, couponName,couponDesc,couponType,couponDiscount,subscriptionType } = this.state;
    let total;
    if(Getcoupon){
    total = Getcoupon.count
    }
   
    // console.log(couponType,"Getcoupon")
    // console.log(couponName,couponType,startDate,endDate,couponDesc,couponDiscount , "couponName")

    return (
      <div className={cx(s.pagecontentWrapper, 'pagecontentAR')}>
        <div>
          <h1 className={s.headerTitle}>
            <FormattedMessage {...messages.addCoupon} />
          </h1>
          <Button bsStyle="primary" style={{marginBottom:"20px"}} bsSize="large" className={cx(bt.btnPrimary, bt.btnLarge)} onClick={this.handleShow}>
            ADD COUPON
          </Button>
          <div className={cx(s.exportSection, "bgBlack")}>
            <div>
              <FormGroup className={s.noMargin}>
                <FormControl
                  type="text"
                  placeholder={formatMessage(messages.search)}
                  onChange={(e) =>
                    this.handleSearchChange(e.target && e.target.value)
                  }
                  className={cx("searchInputControl", "searchInputControlAR")}
                />
              </FormGroup>
            </div>
            <div>
              <FormGroup controlId="formControlsSelect" className={s.noMargin}>
                <FormControl
                  onChange={(e) => this.handleDropDown(e)}
                  value={searchList}
                  componentClass="select"
                  placeholder={formatMessage(messages.selectLabel)}
                  className={cx(
                    bt.commonControlSelect,
                    s.userSelect,
                    "rtlUserSelect"
                  )}
                >
                  <option value="">{formatMessage(messages.allLabel)}</option>
                  <option value="subscription">{formatMessage(messages.admin)}</option>
                  <option value="booking">
                    {formatMessage(messages.host)}
                  </option>
                </FormControl>
              </FormGroup>
            </div>
            <div>
              {Getcoupon && Getcoupon.length > 0 && (
                <div
                  className={cx(s.exportText, "commonFloatLeft", "textWhite")}
                >
                  <span className={s.vtrMiddle}>
                    <FormattedMessage {...messages.exportDataIntoCSV} />
                  </span>
                  <span className={cx(s.exportLinkImg, "exportLinkImgCommon")}>
                    <img src={ExportImage} className={s.exportImg} />
                  </span>
                </div>
              )}
            </div>
          </div>
          <Modal
            aria-labelledby="contained-modal-title-sm"
            show={show} onHide={this.handleClose}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-sm">ADD COUPON</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form>
                <FormGroup
                  controlId="formBasicText"
                >
                  <ControlLabel className={st.formLabel}>PROMO CODE NAME</ControlLabel>
                  <FormControl
                    type="text"
                    minLength={8}
                    maxLength={12}
                    placeholder="Enter Promo code name"
                    value={couponName}
                    onInput={(e) => this.setState({ couponName: e.target.value.replace(/\s+/g, '').toUpperCase() })}
                  // onInput={(e)=>this.setState({couponName:e.target.value.toUpperCase()})}
                  />
                  <br />
                  <div style={{ display: "flex", justifyContent: "space-evenly", gap: "10px" }}>
                    <div style={{ width: "50%" }}>  <ControlLabel className={st.formLabel}>PROMO CODE TYPE</ControlLabel>
                      <FormControl componentClass="select" onChange={(e)=>this.setState({subscriptionType:e.target.value})}>
                        <option value="">select</option>
                        <option value="Bronze">Bronze</option>
                        <option value="Silver">Silver</option>
                        <option value="Gold">Gold</option>
                        <option value="Platinum">Platinum</option>

                      </FormControl>
                    </div>
                    <div style={{ width: "80%" }}>
                      <ControlLabel className={st.formLabel}>PROMO CODE START & END DATE</ControlLabel>
                      <DateRange
                        startDate={startDate}
                        endDate={endDate}
                        onChange={this.handlePickDate}
                        isOutsideRange={() => false}
                      />
                    </div>
                    <br />

                  </div>
                  <br />
                  <ControlLabel className={st.formLabel}>PROMO CODE DISCOUNT</ControlLabel>
                  <InputGroup>
                    <FormControl
                      type="text"
                      step=".01"
                      placeholder="Enter Promo code discount"
                      onInput={(e)=>this.setState({couponDiscount:e.target.value})}
                    />
                    <InputGroup.Addon className={s.prefixIcon}>{"%"}</InputGroup.Addon>

                  </InputGroup>
                  <br />
                  <ControlLabel className={st.formLabel}>PROMO CODE DESCRIPTION</ControlLabel>
                  <FormControl
                    type="text"
                    value={couponDesc}
                    onInput={(e)=>this.setState({couponDesc:e.target.value})}
                    placeholder="Enter Promo code description"
                  />
                  <br />
                  <FormControl.Feedback />
                  <HelpBlock>Here's go the promo code page description</HelpBlock>
                </FormGroup>
              </form>
            </Modal.Body>
            <Modal.Footer>
              <Button bsStyle="info" onClick={this.handleModalSubmit}>Submit</Button>
              <Button onClick={this.handleClose}>Close</Button>
            </Modal.Footer>
          </Modal>
          <div
            className={cx(
              "table-responsive",
              "NewAdminResponsiveTable",
              "NewResponsiveTableAdmin"
            )}
          >
            <Table
              className="table"
              noDataText={formatMessage(messages.noRecordFound)}
              sortable={true}
            // defaultSort={{ column: 'Profile ID', direction: 'desc' }}
            >
              <Thead>
                <Th scope="col">{formatMessage(messages.couponName)}</Th>
                <Th scope="col">{formatMessage(messages.couponFor)}</Th>
                <Th scope="col">{formatMessage(messages.couponDiscount)}</Th>
                <Th scope="col">{formatMessage(messages.couponActiveFrom)}</Th>
                <Th scope="col">{formatMessage(messages.couponActiveTill)}</Th>
                <Th scope="col">{formatMessage(messages.subStatus)}</Th>
              </Thead>    
              {Getcoupon && Getcoupon.result.length > 0 &&
                Getcoupon.result.map((value, key) => {
                  let affiliateStatus = value.activeStatus;
                  // if (affiliateStatus === "1") {
                  //   affiliateStatus = "1";
                  // } else if (affiliateStatus === "Inactive") {
                  //   affiliateStatus = "0";
                  // }
                  return (
                    <Tr key={key}>
                      <Td
                        data-label={formatMessage(messages.couponName)}
                        column={formatMessage(messages.couponName)}
                        data={value.couponCode}
                        className={s.userVerticalAlign}
                      />
                      <Td
                        data-label={formatMessage(messages.couponFor)}
                        column={formatMessage(messages.couponFor)}
                        data={value.couponType}
                        className={s.userVerticalAlign}
                      />
                      <Td
                        data-label={formatMessage(messages.couponDiscount)}
                        column={formatMessage(messages.couponDiscount)}
                        data={value.discount}
                        className={s.userVerticalAlign}
                      />
                      <Td
                        data-label={formatMessage(messages.couponActiveFrom)}
                        column={formatMessage(messages.couponActiveFrom)}
                        data={moment(value.startDate).format('ddd MMM DD YYYY')}
                        className={s.userVerticalAlign}
                      />
                      <Td
                        data-label={formatMessage(messages.couponActiveTill)}
                        column={formatMessage(messages.couponActiveTill)}
                        data={moment(value.endDate).format('ddd MMM DD YYYY')}
                        className={s.userVerticalAlign}
                      />
                      <Td
                        data-label={formatMessage(messages.subStatus)}
                        column={formatMessage(messages.subStatus)}
                      >
                        <select
                          name="affiliateStatus"
                          className={cx(
                            bt.commonControlSelect,
                            s.userVerticalAlign,
                            s.btnMarginBottom
                          )}
                          onChange={(e) => this.handleChange(e)}
                          value={value.activeStatus}
                        >
                          <option value={''}>
                            {formatMessage(messages.selectLabel)}
                          </option>
                          <option value="1">
                            {formatMessage(messages.isActive)}
                          </option>
                          <option value="0">
                            {formatMessage(messages.isNotActive)}
                          </option>
                        </select>
                      </Td>
                    </Tr>
                  );
                })}
                {/* {Getcoupon.result.length > 0 &&
                Getcoupon.result.map((value, key) => {
                  let affiliateStatus = value.status;
                  if (affiliateStatus === 1) {
                    affiliateStatus = "1";
                  } else if (affiliateStatus === 0) {
                    affiliateStatus = "0";
                  }
                  return (
                    <Tr key={key}>
                      <Td
                        data-label={formatMessage(messages.subName)}
                        column={formatMessage(messages.subName)}
                        data={value.couponCode}
                        className={s.userVerticalAlign}
                      />
                      <Td
                        data-label={formatMessage(messages.transactionID)}
                        column={formatMessage(messages.transactionID)}
                        data={value.couponType}
                        className={s.userVerticalAlign}
                      />
                      <Td
                        data-label={formatMessage(messages.planName)}
                        column={formatMessage(messages.planName)}
                        data={value.discount}
                        className={s.userVerticalAlign}
                      />
                      <Td
                        data-label={formatMessage(messages.purchaseData)}
                        column={formatMessage(messages.purchaseData)}
                        data={value.startDate}
                        className={s.userVerticalAlign}
                      />
                      <Td
                        data-label={formatMessage(messages.expireData)}
                        column={formatMessage(messages.expireData)}
                        data={value.endDate}
                        className={s.userVerticalAlign}
                      />
                      
                    </Tr>
                  );
                })} */}
            </Table>
          </div>
          <div>
          <CustomPagination
              total={total}
              currentPage={currentPage}
              defaultCurrent={1}
              defaultPageSize={10}
              change={this.paginationData}
              paginationLabel={formatMessage(messages.coupons)}
            />
          </div>
        </div>
      </div>
    );
  }
}

// export default injectIntl(withStyles(s, bt, st)(AffiliateTable));
export default compose(
  injectIntl,
  withStyles(s, bt, st),
  graphql(AddCouponQuery, {
    name: 'Getcoupon',
    options: (props) => ({
      variables: {
        currentPage: props.currentPage,
        searchList: props.searchList
      },
      fetchPolicy: 'network-only',
    })
  })
)(AffiliateTable);

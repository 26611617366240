import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Redux
import { connect } from 'react-redux';
import { openHeaderModal } from '../../../actions/modalActions';
import { formatLocale } from '../../../helpers/formatLocale';

// Style
import {
	Button,
	Collapse
} from 'react-bootstrap';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './AffiliateSideBar.css';
import * as FontAwesome from 'react-icons/lib/fa';

// Component
import Link from '../../Link/Link';
import history from '../../../core/history';
import HeaderModal from '../../HeaderModal/HeaderModal';

import { choseToTheme } from '../../../actions/getCurrencyRates';

import { validatePrivilege } from '../../../helpers/adminPrivileges';

// Translation
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../../../locale/messages';
// import { adminLogout } from '../../../actions/siteadmin/logout';

//Images
import logoutIcon from '../../../../public/adminIcons/ce2b5b26.svg';
import languageIcon from '../../../../public/languageIcon.svg';
import configIcon from '../../../../public/adminIcons/config.svg'
import linkIcon from '../../../../public/adminIcons/Affiliatelink.svg';
import dashBoardIcon from '../../../../public/adminIcons/dashboard.svg';
import searchIcon from '../../../../public/adminIcons/Affiliatesearch.svg';
import dropDownLightIcon from '../../../../public/SiteIcons/lightIcon.svg';
import dropDownDarkIcon from '../../../../public/SiteIcons/darkIconWhite.svg';
import selectedLightIcon from '../../../../public/SiteIcons/selectedLightIcon.svg';
import selectedDarkIcon from '../../../../public/SiteIcons/selectedDarkIcon.svg';
import homeMobileIcon from '../../../../public/SiteIcons/home.svg';
class SideBar extends Component {

	static defaultProps = {
		isSuperAdmin: false,
		privileges: []
	};

	constructor(props) {
		super(props);
		this.state = {
			// step1: true,
			step1: false,
			step3: false,
			home: false,
			whyHost: false,
			location: ''
		};
		this.openMenu = this.openMenu.bind(this);
		this.openClose = this.openClose.bind(this);
	}

	componentDidMount() {
		this.setState({
			location: history.location.pathname
		});
	}

	componentDidUpdate(prevProps, prevState) {
		const { location } = this.props;
		if (prevState.location !== location) {
			this.setState({
				location
			})
		}
	}

	async openMenu() {
		this.setState({
			isOpen: 1,
		})
	}
	async openClose() {
		this.setState({
			isOpen: 0,
		})
	}

	render() {
		const { isSuperAdmin, privileges, adminLogout, choseToTheme, selectedTheme } = this.props;
		const { step1, step3, home, location } = this.state;
		// console.log(location,"location");
		const { openHeaderModal, currentLocale, listingApproval } = this.props;
		let reviewManagementArray = ['/siteadmin/reviews', '/siteadmin/reviews/edit-review/', '/siteadmin/write-reviews'];
		let adminManagementArray = ['/siteadmin/admin-users', '/siteadmin/admin-roles'];
		let homePageArray = ['/siteadmin/home/caption', '/siteadmin/home/banner', '/siteadmin/home/footer-block', '/siteadmin/popularlocation', '/siteadmin/home/static-info-block', '/siteadmin/home/home-banner', '/siteadmin/popularlocation/add'];
		let whyBecomeHostPageArray = ['/siteadmin/whyHost/Block1', '/siteadmin/whyHost/review', '/siteadmin/whyHost/Block3', '/siteadmin/whyHost/Block4', '/siteadmin/whyHost/Block5', '/siteadmin/whyHost/Block6', '/siteadmin/whyHost/Block7', '/siteadmin/whyHost/review/add'];
		let listSettingStep1 = ['/siteadmin/listsettings/1', '/siteadmin/listsettings/2', '/siteadmin/listsettings/3', '/siteadmin/listsettings/4', '/siteadmin/listsettings/5', '/siteadmin/listsettings/6', '/siteadmin/listsettings/7', '/siteadmin/listsettings/8', '/siteadmin/listsettings/9', '/siteadmin/listsettings/10', '/siteadmin/listsettings/11', '/siteadmin/listsettings/12'];
		let listSettingStep3 = ['/siteadmin/listsettings/13', '/siteadmin/listsettings/14', '/siteadmin/listsettings/15', '/siteadmin/listsettings/16', '/siteadmin/listsettings/17', '/siteadmin/listsettings/18', '/siteadmin/listsettings/19'];

		return (
			<div>
				<div className={cx(s.mobileHeader, 'visible-xs', 'darkModeMobileHeader')}>
					<div onClick={() => this.openMenu()}>
						<div className={cx("hamburger")}>
							<span className="icon-bar"></span>
							<span className="icon-bar"></span>
							<span className="icon-bar"></span>
						</div>
					</div>
					{/* <div>
						<Link to={''} onClick={() => adminLogout()} className={cx(s.logoutIconMobile, 'visible-xs visible-sm')}>
							<div className={cx(s.logoutIcon, 'logoutIconRTL', 'logoutIconDarkMode')}><img src={logoutIcon} /></div>
						</Link>
					</div> */}

				</div>
				<div className={cx({ [s.menuOpen]: this.state.isOpen == 1 }, s.sidebarWrapper, 'adminScrollBar', 'bgBlackTwo')}>
					<div className={cx({ [s.menuClose]: this.state.isOpen == 0 })}>
						<div className={cx(s.closeColor, 'visible-xs', 'closeColorAdminRTL')} onClick={() => this.openClose()} >
							×
						</div>
					</div>
					<div className={cx("hidden-print", s.sidebarNavParent)}>
						<div className={cx(s.sideBarWelcome)}>
							<span><FormattedMessage {...messages.welcomeAffiliate} /></span>
						</div>

						<ul className={s.sidebarNav}>
						   <li className={'visible-xs'}>
								<Link to="/" className={cx(s.sideNavitem, s.disPlayTable)} >
									<span><img src={homeMobileIcon} className={cx(s.languageIcon, 'languageIconRTLAdmin')} /></span>
									<span><FormattedMessage {...messages.home} /></span>
								</Link>
							</li>
							<li className={cx('visible-xs')} onClick={() => { choseToTheme('light') }}>
								<Link to='#' className={cx(s.sideNavitem, s.disPlayTable, { [s.selectedThemeColor]: selectedTheme === "light" })} onClick={() => this.openClose()}>
									<span className={s.configIconSec}>
										{
											(selectedTheme && selectedTheme === "light") ?
												<img src={selectedLightIcon} alt='' className={cx(s.navigationIcon)} /> : <img src={dropDownLightIcon} alt='' className={cx(s.themeIcon, 'themeIconRTL', s.navigationIcon)} />
										}
										<FormattedMessage {...messages.lightMode} />
									</span>
								</Link>
							</li>

							<li className={cx('visible-xs')} onClick={() => { choseToTheme('dark') }}>
								<Link to='#' className={cx(s.sideNavitem, s.disPlayTable, { [s.selectedThemeColor]: selectedTheme === "dark" })} onClick={() => this.openClose()}>
									<span className={s.configIconSec}>
										{
											(selectedTheme && selectedTheme === "dark") ?
												<img src={selectedDarkIcon} alt='' className={cx(s.navigationIcon)} /> : <img src={dropDownDarkIcon} alt='' className={cx(s.themeIcon, 'themeIconRTL', s.navigationIcon)} />
										}
										<FormattedMessage {...messages.darkMode} />
									</span>
								</Link>
							</li>
							<li className={'visible-xs'}>
								<Link className={cx(s.sideNavitem, s.disPlayTable)} onClick={(e) => openHeaderModal('languageModal')}>
									<span><img src={languageIcon} className={cx(s.languageIcon, 'languageIconRTLAdmin')} /></span>
									<span>{formatLocale(currentLocale)}</span>
								</Link>
							</li>
							<li className={cx({ [s.active]: location == '/affiliateDashboard' })}>
								<Link to={'/affiliateDashboard'} className={cx(s.sideNavitem, s.disPlayTable)} onClick={() => this.openClose()}>
									<span className={s.disPlayTabelCell}>
										<img src={dashBoardIcon} className={s.navigationIcon} />
									</span>
									<span className={s.disPlayTabelCell}><FormattedMessage {...messages.dashboard} /></span>
								</Link>
							</li>
							<li className={cx({ [s.active]: location == '/AffiliateSearchListing' })}>
								<Link to={'/AffiliateSearchListing'} className={cx(s.sideNavitem, s.disPlayTable)} onClick={() => this.openClose()}>
									<span className={s.disPlayTabelCell}>
										<img src={searchIcon} className={s.navigationIcon} />
									</span>
									<span className={s.disPlayTabelCell}><FormattedMessage {...messages.searchlisting} /></span>
								</Link>
							</li>
							<li className={cx({ [s.active]: location == '/AffiliateLinkManager' })}>
								<Link to={'/AffiliateLinkManager'} className={cx(s.sideNavitem, s.disPlayTable)} onClick={() => this.openClose()}>
									<span className={s.disPlayTabelCell}>
										<img src={linkIcon} className={s.navigationIcon} />
									</span>
									<span className={s.disPlayTabelCell}><FormattedMessage {...messages.linkmanager} /></span>
								</Link>
							</li>
							
						</ul>
					</div>
				</div>
				{/* <HeaderModal modalType={'languageModal'} hideCurrency={true} /> */}
				<HeaderModal modalType={'languageModal'} />
        		<HeaderModal modalType={'currencyModal'} />
			</div>
		)
	}
}

const mapState = (state) => ({
	isSuperAdmin: state.runtime.isSuperAdmin,
	privileges: state.adminPrevileges.privileges && state.adminPrevileges.privileges.privileges,
	currentLocale: state.intl.locale,
	listingApproval: state.siteSettings.data.listingApproval,
	selectedTheme: state.currency.theme
});

const mapDispatch = {
	// adminLogout,
	openHeaderModal,
	choseToTheme
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(SideBar)));
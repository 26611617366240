import { gql } from 'react-apollo';
import { reset, change } from 'redux-form';
import { hideLoading } from 'react-redux-loading-bar';

import {
  FETCH_SUGGESTED_RESULTS_START,
  FETCH_SUGGESTED_RESULTS_SUCCESS,
  FETCH_SUGGESTED_RESULTS_ERROR
} from '../constants';

import { getSuggestedResults } from './getSuggestedResults';


const query = gql`
query getSuggested($currentPage: Int,$priceRange: [Int],$review:String){
    getSuggested(currentPage: $currentPage, priceRange: $priceRange,review:$review){
        count
           results {
      id
      title
      personCapacity
      lat
      lng
      beds
      bookingType
      coverPhoto
      reviewsCount
      reviewsStarRating
      listPhotos {
        id
        name
        type
        status
      }
      listingData {
        basePrice
        currency
      }
      settingsData {
        listsettings {
          id
          itemName
        }
      }
      wishListStatus
      isListOwner
    }
        errorMessage
        status
    }
}
`;

export function suggestListing({currentPage,priceRange,review }) {
  
  return async (dispatch, getState, { client }) => {

    dispatch({ type: FETCH_SUGGESTED_RESULTS_START });
    dispatch(reset('SearchForm'));

    try {
      const { data } = await client.query({
        query,
        variables: {
            currentPage,
            priceRange,
            review
        },
        fetchPolicy: 'network-only'
      });
      // console.log("data.getSuggested",data.getSuggested)
      if (data.getSuggested) {

        // console.log("hellohellohellohellohellohello",data.getSuggested)
        dispatch({ type: FETCH_SUGGESTED_RESULTS_SUCCESS,
          payload: {
          data: data.getSuggested,
          isResultLoading: false
        } });
        await dispatch(change('SearchForm', 'currentPage', currentPage));
        await dispatch(change('SearchForm', 'priceRange', priceRange));
        await dispatch(change('SearchForm', 'review', review));
        // Default Map Show
        await dispatch(change('SearchForm', 'showMap', true));
        dispatch(hideLoading());
        // console.log("data.getSuggested",data.getSuggested)
      }
    } catch (error) {
      dispatch({
        type: FETCH_SUGGESTED_RESULTS_ERROR,
        payload: {
          error
        }
      });
      dispatch(hideLoading());
      return false;
    }

    return true;
  };
}

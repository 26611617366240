import React from 'react';
import AdminLayout from '../../../components/Layout/SubadminLayout';
import EditStaticPage from './EditStaticPage';
import { restrictUrls } from '../../../helpers/adminPrivileges';

const title = 'Edit Page Details';

export default async function action({ store, params }) {

    // From Redux Store
    let issubAdminAuthenticated = store.getState().runtime.issubAdminAuthenticated;
    let adminPrivileges = store.getState().adminPrevileges.privileges && store.getState().adminPrevileges.privileges.privileges;

    if (!issubAdminAuthenticated) {
        return { redirect: '/subadmin/login' };
    }

    // Admin restriction
    if (!restrictUrls('/subadmin/edit/staticpage/', adminPrivileges)) {
        return { redirect: '/subadmin' };
    }

    let pageName="";
    const pageId = Number(params.pageId);
    if(pageId == 1){
        pageName = "About Us"
    }else if(pageId == 2){
        pageName = "Trust & Safety" 
    }else if(pageId == 3){
        pageName = "Travel Credit"
    }
    else if(pageId == 4){
        pageName = "Terms & Privacy"
    }
    else if(pageId == 5){
        pageName = "Help"
    }
    else if(pageId == 6){
        pageName = "Cookie Policy"
    }

    return {
        title,
        component: <AdminLayout><EditStaticPage title={title} pageId={pageId} pageName={pageName}/></AdminLayout>,
    };
}

import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
// Redux
import { connect } from "react-redux";
import starIcon from "../../../public/SiteIcons/starIcon.svg"
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./HeaderTest.css";
import cx from "classnames";
import { Row,Button } from "react-bootstrap";
// Redux Form
import { formValueSelector } from "redux-form";
import {
  showMap,
  showResults,
  showForm,
  showFilter,
} from "../../actions/mobileSearchNavigation";
import { getListingFields } from "../../actions/getListingFields";
import { suggestListing } from "../../actions/suggestListing";

import HeaderTest from "../../components/HeaderTest";
import Price from "../../components/HeaderTest/Price";
class HeaderText extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
  };
  static propTypes = {
    initialFilter: PropTypes.object,
    searchSettings: PropTypes.object,
    filterToggle: PropTypes.bool,
    showMap: PropTypes.func.isRequired,
    showResults: PropTypes.func.isRequired,
    showForm: PropTypes.func.isRequired,
    formatMessage: PropTypes.func,
    mobileSearch: PropTypes.shape({
      searchMap: PropTypes.bool,
      searchResults: PropTypes.bool,
      searchForm: PropTypes.bool,
    }),
    getListingFields: PropTypes.func,
  };

  static defaultProps = {
    mobileSearch: {
      searchMap: false,
      searchResults: true,
      searchForm: false,
      searchFilter: false,
    },
    isMapShow: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      tabs: {
        price: false,
      },
      currentPage:"",
      priceRange:"",
      review:"",
      overlay: false,
      smallDevice: false,
      verySmallDevice: false,
      tabletDevice: false,
    };

    this.handleTabToggle = this.handleTabToggle.bind(this);
    this.setStateVariable = this.setStateVariable.bind(this);
    this.handleReset = this.handleReset.bind(this);

  }
      setStateVariable(variables) {
        this.setState(variables)
      }
  handleTabToggle(currentTab, isExpand) {
    const { showForm, showResults, showFilter } = this.props;
    const { tabs, smallDevice } = this.state;

    for (let key in tabs) {
      if (key == currentTab) {
        tabs[key] = isExpand;
      } else {
        tabs[key] = false;
      }
    }

    this.setState({
      tabs,
      overlay: isExpand,
    });

    if (smallDevice) {
      if (isExpand) {
        showFilter();
      } else {
        showResults();
      }
    }
  }
async  handleReset(){
    const {suggestListing} = this.props;
    let variables =  {
      currentPage:1,
      priceRange:[],
      review:""
    }
  
    // refetch(variables)
   suggestListing(variables)
   }
  render() {
    const { tabs } = this.state;
    const {
      mobileSearch: { searchMap, searchResults, searchForm, searchFilter },
      searchSettings,
      isMapShow,
      suggestedData,
      dispatch
    } = this.props;
    return (
      <div className={cx(s.root, "searchPage")}>
        <div className={s.container}>
          <div className={s.searchHeaderContainerBox}>
            <div className={s.searchHeaderContainer}>
              <div className={s.filterButtonFlex}>
                <Price
                  className={cx(
                    s.filterButtonContainer,
                    "hidden-xs",
                    s.hideTabletSection
                  )}
                  handleTabToggle={this.handleTabToggle}
                  searchSettings={searchSettings}
                  isExpand={tabs.price}
                />
                <span></span>
                 <Button className={s.ratingButton}  onClick={()=>this.handleReset()}>Reset Filter  </Button> 
                
              </div>
            </div>
          </div>
          <div
            className={cx(s.searchResultContainer, {
              [s.listItemOnly]: isMapShow == false,
            })}
          >
            <div className={cx(s.resultsBody)}>
              <HeaderTest
                searchSettings={searchSettings}
                setStateVariable={this.setStateVariable}
                dispatch={dispatch}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const selector = formValueSelector("SearchForm");

const mapState = (state) => ({
  filterToggle: state.toggle.filterToggle,
  mobileSearch: state.mobileSearch.data,
  isMapShow: state.personalized.showMap,
  extendMap: state.personalized.extendMap,
});

const mapDispatch = {
  showMap,
  showResults,
  showForm,
  getListingFields,
  showFilter,
  suggestListing
};

export default injectIntl(
  withStyles(s)(connect(mapState, mapDispatch)(HeaderText))
);

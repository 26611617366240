// General
import React from 'react';
import PropTypes from 'prop-types';

// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import s from './websitelist.css';
import bt from '../../components/commonStyle.css';
import {
  Col
} from 'react-bootstrap';

// Components
import Weblist from '../../components/weblist';
import SocialLogin from '../../components/SocialLogin/SocialLogin';
import Link from '../../components/Link/Link';

import SidePanel from './weblistSidePanel';


// Translation
import { FormattedMessage, injectIntl } from 'react-intl';

import {
  Stepper,
  StepLabel,
  Step
  
} from '@material-ui/core';

// Locale
import messages from '../../locale/messages';

class Websitelist extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    refer: PropTypes.string
  };

  render() {
    const { refer } = this.props;
    let initialValues = {};
    // let loginURL = '/login';
    // if (refer) {
    //   initialValues = {
    //     refer
    //   };
    //   loginURL = '/login?refer=' + refer;
    // }

    return (
      <div className={s.grid}>
        <SidePanel/>

<div className={s.root}>
         {/* <div className={s.container}> */}
         <div className={s.landingMainContent}>
           <Weblist initialValues={initialValues} />
         </div>
       </div>

       
          </div>
    );
  }
}

export default injectIntl(withStyles(s, bt)(Websitelist));

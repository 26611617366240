import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './affiliatedocumentVerification.css';

import AffiliateDocumentupload from '../../components/AffiliateDocumentupload/AffiliateDocumentupload';

import {
  Grid,
  Row,
  Col
} from 'react-bootstrap';

import cx from 'classnames';

import SidePanel from './affiliatedocumentsidepanel';

import EditProfileSideMenu from '../../components/EditProfileSideMenu/EditProfileSideMenu';
// Translation
import { injectIntl, FormattedMessage } from 'react-intl';
// Locale
import messages from '../../locale/messages';

class AffiliatedocumentVerification extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
  };

  render() {
    const { formatMessage } = this.props.intl;

    return (
  <div className={s.grid}>
        <SidePanel/>
        <div className={s.root}>
        <div className={s.landingMainContent}>
        <h3 className={s.infoTitle}>
                <FormattedMessage {...messages.documentverificaiton} />
          </h3>
        <AffiliateDocumentupload placeholder={formatMessage(messages.documentUploadPlaceholder)} />
        </div>
        </div>


  </div>
      // // <div className={s.container}>
      //   {/* <Grid fluid>
      //     <Row className={cx(s.landingContainer)}>
      //       <Col xs={12} sm={12} md={3} lg={3} className={s.smPadding}>
      //         <EditProfileSideMenu />
      //       </Col>
      //       <Col xs={12} sm={12} md={9} lg={9} className={s.smPadding}>
      //         <h3 className={s.infoTitle}>
      //           <FormattedMessage {...messages.documentverificaiton} />
      //         </h3>
      //         <AffiliateDocumentupload placeholder={formatMessage(messages.documentUploadPlaceholder)} />
      //       </Col>
      //     </Row>
      //   </Grid> */}
      // {/* </div> */}


    );
  }
}

export default injectIntl(withStyles(s)(AffiliatedocumentVerification));

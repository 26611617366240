import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { graphql, gql, compose } from "react-apollo";
import DocumentList from "../DocumentList/DocumentList";
import DropzoneComponent from "react-dropzone-component";

import { Field, reduxForm, change } from "redux-form";
import submit from "./submit";
import validate from "./validate";
import Buttons from "./buttons";
import Loader from "../Loader/Loader";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import createApolloClient from "../../core/createApolloClient/createApolloClient.client";
import CountryList from "../CountryList/CountryList";

// Locale
import messages from "../../locale/messages";
import PopulateData from "../../helpers/populateData";
import CustomCheckbox from "../CustomCheckbox";

// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";
import s from "./weblist.css";
import bt from "../../components/commonStyle.css";
import {
  Button,
  FormGroup,
  FormControl,
  Row,
  Col,
  ControlLabel,
  Checkbox,
} from "react-bootstrap";

import { Stepper, StepLabel, Step } from "@material-ui/core";

const query = gql`
query getAffiliateUserStep {
  getAffiliateUserStep {
    status
    errorMessage
    stepInfo
    stepDetails {
      payeeName
      address
      city
      state
      zipcode
      country
      phoneNumber
      websiteName
      websiteUrl
      websiteAbout
      typeList
      websiteDrive
      typesOfWebsite
      primryJoining
      websiteVisitors
      buildLinks
      websiteMonitize
    }
  }
}
`;

// const params = {
//   userId: "816e3340-0403-11ee-9a73-6b1d77ce8fba",
// };

class manageinformation extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     hideSuggestInput: true,
  //   };
  //   // this.renderCountryList = this.renderCountryList.bind(this);
  // }

  static propTypes = {
    formatMessage: PropTypes.func,
    initialvalues: PropTypes.any,
  };

  constructor(props) {
    super(props);
    // this.complete = this.complete.bind(this);
    // this.addedfile = this.addedfile.bind(this);
    // this.dropzone = null;
    this.state = {
      showPassword: "",
      dateOfBirthData: {},
      hideSuggestInput: true,
      typeList: [],
      websiteDrive: [],
      loading: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.fetchUserData = this.fetchUserData.bind(this);
  }

  async fetchUserData() {
    const client = createApolloClient();
    const { data } = await client.query({
      query,
      // variables: { params },
      fetchPolicy: "network-only",
    });
    let response = data.getAffiliateUserStep;
    let stepDetailsObj = response.stepDetails[0];
    let tempArr = JSON.parse(stepDetailsObj.websiteDrive);
    let tempArr2 = JSON.parse(stepDetailsObj.typeList);
    let array = [],
      array2 = [];
    let tempVal = Object.values(tempArr);
    let tempVal2 = Object.values(tempArr2);
    for (let i in tempVal) {
      let newarr = tempVal[i];
      // console.log(newarr, "newarr");
      if (newarr == true) {
        let j = ++i;
        array.push(j);
      }
    }
    // console.log("array", array);
    for (let i in tempVal2) {
      let newarr2 = tempVal2[i];
      // console.log(newarr2, "newarr");
      if (newarr2 == true) {
        let j = ++i;
        array2.push(j);
      }
    }
    // console.log("array2", array2);
    if (response.status == 200) {
      this.props.initialize({
        websiteName: stepDetailsObj.websiteName,
        websiteUrl: stepDetailsObj.websiteUrl,
        websiteAbout: stepDetailsObj.websiteAbout,
        typeList: array2,
        websiteDrive: array,
        typesOfWebsite: stepDetailsObj.typesOfWebsite,
        primryJoining: stepDetailsObj.primryJoining,
        websiteVisitors: stepDetailsObj.websiteVisitors,
        buildLinks: stepDetailsObj.buildLinks,
        websiteMonitize: stepDetailsObj.websiteMonitize,
      });
    }
  }

  componentDidMount() {
    this.fetchUserData();
    this.setState({ loading: false });
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  renderFormControl = ({
    input,
    label,
    type,
    meta: { touched, error },
    className,
    showPassword,
    placeHolder,
    max,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <FormGroup className={cx(bt.space3, bt.pwdSection)}>
        <FormControl
          {...input}
          type={showPassword === input.name ? input : type}
          className={className}
          max={max}
        />
        {type == "password" && (
          <span
            className={cx(
              bt.pwdImage,
              bt.loginPwdSection,
              "svgImg",
              "pwdImageRTL"
            )}
            onClick={() => this.handleChange(input.name)}
          >
            {showPassword === input.name ? (
              <img src={ShowPassword} />
            ) : (
              <img src={HidePassword} />
            )}
          </span>
        )}
        {touched && error && (
          <span className={bt.errorMessage}>{formatMessage(error)}</span>
        )}
      </FormGroup>
    );
  };

  renderFormControlSelect = ({
    input,
    label,
    meta: { touched, error },
    children,
    className,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <FormGroup className={cx(bt.space3, bt.pwdSection)}>
        <FormControl componentClass="select" {...input} className={className}>
          {children}
        </FormControl>
        {touched && error && (
          <span className={bt.errorMessage}>{formatMessage(error)}</span>
        )}
      </FormGroup>
    );
  };

  checkboxGroup = ({ label, name, options, input }) => (
    <div className={cx(s.listContainer, s.rulesTop)}>
      {options.map((option, index) => {
        // console.log("optipn=====>", { option, index });
        if (option.isEnable === "1") {
          return (
            <div className={cx(s.listContent, s.fullWidth,'homeTypeSpaceRight')} key={index}>
              <span className={s.checkBoxSection}>
                <CustomCheckbox
                  name={`${input.name}[${index}]`}
                  value={option.itemName}
                  checked={input.value.indexOf(option.id) !== -1}
                  className={"icheckbox_square-green"}
                  onChange={(event) => {
                    const newValue = [...input.value];
                    if (event === true) {
                      newValue.push(option.id);
                    } else {
                      newValue.splice(newValue.indexOf(option.id), 1);
                    }
                    return input.onChange(newValue);
                  }}
                />
              </span>
              <span className={cx(s.checkBoxSection, s.checkBoxLabel)}>
                <label className={cx(s.checkboxLabel, "textWhite")}>
                  {option.itemName}
                </label>
              </span>
            </div>
          );
        }
      })}
    </div>
  );

  render() {
    const { error, handleSubmit, submitting } = this.props;
    const { formatMessage } = this.props.intl;

    // const typeList = [
    //   { id: 1, itemName: "Amenities", isEnable: "1" },
    //   { id: 2, itemName: "Country", isEnable: "1" },
    //   { id: 3, itemName: "Lorem", isEnable: "1" },
    // ];
    const typeList = [
      { id: 1, itemName: "Travel Products", isEnable: "1" },
      { id: 2, itemName: "Travel Events", isEnable: "1" },
      { id: 3, itemName: "Other", isEnable: "1" },
    ];
    const websiteDrive = [
      { id: 1, itemName: "ECommerce website", isEnable: "1" },
      { id: 2, itemName: "Business website", isEnable: "1" },
      { id: 3, itemName: "Blog website", isEnable: "1" },
      { id: 4, itemName: "Portfolio website", isEnable: "1" },
      { id: 5, itemName: "Event website", isEnable: "1" },
      { id: 6, itemName: "Membership website", isEnable: "1" },
      { id: 7, itemName: "Personal website", isEnable: "1" },
      { id: 8, itemName: "Nonprofit website", isEnable: "1" },
      { id: 9, itemName: "Informational website", isEnable: "1" },
      { id: 10, itemName: "Online forum", isEnable: "1" },
    ];

    return (
      <div>
        {this.state.loading ? (
          <Loader type={"text"} />
        ) : (
          <div>
            <header>
              <div className="center-stepper">
                <Stepper
                  alternativeLabel
                  style={{ width: "100%" }}
                  activeStep="2"
                >
                  <Step>
                    <StepLabel>Account Information</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Website List</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Upload Your Document</StepLabel>
                  </Step>
                </Stepper>
              </div>
            </header>

            <form
              onSubmit={handleSubmit(submit)}
              className={cx("SelectFocus", "inputDateHide")}
            >
              {error && (
                <span className={bt.errorMessage}>{formatMessage(error)}</span>
              )}
              <ControlLabel>
            <FormattedMessage {...messages.websitename} />
          </ControlLabel>
          <Field
            name="websiteName"
            type="text"
            component={this.renderFormControl}
            label={formatMessage(messages.websitename)}
            className={bt.affiliateControlInput}
          />
          <ControlLabel>
            <FormattedMessage {...messages.AffiliateURL} />
          </ControlLabel>
          <Field
            name="websiteUrl"
            type="text"
            component={this.renderFormControl}
            label={formatMessage(messages.AffiliateURL)}
            className={bt.affiliateControlInput}
            // showPassword={this.state.showPassword}
          />
          <ControlLabel>
            <FormattedMessage {...messages.websiteabout} />
          </ControlLabel>
          <Field
            name="websiteAbout"
            type="text"
            component={this.renderFormControl}
            label={formatMessage(messages.websiteabout)}
            className={bt.affiliateControlInput}
          />

          <ControlLabel>
            <FormattedMessage {...messages.itemslist} />
          </ControlLabel>
          <FormGroup className={s.formGroup}>
            <Field
              name="typeList"
              component={this.checkboxGroup}
              options={typeList}
            />
          </FormGroup>

          <ControlLabel>
            <FormattedMessage {...messages.drivetraffic} />
          </ControlLabel>

          <FormGroup className={s.formGroup}>
            <Field
              name="websiteDrive"
              component={this.checkboxGroup}
              options={websiteDrive}
            />
          </FormGroup>

          <FormGroup className={s.space5}>
            <ControlLabel>
              <FormattedMessage {...messages.typeofsite} />
            </ControlLabel>
            <Field
              name="typesOfWebsite"
              component={this.renderFormControlSelect}
              className={cx(
                s.formControlSelect,
                s.listCommonJumboSelect,
                "listCommonJumboSelectRTL"
              )}
            >
              <option value={""}>
                {formatMessage(messages.selectLabel)}
              </option>
              <option value={"Ecommerce website"}>
                {formatMessage(messages.Ecommerce)}
              </option>
              <option value={"Business website"}>
                {formatMessage(messages.Business)}
              </option>
              <option value={"Blog website"}>
                {formatMessage(messages.WebBlog)}
              </option>
              <option value={"Protfolio website"}>
                {formatMessage(messages.webProtfolio)}
              </option>
              <option value={"Event website"}>
                {formatMessage(messages.webEvent)}
              </option>
              <option value={"Personal website"}>
                {formatMessage(messages.webPersonal)}
              </option>
              <option value={"Membership website"}>
                {formatMessage(messages.webMembership)}
              </option>
              <option value={"Nonprofit website"}>
                {formatMessage(messages.webNonprofit)}
              </option>
              <option value={"Informational website"}>
                {formatMessage(messages.webInformational)}
              </option>
              <option value={"Online forum"}>
                {formatMessage(messages.Onlineforum)}
              </option>
            </Field>
          </FormGroup>
          <ControlLabel>
            <FormattedMessage {...messages.primary} />
          </ControlLabel>
          <Field
            name="primryJoining"
            type="text"
            component={this.renderFormControl}
            label={formatMessage(messages.primary)}
            className={bt.affiliateControlInput}
          />
          <ControlLabel>
            <FormattedMessage {...messages.uniquevisit} />
          </ControlLabel>
          <Field
            name="websiteVisitors"
            type="text"
            component={this.renderFormControl}
            label={formatMessage(messages.uniquevisit)}
            className={bt.affiliateControlInput}
          />
          <ControlLabel>
            <FormattedMessage {...messages.buildlinks} />
          </ControlLabel>
          <Field
            name="buildLinks"
            type="text"
            component={this.renderFormControl}
            label={formatMessage(messages.buildlinks)}
            className={bt.affiliateControlInput}
          />
          <ControlLabel>
            <FormattedMessage {...messages.monitize} />
          </ControlLabel>
          <Field
            name="websiteMonitize"
            type="text"
            component={this.renderFormControl}
            label={formatMessage(messages.monitize)}
            className={bt.affiliateControlInput}
          />

              <FormGroup>
                <Buttons submitting={submitting} />
              </FormGroup>
            </form>
          </div>
        )}
      </div>
    );
  }
}

manageinformation = reduxForm({
  form: "manageinformation", // a unique name for this form
  validate,
})(manageinformation);

export default injectIntl(withStyles(s, bt)(manageinformation));

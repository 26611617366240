// import React from "react"

// import Test2 from "./Test2"
// export default async function action() {
//     return {
//       component: 
//           <Test2  />
//       ,
//     };
//   }


  import React from 'react';
  import Test2 from "./Test2"
import UserLayout from '../../components/Layout/UserLayout';
import fetch from '../../core/fetch';


const title = 'SEO form';

export default async function action({ store,params }) {
    
    const listId = Number(params.listId);
   const getSeoData = `query UserListing($listId:String!) {
  UserListing (listId:$listId) {
   title
  }
}`
const resp = await fetch('/graphql', {
  method: 'post',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    query: getSeoData,
    variables: { listId:listId }
  }),
});
const { data } = await resp.json();
  // From Redux Store
  // console.log("title seo",data);
  // From Redux Store
  let isAuthenticated = store.getState().runtime.isAuthenticated;

  if (!isAuthenticated) {
    return { redirect: '/login' };
  }

  return {
    title,
    component: <UserLayout><Test2 title={title} listId={listId} PropertyTitle={data && data.UserListing.title || "Property title"}/></UserLayout>,
  };
};
//data && data.UserListing.title == null || undefined?"Property Title":data.UserListing.title
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DropzoneComponent from "react-dropzone-component";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { injectIntl } from "react-intl";
import { toastr } from "react-redux-toastr";
import { FormattedMessage } from "react-intl";
import messages from "../../locale/messages";
import Loader from "../Loader/Loader";
import fetch from "../../core/fetch";
import history from '../../core/history';
// Style
import s from "!isomorphic-style-loader!css-loader!./filepicker.css";
import d from "./AffiliateDocumentupload.css";
import bt from "../../components/commonStyle.css";

// Component
import AffiliateDocumentList from "../AffiliateDocumentList/AffiliateDocumentList";
import { Stepper, StepLabel, Step } from "@material-ui/core";

import {
  Button,
  FormGroup,
  FormControl,
  Row,
  Col,
  ControlLabel,
  Checkbox,
} from "react-bootstrap";

//compose
import { graphql, gql, compose } from "react-apollo";

//Image
import documentIcon from "../../../public/SiteIcons/documentUpload.svg";
import { getSpecificConfig } from "../../helpers/getConfigValue";

const query = gql`
  query AffiliateShowDocumentList {
    AffiliateShowDocumentList {
      id
      userId
      fileName
      fileType
    }
  }
`;

class AffiliateDocumentupload extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.complete = this.complete.bind(this);
    // this.addedfile = this.addedfile.bind(this);
    this.dropzone = null;
    this.state = {
      loading: true,
      isChecked: false,
      error: false,
    };
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getDetails = this.getDetails.bind(this);
  }

  getDetails() {
    const isBrowser = typeof window !== "undefined";
    const isDocument = typeof document !== undefined;
    if (isBrowser && isDocument) {
      document.querySelector(".dz-hidden-input").style.visibility = "visible";
      document.querySelector(".dz-hidden-input").style.opacity = "0";
      document.querySelector(".dz-hidden-input").style.height = "100%";
      document.querySelector(".dz-hidden-input").style.width = "100%";
      document.querySelector(".dz-hidden-input").style.cursor = "pointer";
    }
  }

  componentDidMount() {
    this.setState({ loading: false });
    setTimeout(() => {
      this.getDetails();
    }, 1000);
  }

  async complete(file) {
    const { mutate } = this.props;
    let variables = {};
    if (file && file.xhr) {
      const { files } = JSON.parse(file.xhr.response);
      let fileName = files[0].filename;
      let fileType = files[0].mimetype;
      variables = {
        fileName,
        fileType,
      };
      const { data } = await mutate({
        variables,
        refetchQueries: [{ query }],
      });
      // console.log(file.size,"heeeeeeeeeeeeeelllllllllllllllloooooooooooo");

      if (data && data.affiliateuploadDocument) {
        if (data.affiliateuploadDocument.status === 200) {
          toastr.success(
            "Success!",
            "Your document has been uploaded successfully!"
          );
        } else {
          toastr.error("Error!", "Something went wrong!");
        }
      }
    }
    this.dropzone.removeFile(file);
  }

  async addedfile(file) {
    const siteData = await getSpecificConfig({ name: ["maxUploadSize"] });
    if (file.size > 1024 * 1024 * parseInt(siteData.maxUploadSize)) {
      toastr.error(
        "Maximum upload size Exceeded! ",
        "Try again with a smaller sized file"
      );
      this.dropzone.removeFile(file);
      return;
    }
  }

  handleCheckboxChange = (e) => {
    this.setState({
      isChecked: e.target.checked,
    });
  };
  async handleSubmit() {
    // console.log("isChecked ", this.state.isChecked);
    if (this.state.isChecked == true) {
      // console.log("Inside if block");
      this.setState({ error: false });
      const query = `query getAffiliateUserStepSuccess($userId:String) {
        getAffiliateUserStepSuccess(userId: $userId){
          status
          errorMessage
        }
      }`;
      const params = {
        userId: "788df6e0-0a82-11ee-b0da-3359da8a8064",
      };
      const resp = await fetch("/graphql", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: query,
          variables: params,
        }),
        credentials: "include",
      });
      // console.log("RESPONSE FROM API ", resp.status);
      if (resp.status == 200) {
        toastr.success("Success!", "You became An Affiliate");
        window.location.href = "/affiliateDashboard";
      } else {
        toastr.error("Something went wrong");
      }
    } else {
      this.setState({ error: true });
    }
  }
  handleSubmitt(){
    history.push('/affiliate/manageinfo/step2')
  }
  render() {
    const { placeholder, listId } = this.props;
    const errorMessage = messages.required.defaultMessage;
    const djsConfig = {
      dictDefaultMessage: "",
      addRemoveLinks: false,
      maxFilesize: 10,
      maxFiles: 10,
      acceptedFiles: "image/*,application/pdf",
      hiddenInputContainer: ".dzInputContainer",
    };
    const componentConfig = {
      iconFiletypes: [".jpg", ".png", ".pdf"],
      postUrl: "/AffiliateDocument",
    };
    const eventHandlers = {
      init: (dz) => (this.dropzone = dz),
      complete: this.complete,
      addedfile: this.addedfile,
    };

    return (
      <div>
        {this.state.loading ? (
          <div>
            <Loader type={"text"} />
          </div>
        ) : (
          <div className={cx("listPhotoContainer", "documentUpload")}>
            <header>
              <div className="center-stepper">
                <Stepper
                  alternativeLabel
                  style={{ width: "100%" }}
                  activeStep="3"
                >
                  <Step>
                    <StepLabel>Account Information</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Website List</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Upload Your Document</StepLabel>
                  </Step>
                </Stepper>
              </div>
            </header>
            <div className={cx("dzInputContainer")}>
              <DropzoneComponent
                config={componentConfig}
                eventHandlers={eventHandlers}
                djsConfig={djsConfig}
              >
                <img
                  src={documentIcon}
                  className={cx("photoUploadImg", "documentUploadIcon")}
                  alt="PictureImage"
                />
                <span className={"documentPlaceholder"}>{placeholder}</span>
              </DropzoneComponent>
            </div>
            <AffiliateDocumentList />

            {/* <Checkbox
              name="tc"
              increaseArea="20%"
              checked={this.state.isChecked}
              onChange={this.handleCheckboxChange}
            >
            I agree with the terms and conditions
            </Checkbox> */}
              {/* <FormattedMessage {...messages.tc} /> */}
              <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:"5px",alignItems:"flex-start"}}>
            <input type="checkbox"  onChange={this.handleCheckboxChange} checked={this.state.isChecked}/> <p>Agree to terms and conditions</p>

            </div>
            <div>
              {this.state.error && (
                <span className={d.errorMessage}>{errorMessage}</span>
              )}
            </div>
            <div className={d.buttonDiv}>
                <Button
                  style={{width:"40%"}}
                  className={cx(bt.btnPrimary, bt.btnLarge)}
                  onClick={this.handleSubmitt}
                >
                  <FormattedMessage {...messages.goBack} />
                </Button>
                <Button
                  style={{width:"40%"}}
                  className={cx(bt.btnPrimary, bt.btnLarge)}
                  onClick={this.handleSubmit}
                >
                  <FormattedMessage {...messages.continue} />
                </Button>
            </div>
           
          </div>
        )}
      </div>
    );
  }
}

const mapState = (state) => ({});

const mapDispatch = {};

export default compose(
  injectIntl,
  withStyles(s, d),
  graphql(
    gql`
      mutation affiliateuploadDocument($fileName: String, $fileType: String) {
        affiliateuploadDocument(fileName: $fileName, fileType: $fileType) {
          fileName
          fileType
          status
        }
      }
    `
  ),
  connect(mapState, mapDispatch)
)(AffiliateDocumentupload);

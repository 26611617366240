import React from "react";
import ManageCouponG from "../../components/ManageCoupon/ManageCoupon";

class ManageCoupon extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: "host"
        }
    }
    render() {
        
        const {listId} = this.props
        return (<> <ManageCouponG listId={listId} /> </>)
    }
}

export default ManageCoupon;
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment'
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, reset, change } from 'redux-form';
import {
  Row,
  Col,
  FormControl,
  OverlayTrigger,
  Tooltip,
  Button
} from 'react-bootstrap';
import {
  injectStripe,
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from 'react-stripe-elements';
import { toastr } from 'react-redux-toastr';
import { convert } from '../../../helpers/currencyConvertion';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Payment.css';
import bt from '../../../components/commonStyle.css';

// Component
import HouseRules from './HouseRules';
import Loader from '../../Loader';
import Link from '../../Link';
import ModalForm from './ModalForm/ModalForm';

// Locale
import { makePayment } from '../../../actions/booking/makePayment';
import { processCardAction } from '../../../actions/PaymentIntent/processCardAction';
import { openPaymentModal } from '../../../actions/modalActions';

// Helpers
import validate from './validate';
import fetch from '../../../core/fetch';
import { isRTL } from '../../../helpers/formatLocale'
import messages from '../../../locale/messages';

import { applyPaymentModal } from '../../../actions/modalActions';
import { applycoupon } from "../../../actions/Subscription/couponmanageAction";

//Images 
import imageOne from '../../../../public/SiteIcons/payment-icons.png';
import imageTwo from '../../../../public/SiteIcons/stripe-connect.png';
import arrow from '../../../../public/SiteIcons/chevron-right.svg';
import defaultPic from '../../../../public/SiteImages/defaultPic.png';
import cut from '../../../../public/SiteIcons/cut.svg';

const GET_COUPONCODE = `query getcouponcode($couponType:String,$listId:Int){
  getcouponcode(couponType:$couponType,listId:$listId){
      errorMessage
      status
      data{
      couponCode,
      activeStatus,
          couponType,
          discount,
          startDate,
          endDate,
          description,
          userType
      }
  }
}`

const COUPON_USE = `query getcouponuse($couponCode:String,$listId:Int){
  getcouponuse(couponCode:$couponCode,listId:$listId){
      errorMessage
      status
  }
}`

const tooltip = (
  <Tooltip id="tooltip">
    <strong>Hello!</strong> click to copy.
  </Tooltip>
);

const createOptions = (theme) => {
  return {
    style: {
      base: {
        color: theme == 'dark' ? '#fff' :'#484848',
        fontWeight: 400,
        fontFamily: 'inherit',
        fontSize: '14px',
        fontSmoothing: 'antialiased',
        ':focus': {
          color: theme == 'dark' ? '#fff' :'#484848',
        },

        '::placeholder': {
          color: '#aaa',
        },

        ':focus::placeholder': {
          color: '#aaa',
        },
      },
      invalid: {
        color: theme == 'dark' ? '#fff' :'#484848',
        ':focus': {
          color: theme == 'dark' ? '#fff' :'#484848',
        },
        '::placeholder': {
          color: '#aaa',
        },
      },
    }
  }
};
class PaymentForm extends Component {
  static propTypes = {
    houseRules: PropTypes.arrayOf(PropTypes.shape({
      listsettings: PropTypes.shape({
        itemName: PropTypes.string.isRequired
      })
    })),
    hostDisplayName: PropTypes.string.isRequired,
    allowedPersonCapacity: PropTypes.number.isRequired,
    initialValues: PropTypes.shape({
      listId: PropTypes.number.isRequired,
      listTitle: PropTypes.string.isRequired,
      hostId: PropTypes.string.isRequired,
      guestId: PropTypes.string.isRequired,
      checkIn: PropTypes.object.isRequired,
      checkOut: PropTypes.object.isRequired,
      guests: PropTypes.number.isRequired,
      basePrice: PropTypes.number.isRequired,
      cleaningPrice: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
      weeklyDiscount: PropTypes.number,
      monthlyDiscount: PropTypes.number,
      paymentType: PropTypes.number
    }).isRequired,
    paymentCurrencyList: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      symbol: PropTypes.string.isRequired,
      isEnable: PropTypes.bool.isRequired,
      isPayment: PropTypes.bool.isRequired
    })),
    paymentLoading: PropTypes.bool,
    formatMessage: PropTypes.any,
  };

  static defaultProps = {
    paymentCurrencyList: [],
    paymentLoading: false
  };

  constructor(props) {
    super(props);
    this.state = {
      paymentStatus: 2,
      load: true,
      personCapacityData: [],
      couponName:"",
      couponDiscount:"",
      couponExpiresAt:"",
      couponDescription:"",
      sendDiscount:false,
      showCoupon:false,
      couponDisable:false
    }
    this.renderpaymentCurrencies = this.renderpaymentCurrencies.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePayment = this.handlePayment.bind(this);
    this.handleSubmitButton = this.handleSubmitButton.bind(this);

  }

  UNSAFE_componentWillMount() {
    const { listingFields, stripePayment } = this.props;
    if (listingFields != undefined) {
      this.setState({
        personCapacityData: listingFields.personCapacity
      });
    }
    this.setState({
      paymentStatus: !stripePayment ? 1 : 2
    });
  }


  UNSAFE_componentWillReceiveProps(nextProps) {
    const { listingFields } = nextProps;
    if (listingFields != undefined) {
      this.setState({
        personCapacityData: listingFields.personCapacity
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { locale } = this.props.intl;
    const { locale: prevLocale } = prevProps.intl;

    if (locale !== prevLocale) {
      this.setState({ load: false });
      clearTimeout(this.loadSync);
      this.loadSync = null;
      this.loadSync = setTimeout(() => this.setState({ load: true }), 1);
    }
  }

  async  componentDidMount(){
    const { initialValues,listId } = this.props;
    // console.log("initialValuesinitialValuesinitialValues",initialValues)
    let hostID = initialValues.hostId
    const resp = await fetch('/graphql', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query: GET_COUPONCODE,
        variables: {
          couponType:"booking",
          listId:listId
        }
      }),
    });
    const { data } = await resp.json();
    if(data){
      this.setState({
        couponName:data.getcouponcode.data[0].couponCode,
        couponDiscount:data.getcouponcode.data[0].discount,
        couponExpiresAt:data.getcouponcode.data[0].endDate,
        couponDescription:data.getcouponcode.data[0].description})
        this.handleCouponUse()
    }
    // console.log("initialValues",couponExpiresAt,couponName,couponDiscount)
  }
  async handleCouponUse(){
    const { initialValues,listId } = this.props;
    const { couponName,couponDisable } = this.state;
    
    const response = await fetch('/graphql', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query: COUPON_USE,
        variables: {
          couponCode: couponName,
          listId: initialValues.listId
        }
      }),
    }); 
    const  {data}  = await response.json();
    // console.log("couponDisable fetch",data)
    if(data && data.getcouponuse.status != 200){
        this.setState({couponDisable:true})
        //  console.log("couponDisable fetch",couponDisable)
    }
   

    // console.log("couponDisable",couponDisable)
    // console.log("data in usecoupon",data)
}

  renderFormControlSelect = ({ input, label, meta: { touched, error }, children, className, disabled }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormControl disabled={disabled} componentClass="select" {...input} className={className} >
          {children}
        </FormControl>
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </div>
    )
  }

  renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
    const { formatMessage } = this.props.intl;
    if(touched && input.value == ""){
      // alert("zingle bell")
      toastr.error("Oops!", "Please fill message for host",2000);
     }
    return (
      <div>
        <FormControl
          {...input}
          className={className}
          componentClass="textarea"
          placeholder={label}
        >
          {children}
        </FormControl>
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </div>
    );
  }

  renderGuests(personCapacity) {
    const { formatMessage } = this.props.intl;
    const { personCapacityData } = this.state;

    let rows = [];
    for (let i = 1; i <= personCapacity; i++) {
      rows.push(<option key={i} value={i}>{i} {i > 1 ? personCapacityData[0].otherItemName : personCapacityData[0].itemName}</option>);
    }
    return rows;
  }

  renderpaymentCurrencies() {
    const { paymentCurrencyList } = this.props;
    let rows = [];

    if (paymentCurrencyList != null && paymentCurrencyList.length > 0) {
      paymentCurrencyList.map((item, index) => {
        if (item.isEnable && item.isPayment) {
          rows.push(<option key={index} value={item.symbol}>{item.symbol}</option>);
        }
      })
    }
    return rows;
  }

  renderFormControl = ({ input, label, type, placeholder, meta: { touched, error }, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormControl {...input} placeholder={placeholder} type={type} className={className} maxLength={11} />
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </div>
    )
  }

  handleClick() {
    const { dispatch } = this.props;
    dispatch(reset('BookingForm'));
  }

  async handleSubmit(values, dispatch) {
    const { stripe, processCardAction } = this.props;


    let paymentType = values.paymentType;
    let paymentCurrency = values.paymentType == 1 ? values.paymentCurrency : null;

    let query = `query checkReservation ($checkIn: String,$checkOut: String,$listId: Int ){
      checkReservation(checkIn: $checkIn, checkOut:$checkOut, listId:$listId ){
        id
        listId
        hostId
        guestId
        checkIn
        checkOut
        status
      }
    }`;

    values.checkIn = moment(values.checkIn).format('YYYY-MM-DD');
    values.checkOut = moment(values.checkOut).format('YYYY-MM-DD');

    const params = {
      listId: values.listId,
      checkIn: values.checkIn,
      checkOut: values.checkOut,
    };

    const resp = await fetch('/graphql', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query,
        variables: params,
      }),
      credentials: 'include',
    });

    const { data } = await resp.json();

    if (data && data.checkReservation && data.checkReservation.status == "200") {
      let msg = '', paymentMethodId, createPaymentMethod;

      if (paymentType == 2) {
        createPaymentMethod = await stripe.createPaymentMethod('card', {
          card: <CardElement />,
          billing_details: {
            address: {
              postal_code: values.zipcode
            }
          }
        })

        if (createPaymentMethod && createPaymentMethod.paymentMethod) {
          paymentMethodId = createPaymentMethod.paymentMethod.id
        }
      }

      if (createPaymentMethod && createPaymentMethod.error && createPaymentMethod.error.message && paymentType == 2) {
        msg = createPaymentMethod.error.message
        toastr.error("Oops!", msg);
      } else {

        if (Number(values.paymentType) == 2 && !values.zipcode) {
          toastr.error("Oops!", 'Your Zip code is incomplete.');
          return;
        }

        const { status, paymentIntentSecret, reservationId } = await dispatch(makePayment(
          values.listId,
          values.listTitle,
          values.hostId,
          values.guestId,
          values.checkIn,
          values.checkOut,
          values.guests,
          values.message,
          values.basePrice,
          values.cleaningPrice,
          values.currency,
          values.discount,
          values.discountType,
          values.totaldiscount,
          values.couponCode,
          values.guestServiceFee,
          values.hostServiceFee,
          values.total,
          values.bookingType,
          paymentCurrency,
          paymentType,
          values.guestEmail,
          values.bookingSpecialPricing,
          values.isSpecialPriceAssigned,
          values.isSpecialPriceAverage,
          values.dayDifference,
          paymentMethodId,
          null,
          values.checkInStart,
          values.checkInEnd,
          values.hostServiceFeeType,
          values.hostServiceFeeValue,
           values.reffralId,
        )
        );

        if (status == 400 && paymentType == 2) {
          const cardAction = await stripe.handleCardAction(
            paymentIntentSecret,
          );
          let amount = values.total + values.guestServiceFee;
          let confirmPaymentIntentId;

          if (cardAction && cardAction.paymentIntent && cardAction.paymentIntent.id) {
            confirmPaymentIntentId = cardAction.paymentIntent.id;

            const { handleCardActionStatus, errorMessage } = await processCardAction(
              reservationId,
              values.listId,
              values.hostId,
              values.guestId,
              values.listTitle,
              values.guestEmail,
              amount,
              values.currency,
              confirmPaymentIntentId
            );

          } else {
            if (cardAction && cardAction.error && cardAction.error.message) {
              msg = cardAction.error.message;
              toastr.error("Oops!", msg);
            }
          }
        }

      }

    } else {
      toastr.error("Oops!", "Those dates are not available.");

    }

  }

  handlePayment(e) {
    const { change } = this.props;
    let paymentType = e.target.value;

    if (paymentType == 2) {
      this.setState({ paymentStatus: 2 })
    } else {
      change('zipcode', null);
      this.setState({ paymentStatus: 1 })
    }

  }

  handleInput = async (e) => {
    e.preventDefault()
    const {couponName} = this.state;
    try {
      await navigator.clipboard.writeText(couponName);
      // toastr.success("Success!", "Coupon code copied successfully",{timeOut: 1000});
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }
  async handleSubmitButton() {
    // alert("Do you wish to continue?");

    const { applyPaymentModal, checkIn, checkOut, guests, specialPricing } = this.props;
    const { serviceFees, base, rates, currency, listData: { listingData: { weeklyDiscount, monthlyDiscount, basePrice, cleaningPrice } } } = this.props;
    const { formatMessage } = this.props.intl;
    const { couponDiscount, couponName, showCoupon } = this.state;
    const{applycoupon} = this.props;


    // this.setState({ showCoupon: !showCoupon })

    let guestServiceFee = 0, hostServiceFee = 0, priceForDays = 0, hostServiceFeeType = '', couponCode = '', hostServiceFeeValue = 0;
    let discount = 0, coupondiscount = 0,coupondiscountValue = 0, discountType, totalWithoutFees = 0, totaldiscount = 0;
    let momentStartDate, momentEndDate, dayDifference, isAverage = 0;
    let bookingSpecialPricing = [], isSpecialPriceAssigned = false;
    let isDayTotal = 0, totalWithoutServiceFee = 0, currentDay, total = 0;

    if (checkIn != null && checkOut != null) {
      momentStartDate = moment(checkIn);
      momentEndDate = moment(checkOut);
      dayDifference = momentEndDate.diff(momentStartDate, 'days');

      //New 
      if (dayDifference > 0) {

        let stayedNights = [];
        // Find stayed nights
        for (let i = 0; i < dayDifference; i++) {
          let currentDate = moment(checkIn).add(i, 'day');
          stayedNights.push(currentDate);
        }

        if (stayedNights && stayedNights.length > 0) {
          stayedNights.map((item, key) => {
            let isSpecialPricing;
            if (item) {
              let pricingRow, currentPrice;
              currentDay = (moment(item).format('dddd').toLowerCase());
              isSpecialPricing = specialPricing && specialPricing.length > 0 && specialPricing.find(o => moment(item).format('MM/DD/YYYY') == moment(o.blockedDates).format('MM/DD/YYYY'));

              if (isSpecialPricing && isSpecialPricing.isSpecialPrice) {
                isSpecialPriceAssigned = true;
                currentPrice = Number(isSpecialPricing.isSpecialPrice);
              } else {
                currentPrice = Number(basePrice);
              }
              // Price object
              pricingRow = {
                blockedDates: item,
                isSpecialPrice: currentPrice,
              };
              bookingSpecialPricing.push(pricingRow);
            }
          });
        }
      }

      if (isSpecialPriceAssigned) {
        bookingSpecialPricing.map((item, index) => {
          priceForDays = priceForDays + Number(item.isSpecialPrice);
        });
      } else {
        bookingSpecialPricing.map((item, index) => {
          priceForDays = priceForDays + Number(item.isSpecialPrice);
        });
      }
    }

    isAverage = Number(priceForDays) / Number(dayDifference);
    isDayTotal = isAverage.toFixed(2) * dayDifference;
    priceForDays = isDayTotal;

    if (dayDifference >= 7) {
      if (monthlyDiscount > 0 && dayDifference >= 28) {
        discount = (Number(priceForDays) * Number(monthlyDiscount)) / 100;
        discountType = monthlyDiscount + formatMessage(messages.monthlyPriceDiscount);
      } else {
        if (weeklyDiscount > 0) {
          discount = (Number(priceForDays) * Number(weeklyDiscount)) / 100;
          discountType = weeklyDiscount + formatMessage(messages.weeklyPriceDiscount);
        }
      }
    }

    coupondiscount = (Number(priceForDays) * Number(couponDiscount)) / 100;
    couponCode = couponName
    // discountType = couponDiscount + formatMessage(messages.couponDiscountpayment);

    totalWithoutServiceFee = (isDayTotal + cleaningPrice) - discount;

    if (serviceFees) {
      if (serviceFees.guest.type === 'percentage') {
        guestServiceFee = totalWithoutServiceFee * (Number(serviceFees.guest.value) / 100);
      } else {
        guestServiceFee = convert(base, rates, serviceFees.guest.value, serviceFees.guest.currency, currency);
      }
      if (serviceFees.host.type === 'percentage') {
        hostServiceFeeType = serviceFees.host.type;
        hostServiceFeeValue = serviceFees.host.value;
        hostServiceFee = totalWithoutServiceFee * (Number(serviceFees.host.value) / 100);
      } else {
        hostServiceFeeType = serviceFees.host.type;
        hostServiceFeeValue = serviceFees.host.value;
        hostServiceFee = convert(base, rates, serviceFees.host.value, serviceFees.host.currency, currency);
      }
    }
    let discountstatus;
     couponCode = couponName;
    if(showCoupon === true){
        totaldiscount = discount ;
    totalWithoutFees = (priceForDays + cleaningPrice) - discount ;
    total = (priceForDays + guestServiceFee + cleaningPrice) - discount;
      discountstatus = false;
      coupondiscount =  0;
      coupondiscountValue =0;
      applycoupon({ discountstatus,coupondiscount,coupondiscountValue });
    }else{
      discountstatus = true;
    //   coupondiscount =  couponDiscount;
      coupondiscount = (Number(priceForDays) * Number(couponDiscount)) / 100;
      coupondiscountValue = couponDiscount
      totaldiscount = discount + coupondiscount;
    totalWithoutFees = (priceForDays + cleaningPrice) - discount - coupondiscount;
    total = (priceForDays + guestServiceFee + cleaningPrice) - discount - coupondiscount;
      applycoupon({ coupondiscount, couponCode,discountstatus,coupondiscountValue });
    }
    this.setState({ showCoupon: !showCoupon })

    let curentFormValues = {
      discount,
      discountType,
      coupondiscount,
      couponCode,
      totaldiscount,
      guestServiceFee,
      hostServiceFee,
      total: totalWithoutFees,
      isSpecialPriceAssigned,
      bookingSpecialPricing: JSON.stringify(bookingSpecialPricing),
      isSpecialPriceAverage: isAverage.toFixed(2),
      dayDifference,
      hostServiceFeeType,
      hostServiceFeeValue,
      priceForDays,
      totalValue: total
    };

    await applyPaymentModal({ checkIn, checkOut, guests, curentFormValues });
  }
  render() {
    const { hostDisplayName, houseRules, hostPicture, hostProfileId, paymentLoading, intl: { locale } } = this.props;
    const { handleSubmit, submitting, error, pristine, paymentType, restrictEdit } = this.props;
    const { listId, checkIn, checkOut, guests, openPaymentModal, maximumStay, stripePayment, payPalPayment, theme,reffralId} = this.props;
    const { paymentStatus, load,couponName,couponExpiresAt,couponDiscount,couponDescription,sendDiscount,showCoupon,couponDisable } = this.state;
    const { formatMessage } = this.props.intl;
    let checkInDate = checkIn != null ? moment(moment(checkIn).format('YYYY-MM-DD')).format('MM/DD/YYYY') : '';
    let checkOutDate = checkOut != null ? moment(moment(checkOut).format('YYYY-MM-DD')).format('MM/DD/YYYY') : '';
    let paymentModalInitialValues = {
      checkIn,
      checkOut,
      guests,
    }
    
    // console.log("ADD_CLICK ===>>> payment ==>>"+couponDiscount)

    return (
      <div className={cx(s.bookItPanel, s.spaceTop2, s.aboutNoMargin, 'customRatioButton')}>
        <ModalForm listId={listId} initialValues={paymentModalInitialValues}  />
        <form onSubmit={handleSubmit(this.handleSubmit)}>
          <h1 className={s.titleText}><FormattedMessage {...messages.reviewAndPay} /></h1>
          <div className={s.flex}>
            <div>
              <div className={s.dateTitle}><FormattedMessage {...messages.dates} /></div>
              <div className={cx(s.showDate, 'textWhite')}>{checkInDate}{' - '}{checkOutDate}</div>
            </div>
            {!restrictEdit && <div>
              <div><a onClick={() => openPaymentModal()} className={s.editCss}><span><FormattedMessage {...messages.editLabel} /></span> <span className={cx(s.editIcon, 'editIconPayRTL')}><img src={arrow} /></span></a></div>
            </div>}
          </div>
          <div className={cx(s.flex, s.marginTop)}>
            <div>
              <div className={s.dateTitle}><FormattedMessage {...messages.guests} /></div>
              <div className={cx(s.showDate, 'textWhite')}>{guests}{' '}{guests > 1 ? <FormattedMessage {...messages.guests} /> : <FormattedMessage {...messages.guest} />}</div>
            </div>
            {!restrictEdit && <div>
              <div><a onClick={() => openPaymentModal()} className={s.editCss}><span><FormattedMessage {...messages.editLabel} /></span> <span className={cx(s.editIcon, 'editIconPayRTL')}><img src={arrow} /></span></a></div>
            </div>}
          </div>
          <div className={s.commonBorder}></div>
          {
            houseRules.length > 0 && <div className={s.space4}>
              <HouseRules
                hostDisplayName={hostDisplayName}
                houseRules={houseRules}
              />
              <div className={s.commonBorder}></div>
            </div>
          }

          <div className={cx(s.textLeft, 'textAlignRightRtl')}>
            <div className={cx(s.h3, s.bold)}>
              <FormattedMessage {...messages.aboutYourTrip} />
            </div>
            <div className={s.aboutPaymentDesc}>
              <FormattedMessage {...messages.aboutDescPayment} />
            </div>
            <div className={s.hostFlex}>
              <Link to={"/users/show/" + hostProfileId}>
                {<img src={hostPicture ? ('/images/avatar/medium_' + hostPicture) : defaultPic} className={s.avatarImage} />}
              </Link>
              <div className={cx(s.messageSection)}>
                <span><FormattedMessage {...messages.hostedBy} /></span> <span>{hostDisplayName}</span>
              </div>
            </div>
            <div>
              <Field
                className={s.textArea}
                name="message"
                component={this.renderFormControlTextArea}
                label={formatMessage(messages.descriptionInfo)}
              />
            </div>
            <div className={s.commonBorder}></div>
        {couponName &&    <div className={s.couponContainer}>

              <div className={s.divOne}>
                <div className={s.couponDiscountDiv} > {couponDiscount}% </div>
                <div className={s.coupon}>{couponName} </div>
                <p className={s.couponP}> Expires On: {moment(couponExpiresAt).format('YYYY-MM-DD')}  </p>
              </div>

              <div className={s.divTwo}>
                <h3 className={s.divTwoH3} >{couponName} <br /> Get Flat {couponDiscount}% OFF</h3>
                <div className={s.div2ptag}>{couponDescription}</div>
              </div>

              <div className={s.divThree}>
                <div className={s.couponDash}> <img style={{ height: "30px", paddingTop: "8px", marginLeft: "3px" }} src={cut} alt="scissor" />
                  <OverlayTrigger placement="bottom" overlay={tooltip}>
                    <button
                      style={{
                        background: "transparent",
                        border: "none",
                        cursor: "pointer",
                        padding: "5px"
                      }}
                      onClick={(e) => this.handleInput(e)}
                    >
                      {couponName}
                    </button>
                  </OverlayTrigger>
                </div>
                {/* <Button className={s.claimBtn} onClick={this.handleSubmitButton} >{showCoupon ? "REMOVE" :"APPLY COUPON"}</Button> */}
                {
                    couponDisable ? <div> <b style={{color:"orangered"}}>COUPON IS ALREADY USED</b>  </div> :    <Button className={s.claimBtn}  onClick={this.handleSubmitButton} >{showCoupon ? "REMOVE" :"APPLY COUPON"}</Button>
                }
              </div>
            </div>  }
       { couponName     &&      <div className={s.commonBorder}></div>}
          </div>
          <Col md={10} className={cx(s.textLeft, 'textAlignRightRtl', s.noPadding)}>
            <section>
              <header className={s.paymentHeader}>
                <Row>
                  <Col md={10} className={cx(s.textLeft, s.paymentPadding, 'textAlignRightRtl')}>
                    <h3 className={cx(s.pullLeft, s.h3, s.space2, 'pullRightBooking')}><FormattedMessage {...messages.payment} /></h3>
                  </Col>
                </Row>
              </header>
            </section>
            {stripePayment && <span>
              <label
                onClick={(e) => { this.handlePayment(e) }}
              >
                <Field
                  name="paymentType"
                  component="input"
                  type="radio"
                  value="2"
                  className={cx(s.cursorPointer)}
                />
                <span className={cx(s.radioTextSection, 'radioTextSectionRTL')}> <FormattedMessage {...messages.creditCard} /></span>
              </label>
            </span>}
            {
              paymentStatus == 2 ? (!load ? <Loader /> : <Row className={cx(s.responsivecardSection)}>
                <Col lg={10} md={11} sm={8} xs={12} className={cx(s.noPadding, s.cardSection, s.noPaddingLeft)}>
                  <div className={cx('placeHolderFont', 'darkInputColor')}>
                    <label className={cx(s.labelText, 'textWhite')}>
                      <FormattedMessage {...messages.paymentCardNumber} />
                    </label>
                    <CardNumberElement
                      {...createOptions(theme)}
                      placeholder={"4242 4242 4242 4242"}
                      className={cx(s.cardNumber, s.cardNumberSection, s.cardNumberSectionOne, 'cardNumberRtl', isRTL(locale) ? 'placeHolderNameRTL' : 'placeHolderNameLTR', 'textWhite')}
                    />

                    <Col lg={4} md={4} sm={4} xs={6} className={cx(s.noPaddingLeft, 'noPaddingRightRTL')}>
                      <label className={cx(s.labelText, 'textWhite')}>
                        <FormattedMessage {...messages.cardExpires} />
                      </label>
                      <CardExpiryElement
                        placeholder="MM / YY"
                        {...createOptions(theme)}
                        className={cx(s.cardNumber, s.cardNumberSectionTwo, s.cardNumberSection, 'cardNumberRtl', 'textWhite')}
                      />
                    </Col>
                    <Col lg={4} md={4} sm={4} xs={6} className={cx(s.noMobileRightPadding, 'noMobileLeftPaddingRTL')}>
                      <label className={cx(s.labelText, 'textWhite')}>
                        <FormattedMessage {...messages.cvv} />
                      </label>
                      <CardCvcElement
                        placeholder="_ _ _"
                        {...createOptions(theme)}
                        className={cx(s.cardNumber, s.cardNumberSectionThree, s.cardNumberSection, 'cardNumberRtlTwo', 'textWhite')}
                      />
                    </Col>
                    <Col lg={4} md={4} sm={4} xs={12} className={cx(s.noPaddingRight, s.noMobileLeftPadding, 'noLeftPaddingRTL', 'zipCodeMobilePaddingRTL')}>
                      <label className={cx(s.labelText, 'textWhite')}>
                        <FormattedMessage {...messages.zipcode} />
                      </label>
                      <Field
                        name="zipcode"
                        component={this.renderFormControl}
                        className={cx(s.cardInput, s.cardNumber, s.noBoxShadow, s.cardNumberSection, s.cardNumberSectionFour, 'cardNumberRtlTwo')}
                        placeholder={formatMessage(messages.zipcode)}
                      />
                      
                      <FormControl 
                    type="hidden"
                    name="reffralId"
                  
                    value={reffralId}
                 />
                      
                    </Col>
                    <Col lg={6} md={6} sm={5} xs={7} className={s.noPaddingLeft}>
                      <img src={imageOne} className={cx(s.fullWidth, s.stripeImg)} />
                    </Col>
                    <Col lg={5} md={5} sm={4} xs={5} className={cx(s.pullRight, s.textRight, s.noPaddingRight)}>
                      <img src={imageTwo} className={cx(s.fullWidth, s.stripeImg)} />
                    </Col>
                  </div>
                </Col>
              </Row>) : <span></span>
            }

            {payPalPayment && <Row className={s.payPalTop}>
              <Col xs={12} sm={12} md={12} lg={12}>
                <span>
                  <label
                    onClick={(e) => { this.handlePayment(e) }}
                  >
                    <Field
                      name="paymentType"
                      component="input"
                      type="radio"
                      value="1"
                      className={cx(s.cursorPointer)}
                    />
                    <span className={cx(s.radioTextSection, 'radioTextSectionRTL')}> <FormattedMessage {...messages.paypal} /></span>
                  </label>
                </span>
              </Col>
            </Row>}

            {
              paymentStatus == 1 &&
              <Row className={cx(s.space4, s.spaceTop3)}>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <div className={s.countryName}>
                    <span>
                      <FormattedMessage {...messages.paymentCurrency} />
                    </span>
                  </div>
                  <div className={s.selectContainer}>
                    <Field name="paymentCurrency" disabled={paymentType == 2} component={this.renderFormControlSelect} className={cx(s.formControlSelect, bt.commonControlSelect, 'selectPaymentDropdown')} >
                      <option value="">{formatMessage(messages.chooseCurrency)}</option>
                      {
                        this.renderpaymentCurrencies()
                      }
                    </Field>
                  </div>
                  <span className={cx(s.textLight)}>
                    <FormattedMessage {...messages.loginInfo} />
                  </span>
                </Col>
              </Row>
            }

            <div className={s.footerBtns}>
              {
                !paymentLoading && <div className={s.cancelBtn}>
                  <Link
                    to={"/rooms/" + listId}
                    className={cx(s.cancelLinkText)}
                    onClick={this.handleClick}
                  >
                    <FormattedMessage {...messages.cancel} />
                  </Link>
                </div>
              }
              {
                paymentLoading && <div className={s.cancelBtn}>
                  <a
                    href="javascript:void(0)"
                    className={cx(s.cancelLinkText, { [s.disabledLink]: submitting == true })}
                  >
                    <FormattedMessage {...messages.cancel} />
                  </a>
                </div>
              }
              <div className={s.cancelBtn}>
                <Loader
                  type={"button"}
                  buttonType={"submit"}
                  className={cx(bt.btnPrimary, 'arButtonLoader', s.loaderFlex)}
                  disabled={pristine || submitting || error || maximumStay}
                  show={paymentLoading}
                  label={formatMessage(messages.payNow)}
                />
              </div>
            </div>
          </Col>
        </form>
      </div >
    );
  }
}

PaymentForm = reduxForm({
  form: 'PaymentForm', // a unique name for this form
  validate
})(PaymentForm);

// Decorate with connect to read form values
const selector = formValueSelector('PaymentForm'); // <-- same as form name

const mapState = (state) => ({
  paymentCurrencyList: state.currency.availableCurrencies,
  paymentLoading: state.book.paymentLoading,
  paymentType: selector(state, 'paymentType'),
  listingFields: state.listingFields.data,
  checkIn: selector(state, 'checkIn'),
  checkOut: selector(state, 'checkOut'),
  guests: Number(selector(state, 'guests')),
  maximumStay: state.viewListing.maximumStay,
  payPalPayment: state.book.payPalPayment,
  stripePayment: state.book.stripePayment,
  restrictEdit: state.viewListing.restrictEdit,
  theme: state.currency.theme,
  specialPricing: state.viewListing.specialPricing,
	serviceFees: state.book.serviceFees,
  base: state.currency.base,
	rates: state.currency.rates,
	listData: state.book.data,
	currency: selector(state, 'currency'),
});

const mapDispatch = {
  // makePayment,
  processCardAction,
  openPaymentModal,
  applyPaymentModal,
  applycoupon
};

export default injectStripe(injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(PaymentForm))));

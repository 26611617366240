import React, { Component } from "react";

import { FormattedMessage, injectIntl } from "react-intl";
import moment from "moment";
import { connect } from "react-redux";

import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./memberPaymentForm.css";
import bt from "../../components/commonStyle.css";

import { makesubscriptionPayment } from "../../actions/Subscription/makePayment";
import { applycoupon } from "../../actions/Subscription/couponmanageAction";

//Local
import validate from "./validate";
import messages from "../../locale/messages";
import Loader from "../Loader/Loader";
import { isRTL } from "../../helpers/formatLocale";

import { Field, reduxForm, formValueSelector, reset, change } from "redux-form";
import { Row, Col, FormControl } from "react-bootstrap";
import {
  Elements,
  injectStripe,
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "react-stripe-elements";
import { toastr } from "react-redux-toastr";

//Images
import imageOne from "../../../public/SiteIcons/payment-icons.png";
import imageTwo from "../../../public/SiteIcons/stripe-connect.png";
const GET_COUPONCODE = `query getcouponcode($couponType:String,$subscriptionType:String){
  getcouponcode(couponType:$couponType,subscriptionType:$subscriptionType){
      errorMessage
      status
      data{
      couponCode,
      activeStatus,
          couponType,
          discount,
          startDate,
          endDate,
          description,
          userType
      }
  }
}`
const createOptions = (theme) => {
  return {
    style: {
      base: {
        color: theme == "dark" ? "#fff" : "#484848",
        fontWeight: 400,
        fontFamily: "inherit",
        fontSize: "14px",
        fontSmoothing: "antialiased",
        ":focus": {
          color: theme == "dark" ? "#fff" : "#484848",
        },

        "::placeholder": {
          color: "#aaa",
        },

        ":focus::placeholder": {
          color: "#aaa",
        },
      },
      invalid: {
        color: theme == "dark" ? "#fff" : "#484848",
        ":focus": {
          color: theme == "dark" ? "#fff" : "#484848",
        },
        "::placeholder": {
          color: "#aaa",
        },
      },
    },
  };
};

class MemberPaymentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentStatus: 2,
      load: true,
      couponName:"",
      couponDiscount: "",
      couponExpiresAt:"",
      couponDescription:"",
      couponLoading:false
    };

    this.renderpaymentCurrencies = this.renderpaymentCurrencies.bind(this);
    // this.handleClick = this.handleClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePayment = this.handlePayment.bind(this);
  }
  handlePayment(e) {
    const { change } = this.props;
    let paymentType = e.target.value;

    if (paymentType == 2) {
      this.setState({ paymentStatus: 2 });
    } else {
      change("zipcode", null);
      this.setState({ paymentStatus: 1 });
    }
  }

  async handleSubmit(values, dispatch) {
    // console.log("Values on submit", values);
    const { stripe, processCardAction, cardDetails, planPrice,userId,discountstatus,coupondiscount } = this.props;
    // console.log("Values on submit", discountstatus);
    // console.log("Values on submit coupondiscount", coupondiscount);
    // const {couponDiscount} = this.state;

    let planId = cardDetails.id;
    let paymentType = values.paymentType;
    let createPaymentMethod = "";
    let paymentMethodId = "";
    if (paymentType == 2) {
      createPaymentMethod = await stripe.createPaymentMethod("card", {
        card: <CardElement />,
        billing_details: {
          address: {
            postal_code: values.zipcode,
          },
        },
      });

      if (createPaymentMethod && createPaymentMethod.paymentMethod) {
        paymentMethodId = createPaymentMethod.paymentMethod.id;
        // console.log("createPaymentMethod", createPaymentMethod);
        // console.log("paymentMethodId", paymentMethodId);
      }

      if (
        createPaymentMethod &&
        createPaymentMethod.error &&
        createPaymentMethod.error.message &&
        paymentType == 2
      ) {
        msg = createPaymentMethod.error.message;
        toastr.error("Oops!", msg);
      } else {
        if (Number(values.paymentType) == 2 && !values.zipcode) {
          toastr.error("Oops!", "Your Zip code is incomplete.");
          return;
        }
        let amountType = planPrice.planType;
        const { status, paymentIntentSecret, reservationId } = await dispatch(
          makesubscriptionPayment({
            userId:userId,
            planId:planId,
            currency:"USD",
            paymentType: paymentType,
            amountType: amountType,
            paymentMethodId:paymentMethodId,
            couponDiscount:coupondiscount,
            amount:planPrice.price
      })
        );

        // console.log("paymentIntentSecret", paymentIntentSecret);
        if (status == 400 && paymentType == 2) {
          const cardAction = await stripe.handleCardAction(paymentIntentSecret);
          let amount = values.total + values.guestServiceFee;
          let confirmPaymentIntentId;

          if (
            cardAction &&
            cardAction.paymentIntent &&
            cardAction.paymentIntent.id
          ) {
            confirmPaymentIntentId = cardAction.paymentIntent.id;

            const {
              handleCardActionStatus,
              errorMessage,
            } = await processCardAction(
              reservationId,
              planId,
              values.hostId,
              values.guestId,
              values.listTitle,
              values.guestEmail,
              amount,
              values.currency,
              confirmPaymentIntentId
            );
          } else {
            if (cardAction && cardAction.error && cardAction.error.message) {
              msg = cardAction.error.message;
              toastr.error("Oops!", msg);
            }
          }
        }
      }
    } else {
      const { data } = await dispatch(
        makesubscriptionPayment({
          userId:userId,
          planId: planId,
          currency: values.paymentCurrency,
          paymentType: values.paymentType,
          amountType: planPrice.planType,
          couponDiscount:coupondiscount,
          amount:planPrice.price
        })
      );
    }
  }
  renderFormControlSelect = ({
    input,
    label,
    meta: { touched, error },
    children,
    className,
    disabled,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormControl
          disabled={disabled}
          componentClass="select"
          {...input}
          className={className}
        >
          {children}
        </FormControl>
        {touched && error && (
          <span className={s.errorMessage}>{formatMessage(error)}</span>
        )}
      </div>
    );
  };

  renderFormControlTextArea = ({
    input,
    label,
    meta: { touched, error },
    children,
    className,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormControl
          {...input}
          className={className}
          componentClass="textarea"
          placeholder={label}
        >
          {children}
        </FormControl>
        {touched && error && (
          <span className={s.errorMessage}>{formatMessage(error)}</span>
        )}
      </div>
    );
  };

  renderFormControl = ({
    input,
    label,
    type,
    placeholder,
    meta: { touched, error },
    className,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormControl
          {...input}
          placeholder={placeholder}
          type={type}
          className={className}
          maxLength={11}
        />
        {touched && error && (
          <span className={s.errorMessage}>{formatMessage(error)}</span>
        )}
      </div>
    );
  };

  renderpaymentCurrencies() {
    const { paymentCurrencyList } = this.props;
    // console.log("paymentCurrencyList", paymentCurrencyList);
    let rows = [];

    if (paymentCurrencyList != null && paymentCurrencyList.length > 0) {
      paymentCurrencyList.map((item, index) => {
        if (item.isEnable && item.isPayment) {
          rows.push(
            <option key={index} value={item.symbol}>
              {item.symbol}
            </option>
          );
        }
      });
    }
    return rows;
  }
  // async componentDidMount() {

  //   const { userId, cardDetails } = this.props
  //   const resp = await fetch('/graphql', {
  //     method: 'post',
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify({
  //       query: GET_COUPONCODE,
  //       variables: {
  //         // userId:userId,
  //         couponType: "subscription",
  //         subscriptionType: cardDetails.title
  //       }
  //     }),
  //   });
  //   const { data } = await resp.json();
  //   if (data) {
  //     this.setState({
  //       couponDiscount: data.getcouponcode.data[0].discount,
  //     })
  //   }


        
  //   // const{applycoupon}= this.props;
  //   // const {couponDiscount} = this.state;
  //   // if(couponDiscount){
  //   //   let discountstatus = true;
  //   //   await applycoupon({ discountstatus });
  //   // }else{
  //   //   let discountstatus = false;
  //   //   await applycoupon({ discountstatus });
  //   // }

    
  //     }
  render() {
    const { submitting, error, handleSubmit, paymentLoading, theme, intl: { locale } } = this.props;
    const { formatMessage } = this.props.intl;
    const { paymentStatus } = this.state;

    return (
      <div
        className={cx(
          s.bookItPanel,
          s.spaceTop2,
          s.aboutNoMargin,
          "customRatioButton"
        )}
      >
        <form onSubmit={handleSubmit(this.handleSubmit)}>
          <Col
            md={10}
            className={cx(s.textLeft, "textAlignRightRtl", s.noPadding)}
          >
            <section>
              <header className={s.paymentHeader}>
                <Row>
                  <Col
                    md={10}
                    className={cx(
                      s.textLeft,
                      s.paymentPadding,
                      "textAlignRightRtl"
                    )}
                  >
                    <h3
                      className={cx(
                        s.pullLeft,
                        s.h3,
                        s.space2,
                        "pullRightBooking"
                      )}
                    >
                      <FormattedMessage {...messages.payment} />
                    </h3>
                  </Col>
                </Row>
              </header>
            </section>

            <span>
              <label
                onClick={(e) => {
                  this.handlePayment(e);
                }}
              >
                <Field
                  name="paymentType"
                  component="input"
                  type="radio"
                  value="2"
                  className={cx(s.cursorPointer)}
                />
                <span className={cx(s.radioTextSection, "radioTextSectionRTL")}>
                  {" "}
                  <FormattedMessage {...messages.creditCard} />
                </span>
              </label>
            </span>

            {paymentStatus == 2 && (
              <Row className={cx(s.responsivecardSection)}>
                <Col
                  lg={10}
                  md={11}
                  sm={8}
                  xs={12}
                  className={cx(s.noPadding, s.cardSection, s.noPaddingLeft)}
                >
                  <div className={cx("placeHolderFont", "darkInputColor")}>
                    <label className={cx(s.labelText, "textWhite")}>
                      <FormattedMessage {...messages.paymentCardNumber} />
                    </label>
                    <CardNumberElement
                      {...createOptions(theme)}
                      placeholder={"4242 4242 4242 4242"}
                      className={cx(
                        s.cardNumber,
                        s.cardNumberSection,
                        s.cardNumberSectionOne,
                        "cardNumberRtl",
                        isRTL(locale)
                          ? "placeHolderNameRTL"
                          : "placeHolderNameLTR",
                        "textWhite"
                      )}
                    />

                    <Col
                      lg={4}
                      md={4}
                      sm={4}
                      xs={6}
                      className={cx(s.noPaddingLeft, "noPaddingRightRTL")}
                    >
                      <label className={cx(s.labelText, "textWhite")}>
                        <FormattedMessage {...messages.cardExpires} />
                      </label>
                      <CardExpiryElement
                        placeholder="MM / YY"
                        {...createOptions(theme)}
                        className={cx(
                          s.cardNumber,
                          s.cardNumberSectionTwo,
                          s.cardNumberSection,
                          "cardNumberRtl",
                          "textWhite"
                        )}
                      />
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      sm={4}
                      xs={6}
                      className={cx(
                        s.noMobileRightPadding,
                        "noMobileLeftPaddingRTL"
                      )}
                    >
                      <label className={cx(s.labelText, "textWhite")}>
                        <FormattedMessage {...messages.cvv} />
                      </label>
                      <CardCvcElement
                        placeholder="_ _ _"
                        {...createOptions(theme)}
                        className={cx(
                          s.cardNumber,
                          s.cardNumberSectionThree,
                          s.cardNumberSection,
                          "cardNumberRtlTwo",
                          "textWhite"
                        )}
                      />
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                      className={cx(
                        s.noPaddingRight,
                        s.noMobileLeftPadding,
                        "noLeftPaddingRTL",
                        "zipCodeMobilePaddingRTL"
                      )}
                    >
                      <label className={cx(s.labelText, "textWhite")}>
                        <FormattedMessage {...messages.zipcode} />
                      </label>
                      <Field
                        name="zipcode"
                        component={this.renderFormControl}
                        className={cx(
                          s.cardInput,
                          s.cardNumber,
                          s.noBoxShadow,
                          s.cardNumberSection,
                          s.cardNumberSectionFour,
                          "cardNumberRtlTwo"
                        )}
                        placeholder={formatMessage(messages.zipcode)}
                      />
                    </Col>
                    <Col
                      lg={6}
                      md={6}
                      sm={5}
                      xs={7}
                      className={s.noPaddingLeft}
                    >
                      <img
                        src={imageOne}
                        className={cx(s.fullWidth, s.stripeImg)}
                      />
                    </Col>
                    <Col
                      lg={5}
                      md={5}
                      sm={4}
                      xs={5}
                      className={cx(s.pullRight, s.textRight, s.noPaddingRight)}
                    >
                      <img
                        src={imageTwo}
                        className={cx(s.fullWidth, s.stripeImg)}
                      />
                    </Col>
                  </div>
                </Col>
              </Row>
            )}

            <Row className={s.payPalTop}>
              <Col xs={12} sm={12} md={12} lg={12}>
                <span>
                  <label
                    onClick={(e) => {
                      this.handlePayment(e);
                    }}
                  >
                    <Field
                      name="paymentType"
                      component="input"
                      type="radio"
                      value="1"
                      className={cx(s.cursorPointer)}
                    />
                    <span
                      className={cx(s.radioTextSection, "radioTextSectionRTL")}
                    >
                      {" "}
                      <FormattedMessage {...messages.paypal} />
                    </span>
                  </label>
                </span>
              </Col>
            </Row>

            {paymentStatus == 1 && (
              <Row className={cx(s.space4, s.spaceTop3)}>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <div className={s.countryName}>
                    <span>
                      <FormattedMessage {...messages.paymentCurrency} />
                    </span>
                  </div>
                  <div className={s.selectContainer}>
                    <Field
                      name="paymentCurrency"
                      // disabled={paymentType == 2}
                      component={this.renderFormControlSelect}
                      className={cx(
                        s.formControlSelect,
                        bt.commonControlSelect,
                        "selectPaymentDropdown"
                      )}
                    >
                      <option value="">
                        {formatMessage(messages.chooseCurrency)}
                      </option>
                      {this.renderpaymentCurrencies()}
                    </Field>
                  </div>
                  <span className={cx(s.textLight)}>
                    <FormattedMessage {...messages.loginInfo} />
                  </span>
                </Col>
              </Row>
            )}

            <div className={s.footerBtns}>
              {/* {paymentLoading && ( */}
              <div className={s.cancelBtn}>
                <a
                  href="/"
                  className={cx(s.cancelLinkText, {
                    [s.disabledLink]: submitting == true,
                  })}
                >
                  <FormattedMessage {...messages.cancel} />
                </a>
              </div>
              {/* )} */}
              <div className={s.cancelBtn}>
                <Loader
                  type={"button"}
                  buttonType={"submit"}
                  className={cx(s.payBtn, "arButtonLoader", s.loaderFlex)}
                  disabled={submitting}
                  show={paymentLoading}
                  label={formatMessage(messages.payNow)}
                />
              </div>
            </div>
          </Col>
        </form>
      </div>
    );
  }
}

MemberPaymentForm = reduxForm({
  form: "MemberPaymentForm", // a unique name for this form
  validate,
  initialValues:{
    paymentType : "2"
  }
})(MemberPaymentForm);

const selector = formValueSelector('MemberPaymentForm'); // <-- same as form name

const mapState = (state) => ({
  paymentCurrencyList: state.currency.availableCurrencies,
  paymentLoading: state.book.paymentLoading,
  payPalPayment: state.book.payPalPayment,
  stripePayment: state.book.stripePayment,
  theme: state.currency.theme,
  discount: selector(state, 'discount'),
  discountType: selector(state, 'discountType'),
  couponCode: selector(state, 'couponCode'),
  coupondiscount: selector(state, 'coupondiscount'),
  discountstatus: selector(state, 'discountstatus'),
});

const mapDispatch = {
  makesubscriptionPayment,
  // applycoupon
};

export default injectStripe(
  injectIntl(
    withStyles(s, bt)(connect(mapState, mapDispatch)(MemberPaymentForm))
  )
);

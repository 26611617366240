import React from 'react';
import { graphql, compose } from 'react-apollo';
// Style

// Component
import GetEditStaticPage from "./GetEditStaticPage.graphql";
import Layout from '../../../components/Layout';
import Page from '../../../components/Page';
class About extends React.Component {
  render() {
const {getEditStaticPage:{getEditStaticPage}} = this.props
// console.log("getSuggested",getEditStaticPage);
    return (
        <Page title={getEditStaticPage ? getEditStaticPage.metaTitle :"Cookie Policy"} html={getEditStaticPage && getEditStaticPage.content}/>
    )
  }
}

export default compose(
    graphql(GetEditStaticPage, {
        name: "getEditStaticPage",
        options: (props) => ({
          variables: {
            pageName: "Cookie Policy",
          },
          fetchPolicy: "network-only",
        }),
    })
  )(About);

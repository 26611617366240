import React from "react"
import OccupancyI from "../../components/OccupancyInsight/OccupancyInsight"
class OccupancyInsight extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            filter: "Week",
            startDate: "",
            endDate: "",
            endDate:"",
        };
        this.setStateVariable = this.setStateVariable.bind(this);
      }
      setStateVariable(variables) {
        this.setState(variables)
      }
      componentDidMount() {
    
        if (navigator.geolocation) {
          // console.log("navigator",navigator.geolocation)
          navigator.geolocation.getCurrentPosition((position)=>{
            latitude = position.coords.latitude;
            longitude = position.coords.longitude;
            this.setState({lat:latitude})
            this.setState({long:longitude})
          }, error);
        } else {
          console.log("Geolocation not supported");
        }
        let latitude=0;
        let longitude=0
        function success(position) {
           latitude = position.coords.latitude;
           longitude = position.coords.longitude;
    
    
          // console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
        }
        
        function error() {
          console.log("Unable to retrieve your location");
        }
    
        // console.log(doc,"hhhhhhheeeeellllllllooooooo");
      } 
render(){
    const {latitude,longitude} = this.props;
    const {lat,long} = this.state;
    // console.log("occupancyInsight",lat,"occupancyInsight",long)
    return (
        <> <OccupancyI latitude={lat} longitude={long} setStateVariable={this.setStateVariable} /> </>
    )
}
}
export default (OccupancyInsight)
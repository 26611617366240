import React from 'react';
import FooterLessLayout from '../../components/Layout/FooterLessLayout';
import HostMember from './HostMemberhip';

const title = 'HostMember';

export default function action({ store }) {

  // From Redux Store
  let isAuthenticated = store.getState().runtime.isAuthenticated;
  let userId = store.getState().user.id
  if (!isAuthenticated) {
    return { redirect: '/login' };
  }

  return {
    title,
    component: <FooterLessLayout><HostMember title={title} userId={userId}/></FooterLessLayout>,
  };
};

import React from "react"
// import KYIByDate from "../../components/KnowYourIncome/KYIbyDate"
import DemoHTMLL from "../../components/DemoHTML/Receipt";

class DemoHTML extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
        };
      }
   
render(){
    const {userId} = this.props;
    return (
        <> <DemoHTMLL userId={userId}  /> </>
    )
}
}
export default (DemoHTML)
import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './manageSubscription.css';
import ManageSubscriptionc from '../../../components/siteadmin/ManageSubscription';
// import {graphql, compose} from 'react-apollo';
class ManageSubscription extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          currentPage: 1,
          searchList: '',
        };
        this.setStateVariable = this.setStateVariable.bind(this);
      }
      setStateVariable(variables) {
        this.setState(variables)
      }

    render() {
        const { subscriptionData,GET_SUBSCRIPTION_DATA } = this.props
        const { currentPage, searchList} = this.state;
        //console.log(subscriptionData, "index_of_manageqq");
        return (
            <div>
                <ManageSubscriptionc subscriptionData={subscriptionData} currentPage={currentPage}
      searchList={searchList}  setStateVariable={this.setStateVariable}/>
            </div>
        )
    }

}

export default
    withStyles(s)
        (ManageSubscription);

import messages from "../../locale/messages";

const isValidWebsite = (url) => {
  const pattern = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
  return pattern.test(url);
};

const validate = (values) => {
  const errors = {};
  let result = isValidWebsite(values.websiteUrl);

  if (!values.websiteName) {
    errors.websiteName = messages.required;
  } else if (values.websiteName.trim() == "") {
    errors.websiteName = messages.required;
  }

  if (!values.websiteUrl) {
    errors.websiteUrl = messages.required;
  } else if (values.websiteUrl.trim() == "") {
    errors.websiteUrl = messages.required;
  } else if (!result) {
    errors.websiteUrl = messages.urlInvalid;
  }

  if (!values.websiteAbout) {
    errors.websiteAbout = messages.required;
  } else if (values.websiteAbout.trim() == "") {
    errors.websiteAbout = messages.required;
  }

  if (!values.typeList) {
    errors.typeList = messages.required;
  }
  if (!values.websiteDrive) {
    errors.websiteDrive = messages.required;
  }
  if (!values.typesOfWebsite) {
    // console.log("typesOfWebsite is null");
    errors.typesOfWebsite = messages.required;
  } else if (values.typesOfWebsite.trim() == "") {
    errors.typesOfWebsite = messages.required;
  }
  if (!values.primryJoining) {
    errors.primryJoining = messages.required;
  } else if (values.primryJoining.trim() == "") {
    errors.primryJoining = messages.required;
  }
  if (!values.websiteVisitors) {
    errors.websiteVisitors = messages.required;
  } else if (values.websiteVisitors.trim() == "") {
    errors.websiteVisitors = messages.required;
  }
  if (!values.buildLinks) {
    errors.buildLinks = messages.required;
  } else if (values.buildLinks.trim() == "") {
    errors.buildLinks = messages.required;
  }
  if (!values.websiteMonitize) {
    errors.websiteMonitize = messages.required;
  } else if (values.websiteMonitize.trim() == "") {
    errors.websiteMonitize = messages.required;
  }

  // values.websiteName,
  // values.websiteUrl,
  //  values.websiteAbout,
  //  values.typeList,
  // values.websiteDrive,
  //  values.primryJoining,
  //  values.websiteVisitors,
  // values.buildLinks,
  // values.websiteMonitize

  return errors;
};

export default validate;

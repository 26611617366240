import React, { Component } from "react";
import { injectIntl } from "react-intl";
import cut from "../../../public/SiteIcons/cut.svg";

// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./purchasePlan.css";
import bt from "../../components/commonStyle.css";
import { Tooltip,OverlayTrigger,Button } from "react-bootstrap";
import { applycoupon } from "../../actions/Subscription/couponmanageAction";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector, reset, change } from "redux-form";



import moment from 'moment';
// Image
import Logo from "../../../images/favicon/apple-icon-60x60.png";
import Check from "../../../public/SiteIcons/check.svg";
const GET_COUPONCODE = `query getcouponcode($couponType:String,$subscriptionType:String){
  getcouponcode(couponType:$couponType,subscriptionType:$subscriptionType){
      errorMessage
      status
      data{
      couponCode,
      activeStatus,
          couponType,
          discount,
          startDate,
          endDate,
          description,
          userType
      }
  }
}`
const tooltip = (
  <Tooltip id="tooltip">
    <strong>Hello!</strong> click to copy.
  </Tooltip>
);
class PlanDetails extends Component {
  constructor(props) {
    super(props);
    this.state={
      couponName:"",
      couponDiscount: "",
      couponExpiresAt:"",
      couponDescription:"",
      couponLoading:false,
      showCoupon:false,
      finalAmount:0,
    }
    this.handleCoupon = this.handleCoupon.bind(this);
  }
  async  componentDidMount(){
    const {userId,cardDetails} = this.props
            const resp = await fetch('/graphql', {
              method: 'post',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                query: GET_COUPONCODE,
                variables: {
                  // userId:userId,
                  couponType:"subscription",
                  subscriptionType:cardDetails.title
                }
              }),
            });
            const { data } = await resp.json();
            if(data){

              let ENDdate = moment(data.getcouponcode && data.getcouponcode.data[0].endDate).format("DD-MM-YY")
              // console.log("initialValues---->member susvss",ENDdate)
              this.setState({
                couponName:data.getcouponcode.data[0].couponCode,
                couponDiscount:data.getcouponcode.data[0].discount,
                couponExpiresAt:ENDdate,
                couponDescription:data.getcouponcode.data[0].description,
    
              })
            }

            
      }
       handleCoupon(){
        const{couponName,couponExpiresAt, couponDiscount,showCoupon}=this.state;
        const{applycoupon} = this.props;
        let discountstatus,coupondiscount;
        let couponCode = couponName;
        if(showCoupon === true){
          discountstatus = false;
          coupondiscount =  0;
          applycoupon({ discountstatus,coupondiscount });
        }else{
          discountstatus = true;
          coupondiscount =  couponDiscount;
          applycoupon({ coupondiscount, couponCode,discountstatus });
        }
        this.setState({showCoupon: !showCoupon});
      }
      handleInput = async (e) => {
        e.preventDefault()
        const {couponName} = this.state;
        try {
          await navigator.clipboard.writeText(couponName);
          toastr.success("Success!", "Coupon code copied successfully",{timeOut: 1000});
        } catch (err) {
          // console.error('Failed to copy: ', err);
          toastr.error("Failed!", `Failed to copy ${err}`,{timeOut: 1000});
        }
      }
  render() {
    const { cardDetails, planPrice, header, theme } = this.props;
    const { couponName,couponExpiresAt, couponDiscount,showCoupon,finalAmount} = this.state;

    // console.log("plandetails showCouponshowCouponshowCouponshowCoupon",showCoupon);
    return (
      <>
     {couponName &&  <div className={s.couponContainer}>
                    <div className={s.divOne}>
                      <div className={s.couponDiscountDiv} > {couponDiscount}% </div>
                      <div className={s.coupon}>{couponName} </div>
                      <p className={s.couponP}>Expires On:  {couponExpiresAt}</p>
                    </div>

                    <div className={s.divTwo}>
                      <h3 className={s.divTwoH3} >{couponDiscount}% OFF</h3>
                      <div className={s.div2ptag}>Use {couponName} and get {couponDiscount}% discount on this property!</div>
                    </div>

                    <div className={s.divThree}>
                      <div className={s.couponDash}> <img style={{ height: "30px", paddingTop: "8px", marginLeft: "3px" }} src={cut} alt="scissor" />
                          {/* <button
                            style={{
                              background: "transparent",
                              border: "none",
                              cursor: "pointer",
                              padding: "5px"
                            }}
                          >
                            {couponName}
                          </button> */}
                           <OverlayTrigger placement="bottom" overlay={tooltip}>
                    <button
                      style={{
                        background: "transparent",
                        border: "none",
                        cursor: "pointer",
                        padding: "5px"
                      }}
                      onClick={(e) => this.handleInput(e)}
                    >
                      {couponName}
                    </button>
                  </OverlayTrigger>
                      </div>
                      <Button className={s.claimBtn} onClick={this.handleCoupon}>{showCoupon ?"REMOVE" : "CLAIM DEAL"}</Button>
                    </div>
                  </div> }  
     
      <div className={s.cardTemplate} style={{ backgroundColor: theme }}>
        <div className={s.templateHeader} style={{ backgroundColor: header }}>
          <div className={s.logoFlex}>
            <img src={Logo} alt="logo" />
            <div className={s.memberType}>{cardDetails.title} </div>
          </div>
        </div>
        <div className={s.logoTitle}>
        <div className={s.planPrice} style={{ backgroundColor: header,textDecoration: showCoupon ? "line-through":"" }}>
            {"$"}
            {planPrice.price}
            {/* { `/${planPrice.planType && planPrice.planType}` } */}
            {planPrice.planType == "monthly" ? "/Monthly" : "/Annually"}
          </div>
          {showCoupon &&  <div className={s.planPrices} style={{ backgroundColor: header }}>
            {"$"}
            {(planPrice.price-(planPrice.price*couponDiscount /100)).toFixed(2)}
            {/* {`/${planPrice.planType && planPrice.planType}`} */}
            {planPrice.planType == "monthly" ? "/Monthly" : "/Annually"}
          </div>}
        </div>
        <div className={s.infoContainer}>
          <div className={s.details}>
            <img src={Check} alt="check" className={s.image} />
            <span className={s.info}>{cardDetails.one}</span>
          </div>
          <div className={s.details}>
            <img src={Check} alt="check" className={s.image} />
            <span className={s.info}>{cardDetails.two}</span>
          </div>
          <div className={s.details}>
            <img src={Check} alt="check" className={s.image} />
            <span className={s.info}>{cardDetails.three}</span>
          </div>
          <div className={s.details}>
            <img src={Check} alt="check" className={s.image} />
            <span className={s.info}>{cardDetails.four}</span>
          </div>
          <div className={s.details}>
            <img src={Check} alt="check" className={s.image} />
            <span className={s.info}>{cardDetails.five}</span>
          </div>
          <div className={s.details}>
            <img src={Check} alt="check" className={s.image} />
            <span className={s.info}>{cardDetails.six}</span>
          </div>
        </div>
      </div>

      </>
    );
  }
}

// export default injectIntl(withStyles(s, bt)(PlanDetails));
const selector = formValueSelector('MemberPaymentForm'); // <-- same as form name

const mapDispatch = {
  applycoupon
};
const mapState = (state) => ({
  // dayDifference: selector(state, 'dayDifference'),
  // priceForDays: selector(state, 'priceForDays'),
  // discount: selector(state, 'discount'),
  // discountType: selector(state, 'discountType'),
  // couponCode: selector(state, 'couponCode'),
  // coupondiscount: selector(state, 'coupondiscount'),
  // serviceFees: selector(state, 'guestServiceFee'),
  // total: selector(state, 'totalValue'),
  // isSpecialPriceAssigned: selector(state, 'isSpecialPriceAssigned'),
  // isAverage: selector(state, 'isSpecialPriceAverage')
});
export default 
  injectIntl(
    withStyles(s, bt)(connect(mapState,mapDispatch)(PlanDetails))
  );


import {
    LOADING_SUGGESTED_RESULTS,
    FETCH_SUGGESTED_RESULTS_SUCCESS,
    GET_SEARCH_SETTINGS_SUCCESS,
  } from '../constants';
  
  export default function suggested(state = {}, action) {
    switch (action.type) {
      case LOADING_SUGGESTED_RESULTS:
        return {
          ...state,
          isResultLoading: action.payload.isResultLoading,
        };
  
      case FETCH_SUGGESTED_RESULTS_SUCCESS:
        // console.log(action.payload,"action.payloadaction.payload")
        return {
          ...state,
          data: action.payload.data.results,
          count: Number(action.payload.data.count),
          isResultLoading: action.payload.isResultLoading,
        };
  
      default:
        return state;
    }
  }
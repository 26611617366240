import React, { Component } from "react";
import { FormattedMessage,injectIntl } from "react-intl";
import history from "../../core/history";

// Locale
import messages from "../../locale/messages";

//Styles
import cx from "classnames";
import bt from "../../components/commonStyle.css";
import s from "./weblist.css";
import { Button } from "react-bootstrap";

class Buttons extends Component {
  constructor(props) {
    super(props);
    this.handlePreviousPage = this.handlePreviousPage.bind(this);
  }

  async handlePreviousPage() {
    let path = "/affiliate/manageinfo/step1";
    history.push(path);
  }
  render() {
    const { submitting } = this.props;
    // console.log("submitting",submitting);
    return (
      <div className={s.buttonDiv}>
        <Button
          className={cx(bt.btnPrimary, s.button)}
          onClick={this.handlePreviousPage}
          disabled={submitting}
        >
          <FormattedMessage {...messages.back} />
        </Button>
        <Button
          className={cx(bt.btnPrimary, s.button)}
          type="submit"
          disabled={submitting}
        >
          <FormattedMessage {...messages.continue} />
        </Button>
      </div>
    );
  }
}

export default injectIntl(Buttons);

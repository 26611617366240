import React from 'react';
import AdminLayout from '../../../components/Layout/SubadminLayout';
import StaticPage from './StaticPage';
import { restrictUrls } from '../../../helpers/adminPrivileges';

const title = 'Static Content Page Management';

export default async function action({ store }) {

    // From Redux Store
    let issubAdminAuthenticated = store.getState().runtime.issubAdminAuthenticated;

    let adminPrivileges = store.getState().adminPrevileges.privileges && store.getState().adminPrevileges.privileges.privileges;


    if (!issubAdminAuthenticated) {
        return { redirect: '/subadmin/login' };
    }

    // Admin restriction
    if (!restrictUrls('/subadmin/staticpage/management', adminPrivileges)) {
        return { redirect: '/subadmin' };
    }

    return {
        title,
        component: <AdminLayout><StaticPage title={title} /></AdminLayout>,
    };
}

// Fetch request
import fetch from "../../../core/fetch";

// Redux
import {
  getSuggestedResults,
  loadingSuggestedResults,
} from "../../../actions/getSuggestedResults";

async function submitt(values, dispatch) {
  dispatch(loadingSuggestedResults());

  const GET_SUGGESTED_QUERY = `query getSuggested($currentPage: Int,$priceRange: [Int],$review:String){
    getSuggested(currentPage: $currentPage, priceRange: $priceRange,review:$review){
        count
           results {
      id
      title
      personCapacity
      lat
      lng
      beds
      bookingType
      coverPhoto
      reviewsCount
      reviewsStarRating
      listPhotos {
        id
        name
        type
        status
      }
      listingData {
        basePrice
        currency
      }
      settingsData {
        listsettings {
          id
          itemName
        }
      }
      wishListStatus
      isListOwner
    }
        errorMessage
        status
    }
}`;
  const response = await fetch("/graphql", {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: GET_SUGGESTED_QUERY,
      variables: {
        // userId: values.userId,
        currentPage: values.currentPage,
        priceRange: values.priceRange,
      },
    }),
  });
  const { data } = await response.json();
  console.log(data, "dataaaa");

  if (data && data.getSuggested) {
    dispatch(getSuggestedResults(data.getSuggested));
  }

  //   let suggestedData = dataa.data.getSuggested.results;
  //   console.log(suggestedData, "data.getSuggested.results");
}

export default submitt;

import React from "react"
import {
  Grid,
  Row,
  Col,
  Panel,
  Tabs,
  Tab,
} from 'react-bootstrap';
import Select from "react-select";
import {
  CChart
} from '@coreui/react-chartjs';
import '@coreui/coreui/dist/css/coreui.min.css'
import DateRange from "../../components/Home/AffiliateDateRange";
import moment from 'moment';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './ManageReservation.css';
import cx from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import history from '../../core/history';
// Graphql
import { graphql, compose } from 'react-apollo';
import { connect } from 'react-redux';
import ManageListingsQuery from './manageListing.graphql'

// Component
import SideMenu from '../ManageListing/SideMenu/SideMenu';
import NoItem from './NoItem';
import Loader from '../Loader/Loader';

import { openFilterModal } from '../../actions/modalActions';
// Locale
import messages from '../../locale/messages';
//Image
import addIcon1 from '../../../public/SiteIcons/inquiry.svg';
import booking from '../../../public/SiteIcons/booking.svg';
import wallet from '../../../public/SiteIcons/wallet.svg';
import conversion from '../../../public/SiteIcons/conversion.svg';
import available from '../../../public/SiteIcons/available.svg';
import occupacy from '../../../public/SiteIcons/occupacy.svg';
import revenue from '../../../public/SiteIcons/revenue.svg';
import avengers from '../../../public/SiteIcons/avengers.svg';
import booked from '../../../public/SiteIcons/booked.svg';
import filtered_conversion_rate from '../../../public/SiteIcons/filtered_conversion_rate.svg';
 import NoResults from '../SearchListing/NoResults/NoResults';

const options = [
  { label: "Day", value: "Day" },
  { label: "Week", value: "Week" },
  { label: "Month", value: "Month" },
  { label: "Date", value: "Date" },
];
let delayed;
const defaultDate = options[0];
const defaultData = {};


const getListquery = `
query getPropertyManagersData($filter:String,$graphType: String,$startDate: String,$endDate: String){
  getPropertyManagersData(filter:$filter,graphType:$graphType,startDate:$startDate,endDate:$endDate){
   Inquiry_to_Booking_Conversion_Rate
   Occupancy_Rate
   Average_Daily_Rate
   RevPAR
   total1
   total2
   rate
   filter
   graphType
   status
   errorMessage
   graphData
   {
       data1
       {
           graphDate
           value
       }
       data2
       {
           graphDate
           value
       }
   }
  }
}
    `;
class KYIbyDate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      graphData1: [],
      graphData2: [],
      filter: "Week",
      startDate: null,
      endDate: null,
      selectedOption: options[3],
      mergedData: [],
      total1: 0,
      total2: 0,
      rate: 0,
      noData:false
    };
  }
  componentDidMount() {
    const { graphData1, graphData2 } = this.state;
    const { type,data } = this.props;
    if(data.getPropertyManagersData.status != 200 ||data.getPropertyManagersData === null || data.getPropertyManagersData === undefined){
      this.setState({noData:true})
  }else if(data.getPropertyManagersData != null){
  this.setState({ 
    graphData1: data.getPropertyManagersData.graphData.data1,
  total1: data.getPropertyManagersData.total1,
  total2: data.getPropertyManagersData.total2,
  rate: data.getPropertyManagersData.rate
});
  this.setState({ graphData2: data.getPropertyManagersData.graphData.data2 });
}else if(data.getPropertyManagersData ==null || undefined){
  this.setState({ 
    graphData1: [],
  total1: 0,
  total2: 0,
  rate: 0
});
}
 
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data,type } = nextProps;
  
    this.setState({ 
      startDate: null,
      endDate: null,
      filter: "Week"
    });
    if(data.getPropertyManagersData.status != 200){
      this.setState({noData:true})
    }
  if(data.getPropertyManagersData){
    this.setState({ graphData1: data.getPropertyManagersData.graphData.data1,total1: data.getPropertyManagersData.total1,
      total2: data.getPropertyManagersData.total2,
      rate: data.getPropertyManagersData.rate });
    this.setState({ graphData2: data.getPropertyManagersData.graphData.data2 });
  }

  }

  handleChange = async (value) => {
//     if(value.value == "Date"){
// this.setState
//     }
    // const {type} = this.props
    // const response = await fetch("/graphql", {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({
    //     query: getListquery,
    //     variables: {
    //       filter: value.value,
    //       graphType:type,
    //     },
    //   }),
    // });
    // const json = await response.json();
    // if(json.data.getPropertyManagersData.status != 200 ||json.data.getPropertyManagersData === null || json.data.getPropertyManagersData === undefined){
    //     this.setState({noData:true})
    // }else{
    //       this.setState({ 
    //   graphData1: json.data.getPropertyManagersData.graphData.data1, 
    //   graphData2: json.data.getPropertyManagersData.graphData.data2,
    //   filter: value.value,
    //   total1: json.data.getPropertyManagersData.total1,
    // total2: json.data.getPropertyManagersData.total2,
    // rate: json.data.getPropertyManagersData.rate
    //  });
    // }
    if(value.value !== "Date"){
      const {type} = this.props
      const response = await fetch("/graphql", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: getListquery,
          variables: {
            filter: value.value,
            graphType:type,
          },
        }),
      });
      const json = await response.json();
      if(json.data.getPropertyManagersData.status != 200 ||json.data.getPropertyManagersData === null || json.data.getPropertyManagersData === undefined){
          this.setState({noData:true})
      }else{
            this.setState({ 
        graphData1: json.data.getPropertyManagersData.graphData.data1, 
        graphData2: json.data.getPropertyManagersData.graphData.data2,
        filter: value.value,
        total1: json.data.getPropertyManagersData.total1,
      total2: json.data.getPropertyManagersData.total2,
      rate: json.data.getPropertyManagersData.rate
       });
      }
  
    }else{
      this.setState({filter:"Date"})
    }

  };

  handlePickDate = async (value) => {
    const { type,data } = this.props;
    const answer_array = value.replaceAll("'",'').split(" AND ");
    // console.log('dattt'+answer_array);
    // answer_array[0] = "2023-05-01";
    // answer_array[1] = "2023-05-26";
    const response = await fetch("/graphql", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: getListquery,
        variables: {
          graphType:type,
          startDate: answer_array[0],
          endDate: answer_array[1],
          filter: "Date"
        },
      }),
    });
    const json = await response.json();
    // console.log(json.data,"response");
    this.setState({
      fromDate: answer_array[0],
      toDate: answer_array[1],
      filter: "Date",
      graphData1: json.data.getPropertyManagersData.graphData.data1,
      graphData2: json.data.getPropertyManagersData.graphData.data2,
      total1: json.data.getPropertyManagersData.total1,
    total2: json.data.getPropertyManagersData.total2,
    rate: json.data.getPropertyManagersData.rate
    });
    // this.handleAPI();
  };

  handleSelect(key) {
    const { type,data } = this.props;
    let variables = { type: key == 1 ? 'Inquiry' : 'previous' };
    // setStateVariable(variables);
    if (key === 1)
      history.push('/know-your-income/Inquiry')
      // this.setState({ graphData1: data.getPropertyManagersData.graphData.data1 }),
      // this.setState({ graphData2: data.getPropertyManagersData.graphData.data2 })
    else if (key === 2)
      history.push('/know-your-income/Occupancy'),
      this.setState({ graphData1: data.getPropertyManagersData.graphData.data1 }),
      this.setState({ graphData2: data.getPropertyManagersData.graphData.data2 })
    else if (key === 3)
      history.push('/know-your-income/Average')
      // this.setState({ graphData1: data.getPropertyManagersData.graphData.data1 }),
      // this.setState({ graphData2: data.getPropertyManagersData.graphData.data2 })
    else if (key === 4)
      history.push('/know-your-income/RevPAR')
      // this.setState({ graphData1: data.getPropertyManagersData.graphData.data1 }),
      // this.setState({ graphData2: data.getPropertyManagersData.graphData.data2 })

  }
  render() {
    const { type, loading, getAllReservation, userType, dateFilter, data, } = this.props;
    const { graphData1, graphData2,startDate,endDate,filter,total1,total2,rate,noData } = this.state
    

    let graphlables = "", graphfilterlabel = "", graphDataLabel1 = "", graphDataLabel2 = "";
    let inquiryCount = 0, bookingCount = 0, filterCount = 0, count = 0;
    let imageGraph = "", imageData1 = "", imageData2 = "", imageFilter = "";
    let x =[],y=[],z=[],a=[];
    let filteroption = {};
    
    let date = new Date();
    let year =date.getFullYear();
    
    let momentStartDate = moment(date).format('MMM');

    if(filter == "Week")
    {
       filteroption =options[1]
    }
    else if(filter == "Day")
    {
       filteroption =options[0]
    }
    else if(filter == "Date")
    {
       filteroption =options[3]
    }
   else if(filter == "Month")
    {
       filteroption =options[2]
    }
    graphData1 &&   graphData1.forEach((el,i)=>{
      x.push(el.graphDate)
    })
    graphData1 &&  graphData1.forEach((el,i)=>{
       y.push(el.value)
    })
    graphData2 &&  graphData2.forEach((el,i)=>{
       z.push(el.value)
    })
    if(x.length == 0){
      graphData2 &&   graphData2.forEach((el,i)=>{
        x.push(el.graphDate)
      })
    }
    
    // console.log(x, y,z,"image")
    if (type === "Inquiry") {
      imageGraph = wallet,
      imageData1 = addIcon1,
      imageData2 = booking,
      imageFilter = filtered_conversion_rate,
      graphlables = messages.InquiryLabel,
      graphfilterlabel = messages.InquiryLabelfilter,
      graphDataLabel1 = messages.Inquirydata1,
      graphDataLabel2 = messages.Inquirydata2
      count =data.getPropertyManagersData && data.getPropertyManagersData.Inquiry_to_Booking_Conversion_Rate
      inquiryCount = total1
      bookingCount = total2
      filterCount = rate
    }
    else if (type === "Occupancy") {
      imageGraph = occupacy,
      imageData1 = booked,
      imageData2 = available,
      imageFilter = filtered_conversion_rate,
      graphlables = messages.OccupancyLabel,
      graphDataLabel1 = messages.OccupancyData1,
      graphDataLabel2 = messages.OccupancyData2,
      graphfilterlabel = messages.InquiryLabelfilter,
      count = data.getPropertyManagersData.Occupancy_Rate
      inquiryCount = total1
      bookingCount = total2
      filterCount = rate
    }
    else if (type === "Average") {
      imageGraph = avengers,
      imageData1 = revenue,
      imageData2 = booking,
      imageFilter = filtered_conversion_rate,
      graphlables = messages.AverageDailyLabel,
      count = data.getPropertyManagersData.Average_Daily_Rate,
      graphDataLabel1 = messages.AverageDailyData1,
      graphDataLabel2 = messages.AverageDailyData2,
      graphfilterlabel = messages.InquiryLabelfilter
      inquiryCount = total1
      bookingCount = total2
      filterCount = rate
    }
    else if (type === "RevPAR") {
      imageGraph = revenue,
      imageData1 = avengers,
      imageData2 = occupacy,
      imageFilter = filtered_conversion_rate,
      imageGraph = wallet,
      graphlables = messages.RevPARLabel,
      count = data.getPropertyManagersData.RevPAR
      graphDataLabel1 = messages.AverageDailyLabel,
      graphDataLabel2 = messages.OccupancyLabel,
      graphfilterlabel = messages.InquiryLabelfilter
      inquiryCount = total1
      bookingCount = total2
      filterCount = rate
    }

    // console.log(graphData1 && graphData1, "data1");
    // console.log(graphData2 && graphData2, "data2");

         return (
      <>
        <Grid fluid className={'dashBoardContainer'}>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <div className={'dashBoardListingGrid'}>
                <SideMenu />
                <div className={cx('commonListingBg', 'tripContainer')}>
             { noData ?  <NoItem/> :     <Panel className={cx(s.panelHeader, 'bgBlack')}>
                    <div className={cx('listingTab', s.panelBorder, 'listingMobileTab', 'tabReservation')}>
                      <Tabs activeKey={type == 'Inquiry' ? 1 : "" || type == 'Occupancy' ? 2 : "" || type == 'Average' ? 3 : "" || type == 'RevPAR' ? 4 : ""} id="uncontrolled-tab-example" onSelect={(e) => this.handleSelect(e)}>
                        <Tab eventKey={1} title={<FormattedMessage {...messages.Inquiry} />} />
                        <Tab eventKey={2} title={<FormattedMessage {...messages.Occupancy} />} />
                        <Tab eventKey={3} title={<FormattedMessage {...messages.AverageDaily} />} />
                        <Tab eventKey={4} title={<FormattedMessage {...messages.RevPAR} />} />
                      </Tabs>
                    </div>
                    <div className={s.SelectAndDate}>
                      <span className={cx('homeDate', s.formControlInput, s.input, 'homeDateAR', 'headerSearchDate')}>
                        <Select
                          className={s.selectTag}
                          styles={{ width: "70%" }}
                          value={filteroption}
                          options={options}
                          onChange={this.handleChange}
                          name="filterSelect"
                        />
                      </span>

                     {
                      filter ==="Date" && <DateRange
                        startDate={startDate}
                        endDate={endDate}
                      onChange={this.handlePickDate}
                      isOutsideRange={() => false}
                      />
                     } 

                    </div>
                    <div className={s.card_container}>
                      <div className={s.card_one} >
                        <div>
                          <h5>{<FormattedMessage {...graphlables} />}</h5>
                        </div>
                        <div className={s.card_one_2nd_div}>
                          <img src={imageGraph}/>
                          <h4>{type === "RevPAR"? `$${count}`:`${count}%`}</h4>
                        </div>
                      </div>
                      <div className={s.card_one} style={{ background: "linear-gradient(to bottom, #709edb,#B3D0F8 ,#FFFFFF)" }}><div>
                        <h5>{<FormattedMessage {...graphDataLabel1} />}</h5>
                      </div>
                        <div className={s.card_one_2nd_div}>
                          <img src={imageData1} />
                          <h4 >{type === "Average"? `$${inquiryCount}`:""}{type === "RevPAR"? `${inquiryCount}%`:""}{type === "Inquiry"||type === "Occupancy"? `${inquiryCount}`:""}  </h4>
                        </div>
                      </div>
                      <div className={s.card_one} style={{ background: "linear-gradient(to bottom, #03fc6b,#03fc6b ,#FFFFFF)" }}>
                        <div>
                          <h5>{<FormattedMessage {...graphDataLabel2} />}</h5>
                        </div>
                        <div className={s.card_one_2nd_div}>
                          <img src={imageData2} />
                          <h4>{type === "RevPAR"? `${bookingCount}%`: `${bookingCount}`}</h4>
                        </div>
                      </div>
                      <div className={s.card_one} style={{ background: "linear-gradient(to bottom, #f2f5f0,#f2f5f0 ,#FFFFFF)" }}>
                        <div>
                          <h5>{<FormattedMessage {...graphfilterlabel} />}</h5>

                        </div>
                        <div className={s.card_one_2nd_div}>
                          <img src={imageFilter} />
                          <h4>{filterCount}%</h4>
                        </div>
                      </div>
                    </div>


                    {
                      type === "Inquiry" && filter ==="Week" && <CChart
                        style={{ marginTop: "40px" }}
                        type="line"
                        data={{
                          labels:x,
                          datasets: [
                            {
                              label: "Inquiry",
                              backgroundColor: "rgba(220, 220, 220, 0.2)",
                              borderColor: "rgba(23, 191, 29, 1)",
                              pointBackgroundColor: "rgba(23, 191, 29, 1)",
                              pointBorderColor: "#fff",
                              data: y,
                              fill: {
                                target: 'origin',
                                above: "rgba(23, 191, 29, 0.3)"

                              },

                            },
                            {
                              label: "Booking",
                              backgroundColor: "rgba(151, 187, 205, 0.8)",
                              borderColor: "#1562C9",
                              pointBackgroundColor: "rgba(151, 187, 205, 1)",
                              pointBorderColor: "#fff",
                              data:z,
                              fill: {
                                target: 'origin',
                                // above:"rgba(23, 191, 29, 0.3)"
                              },
                            },
                          ],
                        }}
                        options={{
                          plugins: {
                            filler: {
                              propagate: true
                            },
                            legend: {
                              labels: {
                                color: "black",
                              }
                            },
                            tooltip:{
                              callbacks: {
                                title: (context1) => {
                                  return `${context1[0].label}, ${momentStartDate}, ${year}`
                                },
                              }
                            }
                          },
                          scales: {
                            y: {
                              beginAtZero: true
                            }
                              
                          },
                          animation: {
                            onComplete: () => {
                              delayed = true;
                            },
                            delay: (context) => {
                              let delay = 0;
                              if (context.type === 'data' && context.mode === 'default' && !delayed) {
                                delay = context.dataIndex * 300 + context.datasetIndex * 100;
                              }
                              return delay;
                            },
                          },
                          interaction: {
                            intersect: false
                          },

                        }}
                      />}
                       {
                      type === "Inquiry" && filter!="Week" && <CChart
                        style={{ marginTop: "40px" }}
                        type="line"
                        data={{
                          labels:x,
                          datasets: [
                            {
                              label: "Inquiry",
                              backgroundColor: "rgba(220, 220, 220, 0.2)",
                              borderColor: "rgba(23, 191, 29, 1)",
                              pointBackgroundColor: "rgba(23, 191, 29, 1)",
                              pointBorderColor: "#fff",
                              data: y,
                              fill: {
                                target: 'origin',
                                above: "rgba(23, 191, 29, 0.3)"

                              },

                            },
                            {
                              label: "Booking",
                              backgroundColor: "rgba(151, 187, 205, 0.8)",
                              borderColor: "#1562C9",
                              pointBackgroundColor: "rgba(151, 187, 205, 1)",
                              pointBorderColor: "#fff",
                              data:z,
                              fill: {
                                target: 'origin',
                                // above:"rgba(23, 191, 29, 0.3)"
                              },
                            },
                          ],
                        }}
                        options={{
                          plugins: {
                            filler: {
                              propagate: true
                            },
                            legend: {
                              labels: {
                                color: "black",
                              }
                            },
                            tooltip:{
                              callbacks: {
                                title: (context1) => {
                                  return `${context1[0].label} ${year}`
                                },
                              }
                            },
                          },
                          scales: {
                            y: {
                              beginAtZero: true
                            }
                              
                          },
                          animation: {
                            onComplete: () => {
                              delayed = true;
                            },
                            delay: (context) => {
                              let delay = 0;
                              if (context.type === 'data' && context.mode === 'default' && !delayed) {
                                delay = context.dataIndex * 300 + context.datasetIndex * 100;
                              }
                              return delay;
                            },
                          },
                          interaction: {
                            intersect: false
                          },

                        }}
                      />}
                    
                    {
                      type === "Occupancy" && filter ==="Week" &&  <CChart
                        style={{ marginTop: "50px" }}
                        type="bar"
                        data={{
                          labels: x,
                          datasets: [
                            {
                              label: "Booked",
                              backgroundColor: "rgba(255, 99, 132,0.2)",
                              borderColor:"rgb(255, 99, 132)",
                              borderWidth: 2,
                              data:y,
                              barThickness: 30,
                              fill: {
                                target: 'origin',
                                above: "rgba(23, 191, 29, 0.3)"

                              },

                            },
                            {
                              label: "Available",
                              backgroundColor: "rgba(54,162,235,0.2)",
                              borderColor:"rgba(54,162,235)",
                              borderWidth: 2,
                              data:z,
                              barThickness: 20,
                              fill: {
                                target: 'origin',
                                above: "rgba(23, 191, 29, 0.3)"

                              },

                            },
                          ],
                        }}
                        options={{
                          plugins: {
                            filler: {
                              propagate: true
                            },
                            legend: {
                              labels: {
                                color: "black",
                              }
                            },
                            tooltip:{
                              callbacks: {
                                title: (context1) => {
                                  return `${context1[0].label}, ${momentStartDate}, ${year}`
                                },
                              }
                            },
      
                          },
                          animation: {
                            onComplete: () => {
                              delayed = true;
                            },
                            delay: (context) => {
                              let delay = 0;
                              if (context.type === 'data' && context.mode === 'default' && !delayed) {
                                delay = context.dataIndex * 300 + context.datasetIndex * 100;
                              }
                              return delay;
                            },
                          },
                          interaction: {
                            intersect: false
                          },

                        }}
                      />}
                      {
                      type === "Occupancy" && filter!="Week" && <CChart
                        style={{ marginTop: "50px" }}
                        type="bar"
                        data={{
                          labels: x,
                          datasets: [
                            {
                              label: "Booked",
                              backgroundColor: "rgba(255, 99, 132,0.2)",
                              borderColor:"rgb(255, 99, 132)",
                              borderWidth: 2,
                              data:y,
                              barThickness: 30,
                              fill: {
                                target: 'origin',
                                above: "rgba(23, 191, 29, 0.3)"

                              },

                            },
                            {
                              label: "Available",
                              backgroundColor: "rgba(54,162,235,0.2)",
                              borderColor:"rgba(54,162,235)",
                              borderWidth: 2,
                              data:z,
                              barThickness: 20,
                              fill: {
                                target: 'origin',
                                above: "rgba(23, 191, 29, 0.3)"

                              },

                            },
                          ],
                        }}
                        options={{
                          plugins: {
                            filler: {
                              propagate: true
                            },
                            legend: {
                              labels: {
                                color: "black",
                              }
                            },
                            tooltip:{
                              callbacks: {
                                title: (context1) => {
                                  return `${context1[0].label} ${year}`
                                },
                              }
                            }
                          },
                          animation: {
                            onComplete: () => {
                              delayed = true;
                            },
                            delay: (context) => {
                              let delay = 0;
                              if (context.type === 'data' && context.mode === 'default' && !delayed) {
                                delay = context.dataIndex * 300 + context.datasetIndex * 100;
                              }
                              return delay;
                            },
                          },
                          interaction: {
                            intersect: false
                          },

                        }}
                      />}
                      
                         {
                      type === "Average" && filter==="Week" && <CChart
                        style={{ marginTop: "50px" }}
                        type="bar"
                        data={{
                          labels: x,
                          datasets: [
                            {
                              label: "Revenue from Bookings",
                               backgroundColor: "rgba(255, 99, 132,0.2)",
                              borderColor:"rgb(255, 99, 132)",
                              borderWidth: 2,
                              data:y,
                              barThickness: 30,
                              fill: {
                                target: 'origin',
                                above: "rgba(23, 191, 29, 0.3)"

                              },

                            }
                          ],
                        }}
                        options={{
                          plugins: {
                            filler: {
                              propagate: true
                            },
                            legend: {
                              labels: {
                                color: "black",
                              }
                            },
                            tooltip:{
                              callbacks: {
                                label: (context) => {
                                  return `${context.dataset.label}: $${context.raw}`
                                },
                                title: (context1) => {
                                  return `${context1[0].label}, ${momentStartDate}, ${year}`
                                },
                              }
                            }
                          },
                          animation: {
                            onComplete: () => {
                              delayed = true;
                            },
                            delay: (context) => {
                              let delay = 0;
                              if (context.type === 'data' && context.mode === 'default' && !delayed) {
                                delay = context.dataIndex * 300 + context.datasetIndex * 100;
                              }
                              return delay;
                            },
                          },
                          interaction: {
                            intersect: false
                          },

                        }}
                      />
                      }
                      {
                      type === "Average" && filter!="Week" && <CChart
                        style={{ marginTop: "50px" }}
                        type="bar"
                        data={{
                          labels: x,
                          datasets: [
                            {
                              label: "Revenue from Bookings",
                               backgroundColor: "rgba(255, 99, 132,0.2)",
                              borderColor:"rgb(255, 99, 132)",
                              borderWidth: 2,
                              data:y,
                              barThickness: 30,
                              fill: {
                                target: 'origin',
                                above: "rgba(23, 191, 29, 0.3)"

                              },

                            }
                          ],
                        }}
                        options={{
                          plugins: {
                            filler: {
                              propagate: true
                            },
                            legend: {
                              labels: {
                                color: "black",
                              }
                            },
                            tooltip:{
                              callbacks: {
                                label: (context) => {
                                  return `${context.dataset.label}: ${context.raw}`
                                },
                                title: (context1) => {
                                  return `${context1[0].label} ${year}`
                                },
                              }
                            }
                          },
                          animation: {
                            onComplete: () => {
                              delayed = true;
                            },
                            delay: (context) => {
                              let delay = 0;
                              if (context.type === 'data' && context.mode === 'default' && !delayed) {
                                delay = context.dataIndex * 300 + context.datasetIndex * 100;
                              }
                              return delay;
                            },
                          },
                          interaction: {
                            intersect: false
                          },

                        }}
                      />
                      }
                     
                      {type === "Average" && filter==="Week" &&  <CChart
                        style={{ marginTop: "50px" }}
                        type="bar"
                        data={{
                          labels: x,
                          datasets: [
                            {
                              label: "Number of Bookings",
                              backgroundColor: "rgba(54,162,235,0.2)",
                              borderColor:"rgba(54,162,235)",
                              borderWidth: 2,
                              data:z,
                              barThickness: 30,
                              fill: {
                                target: 'origin',
                                above: "rgba(23, 191, 29, 0.3)"

                              },

                            }
                          ],
                        }}
                        options={{
                          plugins: {
                            filler: {
                              propagate: true
                            },
                            legend: {
                              labels: {
                                color: "black",
                              }
                            },
                            tooltip:{
                              callbacks: {
                                label: (context) => {
                                  return `${context.dataset.label}: $${context.raw}`
                                },
                                title: (context1) => {
                                  return `${context1[0].label}, ${momentStartDate}, ${year}`
                                },
                              }
                            }
                          },
                          animation: {
                            onComplete: () => {
                              delayed = true;
                            },
                            delay: (context) => {
                              let delay = 0;
                              if (context.type === 'data' && context.mode === 'default' && !delayed) {
                                delay = context.dataIndex * 300 + context.datasetIndex * 100;
                              }
                              return delay;
                            },
                          },
                          interaction: {
                            intersect: false
                          },

                        }}
                      />}
                      {type === "Average" && filter!="Week" &&  <CChart
                        style={{ marginTop: "50px" }}
                        type="bar"
                        data={{
                          labels: x,
                          datasets: [
                            {
                              label: "Number of Bookings",
                              backgroundColor: "rgba(54,162,235,0.2)",
                              borderColor:"rgba(54,162,235)",
                              borderWidth: 2,
                              data:z,
                              barThickness: 30,
                              fill: {
                                target: 'origin',
                                above: "rgba(23, 191, 29, 0.3)"

                              },

                            }
                          ],
                        }}
                        options={{
                          plugins: {
                            filler: {
                              propagate: true
                            },
                            legend: {
                              labels: {
                                color: "black",
                              }
                            },
                            tooltip:{
                              callbacks: {
                                label: (context) => {
                                  return `${context.dataset.label}: $${context.raw}`
                                },
                                title: (context1) => {
                                  return `${context1[0].label} ${year}`
                                },
                              }
                            }
                          },
                          animation: {
                            onComplete: () => {
                              delayed = true;
                            },
                            delay: (context) => {
                              let delay = 0;
                              if (context.type === 'data' && context.mode === 'default' && !delayed) {
                                delay = context.dataIndex * 300 + context.datasetIndex * 100;
                              }
                              return delay;
                            },
                          },
                          interaction: {
                            intersect: false
                          },

                        }}
                      />}
               
                      
                         {
                      type === "RevPAR" && filter==="Week" && <CChart
                        style={{ marginTop: "50px" }}
                        type="bar"
                        data={{
                          labels: x,
                          datasets: [
                            {
                              label: "Average Daily Rate",
                              backgroundColor: "rgba(255, 99, 132,0.2)",
                              borderColor:"rgb(255, 99, 132)",
                              borderWidth: 2,
                              data:y,
                              barThickness: 30,
                              fill: {
                                target: 'origin',
                                above: "rgba(23, 191, 29, 0.3)"

                              },

                            }
                          ],
                        }}
                        options={{
                          plugins: {
                            filler: {
                              propagate: true
                            },
                            legend: {
                              labels: {
                                color: "black",
                              }
                            },
                            tooltip:{
                              callbacks: {
                                label: (context) => {
                                  return `${context.dataset.label}: ${context.raw}%`
                                },
                                title: (context1) => {
                                  return `${context1[0].label}, ${momentStartDate}, ${year}`
                                },
                              }
                            }
                          },
                          animation: {
                            onComplete: () => {
                              delayed = true;
                            },
                            delay: (context) => {
                              let delay = 0;
                              if (context.type === 'data' && context.mode === 'default' && !delayed) {
                                delay = context.dataIndex * 300 + context.datasetIndex * 100;
                              }
                              return delay;
                            },
                          },
                          interaction: {
                            intersect: false
                          },

                        }}
                      />
                      }
                      {
                      type === "RevPAR" && filter!="Week" && <CChart
                        style={{ marginTop: "50px" }}
                        type="bar"
                        data={{
                          labels: x,
                          datasets: [
                            {
                              label: "Average Daily Rate",
                              backgroundColor: "rgba(255, 99, 132,0.2)",
                              borderColor:"rgb(255, 99, 132)",
                              borderWidth: 2,
                              data:y,
                              barThickness: 30,
                              fill: {
                                target: 'origin',
                                above: "rgba(23, 191, 29, 0.3)"

                              },

                            }
                          ],
                        }}
                        options={{
                          plugins: {
                            filler: {
                              propagate: true
                            },
                            legend: {
                              labels: {
                                color: "black",
                              }
                            },
                            tooltip:{
                              callbacks: {
                                label: (context) => {
                                  return `${context.dataset.label}: ${context.raw}%`
                                },
                                title: (context1) => {
                                  return `${context1[0].label} ${year}`
                                },
                              }
                            }
                          },
                          animation: {
                            onComplete: () => {
                              delayed = true;
                            },
                            delay: (context) => {
                              let delay = 0;
                              if (context.type === 'data' && context.mode === 'default' && !delayed) {
                                delay = context.dataIndex * 300 + context.datasetIndex * 100;
                              }
                              return delay;
                            },
                          },
                          interaction: {
                            intersect: false
                          },

                        }}
                      />
                      }
                      
                      {type === "RevPAR" && filter==="Week" &&  <CChart
                        style={{ marginTop: "50px" }}
                        type="bar"
                        data={{
                          labels: x,
                          datasets: [
                            {
                              label: "Occupancy Rate",
                              backgroundColor: "rgba(54,162,235,0.2)",
                              borderColor:"rgba(54,162,235)",
                              borderWidth: 2,
                              data:z,
                              barThickness: 30,
                              fill: {
                                target: 'origin',
                                above: "rgba(23, 191, 29, 0.3)"

                              },

                            }
                          ],
                        }}
                        options={{
                          plugins: {
                            filler: {
                              propagate: true
                            },
                            legend: {
                              labels: {
                                color: "black",
                              }
                            },
                            tooltip:{
                              callbacks: {
                                label: (context) => {
                                  return `${context.dataset.label}: ${context.raw}%`
                                },
                                title: (context1) => {
                                  return `${context1[0].label}, ${momentStartDate}, ${year}`
                                },
                              }
                            }
                          },
                          animation: {
                            onComplete: () => {
                              delayed = true;
                            },
                            delay: (context) => {
                              let delay = 0;
                              if (context.type === 'data' && context.mode === 'default' && !delayed) {
                                delay = context.dataIndex * 300 + context.datasetIndex * 100;
                              }
                              return delay;
                            },
                          },
                          interaction: {
                            intersect: false
                          },

                        }}
                      />}

                      {type === "RevPAR" && filter!="Week" &&  <CChart
                        style={{ marginTop: "50px" }}
                        type="bar"
                        data={{
                          labels: x,
                          datasets: [
                            {
                              label: "Occupancy Rate",
                              backgroundColor: "rgba(54,162,235,0.2)",
                              borderColor:"rgba(54,162,235)",
                              borderWidth: 2,
                              data:z,
                              barThickness: 30,
                              fill: {
                                target: 'origin',
                                above: "rgba(23, 191, 29, 0.3)"

                              },

                            }
                          ],
                        }}
                        options={{
                          plugins: {
                            filler: {
                              propagate: true
                            },
                            legend: {
                              labels: {
                                color: "black",
                              }
                            },
                            tooltip:{
                              callbacks: {
                                label: (context) => {
                                  return `${context.dataset.label}: ${context.raw}%`
                                },
                                title: (context1) => {
                                  return `${context1[0].label} ${year}`
                                },
                              }
                            }
                          },
                          animation: {
                            onComplete: () => {
                              delayed = true;
                            },
                            delay: (context) => {
                              let delay = 0;
                              if (context.type === 'data' && context.mode === 'default' && !delayed) {
                                delay = context.dataIndex * 300 + context.datasetIndex * 100;
                              }
                              return delay;
                            },
                          },
                          interaction: {
                            intersect: false
                          },

                        }}
                      />}


                  </Panel>}
                </div>
              </div>
            </Col>
          </Row>
        </Grid>
      </>
    )
    
   
  }


}

// export default injectIntl(withStyles(s)(KYIbyDate));
export default compose(
  injectIntl,
  withStyles(s)
)(KYIbyDate);
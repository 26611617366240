
// import React from 'react';
// import PropTypes from 'prop-types';
// import { FormattedMessage, injectIntl } from 'react-intl';
// import { connect } from 'react-redux';
// import { change, submit as submitForm, formValueSelector, reduxForm } from 'redux-form';
// import withStyles from 'isomorphic-style-loader/lib/withStyles';
// import s from './affiliateListing.css';

// import cx from 'classnames';
// import * as FontAwesome from 'react-icons/lib/fa';

// import CurrencyConverter from '../../CurrencyConverter/CurrencyConverter';
// import ListingPhotos from '../../SearchListing/ListingPhotos/ListingPhotos';

// // Locale
// import messages from '../../../locale/messages';

// import { formatURL } from '../../../helpers/formatURL';
// import { isRTL } from '../../../helpers/formatLocale';

// class AffiliateListing extends React.Component {

//   // constructor(props) {
//   //   super(props);
//   //   this.handleMouseOver = this.handleMouseOver.bind(this);
//   //   this.handleMouseOut = this.handleMouseOut.bind(this);
//   // }

//   static propTypes = {
//     formatMessage: PropTypes.func,
//     id: PropTypes.number,
//     basePrice: PropTypes.number,
//     currency: PropTypes.string,
//     city: PropTypes.string,
//     affiliateId: PropTypes.string,
//     referralId: PropTypes.string,
//     isGenerated: PropTypes.string,
//     description: PropTypes.string,
//     title: PropTypes.string,
//     listPhotos: PropTypes.array,
//     coverPhoto: PropTypes.number,
//   };
//   // static defaultProps = {
//   //   isHost: false,
//   //   description: []
//   // };
//   // handleMouseOver(value) {
//   //   const { change } = this.props;
//   //   change('SearchForm', 'markerHighlight', { 'id': value, 'hover': 'true' });
//   // }

//   // handleMouseOut(value) {
//   //   const { change } = this.props;
//   //   change('SearchForm', 'markerHighlight', {});
//   // }

//   render() {
//     const { formatMessage } = this.props.intl;
//     const {
//       id,
//       title,
//       city,
//       affiliateId,
//       referralId,
//        isGenerated,
     
//       basePrice,
//       currency,
//       coverPhoto,
//       listPhotos,
//     } = this.props;
//     let description = 0;
//     description = checkValue(data.description, '');
//     if (description) {
//       firstArray = description.slice(0, count);
//       restArray = description.slice(count, description.length);
//     }
//     let activeItem = 0, photoTemp, photosList = listPhotos.slice();
//     if (listPhotos && listPhotos.length > 1) {
//       listPhotos.map((x, y) => { if (x.id === coverPhoto) activeItem = y });
//       if (activeItem > 0) {
//         photoTemp = photosList[0];
//         photosList[0] = photosList[activeItem];
//         photosList[activeItem] = photoTemp;
//       }
//     }
//     // startDate = personalized && personalized.startDate ? "?&startdate=" + personalized.startDate : '';
//     // endDate = personalized && personalized.endDate ? "&enddate=" + personalized.endDate : '';
//     // guestCount = personCapacity && personalized.startDate && personalized.endDate ? "&guests=" + personCount : '';
//     // personalizedURL = startDate + endDate + guestCount;
//     // onMouseOver={() => this.handleMouseOver(id)} onMouseOut={() => this.handleMouseOut(id)}
//     return (
      
//       <div className={cx(s.listItemContainer)} >
//         {/* {
//           !isListOwner && <WishListIcon listId={id} key={id} isChecked={wishListStatus} heartIcon={heartIcon} />
//         } */}
  
//         <ListingPhotos
//           id={id}
//           coverPhoto={coverPhoto}
//           listPhotos={listPhotos}
//           formatURL={formatURL}
//           title={title}
//           // rtl={isRTL(locale)}
//         />

//         <div className={s.listInfo}>
//           {/* <a className={s.listInfoLink} href={"/rooms/" + formatURL(title) + '-' + id + personalizedURL} target={"_blank"}> */}
//             <div>
//               <div className={s.listFlex}>
//                 <div className={cx(s.textEllipsis, s.infoPrice, s.infoText, s.infoSpaceTop1, 'textWhite')}>
//                   <span className={s.fontWeight}>
//                     {
//                       <CurrencyConverter
//                         amount={basePrice}
//                         from={currency}
//                       />
//                     }
//                   </span>

//                   {/* {' '}<span className={s.nightCss}>/ <FormattedMessage {...messages.perNight} /></span> */}
//                   {/* {
//                     bookingType === "instant" && <span><FontAwesome.FaBolt className={s.instantIcon} /></span>
//                   } */}
//                 </div>
//                 {/* <div className={cx(s.textEllipsis, s.infoReview, s.infoSpaceTop1, 'textWhite')}>
//                   {starRatingValue > 0 && <div className={cx(s.reviewStar, 'small-star-rating')}>
//                     <StarRating
//                       value={starRatingValue}
//                       name={'review'}
//                       className={s.displayInline}
//                       starColor={'#484848'}
//                       emptyStarColor={'#cccccc'}
//                     />
//                     <span className={s.textInline}>&nbsp; {starRatingValue + ' '}
//                     </span>
//                   </div>}
//                 </div> */}
//               </div>
//               <div className={cx(s.textEllipsis, s.listingTitle, 'textWhite')}>
//                 <span className={'textReversing'}>{'title'}</span>
//                 <h2 className={cx(s.sectionTitleText)}> <FormattedMessage {...messages.aboutListing} /></h2>
//               </div>
//               <div className={cx(s.textEllipsis, s.infoDesc, s.infoText, s.infoSpace)}>
//                 <div className={cx(s.listingInfo, 'textWhite')}>
//                 <span className={'textReversing'}>{city}</span>
//                   {/* <span class="roomTypeRtl">{roomType}</span> */}
//                   {/* {beds > 0 && <span><span>&nbsp;/&nbsp;</span>{beds} {beds > 1 ? formatMessage(messages.beds) : formatMessage(messages.bed)}</span>} */}
//                   {/* <span>&nbsp;&#183;&nbsp;</span>
//                     <span>{personCapacity} {guestsLabel}</span> */}
//                 </div>
//               </div>
//             </div>
//           {/* </a> */}
//         </div>
//       </div>
//     );
//   }
// }

// const mapState = (state) => ({
//   // isMapShow: state.personalized.showMap,
//   // personalized: state.personalized,
//   locale: state.intl && state.intl.locale
// });

// const mapDispatch = {
//   change
// };

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { change, submit as submitForm, formValueSelector, reduxForm } from 'redux-form';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './affiliateLinkListings.css';

import { ShareButtons } from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import facebookIcon from '../../../../public/SiteIcons/shareFacebook.svg';
import twitterIcon from '../../../../public/SiteIcons/shareTwitter.svg';
import emailIcon from '../../../../public/SiteIcons/shareEmail.svg';
import copyIcon from '../../../../public/SiteIcons/shareLink.svg';
import { url } from '../../../config';
import {
  Button,
  Row,
  Col,
  Collapse,
  Modal,
  ListGroup,
  ListGroupItem
} from 'react-bootstrap';
import cx from 'classnames';
import * as FontAwesome from 'react-icons/lib/fa';

import CurrencyConverter from '../../CurrencyConverter/CurrencyConverter';
import ListingPhotos from '../../SearchListing/ListingPhotos/ListingPhotos';
import StarRating from '../../StarRating/StarRating';
import WishListIcon from '../../WishListIcon/WishListIcon';
import AffiliateSocialShare from '../../ViewListing/SocialShareModal/SocialShareModal';
// Locale
import * as FontAwesomemd from 'react-icons/lib/md';
import messages from '../../../locale/messages';
import { openSocialShareModal } from '../../../actions/modalActions';
import { formatURL } from '../../../helpers/formatURL';
import { isRTL } from '../../../helpers/formatLocale';
import shareIcon from '../../../../public/SiteIcons/viewListingShareIcon.svg';
import bedIcon from '../../../../public/SiteIcons/revenue_sharing.svg';
import bathIcon from '../../../../public/SiteIcons/AffiliateLinkclicks.svg';
import guestIcon from '../../../../public/SiteIcons/Coin.svg';

const { FacebookShareButton, TwitterShareButton, EmailShareButton } = ShareButtons;
class AffiliateListing extends React.Component {

  constructor(props) {
    super(props);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.handleClick = this.handleClick.bind(this);
    // this.toast = this.toast.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.copyText = this.copyText.bind(this);
    this.state = {
      open: false,
      isLoad: false,
      show: false,
      copied: false
    }
  }

  static propTypes = {
    formatMessage: PropTypes.func,
    id: PropTypes.number,
    basePrice: PropTypes.number,
    description:PropTypes.string,
    currency: PropTypes.string,
    title: PropTypes.string,
    beds: PropTypes.number,
    personCapacity: PropTypes.number,
    roomType: PropTypes.string,
    listPhotos: PropTypes.array,
    coverPhoto: PropTypes.number,
    bookingType: PropTypes.string.isRequired,
    reviewsCount: PropTypes.number,
    reviewsStarRating: PropTypes.number,
    wishListStatus: PropTypes.bool,
    isListOwner: PropTypes.bool
  };

  static defaultProps = {
    isHost: false,
    description: []
  };


 async showModal(){
    const { id,affiliateId } = this.props;
    let variables = {
      propertyId: id,
      affiliateId: affiliateId
    }

    let query = `
    mutation createAffiliateLink ($propertyId: Int, $affiliateId: String) {
      createAffiliateLink (propertyId: $propertyId, affiliateId: $affiliateId) {
        status
        errorMessage
      }
    }
        `;

        const resp = await fetch('/graphql', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                query: query,
                variables
            }),
            credentials: 'include',
        });

        const { data } = await resp.json();

        if(data.createAffiliateLink.status === 200){
          // console.log(data,"DATA---------");
          this.setState({ show: true });
          location.reload();
        }else{
          // console.log("eeeeeeeerrrrrrrroooooooorrrrrr");
          this.setState({ show: true });
          // event.preventDefault();
        }


    
  };

  hideModal() {
    this.setState({ show: false });
  };
  handleMouseOver(value) {
    const { change } = this.props;
    change('SearchForm', 'markerHighlight', { 'id': value, 'hover': 'true' });
  }

  handleMouseOut(value) {
    const { change } = this.props;
    change('SearchForm', 'markerHighlight', {});
  }
  componentDidMount() {
    this.setState({ isLoad: false });
  }
  UNSAFE_componentWillMount() {
    this.setState({ isLoad: true });
  }
  handleClick() {
    this.setState({ open: !this.state.open })
  }

  async copyText() {
    this.setState({
      copied: true
    })

    setTimeout(() => {
      this.setState({
        copied: false
      })
    }, 2000)
  }

  render() {
    const { formatMessage } = this.props.intl;
    const { open, isLoad } = this.state;
    const { openSocialShareModal } = this.props;

    const { socialshareModalStatus, copied } = this.state;
    
    let previewText = `Check out this listing on!`;
    // let bodyText = `Share this place with friends and family!`;
    const {
      id,
      basePrice,
      currency,
      referralId,
      createdAt,
      title,
      affiliate_commission,
      affiliateId,
      earning,
      clickResult,
      street,
      country,
      beds,
      city,
      personCapacity,
      roomType,
      coverPhoto,
      listPhotos,
      bookingType,
      reviewsCount,
      reviewsStarRating,
      wishListStatus,
      isListOwner,
      isMapShow,
      personalized,
      personCount,
      locale,
      WebsiteUrl
    } = this.props;
  

    const shareUrl = WebsiteUrl + '/rooms/' + formatURL(title) + '-' + id+'?ref='+ referralId;
    let count = 150, firstArray,linkname, restArray, dotString = false;
    let bodyText = `Share this place with friends and family!`+`\n` +shareUrl;
    
       linkname = "Copy Link"
    
    // description = checkValue(description, '');
    // if (description) {
    //   firstArray = description.slice(0, count);
    //   restArray = description.slice(count, description.length);
    // }
    // if (restArray && restArray.length > 0) {
    //   dotString = true;
    // }
        // description = checkValue(data.description, '');

       
        // let description = 0;
    let bedsLabel = 'bed';
    let guestsLabel = 'guest';
    let earningsLabel = 'Earning';
    let dateLabel = 'Link Creation Date :';
    let clicksLabel = 'Clicks';
    let sharingLabel = 'Sharing';
    let heartIcon = 'heartIcon';
    let personalizedURL = '', startDate, endDate, guestCount;

    // if (beds > 1) {
    //   bedsLabel = 'beds';
    // }
    // if (description) {
    //   firstArray = description.slice(0, count);
    //   restArray = description.slice(count, descriptions.length);
    // }
    // if (personCapacity > 1) {
    //   guestsLabel = 'guests';
    // }
    let starRatingValue = 0;
    if (reviewsCount > 0 && reviewsStarRating > 0) {
      starRatingValue = Math.round(reviewsStarRating / reviewsCount)
    }
    let activeItem = 0, photoTemp, photosList = listPhotos.slice();
    if (listPhotos && listPhotos.length > 1) {
      listPhotos.map((x, y) => { if (x.id === coverPhoto) activeItem = y });
      if (activeItem > 0) {
        photoTemp = photosList[0];
        photosList[0] = photosList[activeItem];
        photosList[activeItem] = photoTemp;
      }
    }
    startDate = personalized && personalized.startDate ? "?&startdate=" + personalized.startDate : '';
    endDate = personalized && personalized.endDate ? "&enddate=" + personalized.endDate : '';
    guestCount = personalized.startDate && personalized.endDate ? "&guests=" + personCount : '';
    personalizedURL = startDate + endDate + guestCount;

    return (
      <div className={cx(s.listItemContainer)} onMouseOver={() => this.handleMouseOver(id)} onMouseOut={() => this.handleMouseOut(id)}>
        {/* {
          !isListOwner && <WishListIcon listId={id} key={id} isChecked={wishListStatus} heartIcon={heartIcon} />
        } */}
        <ListingPhotos
          id={id}
          coverPhoto={coverPhoto}
          listPhotos={photosList}
          formatURL={formatURL}
          title={title}
          isMapShow={isMapShow}
          personalizedURL={personalizedURL}
          rtl={isRTL(locale)}
        />
        <div className={s.listInfo}>
            <div>
            <div>
              <div className={cx(s.textEllipsis, s.infoPrice1, s.infoText, s.infoSpaceTop1, 'textWhite')}>
                <span className={s.fontWeight} >
                  {
                    <CurrencyConverter
                      amount={basePrice}
                      from={currency}
                    />
                  }
                </span>

              </div>
              {' '}<span className={s.nightCss}>/ <FormattedMessage {...messages.perNight} /></span>
            </div>
            <div className={cx(s.textEllipsis, s.listingTitle, 'textWhite')}>
              <span className={cx(s.sectionTitleText, 'textReversing')}>{title}</span>
            </div>


              <div style={{ borderTop: "1px solid grey" }} className={cx(s.listFlex, s.space2, s.spaceTop2)}>
              <div style={{ color: "#767676",fontSize: "12px" }} className={cx(s.svgImg, s.paddingTop2, 'svgImg')}><img style={{ paddingBottom: "6px" }}  src={bedIcon} />&nbsp;{affiliate_commission}&nbsp; {sharingLabel}</div>
              <div style={{ color: "#767676",fontSize: "12px" }} className={cx(s.svgImg, s.paddingTop2, 'svgImg')}><img style={{ paddingBottom: "7px" }} src={bathIcon} />&nbsp;{clickResult}&nbsp; {clicksLabel}</div>
              <div style={{ color: "#767676",fontSize: "12px" }} className={cx(s.svgImg, s.paddingTop2, 'svgImg')}>
                <img src={guestIcon} style={{ paddingBottom: "4px" }} />
                &nbsp;
                
                  {
                    <CurrencyConverter
                      amount={earning}
                      from={"USD"}
                    />
                  }&nbsp; 
                 {earningsLabel}</div>
            </div>
            
            <div className={cx(s.listFlex)}>
            <Button type="button" style={{backgroundColor:"#8c52ff", color:"white"}} onClick={this.showModal}>
            {linkname}
          </Button>
          <div style={{ color: "#767676",fontSize: "10px" }}>{dateLabel}{createdAt}</div>

            </div>

            <Modal show={this.state.show} animation={false} dialogClassName={cx(s.signupModalContainer, 'signupModal', 'sharesocialModal')}>
          <Modal.Header closeButton onClick={this.hideModal}>
          </Modal.Header>
          <Modal.Body bsClass={s.signupModalBody}>
            <div className={s.paddingbottom24}>
              <div className={s.share}>
                <FormattedMessage {...messages.shareLabel} />
              </div>
              <div className={s.content}>
                <FormattedMessage {...messages.socialShareDesc} /> <FormattedMessage {...messages.checkOutShare} /> <span>{title} <FormattedMessage {...messages.inLabel} /> {city}, {country} <FormattedMessage {...messages.onLabel} />{' '}</span>
              </div>
            </div>
            <ListGroup className={cx('sharingsocial', 'shareSocialLink')}>
              <div className={s.mainBorder}>
                <ListGroupItem tag="a" href={shareUrl} className={s.borderradiusNone}>
                  <FacebookShareButton
                    url={shareUrl}
                  >
                    <img src={facebookIcon} className={cx(s.socialIcons, 'socialIconsRtl')} />
                    <FormattedMessage {...messages.facebook} />
                  </FacebookShareButton>
                </ListGroupItem>
              </div>
              <div className={s.mainBorder}>
                <ListGroupItem tag="a" href={shareUrl}>
                  <TwitterShareButton
                    url={shareUrl}
                    className={s.displayIcon}>
                    <img src={twitterIcon} className={cx(s.socialIcons, 'socialIconsRtl')} />
                    <FormattedMessage {...messages.twitter} />
                  </TwitterShareButton>
                </ListGroupItem>
              </div>
              <div className={s.mainBorder}>
                <ListGroupItem tag="a" href={shareUrl}>
                  <EmailShareButton
                    url={shareUrl}
                    subject={previewText}
                    body={bodyText}
                    className={s.displayIcon}>
                    <img src={emailIcon} className={cx(s.socialIcons, 'socialIconsRtl')} />
                    <FormattedMessage {...messages.emailLabel} />
                  </EmailShareButton>
                </ListGroupItem>
              </div>
              <div>
                <ListGroupItem>
                  <CopyToClipboard
                    text={shareUrl}
                    onCopy={() => this.copyText()}
                  >
                    <div>
                      <img src={copyIcon} className={cx(s.socialIcons, 'socialIconsRtl')} />
                      <span>{copied ? formatMessage(messages.linkCopiedLabel) : formatMessage(messages.copyLinkLabel)}</span>
                    </div>
                  </CopyToClipboard>
                </ListGroupItem>
              </div>
            </ListGroup>
          </Modal.Body>
            {/* <button style={{backgroundColor:"#8c52ff"}} type="button" onClick={this.hideModal}>
          Close
        </button> */}
          </Modal>
          
    
              
              {/* <div className={cx(s.horizontalLineThrough)}>
          <div>
            <p className={cx(s.listingFontSize)} >
              {!this.state.open && count >= 150 &&
                <span className={cx(s.subText, s.lineBreak)}>  {firstArray} {dotString === true && <span>...</span>}</span>
              }
              {
                restArray && restArray.length > 0 &&
                <span>
                  <Collapse in={open}>
                    <div> <span className={cx(s.subText, s.lineBreak)}>
                      {this.state.open && firstArray} {restArray}
                    </span></div>
                  </Collapse>
                  {
                    dotString && <div className={s.btnContainer}>
                      <div className={s.showHidePadding}>
                        <Button
                          bsStyle="link"
                          className={cx(s.button, s.noPadding, s.btnLInk, s.showHideBtn, 'bgTransparent')}
                          onClick={() => this.handleClick()}
                        >
                          {this.state.open ? <FormattedMessage {...messages.closeAll} /> : <FormattedMessage {...messages.showDescription} />}
                          {
                            this.state.open && <FontAwesome.FaAngleUp className={s.navigationIcon} />
                          }
                          {
                            !this.state.open && <FontAwesome.FaAngleDown className={s.navigationIcon} />
                          }
                        </Button>
                      </div>
                    </div>
                  }
                </span>
              }
            </p>
          </div>
        </div> */}
        
            </div>
        </div>
      </div>
    );
    
  }
}

const mapState = (state) => ({
  isMapShow: state.personalized.showMap,
  personalized: state.personalized,
  locale: state.intl && state.intl.locale,
  WebsiteUrl: state.user && state.user.WebsiteUrl
});

const mapDispatch = {
  change,
  openSocialShareModal
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(AffiliateListing)));
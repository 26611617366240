import React from 'react';
import HostMemberShip from '../../components/HostMembership/index';
import PropTypes from 'prop-types';

class HostMember extends React.Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
  };

  render() {
    const { userId} = this.props;
    return (
      <>
        <HostMemberShip userId={userId}/>
      </>
    );
  }
}

export default HostMember

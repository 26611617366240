import React, { Component } from "react";
import s from "./Receipt.css";
import chik from "./ptprologo.png";
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import priceIcon from '../../../public/SiteIcons/printIcon.svg';
import cx from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button } from "react-bootstrap";
import messages from '../../locale/messages';
import moment from 'moment';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Logo from '../Logo';
const GET_RECEIPT_DATA = `query getReceiptPayment($userId: String,){
    getReceiptPayment(userId:$userId){
        errormessage
        status
            data{
                id
                transactionId
                siteTitle
                displayName
                phoneNumber
                email
                total
                createdAt
                reservationId
                expiryDate
                planType
                planId
                paymentType
                title
                currency
                Logo
            }
    }
}`
class Receipt extends Component {

    constructor(props) {
        super(props);
        this.state = {
            receiptData: null,
        };

    }
    // handleConvertToPDF = () => {
    //     html2canvas(document.getElementById('receiptBoxx')).then(canvas => {
    //       const imgData = canvas.toDataURL('image/png');
    
    //       const pdf = new jsPDF();
    //       pdf.addImage(imgData, 'PNG', 10, 10, 190, 280); // You can adjust the dimensions and position
    //       pdf.save('converted.pdf');
    //     });
    //   };
    handleConvertToPDF = () => {
        const content = document.getElementById('receiptBoxx');
    
        html2canvas(content).then(canvas => {
          const imgData = canvas.toDataURL('image/png');
    
          const pdf = new jsPDF('p', 'mm', 'a4'); // Create a PDF with A4 dimensions
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = pdf.internal.pageSize.getHeight();
          const imgWidth = pdfWidth - 20; // Adjust for margins
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
    
          pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
          pdf.save('payment_receipt.pdf');
          window.location.href = "/host/membership"
        });
      };
    
   async componentDidMount(){
    const {userId} = this.props;
        const response = await fetch("/graphql", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              query: GET_RECEIPT_DATA,
              variables: {
                userId: userId,
              },
            }),
            
          });
          const data = await response.json();
        //   console.log("GET_RECEIPT_DATA",data.data.getReceiptPayment.data)
          this.setState({receiptData:data.data.getReceiptPayment.data})
    }
    print() {
		window.print();
	}
    render() {
        const {receiptData} = this.state;
    const {userId} = this.props;
    // console.log("userId userId",userId);
        return (
            <>
                <div className={cx(s.space4, s.rowTable)}>
                {<a className={cx(s.button, "hidden-print", s.printBtn, 'printBtnRTL')} onClick={this.handleConvertToPDF}>
                        <img src={priceIcon} className={s.printIconCss} /> <FormattedMessage {...messages.downloadText} />
                    </a>}
                    <br />
                    <Button className={s.purchaseButton} onClick={()=>window.location.href="/"}>
                     <FormattedMessage  {...messages.home} />
                </Button>
                  <br />
                  <Button className={s.purchaseButton} onClick={()=>window.location.href="/become-a-host?mode=new"}>
                     <FormattedMessage  {...messages.startFromHere} />
                </Button>
                </div>
                <br />
                <div className={s.receiptBox} id="receiptBoxx">
                    <div className={s.sidePart}>
                        <div className={s['sidePart-items']}>
                            <span className={s['sidePart-items-Data']}>
                                Invoice
                            </span>
                            <hr className={s.sideHR} />
                            {/* <span>{receiptData ? receiptData.id :"fetching data..."}</span> */}
                            <span>{receiptData ? ( receiptData.id).toString().padStart(3, '0'):"fetching data..."}</span>

                        </div>
                        <div className={s['sidePart-items']}>
                            <span className={s['sidePart-items-Data']}>
                                Receipt For
                            </span>
                            <hr className={s.sideHR} />

                            <span>{receiptData && receiptData ? receiptData.displayName : "fetching data..."}</span>
                        </div>
                        <div className={s['sidePart-items']}>
                            <span className={s['sidePart-items-Data']}>
                                Amount
                            </span>
                            <hr className={s.sideHR} />

                            {/* <span>{receiptData ? receiptData.total : "fetching data..."}{" "}{(receiptData && receiptData.currency)}</span> */}
                            <span>{"$ "}{receiptData ? receiptData.total : "fetching data..."}</span>
                        </div>
                        <div className={s['sidePart-items']}>
                            <span className={s['sidePart-items-Data']}>
                                Issuer
                            </span>
                            <hr className={s.sideHR} />

                            <span> {receiptData && receiptData.siteTitle ?receiptData.siteTitle:"fetching data..."} </span>
                        </div>
                        <div className={s['sidePart-items']}>
                            <span className={s['sidePart-items-Data']}>
                                Confirmation no.
                            </span>
                            <hr className={s.sideHR} />

                            <span>{receiptData ? receiptData.transactionId : "fetching data..."}</span>
                        </div>
                    </div>
                    <div className={s.mainPart}>
                        <div className={s.imagePart}>
                            {/* <img src={receiptData && receiptData.Logo} alt="logo" height="100px" width="100px" /> */}
                            <Logo link={"/"} className={cx(s.brand, s.brandImg)} />
                        </div>
                        <div className={s.detailsPart}>
                            <div className={s.planPart}>
                                {/* Crown Svg */}
                                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="16px" height="16px"
                                    viewBox="0 0 981.9 981.901" style={{ enableBackground: 'new 0 0 981.9 981.901', marginRight: '2px' }}
                                    xmlSpace="preserve">
                                    <g>
                                        <path
                                            d="M861,863.05c0-30.4-24.6-55-55-55H175.9c-30.4,0-55,24.6-55,55s24.6,55,55,55H806C836.4,918.05,861,893.35,861,863.05z" />
                                        <path d="M65.4,417.85c0.9,0,1.7,0,2.6-0.1l87.2,315.6H491h335.7l87.2-315.6c0.899,0,1.699,0.1,2.6,0.1c36.1,0,65.4-29.3,65.4-65.4
                s-29.301-65.4-65.4-65.4s-65.4,29.3-65.4,65.4c0,7,1.101,13.8,3.2,20.1l-157.7,92.2l-169.5-281
                c17.601-11.7,29.301-31.8,29.301-54.5c0-36.1-29.301-65.4-65.4-65.4s-65.4,29.3-65.4,65.4c0,22.8,11.601,42.8,29.301,54.5
                l-169.5,281l-157.7-92.2c2-6.3,3.2-13.1,3.2-20.1c0-36.1-29.3-65.4-65.4-65.4c-36.2,0-65.5,29.3-65.5,65.4S29.3,417.85,65.4,417.85
                z" />
                                    </g>
                                </svg>
                                <span>{receiptData && receiptData.title}  Plan</span> : <span>{receiptData && receiptData.planType}</span>
                            </div>
                            <div className={s.planDatails} style={{ marginTop: "20px" }}>
                                <div className={s.secondDiv}>Transaction Id : <span> {receiptData ? receiptData.transactionId : "fetching data..."}</span></div>
                                <div className={s.commonBorder}></div>
                            </div>
                            <div className={s.planDatails}>
                                <div className={s.secondDiv}>Payment Method : <span>{receiptData && receiptData.paymentType==2 ? "stripe" : "paypal"}</span></div>
                                <div className={s.commonBorder}></div>

                            </div>
                            <div className={s.planDatails}>
                                {/* <div className={s.secondDiv}>Purchased Date : <span>{receiptData ? moment(receiptData.createdAt).format('YYYY-MM-DD') : "fetching data..."}</span></div> */}
                                <div className={s.secondDiv}>Purchased Date : <span>{receiptData ? receiptData.createdAt : "fetching data..."}</span></div>
                                <div className={s.commonBorder}></div>

                            </div>
                            <div className={s.planDatails}>
                                {/* <div className={s.secondDiv}>Plan Expiry : <span>{receiptData ? moment(receiptData.expiryDate,'YYYY-MM-DD').format('YYYY-MM-DD') : "fetching data..."}</span></div> */}
                                <div className={s.secondDiv}>Plan Expiry : <span>{receiptData ? receiptData.expiryDate : "fetching data..."}</span></div>
                                <div className={s.commonBorder}></div>

                            </div>
                            <div className={s.planDatails}>
                                <div className={s.paidPricePart} style={{ fontWeight: "bold", fontSize: "14px" }}>Total Paid Amount : <span>{receiptData ? receiptData.total : "fetching data..."}{" "}{receiptData ? receiptData.currency : ""}</span></div>
                                <div className={s.commonBorder}></div>

                            </div>
                        </div>
                        <div className={s.lastPart}>
                            Thank You For Choosing Our Plan. if you need support , mail us on {receiptData ?receiptData.email: 'support@ptpro.com'} or call @{receiptData ?receiptData.phoneNumber :'+91 9811389998'}
                        </div>
                    </div>
                </div>
            
            </>
        );
    }




}

export default injectIntl(withStyles(s)(Receipt));

import React from 'react';
import PropTypes from 'prop-types';
import { graphql, gql, compose } from 'react-apollo';

// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './SiteSettings.css';

// Component
import SiteSettingsForm from '../../../components/subadmin/SiteSettingsForm';
import Loader from '../../../components/Loader';

// Query
import siteSettingsQuery from './siteSettingsQuery.graphql';

class SiteSettings extends React.Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.shape({
      loading: PropTypes.bool,
      siteSettings: PropTypes.array,
    })
  };

  static defaultProps = {
    data: {
      loading: true
    }
  };


  render() {
    const { data: { loading, siteSettings } } = this.props;
    let settingsCollection ={
      "siteName": "Zingle",
      "siteTitle": "userId",
      "metaKeyword": "mfvnsdlfkfmvsl",
      "metaDescription": "sdvdfvsdsd",
      "facebookLink": ".mfvnsdlfkfmvsl",
      "twitterLink": "mfvnsdlfkfmvsl",
      "instagramLink": "mfvnsdlfkfmvsl",
      "logoHeight": 7,
      "logoWidth": 4,
      "homePageType": 7,
      "videoLink": "mfvnsdlfkfmvsl",
      "phoneNumberStatus": 777,
      "homeLogoHeight": 77,
      "homeLogoWidth": 777,
      "appAvailableStatus": 0,
      "playStoreUrl": "mfvnsdlfkfmvsl",
      "appStoreUrl": "mfvnsdlfkfmvsl",
      "email": "mfvnsdlfkfmvsl@gmail.com",
      "phoneNumber": 777777,
      "address": "",
      "listingApproval": "0",
      "faviconLogo": "",
      "cancellationInfo": "dvdzddzzz",
      "androidVersion": "fghfgfnf",
      "appForceUpdate": "zxss",
      "iosVersion": "",
      "emailLogo": "",
      "Logo": "",
      "homeLogo": ""
  }; 
 
    if(siteSettings){
      settingsCollection = siteSettings[0]
    }
    // console.log("siteSettings in route",settingsCollection)
    if (loading) {
      return <Loader type={"text"} />;
    } else {
      // siteSettings.map((item, key) => {
      //   settingsCollection[item.name] = item.value;
      // });
      return <SiteSettingsForm initialValues={settingsCollection} />
    }
  }

}

export default compose(
  withStyles(s),
  graphql(siteSettingsQuery,
    {
      options: (props) => ({
        variables: {
          type: 'site_settings',
        },
        fetchPolicy: 'network-only'
      })
    }
  ),
)(SiteSettings);

import React from 'react';
import AdminLayout from '../../../components/Layout/AdminLayout';
import AddCoupon from './addCoupon';
// import fetch from '../../../core/fetch';
const title = 'Add Coupon';

export default async function action({ store }) {
    let isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;
    if (!isAdminAuthenticated) {
        return { redirect: '/siteadmin/login' };
    }

    
    return {
        title,
        component: <AdminLayout> <AddCoupon /> </AdminLayout>,
    };
}

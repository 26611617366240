import React from 'react';
import AdminLayout from '../../../components/Layout/SubadminLayout';
import ViewReservationroute from './ViewReservationroute';
import { restrictUrls } from '../../../helpers/adminPrivileges';


// const title = 'Reservation Details';

export default async function action({ store, params }) {


    // From Redux Store
    let issubAdminAuthenticated = store.getState().runtime.issubAdminAuthenticated;
    let adminPrivileges = store.getState().adminPrevileges.privileges && store.getState().adminPrevileges.privileges.privileges;


    if (!issubAdminAuthenticated) {
        return { redirect: '/subadmin/login' };
    }

    // Admin restriction
    if (!restrictUrls('/subadmin/viewreservation/', adminPrivileges)) {
        return { redirect: '/subadmin' };
    }
    const id = params.id;
    const type = params.type;
    let title = type == 'reservation' ? 'Reservation Details' : 'Payout Details'
    return {
        title,
        component: <AdminLayout><ViewReservationroute id={Number(id)} type={type} /></AdminLayout>,
    };
}

// General
import React from 'react';
import PropTypes from 'prop-types';
import Select from "react-select";
// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import s from '../../components/AffiliateDashboard/AffiliateDashboard.css';
// import bt from '../../components/commonStyle.css';
import DateRange from "../../components/Home/AffiliateDateRange";
import CurrencyConverter from '../../components/CurrencyConverter';
// import DatePicker from 'react-datepicker';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { DateRangePicker } from 'react-dates';
import {
  Row,
  Col,
  Panel,
  Button,
  FormGroup,
  ButtonToolbar,
  DropdownButton,
  MenuItem,
} from "react-bootstrap";
import {
  CChart
} from '@coreui/react-chartjs';

// Components
// import AffiliateDashboard from '../../components/AffiliateDashboard';
// import SocialLogin from '../../components/SocialLogin/SocialLogin';
// import Link from '../../components/Link/Link';
import ClickIcon from '../../../public/SiteIcons/Affiliateclicks.svg'
import DollarIcon from '../../../public/SiteIcons/AffiliateDollar.svg';
import GraphIcon from '../../../public/SiteIcons/Affiliategraph.svg';
import conversion from '../../../public/SiteIcons/conversion.svg';
import revenue from '../../../public/SiteIcons/revenue.svg';
import AffiliateLinkclicks from '../../../public/SiteIcons/AffiliateLinkclicks.svg';
// Translation
import { FormattedMessage, injectIntl } from 'react-intl';

// Locale
import messages from '../../locale/messages';

import totalUser from '../../../public/adminIcons/Totalusers.svg';
// import graphOne from '../../../public/adminIcons/total-user.png';
import totallast from '../../../public/adminIcons/last24hrsusers.svg';
// import graphTwo from '../../../public/adminIcons/last-24-hrs-users.png';
import totalMothe from '../../../public/adminIcons/last30dayusers.svg'; 0
import graphThree from '../../../public/adminIcons/last-30days-users.png';
import motheListing from '../../../public/adminIcons/last30dayslistings.svg';
import hourListing from '../../../public/adminIcons/last24hrslistings.svg';
import totalListing from '../../../public/adminIcons/Totallistings.svg';
import totalListingImage from '../../../public/adminIcons/total-listings-graph.png';
import reservationIcon from '../../../public/adminIcons/reservationicon.svg';
import fetch from "../../core/fetch";


const options = [
  { label: "Day", value: "Day" },
  { label: "Week", value: "Week" },
  { label: "Month", value: "Month" },
  { label: "Date", value: "Date" },
];
const defaultDate = options[0];
const defaultData = {};

const GetDashboardAffiliate = `
query GetDashboardAffiliate($fromDate: String,$toDate: String,$filter: String,$userId: String){
  GetDashboardAffiliate(fromDate:$fromDate,toDate:$toDate,filter:$filter,userId:$userId){
    clickOverviewCount
    clickFilterCount
    conversionOverviewCount
    conversionFilterCount
    earningOverviewCount
    earningFilterCount
    status
    errorMessage
    filter
    graphDataClicks{
          graphDate
          value
      }
      graphDataConversions{
          graphDate
          value
      }
      graphDataEarning{
          graphDate
          value
      }
  }
}
    `;

class ManageInfo extends React.Component {

  constructor() {
    super();
    this.state = {
      graphData: [],
      filter: "Week",
      startDate: null,
      endDate: null,
      selectedOption: options[1],
    };
  }



  async componentWillMount() {
const {userId} = this.props
    const response = await fetch("/graphql", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: GetDashboardAffiliate,
        variables: {
          userId:userId,
          fromDate: this.state.startDate,

          toDate: this.state.endDate,
          filter: this.state.filter,
          graphType: "Clicks",
        },
      }),
    });
    const json = await response.json();
    this.setState({ graphData: json });
    // console.log("api call");
    // .then((data) => {
    //   console.log("page load" + data);
    //   return data.json();
    // })
    // .then((data) => {
    //   this.setState({
    //     graphData: data,
    //   });
    // });
  }

  static propTypes = {
    title: PropTypes.string.isRequired
  };

  handleAPI = async () => {
    const response = await fetch("/graphql", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: GetDashboardAffiliate,
        variables: {
          // userId: "06f50cc0-ca0a-11ed-a51f-1147fb583417",
          fromDate: this.state.startDate,
          toDate: this.state.endDate,
          filter: this.state.filter,
          graphType: "Clicks",
        },
      }),
    });
    const json = await response.json();
    this.setState({ graphData: json });


    // console.log("hamdle api callllllllllllllllllllll");
    // fetch("http://localhost:3001/graphql", {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({
    //     query: GetDashboardAffiliate,
    //     variables: {
    //       userId: "06f50cc0-ca0a-11ed-a51f-1147fb583417",
    //       fromDate: this.state.startDate,
    //       toDate: this.state.endDate,
    //       filter: this.state.filter,
    //       graphType: "Clicks",
    //     },
    //   }),
    // })
    //   .then((data) => {
    //     console.log("page load" + data);
    //     return data.json();
    //   })
    //   .then((data) => {
    //     this.setState({
    //       graphData: data,
    //     });
    //   });
  };

  handleSelct = async (value) => {
    // this.setState({
    //   filter: value.value,
    // });
    const response = await fetch("/graphql", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: GetDashboardAffiliate,
        variables: {
          // userId: "06f50cc0-ca0a-11ed-a51f-1147fb583417",
          fromDate: this.state.startDate,
          toDate: this.state.endDate,
          filter: value.value,
          graphType: "Clicks",
        },
      }),
    });
    const json = await response.json();
    this.setState({ graphData: json, filter: value.value });

  };

  handlePickDate = async (value) => {

    const answer_array = value.replaceAll("'", '').split(" AND ");
    // console.log('dattt'+answer_array);
    // answer_array[0] = "2023-05-01";
    // answer_array[1] = "2023-05-26";
    const response = await fetch("/graphql", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: GetDashboardAffiliate,
        variables: {
          // userId: "06f50cc0-ca0a-11ed-a51f-1147fb583417",
          fromDate: answer_array[0],
          toDate: answer_array[1],
          filter: "Date"
        },
      }),
    });
    const json = await response.json();
    // console.log(json);
    this.setState({
      fromDate: answer_array[0],
      toDate: answer_array[1],
      filter: "Date",
      graphData: json
    });
    // this.handleAPI();
  };


  // handleOptionChange = (selectedOption) => {
  //   this.setState({
  //     selectedOption: selectedOption,
  //   });
  // };


  // handlePickDate = (dateRange) => {
  //   this.setState({
  //     startDate: dateRange.selection.startDate,
  //     endDate: dateRange.selection.endDate,
  //   });
  // };

  // handleSelect = async (selectedOption) => {
  //   const { startDate, endDate } = this.state;

  //   const response = await fetch("/graphql", {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       query: GetDashboardAffiliate,
  //       variables: {
  //         userId: "06f50cc0-ca0a-11ed-a51f-1147fb583417",
  //         fromDate: startDate,
  //         toDate: endDate,
  //         filter: selectedOption.value,
  //         graphType: "Clicks",
  //       },
  //     }),
  //   });

  //   const json = await response.json();
  //   this.setState({ graphData: json, filter: selectedOption.value });
  // };
  // handleOptionChange = (event) => {
  //     this.setState({
  //       selectedOption: event.target.value
  //     });
  //   }









  // handlePickDate = (dateRange) => {
  //   this.setState({
  //     startDate: dateRange.selection.startDate,
  //     endDate: dateRange.selection.endDate,
  //   });
  // };



  render() {

    const { selectedOption } = this.state;


    const { formatMessage } = this.props.intl;
    const { title, graphDataClicks } = this.props;

    let clickCountTotal = 0, conversionCountTotal = 0, earningCountTotal = 0,
      clickCount = 0, conversionCount = 0, earningCount = 0,
      clickData = [], conversionData = [], earningData = [],
      clickKey = [], conversionKey = [], earningKey = [],
      clickValue = [], conversionValue = [], earningValue = [];

    var graphData = this.state.graphData.data;
    let filter = this.state.filter;

    if (graphData != undefined) {

      if (graphData.GetDashboardAffiliate && graphData.GetDashboardAffiliate.status == 200) {

        var data = graphData.GetDashboardAffiliate;

        clickCountTotal = data.clickOverviewCount;
        conversionCountTotal = data.conversionOverviewCount;
        earningCountTotal = data.earningOverviewCount;

        if (data.graphDataClicks.length > 0) {
          data.graphDataClicks.forEach((val, index) => {
            clickKey.push(val.graphDate);
            clickValue.push(val.value);
            clickCount = clickCount + val.value;
          });
        }

        if (data.graphDataConversions.length > 0) {
          data.graphDataConversions.forEach((val, index) => {
            conversionKey.push(val.graphDate);
            conversionValue.push(val.value);
            conversionCount = conversionCount + val.value;
          });
        }

        if (data.graphDataEarning.length > 0) {
          data.graphDataEarning.forEach((val, index) => {
            earningKey.push(val.graphDate);
            earningValue.push(val.value);
            earningCount = earningCount + val.value;
          });
        }
      }
      // console.log("api call state");
    }
    let filteroption = {};
    if (filter == "Week") {
      filteroption = options[1]
    }
    else if (filter == "Day") {
      filteroption = options[0]
    }
    else if (filter == "Date") {
      filteroption = options[3]
    }
    else if (filter == "Month") {
      filteroption = options[2]
    }
    return (

      //  <div className={cx(s.pagecontentWrapper, s.bgColor, ' pagecontentAR', 'bgBlack')}  style={{marginLeft:"0px" }}>
      <div className={cx(s.pagecontentWrapper, s.bgColor, ' pagecontentAR', 'bgBlack')}>
        <div>
          <h1 className={cx(s.headerTitle, 'textWhite')}><FormattedMessage {...messages.adminDashboard} /></h1>
          <div className={s.forMobile}>
            {/* <Row  className={s.forMobile}> */}
            {/* <Col lg={2} md={2} sm={12} xs={12}> */}
            {/* <div className={cx(s.userBox1, 'bgBlackTwo')} style={{ display: "flex",background: "linear-gradient(to right,#F4970D,#EDB35D,#EFD4AC)",borderRadius:"30px" }}>
                           
                                <div className={cx(s.userContent)}>
                                    <h2><FormattedMessage {...messages.Totalconversions} /></h2>
                                    <div>
                                    <span>
                                      {conversionCountTotal !== undefined ? conversionCountTotal: "0"}
                            </span>
                            </div>
                                </div>

                            </div> */}
            <div className={cx(s.userBox1, 'bgBlackTwo')}>
              {/* <div className={cx(s.userImg)}>
                                    <img src={totalUser} />
                                </div> */}
              <div className={cx(s.userContent)}>
                <h1><FormattedMessage {...messages.Totalconversions} /></h1>
                <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: "30px" }}>
                  <img src={conversion} width={"15%"} alt="reservationIcon" />
                  <h2> {conversionCountTotal !== undefined ? conversionCountTotal : "0"} </h2>
                </div>
              </div>

            </div>

            {/* </Col> */}
            {/* <Col lg={2} md={2} sm={12} xs={12}> */}
            {/* <div className={cx(s.userBox1, 'bgBlackTwo')} style={{ display: "flex",	background: "linear-gradient(to right,#58B605,#66BD60,#B5F3B0)" , borderRadius:"30px"}}>
                                
                                <div className={cx(s.userContent)}>
                                    <h2><FormattedMessage {...messages.Totalearning} /> &nbsp;</h2>
                                    <div>
                                    <span>
                                    {
                                          <CurrencyConverter
                                            amount={earningCountTotal !== undefined && earningCountTotal !== null   ? earningCountTotal: "0"}
                                            from={"USD"}
                                          />
                                    }
                                    </span>
                                    </div>
                                </div>
                            </div> */}
            <div className={cx(s.userBox2, 'bgBlackTwo')} >
              {/* <div className={cx(s.userImg)}>
                                    <img src={totalUser} />
                                </div> */}
              <div className={cx(s.userContent)}>
                <h1><FormattedMessage {...messages.Totalearning} /> &nbsp;</h1>
                {/* <h2>Total &nbsp; &nbsp; &nbsp; Earnings</h2> */}
                {/* <div> */}
                {/* <span> */}

                {/* {earningCountTotal !== undefined && earningCountTotal !== null   ? earningCountTotal: "0"} */}
                {/* </span> */}
                {/* </div> */}
                <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: "30px" }}>
                  <img src={revenue} width={"15%"} alt="reservationIcon" />
                  <h2> {
                    <CurrencyConverter
                      amount={earningCountTotal !== undefined && earningCountTotal !== null ? earningCountTotal : "0"}
                      from={"USD"}
                    />
                  } </h2>
                </div>
              </div>
              {/* <div className={s.graph}>
                                    <img src={graphOne} />
                                </div> */}
            </div>
            {/* </Col> */}
            {/* <Col lg={2} md={2} sm={12} xs={12}> */}
            {/* <div className={cx(s.userBox1, 'bgBlackTwo')} style={{ display: "flex",	background: "linear-gradient(to right,#1489E4,#A5CEED)" ,borderRadius:"30px"}}>
                                <div className={cx(s.userContent)}>
                                    <h2><FormattedMessage {...messages.Totalclicks} /></h2>
                                    <div><span>{clickCountTotal !== undefined ? clickCountTotal: "0"}</span></div>
                                </div>
                                
                            </div> */}
            <div className={cx(s.userBox3, 'bgBlackTwo')} >
              {/* <div className={cx(s.userImg)}>
                                    <img src={totallast} />
                                </div> */}
              <div className={cx(s.userContent)}>
                {/* <img src={totallast} /> */}
                <h1><FormattedMessage {...messages.Totalclicks} /></h1>
                {/* <h2>Total &nbsp; Clicks</h2> */}
                {/* <div><span>{clickCountTotal !== undefined ? clickCountTotal : "0"}</span></div> */}
                <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: "30px" }}>
                  <img src={AffiliateLinkclicks} width={"15%"} alt="reservationIcon" />
                  <h2> {clickCountTotal !== undefined ? clickCountTotal : "0"} </h2>
                </div>
              </div>
              {/* <div className={s.graph}>
                                    <img src={graphTwo} />
                                </div> */}
            </div>
            {/* </Col> */}

            {/* </Row> */}
          </div>
          {/* <Col mdOffset={1} lg={4} md={4} sm={12} xs={12} className={s.dateRangeMobile}> */}
            {/* <Row> */}
            <div className={s.dateFilter}>
              <span className={cx('homeDate', s.formControlInput, s.input, 'homeDateAR', 'headerSearchDate')}>
                <Select
                  defaultValue="Week"
                  options={options}
                  onChange={this.handleSelct}
                  value={filteroption}
                  name="filterSelect"
                />
              </span>
              </div>
            {/* </Row> */}
            {/* <Row> */}
              {filter !== 'Date' && (
                <div style={{ visibility: 'hidden' }}>
                  Hidden when 'Show' is not selected
                </div>
              )}
              {filter === 'Date' && (
                <div className={s.dateRange}>
                <DateRange
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  onChange={this.handlePickDate}
                  isOutsideRange={() => false}
                />
              </div>
              )}

            {/* </Row> */}

          {/* </Col> */}

          {/* <Row style={{ "margin-top": "30px" }}> */}
          <Row>
            <Panel>
              <Panel.Heading>Conversions</Panel.Heading>
              <Panel.Body className={s.panelBodyMobile}>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <CChart className={s.charts}
                    // type="bar"
                    data={{
                      labels: conversionKey,
                      datasets: [
                        {
                          label: 'Total Conversion',
                          backgroundColor: '#ffa500',
                          data: conversionValue,
                          barThickness: 20,

                        },
                      ],
                    }}


                    labels="weekly"
                  />
                </Col>
                {/* <Row className={s.listingSectionMain}> */}
                <Col lg={6} md={6} sm={12} xs={12} className={s.mobileMargin}>

                  <div className={cx(s.motheListing, 'bgBlackTwo')}>
                    <div className={cx(s.displayInline, s.listingImg, s.displayBlock, s.pricepart)}>
                      <img style={{ padding: "15px" }} src={GraphIcon} />
                    </div>

                    <div style={{ marginLeft: "13px", boxShadow: '0px 1px 5px 0px gray', borderRadius: "20px", height: 'auto' }} className={cx(s.displayInline, s.listingContent, s.displayBlock, 'listContentAR')}>
                      <div className={s.pricefont}>{conversionCount !== undefined ? conversionCount : "0"}</div>
                      <h5>Total Conversions</h5>

                    </div>
                  </div>
                </Col>
                {/* </Row> */}


              </Panel.Body>
            </Panel>
          </Row>
          <Row>
            <Panel>
              <Panel.Heading>Earning</Panel.Heading>
              <Panel.Body className={s.panelBodyMobile}>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <CChart className={s.charts}
                    // type="bar"
                    data={{
                      labels: earningKey,
                      datasets: [
                        {
                          label: 'Total Earning',
                          backgroundColor: '#2BA70A',
                          data: earningValue,
                          barThickness: 20,
                        },
                      ],
                    }}
                    options={{
                      scales: {
                        x: {
                          grid: {
                            display: false
                          }
                        },
                        y: {
                          grid: {
                            display: false
                          }
                        }
                      }
                    }}
                    labels="weekly"
                  />
                </Col>
                {/* <Row className={s.listingSectionMain}> */}
                <Col lg={6} md={6} sm={12} xs={12} className={s.mobileMargin}>
                  <div className={cx(s.motheListing, 'bgBlackTwo')}>

                    <div className={cx(s.displayInline, s.listingImg, s.displayBlock, s.pricepart)}>
                      <img style={{ padding: '15px' }} src={DollarIcon} />
                    </div>

                    <div style={{ marginLeft: "13px", boxShadow: '0px 1px 5px 0px gray', borderRadius: "20px", height: 'auto' }} className={cx(s.displayInline, s.listingContent, s.displayBlock, 'listContentAR')}>
                      <div className={s.pricefont}>{earningCount !== undefined && earningCount !== null ? earningCount : "0"}</div>
                      <h5>Total Earning</h5>

                    </div>
                  </div>
                </Col>
                {/* </Row> */}

              </Panel.Body>
            </Panel>
          </Row>
          <Row>
            <Panel>
              <Panel.Heading>Clicks</Panel.Heading>
              <Panel.Body className={s.panelBodyMobile}>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <CChart className={s.charts}
                    // type="bar"
                    data={{
                      labels: clickKey,
                      datasets: [
                        {
                          label: 'Total Click',
                          backgroundColor: '#0000FF',
                          data: clickValue,
                          barThickness: 20,
                        },
                      ],
                    }}

                    labels="weekly"
                  />
                </Col>
                {/* <Row className={s.listingSectionMain}> */}
                <Col lg={6} md={6} sm={12} xs={12} className={s.mobileMargin}>
                  <div className={cx(s.motheListing, 'bgBlackTwo')}>
                    <div className={cx(s.displayInline, s.listingImg, s.displayBlock, s.pricepart)}>
                      <img style={{ padding: '15px' }} src={ClickIcon} />
                    </div>
                    <div style={{ marginLeft: "13px", boxShadow: '0px 1px 5px 0px gray', borderRadius: "20px", height: 'auto' }} className={cx(s.displayInline, s.listingContent, s.displayBlock, 'listContentAR')}>
                      <div className={s.pricefont}>{clickCount !== undefined ? clickCount : "0"}</div>
                      <h5>Total Cilcks</h5>

                    </div>
                  </div>
                </Col>
                {/* </Row> */}

              </Panel.Body>
            </Panel>
          </Row>


          {/* </Row> */}

        </div>
      </div>
      //  </div>
      //  {/* </div> */}
      //  </div>


      // </div>
    );
  }
}
const selector = formValueSelector('ManageInfo'); // <-- same as form name
const mapState = state => ({

  startDate: selector(state, 'startDate'),
  endDate: selector(state, 'endDate'),
});

export default injectIntl(withStyles(s)(ManageInfo));


// import React from "react";
// import { FormattedMessage, injectIntl } from 'react-intl';
// import withStyles from 'isomorphic-style-loader/lib/withStyles';
// import s from './ManageCoupon.css';
// import { connect } from "react-redux";
// import { graphql, compose } from 'react-apollo';
// import ManageListingsQuery from './manageListing.graphql'
// import PropTypes from "prop-types";
// import cx from 'classnames';
// import {
//     Grid,
//     Row,
//     Col,
//     Panel,
//     Tooltip
// } from 'react-bootstrap';
// import SideMenu from '../ManageListing/SideMenu/SideMenu';
// import messages from '../../locale/messages';
// import st from "./ManageCoupon.css"
// import { FormGroup, Modal, Button, FormControl, ControlLabel, HelpBlock, FieldGroup, InputGroup } from "react-bootstrap";
// import { toastr } from 'react-redux-toastr';
// import DateRange from "../../components/Home/AffiliateDateRange";
// import moment from 'moment';
// const SEND_MODAL_DATA = `mutation Createcouponcode($couponCode:String,$listId:Int,$couponType:String,$discount:Float,$startDate:String,$endDate:String,$description:String,$userType:String){
//   Createcouponcode(couponCode:$couponCode,listId:$listId,couponType:$couponType,discount:$discount,startDate:$startDate,endDate:$endDate,description:$description,userType:$userType){
//       status
//       errorMessage
//       }
//   }`;
//   const  DELETE_COUPON_QUERY = `mutation ($id: Int!,$couponCode:String) {
//     deleteCoupon (id: $id,couponCode:$couponCode) {
//       status
//       errorMessage
//     }
//   }`
//   const GET_COUPONCODE = `query getcouponcode($userId: String,$couponType:String,$listId:Int){
//     getcouponcode(userId:$userId,couponType:$couponType,listId:$listId){
//         errorMessage
//         status
//         data{
//           id
//         couponCode,
//         activeStatus,
//             couponType,
//             discount,
//             startDate,
//             endDate,
//             description,
//             userType
//         }
//     }
//   }`
// class ManageCoupon extends React.Component {
//      static propTypes = {
//     userId: PropTypes.string,
//   };
//   static defaultProps = {
//     userId: "",
//   };
//     constructor(props) {
//         super(props);
//         this.state = {
//           couponName: "",
//           couponDiscount: 0,
//           startDate: "",
//           endDate: "",
//           couponDesc: "",
//           searchList:"",
//           creatDate :"",
//           expDate:"",
//           couponId:"",
//           updateOrDelete:false
    
//         };
//         // this.handleChange = this.handleChange.bind(this);
//         // this.paginationData = this.paginationData.bind(this);
//         // this.handleSearchChange = debounce(this.handleSearchChange.bind(this));
//         // this.handleDropDown = this.handleDropDown.bind(this);
//         // this.handleClose = this.handleClose.bind(this);
//         // this.handleShow = this.handleShow.bind(this);
//         this.handleDeleteCoupon = this.handleDeleteCoupon.bind(this);
//       }
//        handleModalSubmit = async () => {
//         const { startDate, endDate, couponName, couponDesc, couponDiscount } = this.state;
//         if(!couponName){
//           toastr.error("Opps!", "Cannot submit without name");
//           return;
//         }else if(!couponDiscount){
//           toastr.error("Opps!", "Cannot submit without coupon Discount");
//           return;
//         }else if(!startDate){
//           toastr.error("Opps!", "Cannot submit without start Date");
//           return;
//         }else if(!endDate){
//           toastr.error("Opps!", "Cannot submit coupon without end Date");
//           return;
//         }
//         const {listId} = this.props;
//         const resp = await fetch('/graphql', {
//           method: 'post',
//           headers: {
//             Accept: 'application/json',
//             'Content-Type': 'application/json'
//           },
//           body: JSON.stringify({
//             query: SEND_MODAL_DATA,
//             variables: {
              
//               listId:listId,
//               userType:"host",
//               couponCode: couponName,
//               couponType:"booking",
//               discount: couponDiscount,
//               startDate: startDate,
//               endDate: endDate,
//               description: couponDesc
//             }
//           }),
//         });
//         const { data } = await resp.json();
//         this.setState({ couponCode: "", couponType: "", couponDiscount: "", startDate: "", endDate: "", couponDesc: "" })
  
//         if (data.Createcouponcode.status == 200) {
//           toastr.success("Success!", "Promo code coupon added successfully");
//         }
//         //console.log(data, "SEND_MODAL_DATA");
       
//       }
//       handlePickDate = async (value) => {
//         const answer_array = value.replaceAll("'", '').split(" AND ");
//         console.log(answer_array, "value");
//         this.setState({ startDate: answer_array[0], endDate: answer_array[1] })
    
//       };
//       async     handleDeleteCoupon(){
//         const {couponName,couponId} = this.state;
//         // const {couponId} = this.props;
//         const resp = await fetch('/graphql', {
//           method: 'post',
//           headers: {
//             Accept: 'application/json',
//             'Content-Type': 'application/json'
//           },
//           body: JSON.stringify({
//             query: DELETE_COUPON_QUERY,
//              variables: {
//               id:couponId,
//               couponCode:couponName
//               }
//           }),
//         });
//         const { data } = await resp.json();
//         if(data.deleteCoupon.status == 200){
//         toastr.success("Success!", "Coupon code deleted successfully");
        
//         this.setState({
//         couponName:"",
//         couponDiscount:"",
//         startDate:"",
//         endDate:"",
//         couponDesc:"",
//         startDate:"",
//         endDate:""
            
//         })
//         }

//         console.log("DELETE_COUPON_QUERY",data)
//         console.log("couponName",couponId)
//       }
// async componentDidMount(){
//         const {listId,userId } = this.props;
//         const resp = await fetch('/graphql', {
//           method: 'post',
//           headers: {
//             Accept: 'application/json',
//             'Content-Type': 'application/json'
//           },
//           body: JSON.stringify({
//             query: GET_COUPONCODE,
//              variables: {
//               userId:userId,
//               couponType:"booking",
//               listId:listId
//             }
//           }),
//         });
//         const { data } = await resp.json();
//         if(data.getcouponcode.status == 200){
//                   this.setState({
//                     updateOrDelete:true,
//                     couponId:data.getcouponcode.data[0].id,
//              couponName:data.getcouponcode.data[0].couponCode,
//             couponDiscount:data.getcouponcode.data[0].discount,
//             creatDate:moment(data.getcouponcode.data[0].startDate).format('DD-MM-YYYY'),
//             expDate:moment(data.getcouponcode.data[0].endDate).format('DD-MM-YYYY'),
//             couponDesc:data.getcouponcode.data[0].description})
//         }
//         console.log("userId datatatatatatatata",data);
//         console.log("userId datatatatatatatata",listId);
    
//       }
//     render() {
//         const { startDate, endDate, couponName,couponDesc,couponDiscount,creatDate,expDate,updateOrDelete } = this.state;
//         const {listId,userId} = this.props;
//         const { ManageListingsData, ManageListingsData: { ManageListings } } = this.props;

//         return (
//             <>
//                 <Grid fluid className={'dashBoardContainer'}>
//                     <Row>
//                         <Col xs={12} sm={12} md={12} lg={12}>
//                           <div className={'dashBoardListingGrid'}>
//                                 <SideMenu data={ManageListingsData}/>
//                                 <div className={cx('commonListingBg', 'tripContainer')}>
//                                     <Panel className={cx(s.panelHeader, 'bgBlack')}>
//                                         <div className={cx('listingTab', s.panelBorder, 'listingMobileTab', 'tabReservation')}>
//                                         { updateOrDelete ?  <h1> <FormattedMessage {...messages.updateCoupon} /> </h1>  : <h1> <FormattedMessage {...messages.manageCoupon} /> </h1>}
//                                         </div>
//                                  <form>
//                                       <FormGroup
//                                       controlId="formBasicText"
//                                       >
//                                         <ControlLabel className={st.formLabel}>PROMO CODE NAME</ControlLabel>
//                                                         <FormControl
//                                                             type="text"
//                                                             minLength={8}
//                                                             maxLength={12}
//                                                             placeholder="Enter Promo code name"
//                                                             value={couponName}
//                                                             onInput={(e) => this.setState({ couponName: e.target.value.replace(/\s+/g, '').toUpperCase() })}
//                                                         // onInput={(e)=>this.setState({couponName:e.target.value.toUpperCase()})}
//                                                         />
//                                                         <br />
//                                                         <div style={{ display: "flex", justifyContent: "space-evenly", gap: "10px" }}>
//                                                             <div style={{ width: "60%" }}>
//                                                             <ControlLabel className={st.formLabel}>PROMO CODE DISCOUNT</ControlLabel>
//                                                         <InputGroup>
//                                                             <FormControl
//                                                                 type="text"
//                                                                 step=".01"
//                                                                 placeholder="Enter Promo code discount"
//                                                                value={couponDiscount}
//                                                                 onInput={(e) => this.setState({ couponDiscount: e.target.value })}
//                                                             />
//                                                             <InputGroup.Addon className={s.prefixIcon}>{"%"}</InputGroup.Addon>
//                                                         </InputGroup>
//                                                             </div>
//                                                             <div style={{ width: "60%" }}>
//                                                                 <ControlLabel className={st.formLabel}>PROMO CODE START & END DATE</ControlLabel>
//                                                                 <DateRange
//                                                                     startDate={creatDate ? creatDate :startDate}
//                                                                     endDate={expDate ? expDate :   endDate}
//                                                                     onChange={this.handlePickDate}
//                                                                     isOutsideRange={() => false}
//                                                                 />
//                                                             </div>
//                                                             <br />

//                                                         </div>
//                                                         <br />
//                                                         <ControlLabel className={st.formLabel}>PROMO CODE DESCRIPTION</ControlLabel>
//                                                         <FormControl
//                                                             type="text"
//                                                             value={couponDesc}
//                                                             onInput={(e) => this.setState({ couponDesc: e.target.value })}
//                                                             placeholder="Enter Promo code description"
//                                                         />
//                                                         <br />
//                                                         <FormControl.Feedback />
//                                                         <ControlLabel className={st.formLabel}>Here's go the promo code  description</ControlLabel>
//                                                     </FormGroup>
//                                                     <div style={{display:"flex",gap:"25px"}}>
//                                                     <Button bsStyle="info" onClick={this.handleModalSubmit} disabled={!couponName && !endDate}> {updateOrDelete ? "Update" : "Submit"} </Button>
//                                                     { updateOrDelete  &&   <Button style={{color:"white",backgroundColor:"#E45454"}} onClick={this.handleDeleteCoupon}>Delete</Button> }
//                                                     </div>
//                                 </form>
//                                     </Panel>
//                                 </div>
//                             </div>
//                         </Col>
//                     </Row>
//                 </Grid>
//             </>
//         )
//     }

// }
// const mapState = (state) => ({
//   userId: state.user.id,
// });

// export default compose(
//   injectIntl,
//   withStyles(s), // Define your styles or import from a separate file
//   graphql(ManageListingsQuery, {
//     name: 'ManageListingsData',
//     options: {
//       ssr: false,
//       fetchPolicy: 'network-only',
//     },
//   }),
//   connect(mapState)
// )(ManageCoupon);

import React from "react";
import { FormattedMessage, injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './ManageCoupon.css';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import cx from 'classnames';
import {
    Grid,
    Row,
    Col,
    Panel,
    Tooltip
} from 'react-bootstrap';
import SideMenu from '../ManageListing/SideMenu/SideMenu';
import messages from '../../locale/messages';
import st from "./ManageCoupon.css"
import { FormGroup, Modal, Button, FormControl, ControlLabel, HelpBlock, FieldGroup, InputGroup } from "react-bootstrap";
import { toastr } from 'react-redux-toastr';
import DateRange from "../../components/Home/AffiliateDateRange";
import moment from 'moment';
const SEND_MODAL_DATA = `mutation Createcouponcode($couponCode:String,$listId:Int,$couponType:String,$discount:Float,$startDate:String,$endDate:String,$description:String,$userType:String){
  Createcouponcode(couponCode:$couponCode,listId:$listId,couponType:$couponType,discount:$discount,startDate:$startDate,endDate:$endDate,description:$description,userType:$userType){
      status
      errorMessage
      }
  }`
const  DELETE_COUPON_QUERY = `mutation ($id: Int!,$couponCode:String) {
  deleteCoupon (id: $id,couponCode:$couponCode) {
    status
    errorMessage
  }
}`

const GET_COUPONCODE = `query getcouponcode($userId: String,$couponType:String,$listId:Int){
  getcouponcode(userId:$userId,couponType:$couponType,listId:$listId){
      errorMessage
      status
      data{
          id
          couponCode,
          activeStatus,
          couponType,
          discount,
          startDate,
          endDate,
          description,
          userType
      }
  }
}`

const UPDATE_DELETE = `query getCouponEdit($couponType:String,$listId:Int,$subscriptionType:String){
  getCouponEdit(couponType:$couponType,listId:$listId,subscriptionType:$subscriptionType){
      errorMessage
      status
      data{
          id,
      couponCode,
      activeStatus,
          couponType,
          discount,
          startDate,
          endDate,
          description,
          userType,
      }
  }
}`
class ManageCoupon extends React.Component {
     static propTypes = {
    userId: PropTypes.string,
  };
  static defaultProps = {
    userId: "",
  };
    constructor(props) {
        super(props);
        this.state = {
          couponName: "",
          couponDiscount: "",
          startDate: null,
          endDate: null,
          couponDesc: "",
          searchList:"",
          couponId:0,
          updateOrDelete:false
    
        };
        this.handleDeleteCoupon = this.handleDeleteCoupon.bind(this);
    
      }
      handleModalSubmit = async () => {
        const { startDate, endDate, couponName, couponDesc, couponDiscount } = this.state;
      
        if (!couponName) {
          toastr.error("Opps!", "Cannot submit without name");
          return; // Exit function early if coupon name is missing
        }
      
        if (
          !couponDiscount ||
          isNaN(couponDiscount) ||
          !(Number(couponDiscount) > 0 && Number(couponDiscount) < 100)
        ) {
          toastr.error("Opps!", "Invalid coupon discount value. Please enter a number between 0 and 100.");
          return; // Exit function early if coupon discount is invalid
        }
      
        if (!startDate || startDate == null || startDate == '') {
          toastr.error("Opps!", "Cannot submit without start Date");
          return; // Exit function early if start date is missing
        }
      
        if (!endDate || endDate == null || endDate == '') {
          toastr.error("Opps!", "Cannot submit coupon without end Date");
          return; // Exit function early if end date is missing
        }
      
        // If all validations pass, proceed with the API call
        const { listId } = this.props;
        const resp = await fetch('/graphql', {
          method: 'post',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            query: SEND_MODAL_DATA,
            variables: {
              listId: listId,
              userType: "host",
              couponCode: couponName,
              couponType: "booking",
              discount: couponDiscount,
              startDate: startDate,
              endDate: endDate,
              description: couponDesc
            }
          }),
        });
        const { data } = await resp.json();
        if (data.Createcouponcode.status == 200) {
          toastr.success("Success!", "Promo code coupon added successfully");
          this.setState({ couponName, couponType: "booking", couponDiscount, startDate:startDate, endDate:endDate, couponDesc, updateOrDelete: true });
          window.location.href = `/manage-coupon/${listId}`
        }
      }
      
      handlePickDate = async (value) => {
        const answer_array = value.replaceAll("'", '').split(" AND ");
        // console.log(answer_array, "value");
        this.setState({ startDate: answer_array[0], endDate: answer_array[1] })
    
      };
      
async  componentDidMount(){
    const {listId,userId } = this.props;
    //////////////////////////////////////

    
    // console.log("initialValuesinitialValuesinitialValues",initialValues)
    // let hostID = initialValues.hostId
    const resp = await fetch('/graphql', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query: UPDATE_DELETE,
         variables: {
          couponType:"booking",
          listId:listId,
          subscriptionType:""
      }
      }),
    });
    const { data } = await resp.json();
    if(data.getCouponEdit.status == 200){
              this.setState({
                  updateOrDelete:true,
                  couponId:data.getCouponEdit.data[0].id,
         couponName:data.getCouponEdit.data[0].couponCode,
        couponDiscount:data.getCouponEdit.data[0].discount,
         startDate:moment(data.getCouponEdit.data[0].startDate).format("YYYY-MM-DD"),
        //  startDate:data.getCouponEdit.data[0].startDate,
        //  startDate:moment(data.getCouponEdit.data[0].startDate).format('DD-MM-YYYY'),moment(data.getCouponEdit.data[0].startDate).format("YYYY-MM-DD")
        endDate:moment(data.getCouponEdit.data[0].endDate).format("YYYY-MM-DD"),
        // endDate:data.getCouponEdit.data[0].endDate,
        couponDesc:data.getCouponEdit.data[0].description})
    }
    // console.log("userId UPDATE_DELE/TE",data);
    // console.log("userId datatatatatatatata",couponId);

  }
  
async     handleDeleteCoupon(){
        const {couponName,couponId} = this.state;
        const {listId} = this.props;
        // const {couponId} = this.props;
        const resp = await fetch('/graphql', {
          method: 'post',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            query: DELETE_COUPON_QUERY,
             variables: {
              id:couponId,
              couponCode:couponName
              }
          }),
        });
        const { data } = await resp.json();
        if(data.deleteCoupon.status == 200){
        toastr.info("Success!", "Coupon deleted successfully");
        
        this.setState({
        updateOrDelete:false,
        couponName:"",
        couponDiscount:"",
        startDate:null,
        endDate:null,
        couponDesc:"",
            
        })
        // console.log("DELETE_COUPON_QUERY",data)
        // console.log("couponName",couponId)
        window.location.href= `/manage-coupon/${listId}`
      }
      }
    render() {
        const { startDate, endDate, couponName,couponDesc,couponDiscount,updateOrDelete } = this.state;
        const {listId,userId} = this.props;
    // console.log("render datatatatatatatata",endDate);
    // console.log("render datatatatatatatata",startDate)
        // const { formatMessage } = this.props.intl;

        return (
            <>
                <Grid fluid className={'dashBoardContainer'}>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                          <div className={'dashBoardListingGrid'}>
                                <SideMenu />
                                <div className={cx('commonListingBg', 'tripContainer')}>
                                    <Panel className={cx(s.panelHeader, 'bgBlack')}>
                                        <div className={cx('listingTab', s.panelBorder, 'listingMobileTab', 'tabReservation')}>
                                   { updateOrDelete  ?  <h1> <FormattedMessage {...messages.updateCoupon} /> </h1> :   <h1> <FormattedMessage {...messages.manageCoupon} /> </h1>}
                                        </div>
                                 <form>
                                      <FormGroup
                                      controlId="formBasicText"
                                      >
                                        <ControlLabel className={st.formLabel}>PROMO CODE NAME</ControlLabel>
                                                        <FormControl
                                                            type="text"
                                                            minLength={8}
                                                            maxLength={12}
                                                            placeholder="Enter Promo code name"
                                                            value={couponName}
                                                            onInput={(e) => this.setState({ couponName: e.target.value.replace(/\s+/g, '').toUpperCase() })}
                                                        // onInput={(e)=>this.setState({couponName:e.target.value.toUpperCase()})}
                                                        />
                                                        <br />
                                                        <div style={{ display: "flex", justifyContent: "space-evenly", gap: "10px" }}>
                                                            <div style={{ width: "60%" }}>
                                                            <ControlLabel className={st.formLabel}>PROMO CODE DISCOUNT</ControlLabel>
                                                        <InputGroup>
                                                            <FormControl
                                                                type="text"
                                                                step=".01"
                                                                placeholder="Enter Promo code discount"
                                                                value={couponDiscount}
                                                                onInput={(e) => this.setState({ couponDiscount: e.target.value })}
                                                            />
                                                            <InputGroup.Addon className={s.prefixIcon}>{"%"}</InputGroup.Addon>
                                                        </InputGroup>
                                                            </div>
                                                            <div style={{ width: "60%" }}>
                                                                <ControlLabel className={st.formLabel}>PROMO CODE START & END DATE</ControlLabel>
                                                                <DateRange
                                                                    startDate={startDate}
                                                                    endDate={endDate}
                                                                    onChange={this.handlePickDate}
                                                                    // isOutsideRange={() => false}
                                                                   
                                                                />
                                                            </div>
                                                            <br />

                                                        </div>
                                                        <br />
                                                        <ControlLabel className={st.formLabel}>PROMO CODE DESCRIPTION</ControlLabel>
                                                        <FormControl
                                                            type="text"
                                                            value={couponDesc}
                                                            onInput={(e) => this.setState({ couponDesc: e.target.value })}
                                                            placeholder="Enter Promo code description"
                                                        />
                                                        <br />
                                                        <FormControl.Feedback />
                                                        <ControlLabel className={st.formLabel}>Here's go the promo code page description</ControlLabel>
                                                    </FormGroup>
                                                    
                                                    <div style={{display:"flex",gap:"25px"}}>
                                                    <Button bsStyle="info" onClick={this.handleModalSubmit} disabled={!couponName && !endDate}> {updateOrDelete ? "Update" : "Submit"} </Button>
                                                  
                                                    </div>
                                </form>
                                { updateOrDelete  &&   <Button style={{color:"white",backgroundColor:"#E45454",marginTop:"10px",padding:"6px 15px 6px 15px"}} onClick={this.handleDeleteCoupon}>Delete</Button> }
                                    </Panel>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Grid>
            </>
        )
    }

}
const mapState = (state) => ({
  userId: state.user.id,
});

export default injectIntl(withStyles(s)(connect(mapState)(ManageCoupon)));
// export default (injectIntl,withStyles(s,st),(ManageCoupon));
// export default injectIntl(withStyles(s,st)(ManageCoupon));
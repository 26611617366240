import React, { Component } from "react";

import { Table, Tr, Td, Thead, Th } from "reactable";
import { FormControl, FormGroup } from "react-bootstrap";

// Style
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./affiliateDataTable.css";
import bt from "../../commonStyle.css";
import CustomPagination from "../../CustomPagination/CustomPagination";
import { graphql, gql, compose } from 'react-apollo';
import subscriptionUsers from './subscriptionUsers.graphql';

// Translation
import { FormattedMessage, injectIntl } from "react-intl";
import messages from "../../../locale/messages";

// Image
import ExportImage from "../../../../public/adminIcons/export.png";
import { debounce } from "../../../helpers/debounce";


class ManageSubscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      status: "",
      typingTimeout: 0,
    };
    this.handleChange = this.handleChange.bind(this);
    this.paginationData = this.paginationData.bind(this);
    this.handleSearchChange = debounce(this.handleSearchChange.bind(this));
    this.handleDropDown = this.handleDropDown.bind(this);
  }

  handleChange(e) {
    // console.log("Value",e.target.value );
    let affiliateStatus = e.target.value;
  }
  // FusionCharts(currentOptions);
  
  handleDropDown(e) {
    const { getSubscriptionInfo: { refetch }, setStateVariable } = this.props
    // console.log("Valueeeeeeee",e.target.value);
    let variables = {
      // searchList:'"'+ e.target.value+'"',
      searchList: e.target.value,
      currentPage: 1
    };
    this.setState({status:e.target.value})
    setStateVariable({ searchList: e.target.value, currentPage: 1 });
    refetch(variables)
  }

  paginationData(currentPage) {
    const { getSubscriptionInfo: { refetch }, setStateVariable } = this.props;
    let variables = { currentPage };
    setStateVariable({ currentPage });
    refetch(variables);
  }


  handleSearchChange(searchList) {
    const { getSubscriptionInfo: { refetch }, setStateVariable } = this.props;
    let variables = {
      currentPage: 1,
      searchList
    };
    setStateVariable(variables);
    refetch(variables);
  }

  render() {
    const { formatMessage } = this.props.intl;
    const { getSubscriptionInfo: { getSubscriptionInfo } } = this.props;
    const { currentPage, searchList } = this.props;
    const { status } = this.state;
    const total = getSubscriptionInfo && getSubscriptionInfo.count

    // console.log(getSubscriptionInfo,"status");

    return (
      <div className={cx(s.pagecontentWrapper, 'pagecontentAR')}>
        <div>
          <h1 className={s.headerTitle}>
            <FormattedMessage {...messages.subscriptionManagement} />
          </h1>
          <div className={cx(s.exportSection, "bgBlack")}>
            <div>
              <FormGroup className={s.noMargin}>
                <FormControl
                  type="text"
                  placeholder={formatMessage(messages.search)}
                  onChange={(e) =>
                    this.handleSearchChange(e.target && e.target.value)
                  }
                  className={cx("searchInputControl", "searchInputControlAR")}
                />
              </FormGroup>
            </div>
            <div>
              <FormGroup controlId="formControlsSelect" className={s.noMargin}>
                <FormControl
                  onChange={(e) => this.handleDropDown(e)}
                  value={searchList}
                  componentClass="select"
                  placeholder={formatMessage(messages.selectLabel)}
                  className={cx(
                    bt.commonControlSelect,
                    s.userSelect,
                    "rtlUserSelect"
                  )}
                >
                  <option value="">{formatMessage(messages.allLabel)}</option>
                  <option value="Economy">{formatMessage(messages.economy)}</option>
                  <option value="Recommended">{formatMessage(messages.recommended)}</option>
                  <option value="Gold">{formatMessage(messages.gold)}</option>
                  <option value="Platinum">{formatMessage(messages.platinum)}</option>
                </FormControl>
              </FormGroup>
            </div>
            <div>
              {getSubscriptionInfo && getSubscriptionInfo.length > 0 && (
                <div
                  className={cx(s.exportText, "commonFloatLeft", "textWhite")}
                >
                  <span className={s.vtrMiddle}>
                    <FormattedMessage {...messages.exportDataIntoCSV} />
                  </span>
                  <span className={cx(s.exportLinkImg, "exportLinkImgCommon")}>
                    <img src={ExportImage} className={s.exportImg} />
                  </span>
                </div>
              )}
            </div>
          </div>
          <div
            className={cx(
              "table-responsive",
              "NewAdminResponsiveTable",
              "NewResponsiveTableAdmin"
            )}
          >
            <Table
              className="table"
              noDataText={formatMessage(messages.noRecordFound)}
              sortable={true}
              // defaultSort={{ column: 'Profile ID', direction: 'desc' }}
            >
              <Thead>
                <Th scope="col">{formatMessage(messages.subName)}</Th>
                <Th scope="col">{formatMessage(messages.transactionID)}</Th>
                <Th scope="col">{formatMessage(messages.planName)}</Th>
                <Th scope="col">{formatMessage(messages.purchaseData)}</Th>
                <Th scope="col">{formatMessage(messages.expireData)}</Th>
                <Th scope="col">{formatMessage(messages.subStatus)}</Th>
              </Thead>
              {getSubscriptionInfo && getSubscriptionInfo.Subscriptionuser.length > 0 &&
                getSubscriptionInfo.Subscriptionuser.map((value, key) => {
                  let affiliateStatus = value.status;
                  if (affiliateStatus === 1) {
                    affiliateStatus = "1";
                  } else if (affiliateStatus === 0) {
                    affiliateStatus = "0";
                  }
                  return (
                    <Tr key={key}>
                      <Td
                        data-label={formatMessage(messages.subName)}
                        column={formatMessage(messages.subName)}
                        data={value.name}
                        className={s.userVerticalAlign}
                      />
                      <Td
                        data-label={formatMessage(messages.transactionID)}
                        column={formatMessage(messages.transactionID)}
                        data={value.transactionId}
                        className={s.userVerticalAlign}
                      />
                      <Td
                        data-label={formatMessage(messages.planName)}
                        column={formatMessage(messages.planName)}
                        data={value.planName}
                        className={s.userVerticalAlign}
                      />
                      <Td
                        data-label={formatMessage(messages.purchaseData)}
                        column={formatMessage(messages.purchaseData)}
                        data={value.purchaseDate}
                        className={s.userVerticalAlign}
                      />
                      <Td
                        data-label={formatMessage(messages.expireData)}
                        column={formatMessage(messages.expireData)}
                        data={value.expiryDate}
                        className={s.userVerticalAlign}
                      />
                      <Td
                        data-label={formatMessage(messages.subStatus)}
                        column={formatMessage(messages.subStatus)}
                      >
                        <select
                          name="affiliateStatus"
                          className={cx(
                            bt.commonControlSelect,
                            s.userVerticalAlign,
                            s.btnMarginBottom
                          )}
                          onChange={(e) => this.handleChange(e)}
                          value={affiliateStatus}
                        >
                          <option value="">
                            {formatMessage(messages.selectLabel)}
                          </option>
                          <option value="1">
                            {formatMessage(messages.isActive)}
                          </option>
                          <option value="0">
                            {formatMessage(messages.isNotActive)}
                          </option>
                        </select>
                      </Td>
                    </Tr>
                  );
                })}
            </Table>
          </div>
          <div>
            <CustomPagination
              total={total}
              currentPage={currentPage}
              defaultCurrent={1}
              defaultPageSize={10}
              change={this.paginationData}
              paginationLabel={formatMessage(messages.Subscription)}
            />
          </div>
        </div>
      </div>
    );
  }
}

// export default injectIntl(withStyles(s, bt)(AffiliateTable));
export default compose(
  injectIntl,
  withStyles(s, bt),
  graphql(subscriptionUsers, {
    name: 'getSubscriptionInfo',
    options: (props) => ({
      variables: {
        currentPage: props.currentPage,
        searchList: props.searchList
      },
      fetchPolicy: 'network-only',
    })
  })
)(ManageSubscription);

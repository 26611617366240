import { gql } from 'react-apollo';
import {
  SUBSCRIPTION_PAYMENT_START,
  SUBSCRIPTION_PAYMENT_SUCCESS,
  SUBSCRIPTION_PAYMENT_ERROR,
} from '../../constants';

import { sendPayment } from '../../core/payment/sendPayment';
// Helper
import { convert } from '../../helpers/currencyConvertion';

// Stripe
import { processStripePayment } from '../../core/payment/stripe/processStripePayment';

import { toastr } from 'react-redux-toastr';

export function makesubscriptionPayment(
  userId,
  planId,
  currency,
  paymentType,
  amountType,
  paymentMethodId
          
          
) {

  return async (dispatch, getState, { client }) => {

    dispatch({
      type: SUBSCRIPTION_PAYMENT_START,
      payload: {
        paymentLoading: true
      }
    });

    try {

      const mutation = gql`
      mutation createSubscription(
        $userId: String,
        $planId: Int,
        $currency: String,
        $paymentType: Int,
        $total: Float,
        $amountType: String
      ){
          createSubscription(
            userId: $userId,
            planId: $planId,
            currency: $currency,
            paymentType: $paymentType,
            total: $total,
            amountType: $amountType
          ) {
              results{
              id
            userId
            paymentMethodId
            title
            currency
            total
            planId
            email
              }
            
            errormessage
          }
      }
      `;


      const { data } = await client.mutate({
        mutation,
        variables: {
          userId:userId.userId,
          planId:userId.planId,
          currency:userId.currency,
          paymentType:userId.paymentType,
          total:userId.amount,
          amountType:userId.amountType,
        }
      })

      // console.log(variables,"data---------------------")
      console.log(data,"data---------------------")

      if (data) {
        let reservationId = data.createSubscription.results.id;
        let amount = userId.amount;
        let title = data.createSubscription.results.title;
        let paymentType = userId.paymentType;
        let paymentCurrency = userId.currency;
        let listId = data.createSubscription.results.planId;
        let guestEmail = data.createSubscription.results.email;
        let guestId = userId.userId;
        let couponDiscount = userId.couponDiscount
        /////////////////// APPLYING COUPON CODE /////////////////////
        if (couponDiscount > 0) {
          let discount = (Number(amount) * Number(couponDiscount)) / 100;
          amount = amount - discount
        }
        // console.log("----------------------------------------------------------------reservationId",reservationId);
        // console.log("----------------------------------------------------------------amount",amount);
        // console.log("----------------------------------------------------------------title",title);
        // console.log("----------------------------------------------------------------paymentType",paymentType);
        let rates = getState().currency.rates;
        let currentCurrency = (getState().currency.to) ? getState().currency.to : getState().currency.base;
        let baseCurrency = getState().currency.base;
        let convertedAmount = 0;

        // let overAllAmount = amount && amount.toString().split(".")
        // let isAmount = 0;
        // if (overAllAmount && overAllAmount[1] == "00") {
        //   isAmount = overAllAmount && overAllAmount[0];
        //   // isAmount = Math.round(amount);
        // } else {
        //   isAmount = amount;
        // }

        if (paymentType == 1) {
          
          convertedAmount = convert(baseCurrency, rates, amount, "USD", paymentCurrency);
          // console.log("----------------------------------------------------------------hello reservationId",reservationId);
          // console.log("----------------------------------------------------------------hello world",convertedAmount);
          // console.log("----------------------------------------------------------------hello paymentCurrency",paymentCurrency);
          // console.log("----------------------------------------------------------------hello title",title);
          // convertedAmount = convert(baseCurrency, rates, amount, currency, "USD");
          const { status, errorMessage } = await sendPayment(reservationId, convertedAmount.toFixed(2), paymentCurrency, title,"subscription");
          if (status === 200) {
            await dispatch({
              type: SUBSCRIPTION_PAYMENT_SUCCESS,
              payload: { paymentLoading: true }
            });
          } else {
            errorMessage ? toastr.error('Failed!', errorMessage) : '';
            await dispatch({
              type: SUBSCRIPTION_PAYMENT_ERROR,
              payload: { paymentLoading: false }
            });
          }
        } 
        else {
          convertedAmount = convert(baseCurrency, rates, amount, "USD", currentCurrency)
          let cardDetails = {

          };
          // let cardDetails = {
          //   name,
          //   number: cardNumber,
          //   exp_month: expiryDate,
          //   exp_year: expiryYear,
          //   cvc: cvv
          // };
          let reservationDetails = {
            reservationId,
            listId,
            hostId:guestId,
            guestId,
            guestEmail,
            title,
            amount: convertedAmount.toFixed(2),
            currency: currentCurrency
          };
          const { status, errorMessage, paymentIntentSecret } = await processStripePayment(
            'subscription',
            cardDetails,
            reservationDetails,
            userId.paymentMethodId
          );

          if (status === 200) {
            await dispatch({
              type: SUBSCRIPTION_PAYMENT_SUCCESS,
              payload: { paymentLoading: true }
            });

            return {
              status
            }

          } else {
            errorMessage ? toastr.error('Failed!', errorMessage) : '';
            await dispatch({
              type: SUBSCRIPTION_PAYMENT_ERROR,
              payload: { paymentLoading: false }
            });
            return {
              status,
              paymentIntentSecret,
              reservationId
            }
          }
        }
      }
    } catch (error) {
      dispatch({
        type: SUBSCRIPTION_PAYMENT_ERROR,
        payload: {
          error,
          paymentLoading: false
        }
      });
      return false;
    }

    return true;
  };
}


import React from 'react';
import Layout from '../../../components/Layout';
import Page from '../../../components/Page';
import fetch from '../../../core/fetch';
import Privacy from './Privacy';
const query = `query getEditStaticPage ($pageName: String) {
  getEditStaticPage (pageName: $pageName) {
      id
      pageName
      content
      createdAt
      metaTitle
      metaDescription
  }
}`;

export default async function action({ locale }) {
  // const dataResult = await new Promise((resolve) => {
  //   require.ensure([], (require) => {
  //     try {
  //       resolve(require(`./privacy.${locale}.md`));
  //     } catch (e) {
  //       resolve(require('./privacy.md'));
  //     }
  //   }, 'privacy');
  // });

  // const resp = await fetch('/graphql', {
  //   method: 'post',
  //   headers: {
  //     Accept: 'application/json',
  //     'Content-Type': 'application/json',
  //   },
  //   body: JSON.stringify({
  //     query: query,
  //     variables: { pageName:"Terms & Privacy" },
  //   }),
  //   credentials: 'include',
  // });

  // const { data } = await resp.json();

  // if (data && data.getEditStaticPage) {
    return {
      title: 'Terms & Privacy',
      // description: data.getEditStaticPage.metaDescription,
      component: <Layout><Privacy  /></Layout>,
    };

  // } else {
  //   return {
  //     title: dataResult.title,
  //     component: <Layout><Page {...dataResult} /></Layout>,
  //   };
  // }
}

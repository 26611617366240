import React from 'react';
import Layout from '../../../components/Layout';
import Page from '../../../components/Page';
import fetch from '../../../core/fetch';
import About from './About';

const query = `query getEditStaticPage ($pageName: String) {
  getEditStaticPage (pageName: $pageName) {
      id
      pageName
      content
      createdAt
      metaTitle
      metaDescription
  }
}`;

export default async function action({ locale }) {
  const dataResult = await new Promise((resolve) => {
    require.ensure([], (require) => {
      try {
        resolve(require(`./about.${locale}.md`));
      } catch (e) {
        resolve(require('./about.md'));
      }
    }, 'about');
  });

  // const resp = await fetch('/graphql', {
  //   method: 'post',
  //   headers: {
  //     Accept: 'application/json',
  //     'Content-Type': 'application/json',
  //   },
  //   body: JSON.stringify({
  //     query: query,
  //     variables: { pageName: "About Us" },
  //   }),
  //   credentials: 'include',
  // });

  // const { data } = await resp.json();

  // if (data && data.getEditStaticPage) {
    // console.log("data getEditStaticPage",data);
    return {
      title:"About Us",
      // description: data.getEditStaticPage.metaDescription,
      component: <Layout><About /></Layout>,
    };

  // } else {
  //   return {
  //     title: dataResult.title,
  //     component: <Layout><Page {...dataResult} /></Layout>,
  //   };
  // }
}

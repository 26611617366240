// import React, { Component } from 'react'
// import PlanContainer from './hostMemberShip'

// class HostMembership extends Component {
//   constructor(props) {
//     super(props);
//   }

//   render() {
//     return (
//       <div>
//         <PlanContainer />
//       </div>
//     );
//   }
// }

// export default HostMembership
import React, { Component } from 'react'
import PlanContainer from './hostMemberShip'
const GET_RECEIPT_DATA = `query getReceiptPayment($userId: String,){
    getReceiptPayment(userId:$userId){
        errormessage
        status
            data{
                id
                transactionId
                total
                currency
                createdAt
                reservationId
                expiryDate
                planType
                planId
                paymentType
            }
    }
}`
class HostMembership extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planExpire:""
    };
  }
   async componentDidMount(){
       const {userId} = this.props

        const response = await fetch("/graphql", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              query: GET_RECEIPT_DATA,
              variables: {
                userId:userId,
              },
            }),
            
          });
    const data = await response.json();
    // this.setState({planExpire:moment(data.data.getReceiptPayment.data.expiryDate, "YYYY-MM-DD, HH:mm:ss").format("MMM D, YYYY")})
    this.setState({planExpire:data.data.getReceiptPayment.data.expiryDate})
    // console.log("GET_RECEIPT_DATA",data.data.getReceiptPayment.data.expiryDate);
    }
  render() {
        const {userId} = this.props
        const {planExpire}=this.state;
    return (
      <div>
        <PlanContainer userId={userId} planExpire={planExpire}/>
      </div>
    );
  }
}

export default HostMembership
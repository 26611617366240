import messages from "../../locale/messages";

const validate = (values) => {
  const errors = {};

  if (!values.fullName) {
    errors.fullName = messages.required;
  } else if (values.fullName.trim() == "") {
    errors.fullName = messages.required;
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = messages.required;
  } else if (values.phoneNumber.trim() == "") {
    errors.phoneNumber = messages.required;
  }

  if (!values.email) {
    errors.email = messages.required;
  } else if (values.email.trim() == "") {
    errors.email = messages.required;
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(values.email)) {
    errors.email = messages.emailInvalid;
  }

  if (!values.units) {
    errors.units = messages.required;
  } else if (values.units.trim() == "") {
    errors.units = messages.required;
  }

  if (!values.country) {
    errors.country = messages.countryRequired;
  }

  return errors;
};

export default validate;

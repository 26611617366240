// // Fetch request
// import fetch from '../core/fetch';

// export async function getSpecificConfig({ name }) {

// 	try {

// 		const query = `query getConfigSettings($name: String) {
//             getConfigSettings(name: $name) {
//                 title
//                 name
//                 value
//             }
//         }`;

// 		const resp = await fetch('/graphql', {
// 			method: 'post',
// 			headers: {
// 				Accept: 'application/json',
// 				'Content-Type': 'application/json'
// 			},
// 			body: JSON.stringify({
// 				query: query,
// 				variables: {
// 					name: JSON.stringify(name)
// 				}
// 			}),
// 			credentials: 'include'
// 		});

// 		const { data } = await resp.json();

// 		let settingsData = {};

// 		if (settingsData) {
// 			await Promise.all(data.getConfigSettings.map((item, key) => {
// 				settingsData[item.name] = item.value;
// 			}));
// 		}

// 		return await settingsData;

// 	} catch (error) {
// 		return false;
// 	}

// }
// Fetch request
import fetch from '../core/fetch';

export async function getSpecificConfig({ name }) {

	try {

		// const query = `query getConfigSettings($name: String) {
        //     getConfigSettings(name: $name) {
        //         title
        //         name
        //         value
        //     }
        // }`;
		const query = `query ($type: String) {
			siteSettings(type: $type) {
			siteName
			siteTitle
			metaKeyword
			metaDescription
			facebookLink
			twitterLink
			instagramLink 
			logoHeight 
			logoWidth 
			homePageType 
			videoLink 
			deepLinkBundleId
			maxUploadSize
			phoneNumberStatus 
			homeLogoHeight 
			homeLogoWidth 
			appAvailableStatus 
			playStoreUrl
			appStoreUrl 
			email 
			phoneNumber 
			address 
			listingApproval 
			faviconLogo 
			cancellationInfo 
			androidVersion 
			appForceUpdate 
			iosVersion 
			 emailLogo 
			 Logo 
			 homeLogo
			 websiteLink
			}
		  }
		  `;

		const resp = await fetch('/graphql', {
			method: 'post',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				query: query,
				variables: {
					type: 'site_settings'
				}
			}),
			credentials: 'include'
		});

		const { data } = await resp.json();

		let settingsData = {};

		if (data.siteSettings) {
			settingsData=data.siteSettings[0]
			// await Promise.all(data.getConfigSettings.map((item, key) => {
			// 	settingsData[item.name] = item.value;
			// }));
		}

		return await settingsData;

	} catch (error) {
		return false;
	}

}

import React, { Component } from "react";
import s from "./host.css";

class ToggleButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: true,
    };
    this.callback = this.callback.bind(this);
  }
  callback() {
    const { buttonId } = this.props;
    this.setState({ toggle: !this.state.toggle });
    this.props.fromToggle(!this.state.toggle);
    this.props.fromToggleId(buttonId);
  }

  render() {
    const { toggleColor } = this.props;
    return (
      <div
        className={s.toggle}
        onClick={this.callback}
        style={{ background: toggleColor }}
      >
        {this.state.toggle == true ? (
          <div className={s.toggleLeft} />
        ) : (
          <div className={s.toggleRight} />
        )}
      </div>
    );
  }
}

export default ToggleButton;

// import React from 'react';

// // Style

// // Component

// import Layout from '../../../components/Layout';
// import Page from '../../../components/Page';
// class About extends React.Component {
//   render() {
// const {title,html} = this.props
// console.log("title",title);
//     return (
//         <Page title={title} html={html}/>
//     )
//   }
// }

// // export default (About);
// export default compose(
//   graphql(getWhyHostPageSettings, {
//     options: {
//       fetchPolicy: 'network-only',
//       ssr: false
//     }
//   })
// )(About);
import React from 'react';
import { graphql, compose } from 'react-apollo';
// Style

// Component
import GetEditStaticPage from "./GetEditStaticPage.graphql";
import Layout from '../../../components/Layout';
import Page from '../../../components/Page';
class About extends React.Component {
  render() {
const {getEditStaticPage:{getEditStaticPage}} = this.props
// console.log("getSuggested",getEditStaticPage);
    return (
        <Page html={getEditStaticPage && getEditStaticPage.content} title={"Legal Disclaimer"}/>
    )
  }
}

export default compose(
    graphql(GetEditStaticPage, {
        name: "getEditStaticPage",
        options: (props) => ({
          variables: {
            pageName: "Travel Credit",
          },
          fetchPolicy: "network-only",
        }),
    })
  )(About);

import React from 'react';
import AdminLayout from '../../../components/Layout/SubadminLayout';
import UserReviews from './UserReviews';
import { restrictUrls } from '../../../helpers/adminPrivileges';


const title = 'Users Reviews';

export default async function action({ store }) {

    // From Redux Store
    let issubAdminAuthenticated = store.getState().runtime.issubAdminAuthenticated;
    let adminPrivileges = store.getState().adminPrevileges.privileges && store.getState().adminPrevileges.privileges.privileges;


    if (!issubAdminAuthenticated) {
        return { redirect: '/subadmin/login' };
    }

    // Admin restriction
    // if (!restrictUrls('/subadmin/user-reviews', adminPrivileges)) {
    //     return { redirect: '/subadmin' };
    // }

    return {
        title,
        component: <AdminLayout><UserReviews title={title} /></AdminLayout>,
    };
}

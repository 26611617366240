import React from 'react';
import HeadLessLayout from '../../../components/Layout/HeadLessLayout';
import SubadminLogin from './SubAdminLogin';

const title = 'Sub Admin Log In';

export default function action(context) {

  // From Redux Store
  let isSubAdminAuthenticated = context.store.getState().runtime.issubAdminAuthenticated;

  if (isSubAdminAuthenticated) {
    return { redirect: '/subadmin' };
  }

  return {
    title,
    component: <HeadLessLayout><SubadminLogin title={title} /></HeadLessLayout>,
  };
}

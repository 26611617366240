import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Table, TBody, TR, TD } from 'oy-vey';
import Layout from '../layouts/Layout';
import Header from '../modules/Header';
import Body from '../modules/Body';
import Footer from '../modules/Footer';
import EmptySpace from '../modules/EmptySpace';
import { url, sitename } from '../../../config';
import { PRIMARYCOLOR } from '../../../constants';

class SubadminWebsiteLink extends React.Component {

  // static propTypes = {
  //   content: PropTypes.shape({
  //     // receiverName: PropTypes.string.isRequired,
  //     userType: PropTypes.string.isRequired,
  //     senderName: PropTypes.string.isRequired,
  //     message: PropTypes.string.isRequired,
  //     threadId: PropTypes.number.isRequired,
  //   }).isRequired
  // };

  render() {
    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };

    const btnCenter = {
      textAlign: 'center'
    }

    const buttonStyle = {
      margin: 0,
      fontFamily: 'Arial',
      padding: '10px 16px',
      textDecoration: 'none',
      borderRadius: '2px',
      border: '1px solid',
      textAlign: 'center',
      verticalAlign: 'middle',
      fontWeight: 'bold',
      fontSize: '18px',
      whiteSpace: 'nowrap',
      background: '#ffffff',
      borderColor: PRIMARYCOLOR,
      backgroundColor: PRIMARYCOLOR,
      color: '#ffffff',
      borderTopWidth: '1px',

    }


    const { content: { websiteLink,logo },currentWeb,websiteTitle } = this.props;

    return (
      <Layout>
      <Header color={PRIMARYCOLOR} backgroundColor="#F7F7F7"  websiteTitle={websiteTitle} logo={logo}/>
      <Body textStyle={textStyle}>
        <div>
          Hi User,
        </div>
        <EmptySpace height={20} />
        <div>
          Welcome to {websiteTitle}!
           <p>Thank you for submitting your domain link, Your website will be live in less than one Hour</p> <br />
           <p>You have provided this link</p> <span><h1>{websiteLink}</h1></span> 
        </div>
        <EmptySpace height={40} />
        <EmptySpace height={30} />
        <div>
          Thanks, <br />
          The {websiteTitle} Team
        </div>
      </Body>
      <Footer  websiteTitle={websiteTitle}/>
      <EmptySpace height={20} />
    </Layout>
    );
  }

}

export default SubadminWebsiteLink;